import React, { useState, useEffect } from 'react';
import BackendTable from 'react-backend-table';
import { Link } from "react-router-dom";
import { KTSVG } from '../../_metronic/helpers';
import Swal from 'sweetalert2';
import 'react-loading-skeleton/dist/skeleton.css';

function PageList() {
	const [reloadMyTable, setReloadMyTable] = useState(false);

	let columns = [
		{ title: 'Id', field: 'idPages', sortable: true, searchable: true, thStyle: { textAlign: "center" }, tdStyle: { textAlign: "center" } },
		{ title: 'Parent Name', field: 'parentName', sortable: true, searchable: true },
		{ title: 'Pages Title', field: 'pagesTitle', sortable: true, searchable: true },
		{ title: 'Pages Link', field: 'pagesLink', searchable: true, sortable: true },
		{ title: 'Icon Path', field: 'iconPath', sortable: true, searchable: true },
		{ title: 'Pages Type', field: 'pagesType', sortable: true, searchable: true },
		{ title: 'Is Show', field: 'isShow', sortable: true, searchable: true },
		{
			title: 'Status',
			field: 'activeStatus',
			sortable: true,
			searchable: true,
			tdClass: "text-center",
			hasComponent: true,
			componentValue: ((rowValue) => {
				return (rowValue.activeStatus === "Active" ? <span className="badge badge-light-primary fw-bolder">Active</span> : <span className="badge badge-light-danger fw-bolder">Inactive</span>)
			})
		},
		{ title: 'Sequence', field: 'sequence', sortable: true, searchable: true },
		{
			title: "Action",
			field: "action",
			thStyle: { textAlign: "center" },
			tdStyle: { whiteSpace: 'nowrap' },
			sortable: false,
			searchable: false,
			hasComponent: true,
			componentValue: ((rowValue) => {
				return (
					<>
						<Link to={{ pathname: '/accesscontrol-pagemanagement', info: { idPages: rowValue.idPages } }}>
							<button className="btn btn-light-primary btn-sm">
								<KTSVG
									path='/media/svg/icons/Communication/Write.svg'
									className='svg-icon-5 svg-icon'
								/>		Edit
							</button>
						</Link>
						<button className="btn btn-light-danger btn-sm" onClick={() => deletePage(rowValue.idPages)}>
							<KTSVG
								path='/media/svg/icons/General/Trash.svg'
								className='svg-icon-5 svg-icon'
							/>		Delete
						</button>
					</>

				)
			})
		}
	]
	const jwt = () => {
		return JSON.parse(localStorage.getItem('MyToken'))
	}

	let options = {
		url: process.env.REACT_APP_API_URL + 'pages_list',
		authorization: 'Bearer ' + jwt(),
		perPage: [10, 20, 50, 100],
		orderBy: 'idPages',
		orderType: 'desc',
		columnSearch: true,
	}

	useEffect(() => {
		if (reloadMyTable === true) {
			options.reloadMyTable();
		}
		setReloadMyTable(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reloadMyTable])

	const deletePage = (idPages) => {
		Swal.fire({
			title: 'Are you sure?',
			text: "You want to delete this page!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No',
			confirmButtonColor: '#ec008c'

		}).then((result) => {
			if (result.value) {
				const jwt = () => { return JSON.parse(localStorage.getItem('MyToken')); }
				const requestOptions = {
					method: 'DELETE',
					headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt().token },
				};
				var apiEnd = "page_delete/" + idPages;
				fetch(process.env.REACT_APP_API_URL + apiEnd, requestOptions)
					.then((resp) => {
						return resp.json()
					})
					.then((resp) => {
						console.log(resp);
						if (resp.success === true) {
							Swal.fire({
								icon: 'success',
								title: 'Success',
								text: "Page deleted successfully",
							})
							setReloadMyTable(true);
						}
						else {
							Swal.fire({
								icon: 'error',
								title: 'Error',
								html: resp.errorMessage
							})
						}
					})
					.catch((error) => {
						console.log(error, "catch the hoop")
					});
			}
		})
	}

	return (
		<>
			<div className='card card-custom'>
				<div className='card-header'>
					<div className='card-title'>
						<span>
							<KTSVG
								path='/media/svg/icons/Text/Bullet-list.svg'
								className='svg-icon-1 svg-icon-primary'
							/>
						</span>
						<span className='text fw-bolder' style={{ marginLeft: '10px' }}>
							PAGES LIST
						</span>
					</div>
					<div className="card-toolbar">
						<Link to={`/accesscontrol-pagemanagement`}>
							<button className="btn btn-sm btn-success btn-active-primary"><KTSVG
								path='/media/svg/icons/Code/Plus.svg'
								className='svg-icon-3 svg-icon-primary'
							/>New Page</button>
						</Link>
					</div>
				</div>

				<div className='ml-12'>
					<BackendTable columns={columns} options={options} />
				</div>
			</div>
		</>
	)
}

export default PageList