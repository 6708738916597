import { useEffect, useState } from 'react'
import DataTable from '../components/DataTable';
import { KTSVG } from '../../_metronic/helpers'
import 'react-loading-skeleton/dist/skeleton.css';
import { Link } from 'react-router-dom';
import { getRequestOptions } from '../helpers/Fetchwrapper';
import Select from 'react-select';

function SalesQuotation() {


    const hasPermission = 1; //parseInt(GetPagePermission("/setup-company-list"));

    const [selectProjectOptions, setSelectProjectOptions] = useState([])
    const [project, setProject] = useState({ label: 'Select Project', value: '' });

    const handleProjectChange = (project: any) => {
        setProject(project);

    }

    const getProjectList = async () => {
        const url = process.env.REACT_APP_API_URL + 'projects';

        const response = await fetch(url, getRequestOptions());

        const data = await response.json();

        data.data.map((element: any) => {
            element.value = element.id_projects;
            element.label = element.project_name;
            return element;
        })

        if (data.data.length === 1) {
            setSelectProjectOptions(data.data)
        } else {
            var allProjectsOpt = [{ id: '', name: '', label: 'All Project', value: '' }]
            let allProjects = data.data.concat(allProjectsOpt);
            setSelectProjectOptions(allProjects);
        }
    }

    useEffect(() => {
        getProjectList();
    }, [])

    useEffect(() => {
        if (selectProjectOptions.length === 1) {
            setProject({ label: selectProjectOptions[0]['label'], value: selectProjectOptions[0]['value'] })
        }
    }, [selectProjectOptions])

    const columns = [
        { value: 'id', title: 'ID', style: { textAlign: 'center' } },
        { value: 'projectName', title: 'Project', style: { textAlign: 'left' } },
        { value: 'leadName', title: 'Lead', style: { textAlign: 'left' } },
        { value: 'quotationDate', title: 'Date', style: { textAlign: 'left' } },
        { value: 'referenceNo', title: 'Reference No', style: { textAlign: 'left' } },
        { value: 'createdBy', title: 'Created By', style: { textAlign: 'left' } },
        {
            value: 'action', title: 'Action', style: { textAlign: 'left', whiteSpace: 'nowrap' }, hasActionsComponent: true, actionsComponent: ((row: any) => {
                return (
                    <>
                        <a className="btn btn-light-primary btn-sm" target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_BACKEND}quotationDownload/${row.idSalesQuotations}`}>
                            <KTSVG
                                path='/media/svg/icons/Files/Downloads-folder.svg'
                                className='svg-icon-5 svg-icon'
                            />		Download
                        </a>

                    </>
                )
            })
        }
    ]

    const filters = {
        id: {
            filterType: 'like',
            filterValue: '',
        },
        projectName: {
            filterType: 'like',
            filterValue: '',
        },
        leadName: {
            filterType: 'like',
            filterValue: '',
        },
        quotationDate: {
            filterType: 'remarks',
            filterValue: '',
        },
        referenceNo: {
            filterType: 'remarks',
            filterValue: '',
        },
        createdBy: {
            filterType: 'remarks',
            filterValue: '',
        },
    }

    const url = process.env.REACT_APP_API_URL + 'quotation/dt'

    useEffect(() => {
        setAdditionalPostData({ idProjects: project.value })
    }, [project])

    const [additionalPostData, setAdditionalPostData] = useState({ idProjects: '' });

    return (
        <>
            {hasPermission === 1 &&
                <div className='card card-custom'>
                    <div className='card-header'>
                        <div className="card-title">
                            <KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-1 svg-icon-primary' />
                            <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
                                Sales Quotations
                            </span>
                        </div>
                        <div className="card-toolbar">
                            <div className='d-flex justify-content-end'>
                                <Select
                                    // defaultValue={selectProjectOptions}
                                    onChange={handleProjectChange}
                                    options={selectProjectOptions}
                                    placeholder={project.label}
                                />
                                &nbsp;
                                <Link to={`/sales-quotation-create`} className="btn btn-sm btn-primary"><i className="fa fa-plus"></i>Create New Quotation</Link>
                            </div>
                        </div>
                    </div>

                    <div className='card-body'>
                        <DataTable url={url} columns={columns} filters={filters} additionalPostData={additionalPostData} />
                    </div>
                </div >
            }


            {/* {hasPermission === 0 &&
                <Error />
            }
            {hasPermission === 0 &&
                <div className='card card-custom' style={{ padding: "15px" }} >
                    <SkeletonTheme>
                        <p>
                            <Skeleton
                                containerClassName="avatar-skeleton"
                                count={40} ></Skeleton>
                        </p>
                    </SkeletonTheme>
                </div>
            } */}

        </>
    )
}

export default SalesQuotation
