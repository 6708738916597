import React, { useEffect, useState, useContext } from 'react'
import { KTSVG } from '../../_metronic/helpers'
import { Row, Col, Card, Button } from 'react-bootstrap'
import { getRequestOptions } from '../helpers/Fetchwrapper'
import Select from 'react-select';
import { AppContext } from '../context/AppContext';

interface Company {
    company_name: string;
}

interface AssignedTo {
    first_name: string;
    last_name: string;
}

interface Leads {
    lead_name: string;
    priority: string;
    company: Company;
    estimated_value: number;
    assigned_to?: AssignedTo;
}

interface LeadStageStatus {
    id_leads: number;
    id_lead_stage_status: number;
    id_project_stages: number;
    leads: Leads
}

interface ProjectStages {
    id_project_stages: number;
    stage_name: string;
    lead_stage_status?: LeadStageStatus[]
}

const Projects: React.FC = () => {

    const { currentUser } = useContext(AppContext);
    const [selectProjectOptions, setSelectProjectOptions] = useState([])
    const [selectedProject, setSelectedProject] = useState<{ value: string; label: string; } | null>(null);
    const [stages, setStages] = useState<ProjectStages[]>([]);
    const [movedCardId, setMovedCardId] = useState<number | null>(null);
    const [srs, setSrs] = useState([]);
    const [sr, setSr] = useState({ label: 'Select SR', value: '' });

    const getProjectList = async () => {
        const url = process.env.REACT_APP_API_URL + 'projects';

        const response = await fetch(url, getRequestOptions());

        const data = await response.json();

        data.data.map((element: any) => {
            element.value = element.id_projects;
            element.label = element.project_name;
            return element;
        })

        setSelectProjectOptions(data.data);
        setSelectedProject(data.data[0])
    }

    const getProjectSrs = (idProjects: string) => {
        fetch(
            process.env.REACT_APP_API_URL + 'getAllSr?idProjects=' + idProjects,
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {

                let srsObj = resp.map((item: any) => {
                    item.label = item.first_name + ' ' + item.last_name;
                    item.value = item.id;
                    return item
                })

                var dd = [{ id: '', first_name: '', last_name: '', label: 'All SR', value: '' }]
                let allUsers = srsObj.concat(dd);

                setSrs(allUsers);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const getProjectStages = async (idProject: string, idSr: string = '') => {

        const url = process.env.REACT_APP_API_URL + 'projects/stages/' + idProject + '?idSr=' + idSr;
        const response = await fetch(url, getRequestOptions());
        const data = await response.json();

        // console.log(data.data);
        setStages(data.data);
    }

    const handleSrChange = (sr: any) => {
        // console.log(selectedProject)
        getProjectStages(selectedProject.value, sr.value);
        setSr(sr);
    }

    useEffect(() => {
        getProjectList();
    }, [])

    useEffect(() => {
        if (selectedProject) {
            getProjectStages(selectedProject.value);
            getProjectSrs(selectedProject.value);
            setSr({ label: 'Select SR', value: '' });
        }

    }, [selectedProject])

    useEffect(() => {
        if (selectProjectOptions.length === 1) {
            setSelectedProject({ label: selectProjectOptions[0]['label'], value: selectProjectOptions[0]['value'] })
        }
    }, [selectProjectOptions])

    const movePrevious = async (idStage: number) => {
        if (!selectedProject) return;

        const url = `${process.env.REACT_APP_API_URL}projects/stages/move/previous/${idStage}/${selectedProject.value}`;

        const response = await fetch(url, getRequestOptions());

        const data = await response.json();

        console.log(data.data);
        await getProjectStages(selectedProject.value, sr.value);
        setMovedCardId(idStage);

        setTimeout(() => {
            setMovedCardId(null);
        }, 3000);
    }

    const moveNext = async (idStage: number) => {
        if (!selectedProject) return;

        const url = `${process.env.REACT_APP_API_URL}projects/stages/move/next/${idStage}/${selectedProject.value}`;

        const response = await fetch(url, getRequestOptions());

        const data = await response.json();

        console.log(data.data);
        await getProjectStages(selectedProject.value, sr.value);
        setMovedCardId(idStage);

        setTimeout(() => {
            setMovedCardId(null);
        }, 3000);
    }

    return (
        <div className='card card-custom'>
            <div className='card-header'>
                <div className='card-title'>
                    <KTSVG
                        path='/media/svg/icons/Text/Bullet-list.svg'
                        className='svg-icon-1 svg-icon-primary'
                    />
                    <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
                        Projects
                    </span>
                </div>
                <div className='card-toolbar'>
                    <div className='d-flex justify-content-end align-items-center'>
                        <Select
                            // defaultValue={selectProjectOptions}
                            onChange={setSelectedProject}
                            options={selectProjectOptions}
                            placeholder={selectedProject?.label}
                        />
                        &nbsp;
                        {(currentUser && currentUser.id_roles !== 1) &&

                            <Select
                                value={sr}
                                onChange={handleSrChange}
                                options={srs}
                                menuPosition="fixed"
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            />
                        }
                        &nbsp;
                        <button type='button' className='btn btn-sm btn-primary'>
                            <i className='fa fa-plus'></i>Add Lead
                        </button>
                    </div>
                </div>
            </div>

            <div className='card-body bg-secondary' style={{ minHeight: "80vh" }}>
                <div style={{ overflowX: 'auto' }}>
                    <Row style={{ display: 'flex', flexWrap: 'nowrap', marginLeft: '0', marginRight: '0' }}>
                        {selectedProject &&
                            stages.map((stage, stageIndex) => (
                                <Col key={stageIndex} style={{ paddingLeft: '0', flex: '0 0 250px' }}>
                                    <Card>
                                        <Card.Header style={{ minHeight: "35px" }} as={'h5'}>{stage.stage_name}({stage.lead_stage_status && stage.lead_stage_status.length})</Card.Header>
                                        <Card.Body style={{ padding: '0' }}>
                                            {stage.lead_stage_status &&
                                                stage.lead_stage_status.map((stageStatus, index) => (
                                                    <Row>
                                                        <Col>
                                                            <Card className={`m-1 mb-2 bg-secondary text-dark`}>
                                                                <Card.Body style={stageStatus.id_lead_stage_status === movedCardId ? { backgroundColor: 'antiquewhite' } : {}}>
                                                                    <Card.Title className='mb-1'><a href={"/#/lead-details?idLeads=" + stageStatus.id_leads}>{stageStatus.leads.lead_name}</a></Card.Title>
                                                                    <Card.Text>{stageStatus.leads.company.company_name}</Card.Text>
                                                                    <Card.Text className='mb-1'> {stageStatus.leads.assigned_to?.first_name + ' ' + stageStatus.leads.assigned_to?.last_name} </Card.Text>
                                                                    {stageIndex > 0 && (
                                                                        <Button
                                                                            style={{ float: 'left' }}
                                                                            size='sm'
                                                                            variant='light'
                                                                            onClick={() =>
                                                                                movePrevious(stageStatus.id_lead_stage_status)
                                                                            }>
                                                                            <i className='fa fa-arrow-left'></i>
                                                                        </Button>
                                                                    )}
                                                                    {stageIndex + 1 < stages.length && (
                                                                        <Button
                                                                            style={{ float: 'right' }}
                                                                            size='sm'
                                                                            variant='light'
                                                                            onClick={() =>
                                                                                moveNext(stageStatus.id_lead_stage_status)
                                                                            }>
                                                                            <i className='fa fa-arrow-right'></i>
                                                                        </Button>
                                                                    )}
                                                                </Card.Body>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                ))}
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default Projects
