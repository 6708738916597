import React, { useContext, useEffect } from 'react'
import { AppContext } from '../context/AppContext'
import DataTable from '../components/DataTable';
import { KTSVG } from '../../_metronic/helpers'
import 'react-loading-skeleton/dist/skeleton.css';
import { Link } from 'react-router-dom';
import { numberFormat } from '../helpers/GlobalHelper';
import GetPagePermission from "../components/GetPagePermission";
import { deleteRequestOptions } from '../helpers/Fetchwrapper';
import Swal from 'sweetalert2';

function Purchase() {

    const { currentUser } = useContext(AppContext);

    useEffect(() => {

    }, [currentUser])

    const hasPermission = 1; //parseInt(GetPagePermission("/setup-company-list"));
    const receivedEditPermission = GetPagePermission("/good-receive-edit");

  
    const columns = [
        { value: 'id', title: 'ID', style: { textAlign: 'center' } },
        { value: 'projectName', title: 'Project', style: { textAlign: 'left' } },
        { value: 'purchaseType', title: 'Receive Type', style: { textAlign: 'left' } },
        { value: 'referenceNo', title: 'Reference No', style: { textAlign: 'left' } },
        { value: 'vendorName', title: 'Vendor', style: { textAlign: 'left' } },
        { value: 'purchaseDate', title: 'Receive Date', style: { textAlign: 'left' } },
        { 
            value: 'purchaseAmount', title: 'Amount', style: { textAlign: 'center' },
            hasHtmlComponent: true,
            htmlComponent: (row: any) => {
                return (
                    <>
                        {numberFormat(row.purchaseAmount)}
                    </>
                )
             },
        },
        { value: 'createdBy', title: 'Created By', style: { textAlign: 'left' } },
        {
            value: 'action', title: 'Action', style: { textAlign: 'left', whiteSpace: 'nowrap' }, hasActionsComponent: true, actionsComponent: ((row: any) => {
                return (
                    <>
                        <Link className="btn btn-light-primary btn-sm px-2 py-1" to={`/purchase-details/${row.idPurchase}`}>
                            <KTSVG
                                path='/media/svg/icons/Layout/Layout-grid.svg'
                                className='svg-icon-5 svg-icon'
                            />		Details
                        </Link>

                        {receivedEditPermission === 1 && (
                            <Link className="btn btn-light-primary btn-sm px-2 py-1" to={`/purchase-edit/${row.idPurchase}`}>
                                <KTSVG
                                    path='/media/svg/icons/Design/Edit.svg'
                                    className='svg-icon-5 svg-icon'
                                />		Edit
                            </Link>
                        )}
                        
                        {(currentUser?.id === 3 || currentUser?.id === 4 ) && (
                            <button className="btn btn-light-danger btn-sm px-2 py-1" onClick={() => deleteGoodsReceived(row.idPurchase)}>
                                <KTSVG
                                    path='/media/svg/icons/General/Trash.svg'
                                    className='svg-icon-5 svg-icon'
                                />		Delete
                            </button>
                        )}
                        
                    </>
                )
            })
        }
    ]

    const filters = {
        id: {
            filterType: 'like',
            filterValue: '',
        },
        projectName: {
            filterType: 'like',
            filterValue: '',
        },
        purchaseType: {
            filterType: 'like',
            filterValue: '',
        },
        referenceNo: {
            filterType: 'like',
            filterValue: '',
        },
        vendorName: {
            filterType: 'like',
            filterValue: '',
        },
        purchaseDate: {
            filterType: 'like',
            filterValue: '',
        },
        purchaseAmount: {
            filterType: 'like',
            filterValue: '',
        },
        createdBy: {
            filterType: 'like',
            filterValue: '',
        },
    }

    const url = process.env.REACT_APP_API_URL + 'purchase/dt'


    const deleteGoodsReceived = (id: number) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You will not be able to recover this goods received!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it',
            showLoaderOnConfirm: true,
            customClass: {
                confirmButton: 'btn btn-danger me-2',
                cancelButton: 'btn btn-secondary'
            },
            preConfirm: () => {
                return fetch(process.env.REACT_APP_API_URL + 'purchase/delete/' + id, deleteRequestOptions())
                    .then(response => {
                        if (!response.ok) {
                            throw new Error(response.statusText)
                        }
                        return response.json()
                    })
                    .catch(error => {
                        Swal.showValidationMessage(
                            `Request failed: ${error}`
                        )
                    })
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.value.success) {
                    Swal.fire(
                        'Deleted!',
                        'Goods received has been deleted.',
                        'success'
                    )
                    window.location.reload();

                } else {
                    Swal.fire(
                        'Error!',
                        result.value.errors,
                        'error'
                    )
                }
            }
        })
    }


    return (
        <>
            {hasPermission === 1 &&
                <>
                    <div className='card card-custom'>
                        <div className='card-header'>
                            <div className="card-title">
                                <KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-1 svg-icon-primary' />
                                <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
                                    Goods Received List
                                </span>
                            </div>
                            <div className="card-toolbar">
                                <div className='d-flex justify-content-end'>
                                    <Link to={`/purchase-create`} className="btn btn-sm btn-primary"><i className="fa fa-plus"></i>Create New Goods Receive</Link>
                                </div>
                            </div>
                        </div>

                        <div className='card-body'>
                            <DataTable url={url} columns={columns} filters={filters} />
                        </div>
                    </div >
                </>
            }

        </>
    )
}

export default Purchase
