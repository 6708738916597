/* eslint-disable react-hooks/exhaustive-deps */
import { KTSVG } from '../../_metronic/helpers'
import React, { useState, useEffect } from 'react'
import { Table } from 'react-bootstrap-v5';
import Swal from 'sweetalert2'
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper'
import Select, { components } from 'react-select'
import { Link } from 'react-router-dom';
import validationErrorMsg from '../helpers/ValidationErrorMsg'
import { useHistory } from "react-router-dom";

interface IssueRows {
    idItems: number;
    itemCode: string;
    unitName: string;
    stockQuantity: number;
    quantity: number;
    rate: number;
    amount: number;
    [key: string]: any; // Index signature
}

interface Item {
    idItems: number;
    itemName: string;
}

interface Consumption {
    idInvConsumptions: number;
    consumptionTitle: string;
}

interface OpeningData {
    idProjects: number;
    idInvConsumptions: number;
    idAccLedgers?: number;
    issueDate: string;
    remarks: string;
    totalAmount?: number;
}

const LedgerSingleValue = ({ children, ...props }) => (
	<components.SingleValue {...props}>
		<>
			<div className='row'>
				<span> {props.data.label} </span>
			</div>
		</>
	</components.SingleValue>
)


// Custom Option component
const CustomOption = (props) => {
    return (
        <components.Option {...props}>
            <i className="fa fa-file"></i>
            <span> {props.data.label} </span><br />
            {props.data.ledgerPath && (
                <span><i className="fas fa-route"></i> {props.data.ledgerPath} </span>
            )}
        </components.Option>
    );
};



const InventoryStockIssueCreate = () => {

    let history = useHistory();

    const openingData: OpeningData = {
        idProjects: 0,
        idInvConsumptions: 0,
        idAccLedgers: 0,
        issueDate: '',
        remarks: '',
        totalAmount: 0,
    };

    const [projects, setProjects] = useState([]);
    const [project, setProject] = useState({ label: 'Select Project', value: '' });
    const [consumption, setConsumption] = useState({ label: 'Select Consumption', value: '' });
    const [consumptions, setConsumptions] = useState<Consumption[]>([]);

    const [expenseLedger, setExpenseLedger] = useState({ label: 'Select Ledger', value: '' });
    const [expenseLedgers, setExpenseLedgers] = useState<Consumption[]>([]);

    const [issue, setIssue] = useState(openingData);
    const [issueRows, setIssueRows] = useState<IssueRows[]>([{
        idItems: 0,
        itemCode: '',
        unitName: '',
        stockQuantity: 0,
        quantity: 0,
        rate: 0,
        amount: 0,
    }]);
    
    const [items, setItems] = useState<Item[]>([]);
   
    useEffect(() => {
        getProjects();
        getConsumptions();
        getExpenseLedgers();
    }, [])


    const getProjects = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'lead/projects',
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let projectObj = resp.data.map((item: any) => {
                    item.label = item.name;
                    item.value = item.id;
                    return item
                })
                setProjects(projectObj);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const handleProjectChange = (project: any) => {
        setProject(project);
        setIssueRows([{
            idItems: 0,
            itemCode: '',
            unitName: '',
            stockQuantity: 0,
            quantity: 0,
            rate: 0,
            amount: 0,
        }])
        setIssue({ ...issue, idProjects: project.value });
        getItems();
    }

    const handleConsumptionChange = (consumption: any) => {
        setConsumption(consumption);
        
        setIssue({ ...issue, idInvConsumptions: consumption.value });
    }

    const handleExpenseLedgerChange = (ledger: any) => {
        setExpenseLedger(ledger);
        setIssue({ ...issue, idAccLedgers: ledger.value });
    }


    const handleItemChange = async (data: any, index: number) => {

        if(issue.idProjects===0){
            Swal.fire({
                icon: 'error',
                title: 'Select Project',
                text: 'Please select project first',
            })
            return;
        }

        try {
            let itemStockInfo = await getItemStock(data.value, issue.idProjects);
    
    
            const updatedIssueRows = [...issueRows];
            updatedIssueRows[index].idItems = data.value;
            updatedIssueRows[index].itemCode = data.itemCode;
            updatedIssueRows[index].unitName = data.unitName;
            updatedIssueRows[index].stockQuantity = Number(itemStockInfo.stockQuantity); // Assuming itemStockInfo has stockQuantity property
            updatedIssueRows[index].rate = Number(itemStockInfo.avgRate);
            setIssueRows(updatedIssueRows);
        } catch (error) {
            console.error('Error fetching item stock info:', error);
        }
    };

    const getItems = () => {

        fetch(
            process.env.REACT_APP_API_URL + 'production-items',
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {

                let items = resp.data.map((item: any) => {
                    item.label = item.itemName;
                    item.value = item.idItems;
                    return item
                })
                setItems(items);

            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const getItemStock = (idItems: number, idProjects: any) => {
        return fetch(
            process.env.REACT_APP_API_URL + 'item/stock/' + idItems + '?idProjects=' + idProjects,
            getRequestOptions()
        )
        .then((resp) => resp.json())
        .then((resp) => resp.data)
        .catch((error) => {
            console.error('Error fetching item stock:', error);
            throw error; // Rethrow the error to handle it in handleItemChange
        });
    };

    const getConsumptions = () => {

        fetch(
            process.env.REACT_APP_API_URL + 'get-inv-consumptions',
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {

                let consumptions = resp.data.map((item: any) => {
                    item.label = item.consumptionTitle;
                    item.value = item.idInvConsumptions;
                    return item
                })
                setConsumptions(consumptions);

            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }
    
    const getExpenseLedgers = () => {

        fetch(
            process.env.REACT_APP_API_URL + 'overhead/expense-ledgers',
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {

                let consumptions = resp.data.map((item: any) => {
                    item.label = item.ledgerName;
                    item.value = item.idAccLedgers;
                    return item
                })
                setExpenseLedgers(consumptions);

            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const updatedIssueRows = [...issueRows];
        updatedIssueRows[index][e.target.name] = e.target.value;

        const quantity = parseFloat(updatedIssueRows[index].quantity);
        const rate = parseFloat(updatedIssueRows[index].rate);
        updatedIssueRows[index].amount = parseFloat((quantity * rate).toFixed(2));
       
        setIssueRows(updatedIssueRows);
    }

    const deleteItem = (index: number) => {
        const updatedIssueRows = [...issueRows];
        if (updatedIssueRows && updatedIssueRows[index]) {
            updatedIssueRows.splice(index, 1);
            setIssueRows(updatedIssueRows);
        }
    }

    const addItem = (index: number) => {
        const newRow: IssueRows = {
            idItems: 0,
            itemCode: '',
            unitName: '',
            stockQuantity: 0,
            quantity: 0,
            rate: 0,
            amount: 0,
        };

        const updatedRows = [...issueRows];
        updatedRows.push(newRow);

        setIssueRows(updatedRows);
    }

    const handleInputOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIssue({ ...issue, [event.target.name]: event.target.value });
    }

    const calculateTotalAmount = () => {
        let totalAmount = 0;

        for (const row of issueRows) {
            totalAmount += row.amount;
        }
        return totalAmount;
    };

    
    const numberFormat = (value: any) => {

        value = parseFloat(value)
        if (typeof (value) !== 'number') {
            return value;
        }
        return parseFloat(value.toString()).toLocaleString('en-US', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }

    const issueSave = async (event: any) => {
        event.preventDefault()
      
        setIssue({ ...issue, totalAmount: calculateTotalAmount() });

        const confirm = await Swal.fire({
            title: 'Are you sure?',
            text: "You want to create this Stock Issue?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#ec008c',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Yes, Create it!',
        })
        if (confirm.value) {
            const postData = {
                issue: issue,
                issueRows: issueRows,
            }
            const requestOptions = postRequestOptions(postData)
            try {
                let response = await fetch(
                    `${process.env.REACT_APP_API_URL}inventory-stock-issue`,
                    requestOptions
                )
                let respData = await response.json()
                if (respData.success === true) {
                    const successMsg = respData.successMessage
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: successMsg,
                    })

                    history.push("/stock-issue");
                } else {
                    const errorsMessage = validationErrorMsg(respData.errors).join('<br>')
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        html: errorsMessage,
                    })
                }
              
            } catch (error) {
                console.log(error, 'catch the hoop')
            } 
        }
    }

    return (

        <div className="card card-custom">
            <div className="card-header">
                <div className="card-title">
                    <KTSVG
                        path='/media/svg/icons/Home/Book-open.svg'
                        className='svg-icon-1 svg-icon-primary'
                    />
                    <span className="text fw-bolder" style={{ marginLeft: "10px" }}>
                        Stock Issue
                    </span>
                </div>
            </div>

            <div className="ml-12">
                <div className="card-body">
                    
                    <form onSubmit={issueSave}>
                        
                        <div className="form-group row">
                            <label htmlFor="idProjects" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Select Project <span className="required text-danger"></span></label>
                            <div className="col-lg-5">
                                <Select
                                    value={project}
                                    onChange={handleProjectChange}
                                    options={projects}
                                    menuPosition="fixed"
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                />
                            </div>
                        </div>
                        
                        <div className="form-group row">
                            <label htmlFor="issueDate" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Issue Date <span className="required text-danger"></span></label>
                            <div className="col-lg-5">
                                <input type='date' name="issueDate" id="issueDate" onChange={handleInputOnChange} className="form-control form-control-sm" />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="stockType" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Consumption Type</label>
                            <div className="col-lg-5">
                                <Select
                                    value={consumption}
                                    onChange={handleConsumptionChange}
                                    options={consumptions}
                                    menuPosition="fixed"
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="idProjects" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Select Expense Ledger <span className="required text-danger"></span></label>
                            <div className="col-lg-5">
                                <Select
                                    value={expenseLedger}
                                    onChange={handleExpenseLedgerChange}
                                    options={expenseLedgers}
                                    menuPosition="fixed"
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    components={{ SingleValue: LedgerSingleValue, Option: CustomOption }}
                                />
                            </div>
                        </div>
                        
                        <div className="form-group row mb-2">
                            <label htmlFor="remarks" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Remarks</label>
                            <div className="col-lg-5">
                                <input name="remarks" id="remarks" onChange={handleInputOnChange} className="form-control form-control-sm" />
                            </div>
                        </div>

                        <br></br>
                        <Table size='sm' bordered hover>
                            <thead>
                                <tr>
                                    <th style={{ width: '5%' }} className='text-center'>SL</th>
                                    <th style={{ width: '30%' }}>Item Name </th>
                                    <th style={{ width: '10%' }}>Item Code </th>
                                    <th style={{ width: '10%' }}>Stock </th>
                                    <th style={{ width: '10%' }}>Quantity</th>
                                    <th style={{ width: '10%' }}>Rate</th>
                                    <th style={{ width: '10%' }}>Amount</th>
                                    <th style={{ width: '5%' }}></th>
                                </tr>
                            </thead>

                            <tbody>

                                {issueRows.map((row, index) => (
                                    <tr key={'item-'+index}>
                                        <td className='text-center'>{index + 1}</td>
                                        <td>
                                            <Select
                                                placeholder={row.itemName}
                                                onChange={(selectedOption) => handleItemChange(selectedOption, index)}
                                                options={items}
                                                className='sm'
                                                menuPosition="fixed"
                                                styles={{
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        height: '32px',
                                                        minHeight: '32px',
                                                    }),
                                                    container: (provided, state) => ({
                                                        ...provided,
                                                        height: '32px',
                                                    }),
                                                }}
                                            />
                                            
                                        </td>
                                        <td>
                                            <input type="text" name="itemCode" value={row.itemCode} readOnly className="form-control form-control-sm" />
                                        </td>
                                        <td>
                                            <div className='input-group input-group-sm'>
                                                <input type="text" name="stockQuantity" value={row.stockQuantity} readOnly className="form-control form-control-sm" />
                                                <span className='input-group-text'>{row.unitName}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='input-group input-group-sm'>
                                                <input type="number" name="quantity" value={row.quantity} onChange={(event) => handleChange(event, index)} className="form-control form-control-sm" />
                                                <span className='input-group-text'>{row.unitName}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <input type="number" name="rate" value={row.rate} onChange={(event) => handleChange(event, index)} className="form-control form-control-sm" />
                                        </td>
                                        <td>
                                            <input type="number" name="amount" readOnly value={row.amount} className="form-control form-control-sm" />
                                        </td>
                                        <td>
                                            {
                                                issueRows.length > 1 &&
                                                <button type='button' className="btn btn-light-primary btn-sm px-2 py-1" onClick={() => deleteItem(index)}>
                                                    <KTSVG path='/media/svg/icons/General/Trash.svg' className='svg-icon-5 svg-icon-danger me-0' />
                                                </button>
                                            }
                                            
                                            {index === issueRows.length - 1 && (
                                                <button type='button' className="btn btn-success btn-sm px-2 py-1" onClick={() => addItem(index)}>
                                                    <KTSVG path='/media/svg/icons/Navigation/Plus.svg' className='svg-icon-5 svg-icon-danger me-0' /> Add
                                                </button>
                                            )}
                                        </td>
                                    </tr>
                                ))}

                                <tr>
                                    <td colSpan={6} className='text-end'><b>Total Amount:</b></td>
                                    <td><b>{numberFormat(calculateTotalAmount())}</b></td>
                                </tr>
                               
                            </tbody>

                        </Table>

                        <div className="row mt-4">
                            <div className="col-12 text-center">
                                <button type="submit" className="btn btn-sm btn-primary me-2">
                                    <span>  Submit</span>
                                </button>
                                <Link to='/stock-issue' type="reset" className="btn btn-sm btn-secondary">Cancel</Link>
                            </div>
                        </div>
                        {/* <pre>{JSON.stringify(issue, null, 2)}</pre>
                        <pre>{JSON.stringify(issueRows, null, 2)}</pre> */}
                    </form>

                </div>
            </div>
        </div>

    )
}

export default InventoryStockIssueCreate
