import { useState, useEffect } from 'react'
import { KTSVG } from '../../_metronic/helpers'
import { Button } from 'react-bootstrap'
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper';
import showLoader from "../helpers/ShowLoader"
import Swal from 'sweetalert2'
import 'react-loading-skeleton/dist/skeleton.css';
import { useHistory, useParams } from "react-router-dom"
import validationErrorMsg from '../helpers/ValidationErrorMsg'

interface RouteParams {
    id: string;
}

function CompetitorEdit() {

    const initialState = () => ({
        idCompetitors: '',
        competitorName: '',
        remarks: '',
    })
    let history = useHistory();
    const { id }: RouteParams = useParams();
    const [formData, setFormData] = useState(initialState());

    useEffect(() => {
        if (id !== undefined) {
            getCompetitorEditInfoById();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    useEffect(() => {
        // getAllCompetitorCategories();
    }, [])



    const getCompetitorEditInfoById = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'competitors/edit/' + id,
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {

                setFormData({
                    ...formData,
                    idCompetitors: resp.data.id_competitors,
                    competitorName: resp.data.competitor_name,
                    remarks: resp.data.remarks,
                });
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }



    const handleChange = (e: any) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        showLoader()
        const requestOptions = postRequestOptions(formData)
        try {
            let response = await fetch(
                `${process.env.REACT_APP_API_URL}competitors/update`,
                requestOptions
            )
            let respData = await response.json()
            Swal.close();
            if (respData.success === true) {
                const successMsg = respData.successMessage
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: successMsg,
                })
                getCompetitorEditInfoById();
            } else {
                const errorsMessage = validationErrorMsg(respData.errors).join('<br>')
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    html: errorsMessage,
                })
            }

        } catch (error) {
            console.log(error, 'catch the hoop')
            Swal.close();
        }

    }


    return (
        <>
            <div className='card card-custom'>
                <div className='card-header'>
                    <div className="card-title">
                        <KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-1 svg-icon-primary' />
                        <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
                            Edit Competitor
                        </span>
                    </div>
                </div>

                <div className='card-body'>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group row">
                            <label htmlFor="competitorName" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Name <span className="required text-danger"></span></label>
                            <div className="col-lg-5">
                                <input type="text" name="competitorName" id="competitorName" value={formData.competitorName} onChange={handleChange} className="form-control form-control-sm" />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="remarks" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Remarks</label>
                            <div className="col-lg-5">
                                <input type="text" name="remarks" id="remarks" value={formData.remarks} onChange={handleChange} className="form-control form-control-sm" />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-lg-3 col-form-label"></label>
                            <div className="col-md-5">
                                <button type="submit" className="btn btn-sm btn-primary me-2"><span>  Submit</span></button>
                                <Button variant='secondary' size="sm" onClick={() => history.goBack()}>
                                    Close
                                </Button>
                            </div>
                        </div>
                        {/* <pre>{JSON.stringify(unit, null, 2)}</pre> */}
                    </form>
                </div>
            </div>
        </>
    )
}

export default CompetitorEdit
