/* eslint-disable react-hooks/exhaustive-deps */
import { KTSVG } from '../../_metronic/helpers'
import React, { useState, useEffect } from 'react'
import { Table } from 'react-bootstrap-v5';
import Swal from 'sweetalert2'
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper'
import Select from 'react-select'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom';
import validationErrorMsg from '../helpers/ValidationErrorMsg'
import { useHistory } from "react-router-dom";
import { numberFormat } from '../helpers/GlobalHelper';

interface PurchaseRows {
    idPurchaseRows?: number;
    idItems: number;
    itemName?: string;
    poQuantity: number;
    receivedQuantity: number;
    quantity: number;
    rate: number;
    amount: number;
    [key: string]: any; // Index signature
}

interface Item {
    idItems: number;
    itemName: string;
}

interface PurchaseData {
    idPurchase?: number;
    purchaseType: string;
    idProjects: number;
    idPo: number;
    idVendors: number;
    purchaseDate: string;
    referenceNo: string;
    lcNo: string;
    chalanNo: string;
    chalanDate: string;
    remarks: string;
    purchaseAmount: number;
}

interface RouteParams {
    idPurchase: string;
}

const PurchaseEdit = () => {

    let history = useHistory();
    const { idPurchase }: RouteParams = useParams();

    const purchaseData: PurchaseData = {
        idPurchase: parseInt(idPurchase),
        purchaseType: "Local Purchase",
        idProjects: 0,
        idPo: 0,
        idVendors: 0,
        purchaseDate: '',
        referenceNo: '',
        lcNo: '',
        chalanNo: '',
        chalanDate: '',
        remarks: '',
        purchaseAmount: 0
    };

    const [projects, setProjects] = useState([]);
    const [project, setProject] = useState({ label: 'Select Project', value: '' });
    const [po, setPo] = useState({ label: 'Select PO', value: '' });
    const [purchaseOrders, setPurchaseOrders] = useState([]);

    const [vendors, setVendors] = useState([]);
    const [vendor, setVendor] = useState({ label: 'Select Vendor', value: '' });
    const [purchase, setPurchase] = useState(purchaseData);
    const [purchaseRows, setPurchaseRows] = useState<PurchaseRows[]>([{
        idPurchaseRows: 0,
        idItems: 0,
        itemName: '',
        poQuantity: 0,
        receivedQuantity: 0,
        quantity: 0,
        rate: 0,
        amount: 0,
    }]);
    
    const [items, setItems] = useState<Item[]>([]);
 
    useEffect(() => {
        getProjects();
        getVendors();
        getItems();

        if (idPurchase !== undefined) {
            getPurchaseInfo();
        }

    }, [idPurchase])

    const getPurchaseInfo = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'purchase/' + idPurchase+'/edit',
            getRequestOptions()
        )
        .then((resp) => {
            return resp.json()
        })
        .then((resp) => {
            const purchaseRes = {
                ...purchase,
                idPurchase: resp.data.id_purchase,
                purchaseType: resp.data.purchaseType,
                idProjects: resp.data.id_projects,
                idPo: resp.data.id_po,
                idVendors: resp.data.id_vendors,
                purchaseDate: resp.data.purchaseDate,
                referenceNo: resp.data.referenceNo,
                lcNo: resp.data.lcNo,
                chalanNo: resp.data.chalanNo,
                chalanDate: resp.data.chalanDate,
                remarks: resp.data.remarks,
                purchaseAmount: Number(resp.data.purchaseAmount)
            };
            setPurchase(purchaseRes);
            setProject({ label: resp.data.project.projectName, value: resp.data.id_projects });
            setVendor({ label: resp.data.vendor.vendorName, value: resp.data.id_vendors });
            setPo({ label: resp.data.po.referenceNo, value: resp.data.id_po });
            const rows = resp.data.rows.map((item: any) => {
                const row: PurchaseRows = {
                    idPurchaseRows: item.id_purchase_rows,
                    idItems: item.id_items,
                    itemName: item.item.itemName,
                    poQuantity: Number(item.poQuantity),
                    receivedQuantity: Number(item.receivedQuantity),
                    quantity: Number(item.quantity),
                    rate: Number(item.rate),
                    amount: item.rate*item.quantity,
                };
                
                return row
            });
            setPurchaseRows(rows);
            getProjectPurchase(resp.data.id_projects);
            
        })
        .catch((error) => {
            console.log(error, 'catch the hoop')
        })
    }
    
    const getProjects = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'lead/projects',
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let projectObj = resp.data.map((item: any) => {
                    item.label = item.name;
                    item.value = item.id;
                    return item
                })
                setProjects(projectObj);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const getVendors = () => {

        fetch(
            process.env.REACT_APP_API_URL + 'vendors',
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let leadObj = resp.data.map((item: any) => {
                    item.label = item.vendorName;
                    item.value = item.idVendors;
                    return item
                })
                setVendors(leadObj);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const getProjectPurchase = (idProjects: number) => {

        fetch(
            process.env.REACT_APP_API_URL + 'purchase/po/' + idProjects+'?purchaseType='+purchase.purchaseType,
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let poObj = resp.data.map((item: any) => {
                    item.label = item.referenceNo;
                    item.value = item.idPo;
                    return item
                })
                setPurchaseOrders(poObj);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const handleProjectChange = (project: any) => {
        setProject(project);
        setPurchase({ ...purchase, idProjects: project.value });
        getProjectPurchase(project.value);

    }

    const handlePoChange = (po: any) => {
        setPo(po);

        fetch(
            process.env.REACT_APP_API_URL + 'purchase/po-info/' + po.value,
            getRequestOptions()
        )
        .then((resp) => {
            return resp.json()
        })
        .then((resp) => {

            let poRows = [...resp.purchase.rows];
            poRows = poRows.map((row, index) => ({
                idPoRows: row.id_po_rows,
                idItems: row.id_items,
                itemName: row.item.itemName,
                itemCode: row.item.itemCode,
                poQuantity: Number(row.poQuantity),
                receivedQuantity: Number(row.receivedQuantity),
                quantity: Number(row.quantity),
                rate: Number(row.rate),
                amount: Number(row.quantity)*Number(row.rate)
            }));

            setVendor({ label: resp.purchase.vendor.vendorName, value: resp.purchase.id_vendors });
            setPurchase({ ...purchase, idPo: po.value, lcNo: po.lcNo, idVendors: resp.purchase.id_vendors});

            setPurchaseRows(poRows);
            console.log(poRows, 'poRows')

        })
        .catch((error) => {
            console.log(error, 'catch the hoop')
        })

    }

    const handleVendorChange = (vendor: any) => {
        setVendor(vendor);
        setPurchase({ ...purchase, idVendors: vendor.value });
    }

    const handleItemChange = (data: any, index: number) => {
        const updatedPurchaseRows = [...purchaseRows];
        updatedPurchaseRows[index].idItems = data.value;
        updatedPurchaseRows[index].rate = 0;
        setPurchaseRows(updatedPurchaseRows);
    }

    const getItems = () => {

        fetch(
            process.env.REACT_APP_API_URL + 'production-items',
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {

                let items = resp.data.map((item: any) => {
                    item.label = item.itemName;
                    item.value = item.idItems;
                    return item
                })
                setItems(items);

            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const updatedPurchaseRows = [...purchaseRows];
        updatedPurchaseRows[index][e.target.name] = e.target.value;

        const quantity = parseFloat(updatedPurchaseRows[index].quantity);
        const rate = parseFloat(updatedPurchaseRows[index].rate);
        updatedPurchaseRows[index].amount = parseFloat((quantity * rate).toFixed(2));
       
        setPurchaseRows(updatedPurchaseRows);
    }

    const deleteItem = (index: number) => {
        const updatedPurchaseRows = [...purchaseRows];
        if (updatedPurchaseRows && updatedPurchaseRows[index]) {
            updatedPurchaseRows.splice(index, 1);
            setPurchaseRows(updatedPurchaseRows);
        }
    }

    const addItem = (index: number) => {
        const newRow: PurchaseRows = {
            idPurchaseRows: 0,
            idItems: 0,
            poQuantity: 0,
            receivedQuantity: 0,
            quantity: 0,
            rate: 0,
            amount: 0,
        };

        const updatedRows = [...purchaseRows];
        updatedRows.push(newRow);

        setPurchaseRows(updatedRows);
    }

    const handleInputOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPurchase({ ...purchase, [event.target.name]: event.target.value });
    }

    const handleSelectOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        if(event.target.id === 'purchaseType'){
            setPo({ label: 'Select PO', value: '' });
            setVendor({ label: 'Select Vendor', value: '' });
            setPurchaseOrders([]);
            setPurchase({ ...purchase, idPo: 0, lcNo: '',  idVendors: 0 })
            setPurchaseRows([{
                idPoRows: 0,
                idItems: 0,
                poQuantity: 0,
                receivedQuantity: 0,
                quantity: 0,
                rate: 0,
                amount: 0,
            }])
        }
        setPurchase({ ...purchase, [event.target.id]: event.target.value });
    };

    const calculateTotalAmount = () => {
        let totalAmount = 0;

        for (const row of purchaseRows) {
            totalAmount += row.amount;
        }
        return totalAmount;
    };

    const purchaseSave = async (event: any) => {
        event.preventDefault()
      
        setPurchase({ ...purchase, purchaseAmount: calculateTotalAmount() });

        const confirm = await Swal.fire({
            title: 'Are you sure?',
            text: "You want to update this Good Receive!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#ec008c',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Yes, Update it!',
        })
        if (confirm.value) {
            const postData = {
                purchase: purchase,
                purchaseRows: purchaseRows,
            }
            const requestOptions = postRequestOptions(postData)
            try {
                let response = await fetch(
                    `${process.env.REACT_APP_API_URL}purchase/update`,
                    requestOptions
                )
                let respData = await response.json()
                if (respData.success === true) {
                    const successMsg = respData.successMessage
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: successMsg,
                    })

                    history.push("/purchase");
                } else {
                    const errorsMessage = validationErrorMsg(respData.errors).join('<br>')
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        html: errorsMessage,
                    })
                }
              
            } catch (error) {
                console.log(error, 'catch the hoop')
            } 
        }
    }

    return (

        <div className="card card-custom">
            <div className="card-header">
                <div className="card-title">
                    <KTSVG
                        path='/media/svg/icons/Home/Book-open.svg'
                        className='svg-icon-1 svg-icon-primary'
                    />
                    <span className="text fw-bolder" style={{ marginLeft: "10px" }}>
                        Edit Goods Receive
                    </span>
                </div>
            </div>

            <div className="ml-12">
                <div className="card-body">
                    
                    <form onSubmit={purchaseSave}>
                        <div className="form-group row">
                            <label htmlFor="purchaseType" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Receive Type <span className="required text-danger"></span></label>
                            <div className="col-lg-5">
                                <select name="purchaseType" id="purchaseType" onChange={handleSelectOnChange} className="form-control form-control-sm"  value={purchase.purchaseType}>
                                    <option value={'Local Purchase'}>Local Purchase</option>
                                    <option value={'LC'}>LC</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="idProjects" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Select Project <span className="required text-danger"></span></label>
                            <div className="col-lg-5">
                                <Select
                                    value={project}
                                    onChange={handleProjectChange}
                                    options={projects}
                                    menuPosition="fixed"
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="idPo" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Select PO <span className="required text-danger"></span></label>
                            <div className="col-lg-5">
                                <Select
                                    value={po}
                                    onChange={handlePoChange}
                                    options={purchaseOrders}
                                    menuPosition="fixed"
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="idVendors" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Select Vendor <span className="required text-danger"></span></label>
                            <div className="col-lg-5">
                                <Select
                                    value={vendor}
                                    onChange={handleVendorChange}
                                    options={vendors}
                                    menuPosition="fixed"
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    isDisabled={purchase.purchaseType === 'Local Purchase' || purchase.purchaseType === 'LC'}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="purchaseDate" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Receive Date <span className="required text-danger"></span></label>
                            <div className="col-lg-5">
                                <input type='date' name="purchaseDate" id="purchaseDate" onChange={handleInputOnChange} className="form-control form-control-sm" value={purchase.purchaseDate} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="referenceNo" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Reference No</label>
                            <div className="col-lg-5">
                                <input type='text' name="referenceNo" id="referenceNo" onChange={handleInputOnChange} className="form-control form-control-sm"  value={purchase.referenceNo} disabled/>
                            </div>
                        </div>
                        {
                            purchase.purchaseType === 'LC' && (
                            <div className="form-group row mb-2">
                                <label htmlFor="lcNo" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>LC No <span className="required text-danger"></span></label>
                                <div className="col-lg-5">
                                    <input name="lcNo" id="lcNo" onChange={handleInputOnChange} className="form-control form-control-sm"  value={purchase.lcNo} readOnly/>
                                </div>
                            </div>
                            )
                        }
                        
                        <div className="form-group row mb-2">
                            <label htmlFor="chalanNo" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Chalan No</label>
                            <div className="col-lg-5">
                                <input name="chalanNo" id="chalanNo" onChange={handleInputOnChange} className="form-control form-control-sm"  value={purchase.chalanNo} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="chalanDate" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Chalan Date</label>
                            <div className="col-lg-5">
                                <input type='date' name="chalanDate" id="chalanDate" onChange={handleInputOnChange} className="form-control form-control-sm"  value={purchase.chalanDate} />
                            </div>
                        </div>
                        <div className="form-group row mb-2">
                            <label htmlFor="remarks" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Remarks</label>
                            <div className="col-lg-5">
                                <input name="remarks" id="remarks" onChange={handleInputOnChange} className="form-control form-control-sm"  value={purchase.remarks}/>
                            </div>
                        </div>

                        <br></br>
                        <Table size='sm' bordered hover>
                            <thead>
                                <tr>
                                    <th style={{ width: '5%' }} className='text-center'>SL</th>
                                    <th style={{ width: '35%' }}>Item Name <span className='text-danger'>*</span> </th>
                                    <th style={{ width: '10%' }}>Rate</th>
                                    <th style={{ width: '10%' }}>Quantity</th>
                                    <th style={{ width: '10%' }}>Amount</th>
                                    <th style={{ width: '10%' }}></th>
                                </tr>
                            </thead>

                            <tbody>

                                {purchaseRows.map((row, index) => (
                                    <tr key={'item-'+index}>
                                        <td className='text-center'>{index + 1}</td>
                                        <td>
                                            <Select
                                                placeholder={row.itemName}
                                                onChange={(selectedOption) => handleItemChange(selectedOption, index)}
                                                options={items}
                                                className='sm'
                                                menuPosition="fixed"
                                                styles={{
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        height: '32px',
                                                        minHeight: '32px',
                                                    }),
                                                    container: (provided, state) => ({
                                                        ...provided,
                                                        height: '32px',
                                                    }),
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <input type="number" name="rate" value={row.rate} onChange={(event) => handleChange(event, index)} className="form-control form-control-sm"  />
                                        </td>
                                        <td>
                                            <input type="number" name="quantity" value={row.quantity} onChange={(event) => handleChange(event, index)} className="form-control form-control-sm" />
                                            {row.poQuantity > 0 && (
                                                <> {/* Wrap in a fragment to avoid unnecessary DOM nodes */}
                                                    <small><strong>{row.poQuantity}</strong> ordered </small>
                                                    <small><strong>{row.receivedQuantity}</strong> Received </small>
                                                    {row.poQuantity === row.receivedQuantity ? (
                                                        <small className='text-danger'>(PO Quantity already received)</small>
                                                    ) : row.receivedQuantity > row.poQuantity ? (
                                                        <small className='text-danger'>(Received quantity exceeds PO quantity)</small>
                                                    ) : (
                                                        <small className='text-danger'><strong>{row.poQuantity - row.receivedQuantity}</strong> available</small>
                                                    )}
                                                </>
                                            )}
                                        </td>
                                        <td>
                                            <input type="number" name="amount" readOnly value={row.amount} className="form-control form-control-sm" />
                                        </td>
                                        <td>
                                            {
                                                purchaseRows.length > 1 &&
                                                <button type='button' className="btn btn-light-primary btn-sm px-2 py-1" onClick={() => deleteItem(index)}>
                                                    <KTSVG path='/media/svg/icons/General/Trash.svg' className='svg-icon-5 svg-icon-danger me-0' />
                                                </button>
                                            }
                                            
                                            {index === purchaseRows.length - 1 && (
                                                <button type='button' className="btn btn-success btn-sm px-2 py-1" onClick={() => addItem(index)}>
                                                    <KTSVG path='/media/svg/icons/Navigation/Plus.svg' className='svg-icon-5 svg-icon-danger me-0' /> Add
                                                </button>
                                            )}
                                        </td>
                                    </tr>
                                ))}

                                <tr>
                                    <td colSpan={4} className='text-end'><b>Total Amount:</b></td>
                                    <td><b>{numberFormat(calculateTotalAmount())}</b></td>
                                </tr>
                               
                            </tbody>

                        </Table>

                        <div className="row mt-4">
                            <div className="col-12 text-center">
                                <button type="submit" className="btn btn-sm btn-primary me-2">
                                    <span>  Submit</span>
                                </button>
                                <Link to='/purchase' type="reset" className="btn btn-sm btn-secondary">Cancel</Link>
                            </div>
                        </div>

                        {/* <pre>{JSON.stringify(purchase, null, 2)}</pre>
                        <pre>{JSON.stringify(purchaseRows, null, 2)}</pre> */}
                    </form>

                </div>
            </div>
        </div>

    )
}

export default PurchaseEdit
