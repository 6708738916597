import DataTable from '../components/DataTable';
import { KTSVG } from '../../_metronic/helpers'
import 'react-loading-skeleton/dist/skeleton.css';
import { Link } from 'react-router-dom';
import GetPagePermission from "../components/GetPagePermission";
import Swal from 'sweetalert2';
import { deleteRequestOptions } from '../helpers/Fetchwrapper';

function InventoryStockIssue() {

    const hasPermission = GetPagePermission("/stock-issue");
    const issueDeleteHasPermission = GetPagePermission("/stock-issue-delete");
    const numberFormat = (value: any) => {

        value = parseFloat(value)
        if (typeof (value) !== 'number') {
            return value;
        }
        return parseFloat(value.toString()).toLocaleString('en-US', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }


    const columns = [
        { value: 'id', title: 'ID', style: { textAlign: 'center' } },
        { value: 'projectName', title: 'Project', style: { textAlign: 'left' } },
        { value: 'referenceNo', title: 'Issue No', style: { textAlign: 'left' } },
        { value: 'issueDate', title: 'Issue Date', style: { textAlign: 'left' } },
        { 
            value: 'totalAmount', title: 'Amount', style: { textAlign: 'center' },
            hasHtmlComponent: true,
            htmlComponent: (row: any) => {
                return (
                    <>
                        {numberFormat(row.totalAmount)}
                    </>
                )
             },
        },
        { value: 'createdBy', title: 'Created By', style: { textAlign: 'left' } },
        {
            value: 'action', title: 'Action', style: { textAlign: 'left', whiteSpace: 'nowrap' }, hasActionsComponent: true, actionsComponent: ((row: any) => {
                return (
                    <>
                        <Link className="btn btn-light-primary btn-sm px-2 py-1" to={`/stock-issue-details/${row.idInvIssues}`}>
                            <KTSVG
                                path='/media/svg/icons/Layout/Layout-grid.svg'
                                className='svg-icon-5 svg-icon'
                            />		Details
                        </Link>
                        {issueDeleteHasPermission===1 && (
                            <button className="btn btn-light-danger btn-sm px-2 py-1" onClick={() => deleteIssue(row.idInvIssues, row.referenceNo)}>
                                <KTSVG
                                    path='/media/svg/icons/General/Trash.svg'
                                    className='svg-icon-5 svg-icon'
                                />		Delete
                            </button>
                        )}
                        
                    </>
                )
            })
        }
    ]

    const filters = {
        id: {
            filterType: 'like',
            filterValue: '',
        },
        projectName: {
            filterType: 'like',
            filterValue: '',
        },
        referenceNo: {
            filterType: 'like',
            filterValue: '',
        },
        issueDate: {
            filterType: 'like',
            filterValue: '',
        },
        totalAmount: {
            filterType: 'like',
            filterValue: '',
        },
        createdBy: {
            filterType: 'like',
            filterValue: '',
        },
    }

    const url = process.env.REACT_APP_API_URL + 'inventory-stock-issue/dt'


    const deleteIssue = (id: number, referenceNo: string) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You will not be able to recover this " + referenceNo + "!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it',
            showLoaderOnConfirm: true,
            customClass: {
                confirmButton: 'btn btn-danger me-2',
                cancelButton: 'btn btn-secondary'
            },
            preConfirm: () => {
                return fetch(process.env.REACT_APP_API_URL + 'inventory-stock-issue/' + id, deleteRequestOptions())
                    .then(response => {
                        if (!response.ok) {
                            throw new Error(response.statusText)
                        }
                        return response.json()
                    })
                    .catch(error => {
                        Swal.showValidationMessage(
                            `Request failed: ${error}`
                        )
                    })
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.value.success) {
                    Swal.fire(
                        'Deleted!',
                        referenceNo + ' has been deleted.',
                        'success'
                    )
                    window.location.reload();
                   
                } else {
                    Swal.fire(
                        'Error!',
                        result.value.errors,
                        'error'
                    )
                }
            }
        })
    }

    return (
        <>
            {hasPermission === 1 &&
                <>
                    <div className='card card-custom'>
                        <div className='card-header'>
                            <div className="card-title">
                                <KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-1 svg-icon-primary' />
                                <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
                                    Stock Issue List
                                </span>
                            </div>
                            <div className="card-toolbar">
                                <div className='d-flex justify-content-end'>
                                    <Link to={`/stock-issue-create`} className="btn btn-sm btn-primary"><i className="fa fa-plus"></i>Create New Stock Issue</Link>
                                </div>
                            </div>
                        </div>

                        <div className='card-body'>
                            <DataTable url={url} columns={columns} filters={filters} />
                        </div>
                    </div >
                </>
            }
            {/* <h1>Permission Denied</h1> */}

        </>
    )
}

export default InventoryStockIssue
