/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button } from 'react-bootstrap'
import Spinner from 'react-bootstrap/Spinner'
import { Form } from 'react-bootstrap-v5'
import { postRequestOptions } from "../helpers/Fetchwrapper";



function BankCreate({ show, handleClose, id, getBankList }: { show: boolean, handleClose: any, id: number, getBankList: any }) {
    const [isSubmitDisable] = useState(false);
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        bankNameShort: '',
        bankNameFull: '',
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true);

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + 'banks', postRequestOptions(formData));

            const data = await response.json();

            if (data.success) {
                toast(<strong>Bank created successfully</strong>, { type: 'success', position: 'bottom-right', autoClose: 3000 });
                setFormData({
                    bankNameShort: '',
                    bankNameFull: '',
                });
                handleClose(false);
                getBankList();
            } else {
                for (const i in data.errors) {
                    toast(<strong>{data.errors[i].join(' ')}</strong>, { type: 'error', position: 'bottom-right', autoClose: 3000 });
                }
            }
        } catch (error) {
            console.error('Error submitting data:', error);
        } finally {
            setLoading(false);
        }
    };

    return (<Modal show={show} onHide={() => handleClose(false)} size="lg">
        <Modal.Header closeButton>
            <Modal.Title>Create Bank</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
            <Modal.Body>
                <Form.Group controlId="bankNameShort">
                    <Form.Label>Bank Name (Short)</Form.Label>
                    <Form.Control
                        type="text"
                        name="bankNameShort"
                        value={formData.bankNameShort}
                        onChange={handleChange}
                    />
                </Form.Group>

                <Form.Group controlId="bankNameFull">
                    <Form.Label>Bank Name (Full)</Form.Label>
                    <Form.Control
                        type="text"
                        name="bankNameFull"
                        value={formData.bankNameFull}
                        onChange={handleChange}
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>

                <button type="submit" disabled={isSubmitDisable || loading} className="btn btn-sm btn-primary me-2">
                    {loading &&
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    }
                    <span>  Submit</span>
                </button>
                <Button variant='secondary' size="sm" onClick={() => handleClose(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Form>
    </Modal>)
}

export default BankCreate