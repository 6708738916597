/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { KTSVG } from '../../_metronic/helpers';
import { Modal, Button, Form } from 'react-bootstrap';
import { postRequestOptions } from '../helpers/Fetchwrapper';
// import { AppContext } from '../context/AppContext';
import Swal from 'sweetalert2'
import validationErrorMsg from '../helpers/ValidationErrorMsg'
import scrollTo from "../helpers/ScrollTo";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

interface EventSubmitDataType {
    idLeads?: number,
    activityType?: string,
    activityAt?: string,
    activityDescription?: string,
    content?: string,
    activityStatus?: string,
}

interface EventProps {
    show: boolean;
    handleClose: (show: boolean) => void;
    id: number;
    idLeadsForVisit: number;
    fromDate: string;
    toDate: string;
}


const containerStyle = {
    width: '100%',
    height: '400px'
};
const defaultCenter = {
    lat: null, // Default 40.7128 latitude (New York City)
    lng: null // Default -74.0060 longitude 
};

const VisitPlanAddEvent: React.FC<EventProps> = ({ show, handleClose, id, idLeadsForVisit, fromDate, toDate }) => {
    // const { currentUser } = useContext(AppContext);
    const [isLoading, setIsLoading] = useState(false);

    const [eventSubmitData, setEventSubmitData] = useState<EventSubmitDataType>({ activityType: 'visit', activityStatus: 'scheduled' });
    const [location, setLocation] = useState<any>(null);
    const [mapCenter, setMapCenter] = useState(defaultCenter);

    // Load Google Maps API
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY as string,
        libraries: ['places'],
    });


    const handleChange = (e: any) => {
        const { name, value } = e.target;
        if (name === 'activityAt' || name === 'activityDescription' || name === 'content') {
            setEventSubmitData({ ...eventSubmitData, [name]: value.toString() });
        } else if (name === 'activityStatus') {
            setEventSubmitData({ ...eventSubmitData, [name]: value.toString() });
        }
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setIsLoading(true);
        setEventSubmitData({ ...eventSubmitData, activityType: 'visit' });

        const requestOptions = postRequestOptions({ ...eventSubmitData, activityType: 'visit', location: location, idLeads: idLeadsForVisit })
        try {
            let response = await fetch(
                `${process.env.REACT_APP_API_URL}add-visit-plan`,
                requestOptions
            )
            let respData = await response.json()
            setIsLoading(false);
            if (respData.success === true) {
                window.scrollBy(0, 30);
                scrollTo(180, 20);
                handleClose(false);
                setEventSubmitData({ activityType: 'visit', activityStatus: 'scheduled' });
                setLocation(null)
            } else {
                const errorsMessage = validationErrorMsg(respData.errors).join('<br>')
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    html: errorsMessage,
                })
            }

        } catch (error) {
            setIsLoading(false);
        }

    }

    const onChangeLocation = (data: any) => {
        setLocation(JSON.stringify(data));

        const placeId = data.value.place_id;

        if (window.google) {
            const service = new window.google.maps.places.PlacesService(document.createElement('div'));
            service.getDetails({ placeId: placeId }, (place, status) => {
                if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                    const locationDetails = place.geometry?.location;
                    if (locationDetails) {
                        setMapCenter({
                            lat: locationDetails.lat(),
                            lng: locationDetails.lng(),
                        });
                    }
                } else {
                    console.error('Place details request failed due to: ' + status);
                }
            });
        }

    };

    return (<Modal show={show} onHide={() => handleClose(false)} size="lg">
        <Modal.Header closeButton>
            <Modal.Title>Add Event</Modal.Title>
            {/* <pre>{JSON.stringify(eventSubmitData, null, 2)}</pre> */}
        </Modal.Header>
        <Modal.Body>



            <Form onSubmit={handleSubmit}>

                <div className='fv-row mb-4'>
                    <label className='form-label required'>Purpose:</label>
                    <Form.Control placeholder="e.g. visit to factoy / call with manager" name='activityDescription' type="text" size={"sm"} className='form-control-solid' onChange={handleChange} />
                    <div className='text-danger mt-2'>
                        <Form.Control.Feedback />
                    </div>
                </div>

                <div className='fv-row mb-4'>
                    <label className='d-flex align-items-center form-label'>
                        <span style={{ textTransform: "capitalize" }} className='required'>Visit Date:</span>
                    </label>

                    <Form.Control
                        name='activityAt'
                        size={"sm"}
                        className='form-control-solid'
                        type="datetime-local"
                        onChange={handleChange}
                        min={fromDate + ' 00:00:00'}
                        max={toDate + ' 23:59:59'}
                    // value={fromDate + ' 12:00:00'}

                    // value={new Date().toISOString().slice(0, 16)}
                    />
                    <div className='text-danger mt-2'>
                        <Form.Control.Feedback />
                    </div>
                </div>


                <div className='fv-row mb-4'>
                    <label className='form-label'>Location:</label>
                    <div>
                        <GooglePlacesAutocomplete
                            apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
                            selectProps={{
                                value: JSON.parse(location),
                                onChange: (data) => { onChangeLocation(data) },
                            }}
                            // Use searchOptions to restrict results to Bangladesh
                            autocompletionRequest={{
                                componentRestrictions: { country: ['bd'] }, // Restrict to Bangladesh
                            }}
                        />
                    </div>
                </div>

                {isLoaded && mapCenter?.lat && (
                    <div className="mb-4">
                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={mapCenter}
                            zoom={12}
                        >
                            <Marker position={mapCenter} />
                        </GoogleMap>
                    </div>
                )}


                <div className="d-flex justify-content-end px-3">
                    <Button disabled={isLoading} variant="success" size="sm" type="submit" className='me-2'>
                        {isLoading ? <i className='fa fa-spin fa-spinner'></i> : <><KTSVG className="svg-icon-3" path="/media/svg/icons/Communication/Send.svg" /> Submit</>}
                    </Button>
                    <Button variant='secondary' size="sm" onClick={() => handleClose(false)}>
                        Close
                    </Button>
                </div>
                {/* <pre>{JSON.stringify(eventSubmitData, null, 2)}</pre> */}
            </Form>
        </Modal.Body>
    </Modal>)
}

export default VisitPlanAddEvent