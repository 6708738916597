/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import { Modal, Button } from 'react-bootstrap'
import Spinner from 'react-bootstrap/Spinner'
import { Form } from 'react-bootstrap-v5'
import Select, { components } from 'react-select'
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper';
import validationErrorMsg from '../helpers/ValidationErrorMsg'

interface InitialState {
    ledgerType: string;
    ledgerName: string;
    parentId: string;
}

const LedgerSingleValue = ({ children, ...props }) => (
	<components.SingleValue {...props}>
		<>
			<div className='row'>
				<span> {props.data.label} </span>
			</div>
		</>
	</components.SingleValue>
)


// Custom Option component
const CustomOption = (props) => {
    return (
        <components.Option {...props}>
            <i className="fa fa-file"></i>
            <span> {props.data.label} </span><br />
            {props.data.ledgerPath && (
                <span><i className="fa fa-map-marker"></i> {props.data.ledgerPath} </span>
            )}
        </components.Option>
    );
};


function ChartOfAccountCreate({ show, handleClose, id }: { show: boolean, handleClose: any, id: number }) {
    const initialState: InitialState = {
        ledgerType: 'group',
        ledgerName: '',
        parentId: '',
    };

    const [loading, setLoading] = useState(false);
    const [groups, setGroups] = useState([]);
    const [group, setGroup] = useState({ label: 'Select Group', value: '' });
    const [formData, setFormData] = useState(initialState);

    useEffect(() => {
        getGroups();
        setFormData(initialState);
    }, [id])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const ledgerTypeChange = (event: any) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const getGroups = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'chart-of-accounts/groups',
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                
                const groupObj = resp.data.map((item: { ledgerName: string; id: number; ledgerPath?: string }) => ({
                    label: item.ledgerName,
                    value: item.id,
                    ledgerPath: item?.ledgerPath,
                }));
                
                setGroups(groupObj);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const handleGroupChange = (group: any) => {
        setGroup(group);
        setFormData({ ...formData, parentId: group?.value });
    }

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault()
        setLoading(true);
        const requestOptions = postRequestOptions(formData)
        try {

            let response = await fetch(
                `${process.env.REACT_APP_API_URL}chart-of-accounts/store`,
                requestOptions
            )

            let respData = await response.json()
            Swal.close();
            if (respData.success === true) {
                handleClose(respData.success)
            } else {
                const errorsMessage = validationErrorMsg(respData.errors).join('<br>')
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    html: errorsMessage,
                })
            }
        } catch (error) {
            Swal.close();
            console.log(error, 'catch the hoop')
        } finally {
            setLoading(false);
        }
    }


    return (<Modal show={show} onHide={() => handleClose(false)} size="lg">
        <Modal.Header closeButton>
            <Modal.Title>Create Ledger</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
            <Modal.Body>

                <Form.Group className="mb-3" controlId="ledgerType">
                    <Form.Label>Select Ledger Type</Form.Label>
                    <select className="form-select form-select-sm"
                        name='ledgerType'
                        value={formData.ledgerType}
                        onChange={ledgerTypeChange}
                    >
                        <option value="group">Group</option>
                        <option value="gl">General Ledger</option>
                    </select>
                </Form.Group>
                <Form.Group className="mb-3" controlId="parentId">
                    <Form.Label>Select Parent Group<span className="required text-danger"></span></Form.Label>
                    <Select
                        value={group}
                        onChange={handleGroupChange}
                        options={groups}
                        menuPosition="fixed"
                        isClearable={true}
                        components={{ SingleValue: LedgerSingleValue, Option: CustomOption }}
                        
                    />
                </Form.Group>
                <Form.Group controlId="ledgerName">
                    {formData.ledgerType === 'group' &&
                        <Form.Label>Group Name<span className="required text-danger"></span></Form.Label>
                    }

                    {formData.ledgerType === 'gl' &&
                        <Form.Label>Ledger Name<span className="required text-danger"></span></Form.Label>
                    }

                    <Form.Control
                        type="text"
                        name="ledgerName"
                        value={formData.ledgerName}
                        onChange={handleChange}
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>

                <button type="submit" disabled={loading} className="btn btn-sm btn-primary me-2">
                    {loading &&
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    }
                    <span>  Submit</span>
                </button>
                <Button variant='secondary' size="sm" onClick={() => handleClose(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Form>
    </Modal>)
}

export default ChartOfAccountCreate