import { useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap-v5";
import { KTSVG } from '../../_metronic/helpers';
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper';
import validationErrorMsg from '../helpers/ValidationErrorMsg'
import Swal from 'sweetalert2'
import Select from 'react-select'

interface FormDataType {
    idLeads?: number,
    idCompanies?: any,
    idProjects?: any,
    projectName?: any,
    leadName?: any,
    estimatedValue?: any,
}

interface LeadUseProps {
    show: boolean,
    handleClose: any,
    leadInfo: any,
    getLeadInfo: any,
}


export default function LeadEdit({ show, handleClose, leadInfo, getLeadInfo }: LeadUseProps) {

    const initialState: FormDataType = {
        idLeads: leadInfo?.idLeads,
        idCompanies: leadInfo.idCompanies,
        idProjects: leadInfo.idProjects,
        projectName: leadInfo.project?.projectName ? leadInfo.project?.projectName : null,
        leadName: leadInfo.leadName,
        estimatedValue: leadInfo.estimatedValue
    };

    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState<FormDataType>(initialState);

    const [companies, setCompanies] = useState([]);
    const [company, setCompany] = useState({ label: leadInfo.company?.companyName ? leadInfo.company?.companyName : 'Select Client', value: leadInfo?.idCompanies });

    useEffect(() => {
        getCompanies();
    }, []);

    const getCompanies = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'lead/companies',
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let companiesObj = resp.data.map((item: any) => {
                    item.label = item.name;
                    item.value = item.id;
                    return item
                })
                setCompanies(companiesObj);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const handleCompanyChange = (comapny: any) => {
        setCompany(comapny);
        setFormData({ ...formData, idCompanies: comapny?.value });
    }

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value.toString() });
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setIsLoading(true);
        // return false;
        setFormData({ ...formData, idLeads: leadInfo?.idLeads });

        const requestOptions = postRequestOptions(formData)
        try {
            let response = await fetch(
                `${process.env.REACT_APP_API_URL}lead/update`,
                requestOptions
            )
            let respData = await response.json()
            setIsLoading(false);
            if (respData.success === true) {
                setFormData(initialState);
                getLeadInfo();
            } else {
                const errorsMessage = validationErrorMsg(respData.errors).join('<br>')
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    html: errorsMessage,
                })
            }

        } catch (error) {
            setIsLoading(false);
        }

    }


    return (<>

        <Modal show={show} onHide={handleClose}>
            <Form onSubmit={handleSubmit}>
                <Modal.Header className="py-3" closeButton>
                    <Modal.Title>Edit Lead</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3" controlId="leadName">
                        <Form.Label>Lead Name<span className="required text-danger"></span></Form.Label>
                        <Form.Control onChange={handleChange} name="leadName" type="text" placeholder="Enter lead name" value={formData?.leadName} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="company">
                        <Form.Label>Client<span className="required text-danger"></span></Form.Label>
                        <Select
                            value={company}
                            onChange={handleCompanyChange}
                            options={companies}
                            menuPosition="fixed"
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="project">
                        <Form.Label>Project</Form.Label>
                        <Form.Control type="text" placeholder="Enter lead name" value={formData?.projectName} readOnly />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="totalUsageValue">
                        <Form.Label>Total Usage Value</Form.Label>
                        <Form.Control type="number" onChange={handleChange} name='estimatedValue' placeholder="Enter total usage value" value={formData.estimatedValue} />
                    </Form.Group>

                    {/* <pre>{JSON.stringify(formData, null, 2)}</pre> */}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size="sm" onClick={handleClose}>
                        Close
                    </Button>

                    <Button disabled={isLoading} variant="success" size="sm" type="submit" onClick={handleClose}>
                        {isLoading ? <i className='fa fa-spin fa-spinner'></i> : <><KTSVG className="svg-icon-3" path="/media/svg/icons/General/Save.svg" /> Update</>}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    </>)
}