/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button, Form } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import Select from 'react-select';
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper';
import { AppContext } from '../context/AppContext';
import Swal from 'sweetalert2'
import validationErrorMsg from '../helpers/ValidationErrorMsg'

interface FormData {
    idAccExpenses?: string;
    expenseDate: string;
    transactionType: string;
    idBankCash: string;
    idAccLedgers: string;
    remarks: string;
    latestStatus?: string;
}

interface ExpenseData {
    expenseAmount: string;
    expenseDate: string;
    expenseCategory: string;
    idUsers: string;
    idApprover: string;
    idProjects: string;
    idLeads: string;
    transactionType: string;
    idBankCash: string;
    idAccLedgers: string;
    remarks: string;
    latestStatus?: string;
    user: any;
    approver: any;
    project: any;
    lead: any;
    bankCash: any;
    ledger: any;
}

interface ExpenseCreateProps {
    show: boolean;
    handleClose: (show: boolean) => void;
    id: number;
}

// approved
// requested
const ExpensePayment: React.FC<ExpenseCreateProps> = ({ show, handleClose, id }) => {
    const { currentUser } = useContext(AppContext);
    console.log(currentUser, "currentUser")
    const [isSubmitDisable] = useState(false);
    const [loading, setLoading] = useState(false);

    const [bankCashes, setBankCashes] = useState([]);
    const [bankCash, setBankCash] = useState({ label: 'Select Bank Cash', value: '' });

    const [ledgers, setLedgers] = useState([]);
    const [ledger, setLedger] = useState({ label: 'Select Expense Ledger', value: '' });
    const [expenseData, setExpenseData] = useState<ExpenseData>(null)
    const [formData, setFormData] = useState<FormData>({
        idAccExpenses: '',
        expenseDate: '',
        transactionType: 'bank_account',
        idBankCash: '',
        idAccLedgers: '',
        remarks: '',
        latestStatus: '',
    });

    useEffect(() => {

        getExpenseInfo();

    }, [id]);

    useEffect(() => {
        getBankCash('bank_account');
        getLedgers();
    }, [])

    const getExpenseInfo = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'expense/details/' + id,
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                setExpenseData(resp.data);
                const updatedFormData = {
                    ...formData,
                    idAccExpenses: resp.data.idAccExpenses || formData.idAccExpenses,
                    expenseDate: resp.data.expenseDate || formData.expenseDate,
                };
                setFormData(updatedFormData);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const getBankCash = (transactionType: string) => {
        fetch(
            process.env.REACT_APP_API_URL + 'expense/bank-cash/' + transactionType,
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let bankCashObj = resp.data.map((item: any) => {
                    item.label = item.name;
                    item.value = item.idBankCash;
                    return item
                })
                setBankCashes(bankCashObj);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }


    const getLedgers = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'expense/ledgers',
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let ledgerObj = resp.data.map((item: any) => {
                    item.label = item.ledgerName;
                    item.value = item.idAccLedgers;
                    return item
                })
                setLedgers(ledgerObj);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const transactionTypeChange = (event: any) => {
        const { name, value } = event.target;
        setBankCash({ label: 'Select Bank Cash', value: '' });
        getBankCash(value);
        setFormData({ ...formData, [name]: value });
    };

    const handleBankCashChange = (bankCash: any) => {
        setBankCash(bankCash);
        setFormData({ ...formData, idBankCash: bankCash.value });
    }

    const handleLedgerChange = (ledger: any) => {
        setLedger(ledger);
        setFormData({ ...formData, idAccLedgers: ledger.value });
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true);

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + 'expense/payment', postRequestOptions(formData));

            const data = await response.json();

            if (data.success) {
                toast(<strong>Expense payment successfully</strong>, { type: 'success', position: 'bottom-right', autoClose: 3000 });
                setFormData({
                    idAccExpenses: expenseData?.idAccExpenses,
                    expenseDate: expenseData?.expenseDate,
                    transactionType: 'bank_account',
                    idBankCash: '',
                    idAccLedgers: '',
                    remarks: '',
                });
                handleClose(false);
            } else {

                Swal.close();
                if (data?.errors) {
                    const errorsMessage = validationErrorMsg(data.errors).join('<br>')
                    Swal.fire({
                        icon: 'error',
                        title: 'Invalid Data',
                        html: errorsMessage,
                    })
                }

                if (data?.errorMessage) {
                    toast(data?.errorMessage, { type: 'error', position: 'bottom-right', autoClose: 4000 });
                }


            }
        } catch (error) {
            console.error('Error submitting data:', error);
        } finally {
            setLoading(false);
        }
    };

    return (<Modal show={show} onHide={() => handleClose(false)} size="lg">
        <Modal.Header closeButton>
            <Modal.Title>Expense Payment</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
            <Modal.Body>
                <Form.Group controlId="expenseDate">
                    <Form.Label>Expense Date <span className="required text-danger"></span></Form.Label>
                    <Form.Control
                        type="date"
                        name="expenseDate"
                        value={formData.expenseDate}
                        onChange={handleChange}
                        className='form-control-sm'
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="expenseAmount">
                    <Form.Label>Expense Amount</Form.Label>
                    <Form.Control
                        type="number"
                        name="expenseAmount"
                        value={expenseData?.expenseAmount}
                        className='form-control-sm'
                        min={0}
                        step={'any'}
                        readOnly
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="expenseCategory">
                    <Form.Label>Expense Category</Form.Label>
                    <Form.Control
                        type="text"
                        name="expenseCategory"
                        value={expenseData?.expenseCategory}
                        className='form-control-sm'
                        readOnly
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="user">
                    <Form.Label>Sales Representative</Form.Label>
                    <Form.Control
                        type="text"
                        value={expenseData?.user?.name}
                        className='form-control-sm'
                        disabled={true}
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="idApprover">
                    <Form.Label>Approver</Form.Label>
                    <Form.Control
                        type="text"
                        value={expenseData?.approver?.name}
                        className='form-control-sm'
                        readOnly
                    />
                </Form.Group>

                <Form.Group className="mb-3" controlId="project">
                    <Form.Label>Project</Form.Label>
                    <Form.Control
                        type="text"
                        value={expenseData?.project?.project_name}
                        className='form-control-sm'
                        readOnly
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="lead">
                    <Form.Label>Lead</Form.Label>
                    <Form.Control
                        type="text"
                        value={expenseData?.lead?.lead_name}
                        className='form-control-sm'
                        readOnly
                    />
                </Form.Group>

                <Form.Group className="mb-3" controlId="transactionType">
                    <Form.Label>Mode of Transaction</Form.Label>
                    <select className="form-select form-select-sm"
                        name='transactionType'
                        value={formData.transactionType}
                        onChange={transactionTypeChange}
                    >
                        <option value="bank_account">Bank</option>
                        <option value="cash_box">Cash</option>
                        <option value="mobile_wallet">Mobile Wallet</option>
                    </select>
                </Form.Group>
                <Form.Group className="mb-3" controlId="lead">
                    <Form.Label>Select Bank/Cash <span className="required text-danger"></span></Form.Label>
                    <Select
                        value={bankCash}
                        onChange={handleBankCashChange}
                        options={bankCashes}
                        menuPosition="fixed"
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="lead">
                    <Form.Label>Select Expense Ledger<span className="required text-danger"></span></Form.Label>
                    <Select
                        value={ledger}
                        onChange={handleLedgerChange}
                        options={ledgers}
                        menuPosition="fixed"
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    />
                </Form.Group>

                <Form.Group className="mb-3" controlId="lead">
                    <Form.Label>Remarks</Form.Label>
                    <Form.Control as="textarea" rows={3} name="remarks" value={formData.remarks} onChange={handleChange} />
                </Form.Group>

            </Modal.Body>
            <Modal.Footer>

                <button type="submit" disabled={isSubmitDisable || loading} className="btn btn-sm btn-primary me-2">
                    {loading &&
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    }
                    <span>  Submit</span>
                </button>
                <Button variant='secondary' size="sm" onClick={() => handleClose(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Form>
    </Modal>)
}

export default ExpensePayment