/* eslint-disable react-hooks/exhaustive-deps */
import { KTSVG } from '../../_metronic/helpers'
import React, { useState, useEffect } from 'react'
import { Table } from 'react-bootstrap-v5';
import Swal from 'sweetalert2'
import { Button } from 'react-bootstrap'
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper'
import Spinner from 'react-bootstrap/Spinner'
import Select from 'react-select'

interface Product {
	productName: string;
	unitName: string;
	size: number;
	salePerUnit: number;
	competitorPrice: number;
	idProducts: number;
	[key: string]: any; // Index signature
}

interface Category {
	id_product_categoriess: number;
	category_name: string;
}

const ProductList = () => {

	const [categories, setCategories] = useState<Category[]>([]);
	const [products, setProducts] = useState<Product[]>([]);

	const [projects, setProjects] = useState([]);
	const [project, setProject] = useState({ label: 'Select Project', value: '' });

	const getAllProjects = () => {
		fetch(
			process.env.REACT_APP_API_URL + 'projects',
			getRequestOptions()
		)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {

				console.log(resp)
				let projects = resp.data.map((item: any) => {
					item.label = item.project_name;
					item.value = item.id_projects;
					return item
				})
				setProjects(projects);
			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})
	}

	useEffect(() => {
		getAllProjects()
	}, [])


	const handleCategoryChange = (data: any, productIndex: number) => {

		//get Category Products
		fetch(
			process.env.REACT_APP_API_URL + 'getCategoryProducts/' + data.id_product_categories,
			getRequestOptions()
		)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {

				let catProducts = resp.map((item: any) => {
					item.label = item.product_name;
					item.value = item.id_products;
					return item
				})

				const updatedProducts = [...products];
				updatedProducts[productIndex].products = catProducts;
				setProducts(updatedProducts);

			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})

	}

	const handleProductChange = (data: any, productIndex: number) => {
		const updatedProducts = [...products];
		updatedProducts[productIndex].salePerUnit = data.sale_per_unit;
		updatedProducts[productIndex].unitName = data.unit_name;
		updatedProducts[productIndex].idProducts = data.id_products;
		setProducts(updatedProducts);

	}

	const getCategories = (project: number) => {

		//get Categories
		fetch(
			process.env.REACT_APP_API_URL + 'allProductCategories?idProjects=' + project,
			getRequestOptions()
		)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {

				let categories = resp.map((item: any) => {
					item.label = item.category_name;
					item.value = item.id_product_categoriess;
					return item
				})
				setCategories(categories);

			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})

		//get Competitor Products
		fetch(
			process.env.REACT_APP_API_URL + 'competitorProducts?idProjects=' + project,
			getRequestOptions()
		)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {

				let products = resp.map((item: any) => {
					item.value = item.id_product_categoriess;
					return item
				})
				setProducts(products);

			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})
	}

	const [isSubmitDisable, setSubmitDisable] = useState(false);
	const [loading, setLoading] = useState(false);

	const deleteProduct = (productIndex: number) => {

		const updatedProducts = [...products];
		updatedProducts.splice(productIndex, 1);
		setProducts(updatedProducts);

	}

	const productSave = async (event: any) => {
		event.preventDefault()
		setSubmitDisable(true);
		setLoading(true);
		const confirm = await Swal.fire({
			title: 'Are you sure?',
			text: "You want to save this Product(s)?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#ec008c',
			cancelButtonText: 'Cancel',
			confirmButtonText: 'Yes, Save it!',
		})
		if (confirm.value) {
			const requestOptions = postRequestOptions({ ...categories, idProjects: project.value })//postRequestOptions(categories)postRequestOptions(products)
			try {
				let response = await fetch(
					`${process.env.REACT_APP_API_URL}competitorProducts/store`,
					requestOptions
				)
				let respData = await response.json()
				// if (respData.success === true) {
				const successMsg = respData.successMessage
				Swal.fire({
					icon: 'success',
					title: 'Success',
					text: successMsg,
				})
				// } else {
				// 	const errorsMessage = validationErrorMsg(respData.errors).join('<br>')
				// 	Swal.fire({
				// 		icon: 'error',
				// 		title: 'Error',
				// 		html: errorsMessage,
				// 	})
				// }
				setSubmitDisable(false);
				setLoading(false);
			} catch (error) {
				console.log(error, 'catch the hoop')
				setSubmitDisable(false);
				setLoading(false);
			}
		}
	}

	const addProduct = () => {
		const newProduct: Product = {
			productName: '',
			unitName: ' ',
			size: 0,
			salePerUnit: 0,
			competitorPrice: 0,
			idProducts: 0,
		};

		const updatedProducts = [...products];
		updatedProducts.push(newProduct);

		setProducts(updatedProducts);
	}

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>, productIndex: number) => {

		const updatedProducts = [...products];
		updatedProducts[productIndex][e.target.name] = e.target.value;
		setProducts(updatedProducts);
	}

	const handleProjectChange = (project: any) => {
		setProject(project);
		// getCategoryUnits(project);
		getCategories(project.value)
		// addProduct()
	}

	return (
		<form onSubmit={productSave}>

			<div className="form-group row">
				<label htmlFor="project" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Project <span className="required text-danger"></span></label>
				<div className="col-lg-5">
					<Select
						value={project}
						onChange={handleProjectChange}
						options={projects}
						menuPosition="fixed"
					/>
				</div>
			</div>

			{/* <pre>{JSON.stringify(products, null, 2)}</pre> */}

			{(project.value !== "") &&

				<>
					<Table size='sm' bordered hover>
						<thead>
							<tr>
								<th style={{ width: '30%' }}>Category</th>
								<th style={{ width: '30%' }}>Product</th>
								<th style={{ width: '10%' }}>Sale Price</th>
								<th style={{ width: '10%' }}>Size</th>
								<th style={{ width: '10%' }}>Competitor Price</th>
								<th style={{ width: '10%' }}><Button variant='success' size="sm" onClick={() => addProduct()}>Add Product</Button></th>
							</tr>
						</thead>


						<tbody>
							{products.map((product, productIndex) => (
								<tr key={productIndex}>
									<td>
										<Select
											placeholder={product.categoryName}
											onChange={(selectedOption) => handleCategoryChange(selectedOption, productIndex)}
											options={categories}
											className='sm'
											menuPosition="fixed"
											styles={{
												control: (provided, state) => ({
													...provided,
													height: '32px',
													minHeight: '32px',
												}),
												container: (provided, state) => ({
													...provided,
													height: '32px',
												}),
											}}
										/>
									</td>
									<td>
										<Select
											placeholder={product.productName}
											onChange={(selectedOption) => handleProductChange(selectedOption, productIndex)}
											options={product.products}
											className='sm'
											menuPosition="fixed"
											styles={{
												control: (provided, state) => ({
													...provided,
													height: '32px',
													minHeight: '32px',
												}),
												container: (provided, state) => ({
													...provided,
													height: '32px',
												}),
											}}
										/>
									</td>
									<td>
										<input type="number" name="salePerUnit" value={product.salePerUnit} readOnly className="form-control form-control-sm" />
									</td>
									<td>
										<div className="input-group">
											<input type="number" name="size"
												onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, productIndex)}
												value={product.size} className="form-control form-control-sm" />
											<span className="input-group-text" style={{ height: "32px" }}>{product.unitName}</span>
										</div>
									</td>
									<td>
										<input type="number" name="competitorPrice"
											onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, productIndex)}
											value={product.competitorPrice} className="form-control form-control-sm" />
									</td>
									<td className='text-center'>
										<button type='button' className="btn btn-light-primary btn-sm px-2 py-1" onClick={() => deleteProduct(productIndex)}>
											<KTSVG path='/media/svg/icons/General/Trash.svg' className='svg-icon-5 svg-icon-danger me-0' />
										</button>
									</td>
								</tr>
							))}
						</tbody>



					</Table>

					{/* <pre>{JSON.stringify(categories, null, 2)}</pre> */}
					<div className='row'>
						<div className='col text-center'>
							<button type="submit" disabled={isSubmitDisable || loading} className="btn btn-sm btn-primary me-2">
								{loading &&
									<Spinner
										as="span"
										animation="grow"
										size="sm"
										role="status"
										aria-hidden="true"
									/>
								}
								<span>  Submit</span>
							</button>
							<Button variant='secondary' size="sm">
								Close
							</Button>
						</div>
					</div>
				</>
			}


		</form>
	)
}

export default ProductList
