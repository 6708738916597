import { useState } from 'react';
import { KTSVG } from '../../_metronic/helpers';
import CashBankCreate from '../components/CashBankAccountCreate';
import { ToastContainer } from 'react-toastify';
import DataTable from '../components/DataTable';
import Swal from 'sweetalert2';
import { deleteRequestOptions } from '../helpers/Fetchwrapper';

function CashBankList() {
    const [newData, setNewData] = useState({});
    
    const [createCashBankAccountModalShow, setCreateCashBankAccountModalShow] = useState(false);
    const [entryType, setEntryType] = useState<'bank_account' | 'cash_box' | 'mobile_wallet'>('cash_box');

    const handleCreateCashBankAccountModalClose = (hide: boolean) => {
        setCreateCashBankAccountModalShow(hide);
        if (!hide) {
            setNewData({ id: Date.now() });
        }
    }

    const deleteBankCashWallet = (id: number, category: string) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You will not be able to recover this " + category.split('_').join(' ') + "!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it',
            showLoaderOnConfirm: true,
            customClass: {
                confirmButton: 'btn btn-danger me-2',
                cancelButton: 'btn btn-secondary'
            },
            preConfirm: () => {
                return fetch(process.env.REACT_APP_API_URL + 'bank-cash/' + id, deleteRequestOptions())
                    .then(response => {
                        if (!response.ok) {
                            throw new Error(response.statusText)
                        }
                        setNewData({ id: Date.now() });
                        return response.json()
                    })
                    .catch(error => {
                        Swal.showValidationMessage(
                            `Request failed: ${error}`
                        )
                    })
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.value.success) {
                    Swal.fire(
                        'Deleted!',
                        category.split('_').join(' ') + ' has been deleted.',
                        'success'
                    )
                } else {
                    Swal.fire(
                        'Error!',
                        result.value.errors,
                        'error'
                    )
                }
            }
        })
    }

    const columns = [
        { value: 'id', title: 'Id', style: { textAlign: 'center' } },
        { value: 'projectName', title: 'Project Name', style: { textAlign: 'left' } },
        { value: 'category', title: 'Type', style: { textAlign: 'left' } },
        { value: 'bankName', title: 'Bank', style: { textAlign: 'left' } },
        { value: 'accountNumber', title: 'Account No', style: { textAlign: 'left' } },
        { value: 'routingNumber', title: 'Routing', style: { textAlign: 'left' } },
        { value: 'cashBoxName', title: 'Cash Box', style: { textAlign: 'left' } },
        { value: 'cashBoxLocation', title: 'Cash location', style: { textAlign: 'left' } },
        { value: 'walletName', title: 'Mobile Wallet', style: { textAlign: 'left' } },
        { value: 'walletNumber', title: 'Wallet Number', style: { textAlign: 'left' } },
        { value: 'serviceName', title: 'Provider', style: { textAlign: 'left' } },

        {
            value: 'action', title: 'Action', style: { textAlign: 'left', whiteSpace: 'nowrap' }, hasActionsComponent: true, actionsComponent: ((row: any) => {
                return (
                    <>
                        {/* <Link to={`/setup-company-edit/${row.idCompanies}`}>
                            <button className="btn btn-light-primary btn-sm px-2 py-1">
                                <KTSVG
                                    path='/media/svg/icons/Communication/Write.svg'
                                    className='svg-icon-5 svg-icon'
                                />		Edit
                            </button>
                        </Link> */}
                        <button className="btn btn-light-danger btn-sm px-2 py-1" onClick={() => deleteBankCashWallet(row.id, row.category)}>
                            <KTSVG
                                path='/media/svg/icons/General/Trash.svg'
                                className='svg-icon-5 svg-icon'
                            />		Delete
                        </button>
                    </>
                )
            })
        }
    ]

    let filters: any = {};
    columns.forEach((column: any) => {
        filters[column.value] = { filterValue: '', filterType: 'text' };
    })

    const url = process.env.REACT_APP_API_URL + 'bank-cash-dt';

    return (
        <>
            <div className='card card-custom'>
                <div className='card-header'>
                    <div className="card-title">
                        {/* <KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-1 svg-icon-primary' /> */}
                        <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
                            Bank Accounts & Cash Box
                        </span>
                    </div>
                    <div className="card-toolbar">
                        <div className='d-flex justify-content-end'>
                            <button type="button" className="btn btn-sm btn-primary me-2" onClick={() => { setEntryType('cash_box'); setCreateCashBankAccountModalShow(true) }}><i className="fa fa-plus"></i>Add Cash box</button>
                            <button type="button" className="btn btn-sm btn-primary me-2" onClick={() => { setEntryType('mobile_wallet'); setCreateCashBankAccountModalShow(true) }}><i className="fa fa-plus"></i>Add Mobile wallet</button>
                            <button type="button" className="btn btn-sm btn-primary" onClick={() => { setEntryType('bank_account'); setCreateCashBankAccountModalShow(true) }}><i className="fa fa-plus"></i>Add Bank Account</button>
                        </div>
                    </div>
                </div>

                <div className='card-body pt-3'>
                    <DataTable url={url} columns={columns} filters={filters} newData={newData} />
                </div>
            </div>
            <CashBankCreate show={createCashBankAccountModalShow} entryType={entryType} handleClose={handleCreateCashBankAccountModalClose} id={Date.now()} />
            <ToastContainer />
        </>
    )
}

export default CashBankList;