/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import { Modal, Button } from 'react-bootstrap'
import Spinner from 'react-bootstrap/Spinner'
import { Form } from 'react-bootstrap-v5'
import { postRequestOptions, getRequestOptions } from '../helpers/Fetchwrapper';
import validationErrorMsg from '../helpers/ValidationErrorMsg'

interface InitialState {
	ledgerType: string;
	ledgerName: string;
	parentId: string;
    category: string;
}

function ChartOfAccountEdit({ show, handleClose, id }: { show: boolean, handleClose: any, id: number}) {
    
    const initialState: InitialState = {
		ledgerType: 'group',
        ledgerName: '',
        parentId: '',
        category: '',
	};

    const [isSubmitDisable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState(initialState);

    const getLedgerInfo = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'chart-of-accounts/edit/'+id,
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                initialState.ledgerType = resp.data.ledger_type;
                initialState.ledgerName = resp.data.ledger_name;
                initialState.parentId = resp.data.parent_id;
                initialState.category = resp.data.category;
                setFormData(initialState);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    useEffect(() => {
        getLedgerInfo();
	}, [id])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault()
		setLoading(true);
		const requestOptions = postRequestOptions(formData)
		try {

			let response = await fetch(
				`${process.env.REACT_APP_API_URL}chart-of-accounts/update/${id}`,
				requestOptions
			)

			let respData = await response.json()
			Swal.close();
			if (respData.success === true) {
                Swal.fire({
					icon: 'success',
					title: 'Success',
					html: 'Ledger Updated Successfully',
				})
				handleClose(respData.success)
			} else {
				const errorsMessage = validationErrorMsg(respData.errors).join('<br>')
				Swal.fire({
					icon: 'error',
					title: 'Error',
					html: errorsMessage,
				})
			}
		} catch (error) {
			Swal.close();
			console.log(error, 'catch the hoop')
		} finally {
            setLoading(false);
        }
	}


    return (<Modal show={show} onHide={() => handleClose(false)} size="lg">
        <Modal.Header closeButton>
            <Modal.Title>Edit Ledger</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
            <Modal.Body>
                
                <Form.Group className="mb-3" controlId="ledgerType">
                    <Form.Label>Ledger Type</Form.Label>
                    <Form.Control
                        type="text"
                        name="ledgerType"
                        value={formData.ledgerType === 'group' ? 'Group' : 'General Ledger'}
                        readOnly
                    />
                </Form.Group>
                <Form.Group controlId="ledgerName">
                    {formData.ledgerType === 'group' &&
                        <Form.Label>Group Name<span className="required text-danger"></span></Form.Label>
                    }

                    {formData.ledgerType === 'gl' &&
                        <Form.Label>Ledger Name<span className="required text-danger"></span></Form.Label>
                    }
                    
                    <Form.Control
                        type="text"
                        name="ledgerName"
                        value={formData.ledgerName}
                        onChange={handleChange}
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>

                <button type="submit" disabled={isSubmitDisable || loading} className="btn btn-sm btn-primary me-2">
                    {loading &&
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    }
                    <span>  Submit</span>
                </button>
                <Button variant='secondary' size="sm" onClick={() => handleClose(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Form>
    </Modal>)
}

export default ChartOfAccountEdit