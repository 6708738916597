import React, { useEffect, useState } from 'react';
import { KTSVG } from '../../_metronic/helpers'

const EmailForm = (props) => {

    const [emails, setEmails] = useState(props.emails?.length>0 ? props.emails : [{ id: '', email: '', emailType: '' }]);

    useEffect(()=>{
        setEmails(props.emails?.length>0 ? props.emails : [{ id: '', email: '', emailType: '' }])
    },[props.emails])
    
    const handleEmailChange = (index, event) => {
      const { name, value } = event.target;
      const updatedEmails = [...emails];
      updatedEmails[index][name] = value;
      setEmails(updatedEmails);
      props.getEmailsData(updatedEmails);
    };
  
    const addEmailField = () => {
        setEmails(emails.concat({ id: '', email: '', emailType: '' }));
        props.getEmailsData(emails.concat({ id: '',  email: '', emailType: '' }));
    };
  
    const removeEmailField = (index) => {        
        const updatedEmails = [...emails];
        updatedEmails.splice(index, 1);
        setEmails(updatedEmails);
        props.getEmailsData(updatedEmails);
    };

    return (
        <>
            {emails.map((email, index) => (
            <div key={index}>
                <div className="input-group mb-3">
                    <input type="text" className="form-control form-control-sm" name='email' value={email.email == null ? '' : email.email} onChange={(event) => handleEmailChange(index, event)} />
                    <select className="form-select form-select-sm" name='emailType' value={email.emailType == null ? '' : email.emailType} onChange={(event) => handleEmailChange(index, event)}>
                        <option value="">Select Type</option>
                        <option value="Home">Home</option>
                        <option value="Work">Work</option>
                        <option value="Mobile">Mobile</option>
                        <option value="Other">Other</option>
                    </select>
                    {index === (emails.length - 1) &&
                        <button type="button" className="btn btn-icon btn-light btn-active-light-primary  btn-sm me-1" onClick={addEmailField}>
                            <KTSVG path='/media/svg/icons/Navigation/Plus.svg' className='svg-icon svg-icon-5' />
                        </button>
                    }

                    {emails.length > 1 &&
                        <button type="button" className="btn btn-icon btn-light btn-active-light-danger btn-sm" onClick={() => removeEmailField(index)}>
                            <KTSVG path='/media/svg/icons/General/Trash.svg' className='svg-icon svg-icon-5' />
                        </button>
                    }
                </div>
            </div>
           
            ))}
       </>
    );
  };
  
  export default EmailForm;
  
