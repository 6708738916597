/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react'
import { AppContext } from '../context/AppContext'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ItemList from '../components/ItemList';
import ItemCategoryList from '../components/ItemCategoryList';
import UnitList from '../components/UnitList';

const ManageItemsView = () => {
    const { currentUser } = useContext(AppContext);
    useEffect(() => {

    }, [currentUser])


    return (
        <>

            <div className='card card-custom'>
                <div className='card-header card-header-stretch overflow-auto'>
                    <div className='card-title'>
                        <h3 className='card-label'>Manage Items</h3>
                    </div>
                </div>
                <div className="card-body">

                    <Tabs
                        defaultActiveKey="item"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                    >

                        <Tab eventKey="item" title="Item">
                            <ItemList></ItemList>
                        </Tab>
                        <Tab eventKey="item-category" title="Item Category">
                            <ItemCategoryList></ItemCategoryList>
                        </Tab>
                        <Tab eventKey="unit" title="Unit">
                            <UnitList></UnitList>
                        </Tab>

                    </Tabs>
                </div>
            </div>


            <style>
                {`
    .react-bootstrap-tabs .nav-link {
      padding: 1000px;
    }
  `}
            </style>
        </>

    )
}

export default ManageItemsView
