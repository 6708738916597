import { useState } from 'react'
import DataTable from '../components/DataTable';
import { KTSVG } from '../../_metronic/helpers'
import Swal from 'sweetalert2'
import VendorManage from '../components/VendorManage';
import 'react-loading-skeleton/dist/skeleton.css';

function VendorList() {

    const hasPermission = 1; //parseInt(GetPagePermission("/setup-company-list"));
    const [newData, setNewData] = useState({});
    const [id, setId] = useState(0);

    const [createVendorModalShow, setCreateVendorModalShow] = useState(false);

    const handleCreateVendorModalShow = () => {
        setId(0);
        setCreateVendorModalShow(true);
    }

    const handleCreateVendorModalClose = (isReload: boolean) => {
        setCreateVendorModalShow(false);
        if (isReload) {
            setNewData({ id: Date.now() });
        }
    }

    const deleteVendor = async (id: number): void => {
        const confirm = await Swal.fire({
            title: 'Are you sure?',
            text: "You want to delete this Vendor?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#ec008c',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Yes, Delete it!',
        })
        if (confirm.value) {
            const myToken: string | null = localStorage.getItem('MyToken');
            let jwt: string | null = (myToken != null) ? JSON.parse(myToken) : null;
            fetch(`${process.env.REACT_APP_API_URL}vendor/delete/${id}`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwt}` }
            })
            .then((resp: Response) => {
                return resp.json();
            })
            .then((resp: any) => {
                if (resp.success) {
                    const successMsg: string = resp.successMessage;
                    Swal.fire({
                        icon: 'success',
                        title: 'Deleted!',
                        text: successMsg,
                    });
                    setNewData({ id: Date.now() });
                }else{
                    const errorMsg: string = resp.errorMessage;
                    Swal.fire({
                        icon: 'error',
                        title: 'Delete Failed!',
                        text: errorMsg,
                    });
                }
            })
            .catch((error: any) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Vendor not found!',
                });
                console.error('Error:', error);
            });
        }
        
    };

    const editVendor = (id: number): void => {
        console.log("Edit Vendor ID = ",id);
        console.log("ID = ",id);
        setId(id);
        setCreateVendorModalShow(true);
    };

    const columns = [
        { value: 'id', title: 'Id', style: { textAlign: 'center' } },
        { value: 'name', title: 'Name', style: { textAlign: 'left' } },
        { value: 'phone', title: 'Phone', style: { textAlign: 'left' } },
        { value: 'address', title: 'Address', style: { textAlign: 'left' } },
        {
            value: 'action', title: 'Action', style: { textAlign: 'left', whiteSpace: 'nowrap' }, hasActionsComponent: true, actionsComponent: ((row: any) => {
                return (
                    <>

                        <button className="btn btn-light-primary btn-sm" onClick={() => editVendor(row.id)}>
                            <KTSVG
                                path='/media/svg/icons/Communication/Write.svg'
                                className='svg-icon-5 svg-icon'
                            />		Edit
                        </button>

                        <button className="btn btn-light-danger btn-sm p-2" onClick={() => deleteVendor(row.id)}>
                            <KTSVG
                                path='/media/svg/icons/General/Trash.svg'
                                className='svg-icon-5 svg-icon'
                            />		Delete
                        </button>
                    </>
                )
            })
        }
    ]

    const filters = {
        id: {
            filterType: 'like',
            filterValue: '',
        },
        name: {
            filterType: 'like',
            filterValue: '',
        },
        phone: {
            filterType: 'like',
            filterValue: '',
        },
        address: {
            filterType: 'like',
            filterValue: '',
        },
    }

    const url = process.env.REACT_APP_API_URL + 'vendor/dt'

    return (
        <>
            {hasPermission === 1 &&
                <div className='card card-custom'>
                    <div className='card-header'>
                        <div className="card-title">
                            <KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-1 svg-icon-primary' />
                            <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
                                Vendor List
                            </span>
                        </div>
                        <div className="card-toolbar">
                            <div className='d-flex justify-content-end'>
                                <button type="button" className="btn btn-sm btn-primary" onClick={() => handleCreateVendorModalShow()}><i className="fa fa-plus"></i> Add Vendor</button>
                            </div>
                        </div>
                    </div>

                    <div className='card-body'>
                        <DataTable url={url} columns={columns} filters={filters} newData={newData} />
                    </div>
                </div>
            }

            <VendorManage show={createVendorModalShow} handleClose={handleCreateVendorModalClose} id={id} />
        </>
    )
}

export default VendorList
