/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import { Modal, Button } from 'react-bootstrap'
import { postRequestOptions, getRequestOptions } from '../helpers/Fetchwrapper'
import validationErrorMsg from '../helpers/ValidationErrorMsg'
import Spinner from 'react-bootstrap/Spinner'

interface SrCategoryCreateProps {
	show: boolean;
	handleClose: any;
	id: number;
}

interface InitialState {
	idSrCategorys: number | null;
	srCategoryName: string;
}


const SrCategoryCreate: React.FC<SrCategoryCreateProps> = ({ show, handleClose, id }) => {


	const initialState: InitialState = {
		idSrCategorys: 0,
		srCategoryName: '',
	};

	const [srCategory, setSrCategory] = useState(initialState);


	useEffect(() => {

	}, [])

	useEffect(() => {
		if (id === 0) {
			setSrCategory({ idSrCategories: null, srCategoryName: '' });

		} else {
			fetch(
				process.env.REACT_APP_API_URL + 'srCategory/edit/' + id,
				getRequestOptions()
			)
				.then((resp) => {
					return resp.json()
				})
				.then((resp) => {
					setSrCategory({ idSrCategories: resp.data.id_sr_categories, srCategoryName: resp.data.srCategory_name });
				})
				.catch((error) => {
					console.log(error, 'catch the hoop')
				})
		}
	}, [id]);


	const [isSubmitDisable, setSubmitDisable] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleChange = (e: any) => {
		setSrCategory({ ...srCategory, [e.target.name]: e.target.value });
	};

	const srCategorySave = async (event: any) => {
		event.preventDefault()
		setSubmitDisable(true);
		setLoading(true);
		const confirm = await Swal.fire({
			title: 'Are you sure?',
			text: "You want to " + (id === 0 ? "create" : "update") + " this SR Category?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#ec008c',
			cancelButtonText: 'Cancel',
			confirmButtonText: 'Yes, Submit it!',
		})
		if (confirm.value) {
			const requestOptions = postRequestOptions(srCategory)
			try {

				let url = (id === 0) ? `${process.env.REACT_APP_API_URL}srCategory/store` : `${process.env.REACT_APP_API_URL}srCategory/update`

				let response = await fetch(
					url,
					requestOptions
				)
				let respData = await response.json()
				if (respData.success === true) {
					const successMsg = respData.successMessage
					Swal.fire({
						icon: 'success',
						title: 'Success',
						text: successMsg,
					})
					handleClose(respData.success)
				} else {
					const errorsMessage = validationErrorMsg(respData.errors).join('<br>')
					Swal.fire({
						icon: 'error',
						title: 'Error',
						html: errorsMessage,
					})
				}
				setSubmitDisable(false);
				setLoading(false);
			} catch (error) {
				setLoading(false);
				console.log(error, 'catch the hoop')
				setSubmitDisable(false);
			}
		}
	}


	return (
		<Modal show={show} onHide={() => handleClose(false)} size="lg">
			<form onSubmit={srCategorySave}>
				<Modal.Header closeButton>
					<Modal.Title>{id === 0 ? 'Create' : 'Edit'} SR Category</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="form-group row">
						<label htmlFor="srCategoryName" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Name <span className="required text-danger"></span></label>
						<div className="col-lg-5">
							<input type="text" name="srCategoryName" id="srCategoryName" value={srCategory.srCategoryName} onChange={handleChange} className="form-control form-control-sm" />
						</div>
					</div>
					{/* <pre>{JSON.stringify(company, null, 2)}</pre> */}
				</Modal.Body>
				<Modal.Footer>

					<button type="submit" disabled={isSubmitDisable || loading} className="btn btn-sm btn-primary me-2">
						{loading &&
							<Spinner
								as="span"
								animation="grow"
								size="sm"
								role="status"
								aria-hidden="true"
							/>
						}
						<span>  Submit</span>
					</button>
					<Button variant='secondary' size="sm" onClick={() => handleClose(false)}>
						Close
					</Button>
				</Modal.Footer>
			</form>
		</Modal>
	)
}

export default SrCategoryCreate
