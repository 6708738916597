import { useState, useEffect } from 'react'
import { KTSVG } from '../../_metronic/helpers'
import { Button, Form } from 'react-bootstrap'
import Select from 'react-select'
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper';
import showLoader from "../helpers/ShowLoader"
import Swal from 'sweetalert2'
import { useHistory, useParams } from "react-router-dom"
import EmailForm from '../components/EmailForm';
import PhoneForm from '../components/PhoneForm';
import validationErrorMsg from '../helpers/ValidationErrorMsg'

function CompanyEdit() {

    const initialState = () => ({
        companyName: '',
        companyAddress: '',
        companyWebsite: '',
        companyLogo: '',
        idCompanyCategories: '',
        internal: 'No',
        emails: [],
        phones: [],
    })
    let history = useHistory();
    const { id } = useParams();
    const [formData, setFormData] = useState(initialState());
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState({ label: 'Select Category', value: '' });

    useEffect(() => {
        if (id !== undefined) {
            getCompanyEditInfoById();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    useEffect(() => {
        getAllCompanyCategories();
    }, [])

    const getCompanyEditInfoById = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'company/edit/' + id,
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {

                setFormData({
                    ...formData,
                    idCompanies: resp.data.idCompanies,
                    companyName: resp.data.companyName,
                    companyAddress: resp.data.companyAddress,
                    companyWebsite: resp.data.companyWebsite,
                    companyLogo: resp.data.companyLogo,
                    internal: resp.data.internal,
                    idCompanyCategories: resp.data.idCompanyCategories,
                    emails: resp.data.emails,
                    phones: resp.data.phones,
                    defaultCreditLimit: resp.data.defaultCreditLimit,
                    maximumCreditLimit: resp.data.maximumCreditLimit
                });
                setCategory({ label: resp.data.company_category.name, value: resp.data.company_category.id, id: resp.data.company_category.id });
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const getAllCompanyCategories = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'companies/categories',
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let companyCategories = resp.data.map((item) => {
                    item.label = item.name;
                    item.value = item.id;
                    return item
                })
                setCategories(companyCategories);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const handleChange = (e) => {
        if (e.target.name === 'internal') {
            setFormData({ ...formData, [e.target.name]: (e.target.value === 'No' ? 'Yes' : 'No') });
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }
    };

    const handleCategoryChange = category => {
        setCategory(category);
        setFormData({ ...formData, idCompanyCategories: category.id });
    }

    const getEmailsData = (data) => {
        setFormData({ ...formData, emails: data });
    }

    const getPhonesData = (data) => {
        setFormData({ ...formData, phones: data });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        showLoader()
        const requestOptions = postRequestOptions(formData)
        try {
            let response = await fetch(
                `${process.env.REACT_APP_API_URL}company/update`,
                requestOptions
            )
            let respData = await response.json()
            Swal.close();
            if (respData.success === true) {
                const successMsg = respData.successMessage
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: successMsg,
                })
                getCompanyEditInfoById();
            } else {
                const errorsMessage = validationErrorMsg(respData.errors).join('<br>')
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    html: errorsMessage,
                })
            }

        } catch (error) {
            console.log(error, 'catch the hoop')
            Swal.close();
        }

    }


    return (
        <>
            <div className='card card-custom'>
                <div className='card-header'>
                    <div className="card-title">
                        <KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-1 svg-icon-primary' />
                        <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
                            Edit Client
                        </span>
                    </div>
                </div>

                <div className='card-body'>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group row">
                            <label htmlFor="companyName" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Name <span className="required text-danger"></span></label>
                            <div className="col-lg-5">
                                <input type="text" name="companyName" id="companyName" value={formData.companyName} onChange={handleChange} className="form-control form-control-sm" />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="idCompanyCategories" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Category <span className="required text-danger"></span></label>
                            <div className="col-lg-5">
                                <Select
                                    value={category}
                                    onChange={handleCategoryChange}
                                    options={categories}
                                    menuPosition="fixed"
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="companyAddress" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Address</label>
                            <div className="col-lg-5">
                                <input type="text" name="companyAddress" id="companyAddress" value={formData.companyAddress} onChange={handleChange} className="form-control form-control-sm" />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="companyWebsite" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Website</label>
                            <div className="col-lg-5">
                                <input type="text" name="companyWebsite" id="companyWebsite" value={formData.companyWebsite} onChange={handleChange} className="form-control form-control-sm" />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="email" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Email</label>
                            <div className="col-lg-5">
                                <EmailForm getEmailsData={getEmailsData} emails={formData.emails}></EmailForm>
                            </div>

                        </div>
                        <div className="form-group row">
                            <label htmlFor="email" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Phone</label>
                            <div className="col-lg-5">
                                <PhoneForm getPhonesData={getPhonesData} phones={formData.phones}></PhoneForm>
                            </div>

                        </div>

                        {/* Default credit limit amount */}
                        <div className="form-group row">
                            <label htmlFor="defaultCreditLimit" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Default Credit Limit</label>
                            <div className="col-lg-5">
                                <input type="number" name="defaultCreditLimit" id="defaultCreditLimit" value={formData.defaultCreditLimit} onChange={handleChange} className="form-control form-control-sm" />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="maximumCreditLimit" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Maximum Credit Limit</label>
                            <div className="col-lg-5">
                                <input type="number" name="maximumCreditLimit" id="maximumCreditLimit" value={formData.maximumCreditLimit} onChange={handleChange} className="form-control form-control-sm" />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="internal" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}></label>
                            <div className="col-lg-5">
                                <Form.Check // prettier-ignore
                                    type={"checkbox"}
                                    id={`default-checkbox-sc`}
                                    label={`Internal`}
                                    value={formData.internal}
                                    className='me-10 form-check-solid'
                                    name="internal"
                                    checked={formData.internal === 'Yes'}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="form-group row mt-4">
                            <label className="col-lg-3 col-form-label"></label>
                            <div className="col-md-5">
                                <button type="submit" className="btn btn-sm btn-primary me-2"><span>  Submit</span></button>
                                <Button variant='secondary' size="sm" onClick={() => history.goBack()}>
                                    Close
                                </Button>
                            </div>
                        </div>
                        <pre>{JSON.stringify(formData, null, 2)}</pre>
                    </form>
                </div>
            </div>

        </>
    )
}

export default CompanyEdit
