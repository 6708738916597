/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button, Form } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
import Select, { components } from 'react-select';
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper';
import AsyncSelect from 'react-select/async'
import { Link } from 'react-router-dom';

interface FormData {
    voucherDate: string;
    voucherType: string;
    idProjects: string;
    idSr: string;
    narration: string;
}

// Define the shape of the ledger entry
interface ILedgerEntry {
    ledger: string;
    drAmount: string;
    crAmount: string;
    remarks: string;
}

interface JournalProps {
    show: boolean;
    handleClose: (show: boolean) => void;
    id: number;
}

const LedgerSingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
        <>
            <div className='row'>
                <span> {props.data.label} </span>
            </div>
        </>
    </components.SingleValue>
)

const JournalModal: React.FC<JournalProps> = ({ show, handleClose, id }) => {

    const [isSubmitDisable] = useState(false);
    const [loading, setLoading] = useState(false);

    const [projects, setProjects] = useState([]);
    const [project, setProject] = useState({ label: 'Select Project', value: '' });

    const [companySelectAbility, setCompanySelectAbility] = useState(false);
    const [srs, setSrs] = useState([]);
    const [sr, setSr] = useState({ label: 'Select SR', value: '' });

    const voucherTypes = [
        { value: 'journal', label: 'Journal' },
        { value: 'contra', label: 'Contra' },
        { value: 'payment', label: 'Payment' },
        { value: 'receipt', label: 'Receipt' },
    ];

    const [voucherType, setVoucherType] = useState({ label: 'Journal', value: 'journal' });

    const [defaultLedgers, setDefaultLedgers] = useState([]);

    // Define state for form fields here
    const [formData, setFormData] = useState<FormData>({
        voucherDate: '',
        voucherType: 'journal',
        idProjects: '',
        idSr: '',
        narration: '',
    });

    // Define state for form fields here
    const [entries, setEntries] = useState<ILedgerEntry[]>([
        { ledger: '', drAmount: '', crAmount: '', remarks: '' },
    ]);

    useEffect(() => {
        setEntries([{ ledger: '', drAmount: '', crAmount: '', remarks: '' }]);
        setDefaultLedgers([]);
    }, [id]);

    useEffect(() => {
        getProjects();
    }, [])


    const getProjects = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'lead/projects',
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let projectObj = resp.data.map((item: any) => {
                    item.label = item.name;
                    item.value = item.id;
                    return item
                })
                setProjects(projectObj);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const getLedgers = (inputValue: any, callback: any, voucherType = '') => {
        voucherType = voucherType === '' ? formData.voucherType : voucherType;

        let url = '';
        if (!inputValue) {
            url = `${process.env.REACT_APP_API_URL}transaction/ledgers?type=` + voucherType+`&idProjects=`+formData.idProjects;
        }
        else {
            url = `${process.env.REACT_APP_API_URL}transaction/ledgers?type=` + voucherType+`&idProjects=`+formData.idProjects + `&q=` + inputValue;
        }
        setTimeout(() => {
            fetch(url,
                getRequestOptions())
                .then((resp) => {
                    return resp.json()
                })
                .then((resp) => {
                    if (resp.data !== null) {
                        let tempArray = resp.data.map(function (element: any) {
                            element.label = `${element.ledgerCode} ${element.ledgerName}`;
                            element.value = element.idAccLedgers;
                            // element.ledgerPath = element.ledgerPath;
                            return element;
                        });
                        setDefaultLedgers(tempArray);
                        callback(tempArray);
                    }
                    else {
                        callback([]);
                    }
                })
                .catch((error) => {
                    console.log(error, "catch the hoop")
                });
        })


    }

    const getProjectSrs = (idProjects: number) => {
        fetch(
            process.env.REACT_APP_API_URL + 'getAllSr?idProjects=' + idProjects,
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let srsObj = resp.map((item: any) => {
                    item.label = item.first_name + ' ' + item.last_name;
                    item.value = item.id;
                    return item
                })
                setSrs(srsObj);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const handleSrChange = (sr: any) => {
        setSr(sr);
        setFormData({ ...formData, idSr: sr.value });
    }

    // Handle project change
    const handleProjectChange = (project: any) => {
        setProject(project);
        setFormData({ ...formData, idProjects: project.value });

        getProjectSrs(project.value);
        setCompanySelectAbility(false)
    }

    // Handle voucher type change
    const handleVoucherTypeChange = (voucherType: any) => {
        setVoucherType(voucherType);
        setFormData({ ...formData, voucherType: voucherType.value });
        setEntries([{ ledger: '', drAmount: '', crAmount: '', remarks: '' }]);
        getLedgers('', (data: any) => { }, voucherType.value);
    };

    // Handle input changes
    const handleInputChange = (index: number, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newEntries = [...entries];
        const { name, value } = event.target;

        const currentEntry = { ...newEntries[index] };
        if (name === 'drAmount') {
            currentEntry.drAmount = value;
            currentEntry.crAmount = ''; // Clearing Credit Amount if Debit Amount is entered
        } else if (name === 'crAmount') {
            currentEntry.crAmount = value;
            currentEntry.drAmount = ''; // Clearing Debit Amount if Credit Amount is entered
        } else {
            // Assuming ILedgerEntry has a type definition that includes [key: string]: any
            currentEntry[name as keyof ILedgerEntry] = value;
        }

        newEntries[index] = currentEntry; // Correctly updating the entry at the given index
        setEntries(newEntries);

    };

    // Handle adding new row
    const handleAddRow = () => {
        setEntries([...entries, { ledger: '', drAmount: '', crAmount: '', remarks: '' }]);
    };

    // Handle removing row
    const handleRemoveRow = (index: number) => {
        const newEntries = [...entries];
        newEntries.splice(index, 1);
        setEntries(newEntries);
    };

    // Handle ledger change
    const handleLedgerChange = (selectedOption: any, index: number) => {
        const updatedEntries = entries.map((entry, idx) => {
            if (idx === index) {
                return { ...entry, ledger: selectedOption, drAmount: '', crAmount: '' };
            }
            return entry;
        });

        setEntries(updatedEntries);
    };


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true);
        // Correctly constructing postData
        const postData = {
            ...formData,  // Spreads all formData properties
            entries: entries // Adds entries array
        };

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + 'transaction/store', postRequestOptions(postData));

            const data = await response.json();
            // console.log(data, 'data');

            if (data.success) {
                toast(<strong>Voucher created successfully</strong>, { type: 'success', position: 'bottom-right', autoClose: 3000 });
                handleClose(false);
            } else {

                if (data?.errorMessage) {
                    toast(data?.errorMessage, { type: 'error', position: 'bottom-right', autoClose: 4000 });
                }

                for (const i in data.errors) {
                    toast(<strong>{data.errors[i].join(' ')}</strong>, { type: 'error', position: 'bottom-right', autoClose: 4000 });
                }
            }
        } catch (error) {
            console.error('Error submitting data:', error);
        } finally {
            setLoading(false);
        }
    };


    return (<Modal show={show} onHide={() => handleClose(false)}  fullscreen={true} animation={true} dialogClassName="modal-dialog-scrollable">
        <Modal.Header closeButton>
            <Modal.Title>Create Voucher</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
            <Modal.Body className="modal-body-custom" style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                <div className="row">
                    <div className="col-12">
                        <div className="alert alert-warning" role="alert">Please add payment against sales from <Link to={`/sales-invoices`} className="text-primary">Invoice page</Link> or <Link to={`/sales-order-create`} className="text-primary">Sales Order page.</Link></div>
                    </div>
                </div>
                <div className="row">
                    <div className="offset-md-2 col-md-6">
                        <Form.Group className="mb-3 row" controlId="project">
                            <Form.Label className='col-md-4 text-end'>Project<span className="required text-danger"></span></Form.Label>
                            <div className="col-md-8">
                                <Select
                                    value={project}
                                    onChange={handleProjectChange}
                                    options={projects}
                                    menuPosition="fixed"
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                />
                            </div>
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="offset-md-2 col-md-6">
                        <Form.Group className="mb-3 row" controlId="project">
                            <Form.Label className='col-md-4 text-end'>Select SR</Form.Label>
                            <div className="col-md-8">
                                <Select
                                    value={sr}
                                    onChange={handleSrChange}
                                    options={srs}
                                    isDisabled={companySelectAbility}
                                    menuPosition="fixed"
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                />
                            </div>
                        </Form.Group>
                    </div>
                </div>
                
                <div className="row">
                    <div className="offset-md-2 col-md-6">
                        <Form.Group className="mb-3 row" controlId="voucherType">
                            <Form.Label className='col-md-4 text-end'>Voucher Type</Form.Label>
                            <div className="col-md-8">
                                <Select
                                    value={voucherType}
                                    onChange={handleVoucherTypeChange}
                                    options={voucherTypes}
                                    menuPosition="fixed"
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                />

                            </div>
                        </Form.Group>
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="offset-md-2 col-md-6">
                        <Form.Group className='row' controlId="voucherDate">
                            <Form.Label className='mb-3 col-md-4 text-end'>Date<span className="required text-danger"></span></Form.Label>
                            <div className="col-md-8">
                                <Form.Control
                                    type="date"
                                    name="voucherDate"
                                    value={formData.voucherDate}
                                    onChange={handleChange}
                                    className='form-control-sm'
                                />
                            </div>
                        </Form.Group>
                    </div>
                </div>



                <Table bordered responsive>
                    <thead>
                        <tr>
                            <th style={{ width: '40%' }}>General Ledger</th>
                            <th style={{ width: '10%' }}>Dr Amount (Tk)</th>
                            <th style={{ width: '10%' }}>Cr Amount (Tk)</th>
                            <th style={{ width: '20%' }}>Remarks</th>
                            <th style={{ width: '10%' }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {entries.map((entry, index) => (
                            <tr key={index}>
                                <td>
                                    <AsyncSelect
                                        value={entry.ledger}
                                        loadOptions={getLedgers}
                                        placeholder="Please select ledger"
                                        defaultOptions={defaultLedgers}
                                        onChange={(selectedOption) => handleLedgerChange(selectedOption, index)}
                                        menuPosition="fixed"
                                        isClearable={true}
                                        components={{ SingleValue: LedgerSingleValue }}
                                        getOptionLabel={(option) => (
                                            <>
                                                <i className='fa fa-file'></i>
                                                <span> {option.label} </span><br></br>
                                                <i className='fa fa-map-marker'></i>
                                                <span> {option.ledgerPath} </span>
                                            </>
                                        )}

                                    />

                                </td>
                                <td>
                                    <Form.Control
                                        type="number"
                                        name="drAmount"
                                        value={entry.drAmount}
                                        onChange={(e) => handleInputChange(index, e)}
                                        min={0}
                                        step={'any'}
                                        disabled={((formData.voucherType === 'payment' && entry?.ledger?.idBankCash !== null) || (formData.voucherType === 'receipt' && entry?.ledger?.idBankCash == null)) ? true : false}
                                    />
                                </td>
                                <td>
                                    <Form.Control
                                        type="number"
                                        name="crAmount"
                                        value={entry.crAmount}
                                        onChange={(e) => handleInputChange(index, e)}
                                        min={0}
                                        step={'any'}
                                        disabled={((formData.voucherType === 'receipt' && entry?.ledger?.idBankCash !== null) || (formData.voucherType === 'payment' && entry?.ledger?.idBankCash == null)) ? true : false}
                                    />
                                </td>
                                <td>
                                    <Form.Control
                                        type="text"
                                        name="remarks"
                                        value={entry.remarks}
                                        onChange={(e) => handleInputChange(index, e)}
                                    />
                                </td>
                                <td>
                                    <div className='d-flex'>
                                        {entries.length > 1 &&
                                            <Button className='btn btn-sm ms-2' variant="danger" onClick={() => handleRemoveRow(index)}>
                                                <i className='fa fa-trash'></i>
                                            </Button>
                                        }

                                        {index === entries.length - 1 && (
                                            <Button className='btn btn-sm ms-2' variant="primary" onClick={handleAddRow}>
                                                <i className='fa fa-plus'></i>
                                            </Button>
                                        )}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

                <Form.Group controlId="formNarration">
                    <Form.Label>Narration</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        name="narration"
                        value={formData.narration}
                        onChange={handleChange}
                    />
                </Form.Group>

            </Modal.Body>
            <Modal.Footer>

                <button type="submit" disabled={isSubmitDisable || loading} className="btn btn-sm btn-primary me-2">
                    {loading &&
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    }
                    <span> <i className='fa fa-save'></i> Submit</span>
                </button>
                <Button variant='secondary' size="sm" onClick={() => handleClose(false)}>
                    Close
                </Button>
            </Modal.Footer>
            {/* <pre>{JSON.stringify(formData, null, 2)}</pre>
            <pre>{JSON.stringify(entries, null, 2)}</pre> */}
        </Form>
    </Modal>)
}

export default JournalModal