/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import { userPermissionContext } from '../context/UserPagePermission';

function GetPagePermission(value) {
	const { pagesLink } = useContext(userPermissionContext);
	const [hasPermission, setPermission] = useState(0);
	
	useEffect(() => {
		if (pagesLink && pagesLink.length > 0) {
            // Check if any page link matches the provided value
            const hasPermission = pagesLink.some(link => link.pagesLink === value);
            setPermission(hasPermission ? 1 : 0); // Set permission based on whether any match is found
        } else {
            setPermission(0); // If pagesLink is empty or undefined, set permission to 0
        }

	}, [pagesLink, value]);


	return hasPermission;

}
export default GetPagePermission;
