import { useState } from 'react'
import DataTable from './DataTable'
import { KTSVG } from '../../_metronic/helpers'
import Swal from 'sweetalert2'
import ProductGroupCreate from './ProductGroupCreate';
import 'react-loading-skeleton/dist/skeleton.css';

function ProductGroupList() {

    const hasPermission = 1; //parseInt(GetPagePermission("/setup-company-list"));
    const [newData, setNewData] = useState({});
    const [createItemModalShow, setCreateItemModalShow] = useState(false);
    const [id, setId] = useState(0);

    const handleCreateItemModalShow = () => {
        setId(0)
        setCreateItemModalShow(true)
    }

    const handleCreateItemModalClose = (isReload: boolean) => {
        setCreateItemModalShow(false);
        if (isReload) {
            setNewData({ id: Date.now() });
        }
    }

    const editItem = (id: number): void => {
        setId(id);
        setCreateItemModalShow(true);
    };

    const deleteItem = (id: number): void => {
        if (window.confirm("Are you sure you want to delete this Product Group?")) {
            const myToken: string | null = localStorage.getItem('MyToken');
            let jwt: string | null = (myToken != null) ? JSON.parse(myToken) : null;
            fetch(`${process.env.REACT_APP_API_URL}items/${id}`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwt}` }
            })
                .then((resp: Response) => {
                    return resp.json();
                })
                .then((resp: any) => {
                    if (resp.success) {
                        const successMsg: string = resp.successMessage;
                        Swal.fire({
                            icon: 'success',
                            title: 'Deleted!',
                            text: successMsg,
                        });
                        setNewData({ id: Date.now() });
                    }
                })
                .catch((error: any) => {
                    console.log(error, 'catch the hoop');
                });
        }
    };

    const columns = [
        { value: 'id', title: 'Id', style: { textAlign: 'center' } },
        { value: 'itemName', title: 'Name', style: { textAlign: 'left' } },
        { value: 'itemCode', title: 'Code', style: { textAlign: 'left' } },
        { value: 'unitName', title: 'Unit', style: { textAlign: 'left' } },
        {
            value: 'action', title: 'Action', style: { textAlign: 'left', whiteSpace: 'nowrap' }, hasActionsComponent: true, actionsComponent: ((row: any) => {
                return (
                    <>
                        <button className="btn btn-light-danger btn-sm p-2" onClick={() => editItem(row.idItemCategories)}>
                            <button className="btn btn-light-primary btn-sm">
                                <KTSVG
                                    path='/media/svg/icons/Communication/Write.svg'
                                    className='svg-icon-5 svg-icon'
                                />		Edit
                            </button>
                        </button>
                        <button className="btn btn-light-danger btn-sm p-2" onClick={() => deleteItem(row.idItemCategories)}>
                            <KTSVG
                                path='/media/svg/icons/General/Trash.svg'
                                className='svg-icon-5 svg-icon'
                            />		Delete
                        </button>
                    </>
                )
            })
        }
    ]

    const filters = {
        id: {
            filterType: 'like',
            filterValue: '',
        },
        itemName: {
            filterType: 'like',
            filterValue: '',
        },
        itemCode: {
            filterType: 'like',
            filterValue: '',
        },
        unitName: {
            filterType: 'like',
            filterValue: '',
        },
    }

    const url = process.env.REACT_APP_API_URL + 'items'

    const additionalPostData = {
        type: 'product',
    };

    return (
        <>
            {hasPermission === 1 &&
                <div className='card card-custom'>
                    <div className='card-header'>
                        <div className="card-title">
                            <KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-1 svg-icon-primary' />
                            <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
                                Product Group List
                            </span>
                        </div>
                        <div className="card-toolbar">
                            <div className='d-flex justify-content-end'>
                                <button type="button" className="btn btn-sm btn-primary" onClick={() => handleCreateItemModalShow()}><i className="fa fa-plus"></i>Add Product Group</button>
                            </div>
                        </div>
                    </div>

                    <div className='card-body'>
                        <DataTable url={url} columns={columns} filters={filters} newData={newData} additionalPostData={additionalPostData} />
                    </div>
                </div>
            }

            <ProductGroupCreate show={createItemModalShow} handleClose={handleCreateItemModalClose} id={id} />

            {/* {hasPermission === 0 &&
                <Error />
            }
            {hasPermission === 0 &&
                <div className='card card-custom' style={{ padding: "15px" }} >
                    <SkeletonTheme>
                        <p>
                            <Skeleton
                                containerClassName="avatar-skeleton"
                                count={40} ></Skeleton>
                        </p>
                    </SkeletonTheme>
                </div>
            } */}

        </>
    )
}

export default ProductGroupList
