import { useState } from "react";
import { Button, Form } from "react-bootstrap-v5";
import { KTSVG } from '../../_metronic/helpers';
import { iconArray } from "./LeadActivities";
import { postRequestOptions } from '../helpers/Fetchwrapper';
import validationErrorMsg from '../helpers/ValidationErrorMsg'
import Swal from 'sweetalert2'
import scrollTo from "../helpers/ScrollTo";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

interface EventSubmitDataType {
    idLeads?: number,
    activityType?: string,
    activityAt?: string,
    activityDescription?: string,
    content?: string,
    activityStatus?: string,
}

interface AddEventProps {
    idLeads: number,
    getLeadInfo: any,
}

export default function AddEvent({ idLeads, getLeadInfo }: AddEventProps) {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedMethod, setSelectedMethod] = useState('visit');
    const [location, setLocation] = useState<any>(null);



    const [eventSubmitData, setEventSubmitData] = useState<EventSubmitDataType>({ activityType: 'visit', activityStatus: 'done' });

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        if (name === 'activityAt' || name === 'activityDescription' || name === 'content') {
            setEventSubmitData({ ...eventSubmitData, [name]: value.toString() });
        } else if (name === 'activityStatus') {
            setEventSubmitData({ ...eventSubmitData, [name]: value.toString() });
        }
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setIsLoading(true);
        console.log(idLeads);
        setEventSubmitData({ ...eventSubmitData, activityType: selectedMethod, idLeads: idLeads });

        const requestOptions = postRequestOptions({ ...eventSubmitData, activityType: selectedMethod, idLeads: idLeads, location: location })
        try {
            let response = await fetch(
                `${process.env.REACT_APP_API_URL}lead/add-event`,
                requestOptions
            )
            let respData = await response.json()
            setIsLoading(false);
            if (respData.success === true) {
                getLeadInfo();
                window.scrollBy(0, 30);
                scrollTo(180, 20);
            } else {
                const errorsMessage = validationErrorMsg(respData.errors).join('<br>')
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    html: errorsMessage,
                })
            }

        } catch (error) {
            setIsLoading(false);
        }

    }

    const onChangeLocation = (data: any) => {
        setLocation(JSON.stringify(data));
    }


    return (<>

        <div className="row">
            {['visit', 'meeting', 'call', 'sms', 'email'].map((item, index) => {
                return <div key={'at_' + index} className="col-sm-2">
                    <button onClick={() => { setSelectedMethod(item) }} className={"w-100 btn btn-outline btn-outline-dashed p-2 d-flex align-items-center justify-content-between mb-10 btn-active-light-" + (item === selectedMethod ? "primary" : "secondary")}>
                        <span className="d-block fw-semibold text-start text-dark fs-7">
                            {iconArray[item].text}
                        </span>
                        <KTSVG className="svg-icon-3" path={iconArray[item].icon} />
                    </button>
                </div>
            })}
        </div>
        <Form onSubmit={handleSubmit}>
            <div className='fv-row mb-5 d-flex flex-start'>
                <Form.Check // prettier-ignore
                    type={"radio"}
                    id={`default-checkbox-sc`}
                    label={`Scheduled`}
                    value={"scheduled"}
                    className='me-10 form-check-solid'
                    name="activityStatus"
                    checked={eventSubmitData.activityStatus === 'scheduled'}
                    onChange={handleChange}
                />
                <Form.Check // prettier-ignore
                    type={"radio"}
                    id={`default-checkbox-do`}
                    label={`Done`}
                    value={"done"}
                    className='me-10 form-check-solid'
                    name="activityStatus"
                    checked={eventSubmitData.activityStatus === 'done'}
                    onChange={handleChange}
                />
            </div>
            <div className='fv-row mb-4'>
                <label className='form-label required'>Purpose:</label>
                <Form.Control placeholder="e.g. visit to factoy / call with manager" name='activityDescription' type="text" size={"sm"} className='form-control-solid' onChange={handleChange} />
                <div className='text-danger mt-2'>
                    <Form.Control.Feedback />
                </div>
            </div>

            <div className='fv-row mb-4'>
                <label className='d-flex align-items-center form-label'>
                    <span style={{ textTransform: "capitalize" }} className='required'>{selectedMethod} date:</span>
                </label>

                <Form.Control
                    name='activityAt'
                    size={"sm"}
                    className='form-control-solid'
                    type="datetime-local"
                    onChange={handleChange}
                    max={eventSubmitData.activityStatus === 'done' ? new Date().toISOString().split('T')[0] + ' 23:59:59' : ''}
                    min={eventSubmitData.activityStatus !== 'done' ? new Date().toISOString().split('T')[0] + ' 00:00:00' : ''}
                />
                <div className='text-danger mt-2'>
                    <Form.Control.Feedback />
                </div>
            </div>

            <div className='fv-row mb-4'>
                <label className='form-label'>Outcome / Discussion:</label>

                <Form.Control
                    as='textarea'
                    name='content'
                    className='form-control-solid'
                    rows={3}
                    size={"sm"}
                    placeholder='meeting minutes, discussion points, email body etc.'
                    onChange={handleChange}
                ></Form.Control>
            </div>
            {(selectedMethod === 'visit' || selectedMethod === 'meeting') &&
                <div className='fv-row mb-4'>
                    <label className='form-label'>Location:</label>
                    <div>
                        <GooglePlacesAutocomplete
                            apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
                            selectProps={{
                                value: JSON.parse(location),
                                onChange: (data) => { onChangeLocation(data) },
                            }}
                            // Use searchOptions to restrict results to Bangladesh
                            autocompletionRequest={{
                                componentRestrictions: { country: ['bd'] }, // Restrict to Bangladesh
                            }}
                        />
                    </div>
                </div>
            }
            <div className="d-flex justify-content-end px-3">
                <Button disabled={isLoading} variant="success" size="sm" type="submit">
                    {isLoading ? <i className='fa fa-spin fa-spinner'></i> : <><KTSVG className="svg-icon-3" path="/media/svg/icons/Communication/Send.svg" /> Submit</>}
                </Button>
            </div>
            {/* <pre>{JSON.stringify(location, null, 2)}</pre> */}
        </Form>
    </>)
}