import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import { getRequestOptions, postRequestOptions } from "../helpers/Fetchwrapper";
import validationErrorMsg from "../helpers/ValidationErrorMsg";
import { KTSVG } from '../../_metronic/helpers'
import Error from '../components/Error500'
import GetPagePermission from "../components/GetPagePermission";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function GeneralConfiguration(props) {

	const hasPermission = GetPagePermission("/setup-general-configuration");
	const [emailRequisitionApprove, setEmailRequisitionApprove] = useState(false);
	const [emailRequisitionReceive, setEmailRequisitionReceive] = useState(false);
	const [emailRequisitionTaskComplete, setEmailRequisitionTaskComplete] = useState(false);
	const [emailMaintenanceUpcomingTask, setEmailMaintenanceUpcomingTask] = useState(false);
	const [emailSendBackForReview, setEmailSendBackForReview] = useState(false);

	useEffect(() => {
		getEmailConfigurationData();
	}, [])

	const getEmailConfigurationData = () => {
		fetch(process.env.REACT_APP_API_URL + "email_configuration_data",
			getRequestOptions())
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				setEmailRequisitionApprove(resp.data.find(function (row) {
					return row.settings_key === 'requisition_approve';
				}).is_enabled === 'yes' ? true : false);
				setEmailRequisitionReceive(resp.data.find(function (row) {
					return row.settings_key === 'requisition_receive';
				}).is_enabled === 'yes' ? true : false);
				setEmailRequisitionTaskComplete(resp.data.find(function (row) {
					return row.settings_key === 'requisition_task_complete';
				}).is_enabled === 'yes' ? true : false);
				setEmailMaintenanceUpcomingTask(resp.data.find(function (row) {
					return row.settings_key === 'maintenance_upcoming_task';
				}).is_enabled === 'yes' ? true : false);
				setEmailSendBackForReview(resp.data.find(function (row) {
					return row.settings_key === 'send_back_for_review';
				}).is_enabled === 'yes' ? true : false);
			})
			.catch((error) => {
				console.log(error, "catch the hoop")
			});
	}





	const handleSubmit = (evt) => {
		evt.preventDefault()

		Swal.fire({
			title: 'Are you sure?',
			text: 'You wont to save configuration data',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No',
			confirmButtonColor: '#ec008c'
		}).then((result) => {
			if (result.value) {
				const formData = {
					emailRequisitionApprove: emailRequisitionApprove,
					emailRequisitionReceive: emailRequisitionReceive,
					emailRequisitionTaskComplete: emailRequisitionTaskComplete,
					emailMaintenanceUpcomingTask: emailMaintenanceUpcomingTask,
					emailSendBackForReview: emailSendBackForReview,
				}

				const requestOptions = postRequestOptions(formData);

				const apiEnd = 'configuration_data_save';

				fetch(process.env.REACT_APP_API_URL + apiEnd, requestOptions)
					.then((resp) => {
						return resp.json()
					})
					.then((resp) => {
						console.log(resp)

						if (resp.success === true) {
							const successMsg = resp.successMessage;

							Swal.fire({
								icon: 'success',
								title: 'Success',
								text: successMsg,
							})
							getEmailConfigurationData();

						} else {

							const errorsMessage = validationErrorMsg(resp.errors).join("<br>");
							Swal.fire({
								icon: 'error',
								title: 'Error',
								html: errorsMessage,
							})
						}
					})
					.catch((error) => {
						console.log(error, 'catch the hoop')
					})
			}
		})
	}

	return (
		<>
			{hasPermission === 1 &&
				<div className='card card-custom'>
					<div className='card-header'>
						<div className='card-title'>
							<KTSVG
								path='/media/svg/icons/Code/Settings4.svg'
								className='svg-icon-1 svg-icon-primary'
							/>
							<span className='text fw-bolder' style={{ marginLeft: '10px' }}>
								General Configurations
							</span>
						</div>
					</div>

					<div className='card-body'>
						<div className='ml-12'>
							<div className="col-md-12 mb-5">
								<h5 className="font-dark bold">
									Email Configurations
								</h5>
							</div>
							<form onSubmit={handleSubmit}>

								<div className='form-group row mb-5'>
									<div className='col-lg-2 col-md-3' style={{ textAlign: 'left' }}>
										Send Email On Requisition Approve
									</div>
									<div className='col-lg-6'>
										<label className='form-check form-switch form-check-custom form-check-solid'>
											<span>
												<input className='form-check-input' type='checkbox' checked={emailRequisitionApprove} value={emailRequisitionApprove} onChange={(evt) => setEmailRequisitionApprove(!emailRequisitionApprove)} />
											</span>
											<span className='form-check-label fw-semibold text-muted'>Yes</span>
										</label>
									</div>
								</div>

								<div className='form-group row mb-5'>
									<div className='col-lg-2 col-md-3' style={{ textAlign: 'left' }}>
										Send Email On Requisition Receive
									</div>
									<div className='col-lg-6'>
										<label className='form-check form-switch form-check-custom form-check-solid'>
											<span>
												<input className='form-check-input' type='checkbox' checked={emailRequisitionReceive} value={emailRequisitionReceive} onChange={(evt) => setEmailRequisitionReceive(!emailRequisitionReceive)} />
											</span>
											<span className='form-check-label fw-semibold text-muted'>Yes</span>
										</label>
									</div>
								</div>

								<div className='form-group row mb-5'>
									<div className='col-lg-2 col-md-3' style={{ textAlign: 'left' }}>
										Send Back for Review Email
									</div>
									<div className='col-lg-6'>
										<label className='form-check form-switch form-check-custom form-check-solid'>
											<span>
												<input className='form-check-input' type='checkbox' checked={emailSendBackForReview} value={emailSendBackForReview} onChange={(evt) => setEmailSendBackForReview(!emailSendBackForReview)} />
											</span>
											<span className='form-check-label fw-semibold text-muted'>Yes</span>
										</label>
									</div>
								</div>


								<div className='form-group row mb-5'>
									<div className='col-lg-2 col-md-3' style={{ textAlign: 'left' }}>
										Send Email On Requisition Task Complete
									</div>
									<div className='col-lg-6'>
										<label className='form-check form-switch form-check-custom form-check-solid'>
											<span>
												<input className='form-check-input' type='checkbox' checked={emailRequisitionTaskComplete} value={emailRequisitionTaskComplete} onChange={(evt) => setEmailRequisitionTaskComplete(!emailRequisitionTaskComplete)} />
											</span>
											<span className='form-check-label fw-semibold text-muted'>Yes</span>
										</label>
									</div>
								</div>

								<div className='form-group row mb-5'>
									<div className='col-lg-2 col-md-3' style={{ textAlign: 'left' }}>
										Send Email On Maintenance Upcoming Task
									</div>
									<div className='col-lg-6'>
										<label className='form-check form-switch form-check-custom form-check-solid'>
											<span>
												<input className='form-check-input' type='checkbox' checked={emailMaintenanceUpcomingTask} value={emailMaintenanceUpcomingTask} onChange={(evt) => setEmailMaintenanceUpcomingTask(!emailMaintenanceUpcomingTask)} />
											</span>
											<span className='form-check-label fw-semibold text-muted'>Yes</span>
										</label>
									</div>
								</div>


								<div className='row'>
									<div className='col-2'></div>
									<div className='col-6'>
										<input
											type='submit'
											className='btn btn-sm btn-success mt-2'
											value="Save"
										/>
									</div>
								</div>

								{/* <pre>
								{JSON.stringify(emailMaintenanceUpcomingTask, null, 2)}
							</pre> */}

							</form>
						</div>
					</div>
				</div>
			}
			{hasPermission === 0 &&
				<Error />
			}
			{hasPermission === '' &&
				<div className='card card-custom' style={{ padding: "15px" }} >
					<SkeletonTheme>
						<p>
							<Skeleton
								containerClassName="avatar-skeleton"
								count={40} ></Skeleton>
						</p>
					</SkeletonTheme>
				</div>
			}
		</>
	)
}

export default GeneralConfiguration
