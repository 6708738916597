/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { getRequestOptions } from '../helpers/Fetchwrapper';
import { KTSVG } from '../../_metronic/helpers';
import { Col, Row, Table } from 'react-bootstrap-v5';
import 'react-dropzone-uploader/dist/styles.css'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom';
import Dropzone from 'react-dropzone-uploader'
import Swal from 'sweetalert2'
import showLoader from '../helpers/ShowLoader';
import axios, { AxiosResponse, AxiosError } from 'axios'
import validationErrorMsg from '../helpers/ValidationErrorMsg'
import Error from '../components/Error500'
import GetPagePermission from "../components/GetPagePermission";
import ShipmentDetails from '../components/ShipmentDetails';


interface TransactionInfo {
    id_acc_vouchers: number;
    id_projects: number;
    voucherNo: string;
    voucherDate: string;
    voucherType: string;
    narration: string;
    refType: string;
    idRef: string;
    created_by: number;
    createdAt: string;
    project: {
        id_projects: number;
        projectName: string;
        projectShortName: string;
    };
    voucher_rows: { 
        id_acc_voucher_rows: number, 
        id_acc_vouchers: number, 
        id_acc_ledgers: number, 
        excludingRate: number, 
        dr_amount: number, 
        cr_amount: number, 
        drcr: number, 
        remarks: number, 
        ledger: any 
    }[];
    creator:any;
    sales_representative: any;
    files: { id_ref: number, refType:string, attachmentName: string, attachmentPath: string, fileSize: number, created_at: string, creator: any }[];
}

interface RouteParams {
    idAccVouchers: string;
}

export function TransactionDetails(props: any) {
    const hasPermission = GetPagePermission("/transactions");

    const [transactionInfo, setTransactionInfo] = useState<TransactionInfo | null>(null);
    const { idAccVouchers }: RouteParams = useParams();

    const [detailsShipmentModalShow, setDetailsShipmentModalShow] = useState(false);

    useEffect(() => {
        if (idAccVouchers !== undefined) {
            getTransactionInfo();
        }
    }, [idAccVouchers])

    const getTransactionInfo = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'transaction/' + idAccVouchers,
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                const data: TransactionInfo = resp.data;
                setTransactionInfo(data);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }


    const numberFormat = (value: any) => {

        value = parseFloat(value)
        if (typeof (value) !== 'number') {
            return value;
        }
        return parseFloat(value.toString()).toLocaleString('en-US', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }


    const getReadableFileSizeString = (fileSizeInBytes: number) => {
        return ((fileSizeInBytes / (1024 * 1024)).toFixed(4) + " MB");
    }

    const jwt = () => {
        const myToken = localStorage.getItem('MyToken');
        return (myToken != null) ? JSON.parse(myToken) : null;
    }

    const handleFileSubmit = (files: any, allFiles: []) => {
        showLoader()

        const postFormData = new FormData();
        postFormData.append('idRef', idAccVouchers.toString());
        postFormData.append('refType', 'acc_vouchers');
        postFormData.append('folderPath', 'vouchers');
        for (let i = 0; i < files.length; i++) {
            postFormData.append('files[]', files[i].file);
        }

        const url: string = `${process.env.REACT_APP_API_URL}file/upload`;

        axios({
            method: 'POST',
            url: url,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer ' + jwt(),
            },
            data: postFormData,
        })
        .then((response: AxiosResponse<any>) => {
            Swal.close();
            allFiles.forEach((f: any) => f.remove());
            getTransactionInfo();
        })
        .catch((error: AxiosError<any>) => {
            Swal.close();
            // const errorMessage: string = error.response?.data?.errorMessage;
            const errorsMessage: string = validationErrorMsg(error?.response?.data?.errors).join('<br>');
            Swal.fire({
                icon: 'error',
                title: 'Error',
                html: errorsMessage,
            });
        });

    }

    const totalDrAmount = transactionInfo?.voucher_rows?.reduce((total, row) => total + Number(row.dr_amount), 0);
    const totalCrAmount = transactionInfo?.voucher_rows?.reduce((total, row) => total + Number(row.cr_amount), 0);

    const handleDetailsShipmentModalClose = (isReload: boolean) => {
        setDetailsShipmentModalShow(false);
    }

    return (
        <>
            {hasPermission && 
                <>
                    <div className='card card-custom'>
                        <div className='card-header card-header-stretch overflow-auto'>
                            <div className='card-title'>
                                <h3 className='card-label'>Transaction Info</h3>
                                <small className='text-muted'>| Information about the Voucher</small>
                            </div>
                        </div>
                        <div className="card-body">
                            
                        </div>
                    </div>

                    <Row>
                        <Col md={4} className="pe-2">
                            <div className="card card-flush overflow-hidden mt-3 ms-2">
                                <div className="card-header py-5">
                                    <h3 className="card-title align-items-start flex-column">
                                        <span className="card-label fw-bold text-dark">Basic Information</span>
                                    </h3>
                                </div>
                                <div className="card-body pt-0">
                                    <Table striped bordered hover size='sm'>
                                        <tbody>
                                            <tr>
                                                <td style={{ width: '50%' }}>ID</td>
                                                <td style={{ width: '50%' }}>{transactionInfo?.id_acc_vouchers}</td>
                                            </tr>
                                            <tr>
                                                <td>Voucher Type</td>
                                                <td><span className="badge badge-success">{transactionInfo?.voucherType}</span></td>
                                            </tr>
                                            <tr>
                                                <td>Narration</td>
                                                <td>{transactionInfo?.narration}</td>
                                            </tr>
                                            <tr>
                                                <td>Project</td>
                                                <td>{transactionInfo?.project?.projectName}</td>
                                            </tr>
                                            <tr>
                                                <td>Voucher Date </td>
                                                <td>{transactionInfo?.voucherDate}</td>
                                            </tr>
                                            <tr>
                                                <td>Voucher No </td>
                                                <td>{transactionInfo?.voucherNo}</td>
                                            </tr>
                                            <tr>
                                                <td>Ref Type</td>
                                                <td>
                                                    {transactionInfo?.refType === 'Sales Invoice' &&
                                                        <Link to={`/sales-invoice/${transactionInfo?.idRef}`}>{transactionInfo?.refType}</Link>
                                                    }
                                                    {transactionInfo?.refType === 'Expense' &&
                                                        <Link to={`/expenses`}>{transactionInfo?.refType}</Link>
                                                    }
                                                    {transactionInfo?.refType === 'Sales Payment' &&
                                                        <Link to={`/sales-payments`}>{transactionInfo?.refType}</Link>
                                                    }
                                                    {transactionInfo?.refType === 'Journal' &&
                                                        <Link to={`/transactions`}>{transactionInfo?.refType}</Link>
                                                    }
                                                    {transactionInfo?.refType === 'Manufacturing' &&
                                                        <Link to={`/manufacturing-details/${transactionInfo?.idRef}`}>{transactionInfo?.refType}</Link>
                                                    }
                                                    {transactionInfo?.refType === 'Shipment' &&
                                                        <button className='btn btn-sm btn-primary' onClick={() => setDetailsShipmentModalShow(true)}>{transactionInfo?.refType}</button>
                                                    }
                                                    {(transactionInfo?.refType === 'Local Purchase' || transactionInfo?.refType === 'LC Purchase') &&
                                                        <Link to={`/purchase-details/${transactionInfo?.idRef}`}>{transactionInfo?.refType}</Link>
                                                    }
                                                     {transactionInfo?.refType === 'Stock Issue' &&
                                                        <Link to={`/stock-issue-details`}>{transactionInfo?.refType}</Link>
                                                    }
                                                    {![
                                                        'Sales Invoice', 
                                                        'Expense', 
                                                        'Sales Payment', 
                                                        'Journal', 
                                                        'Manufacturing', 
                                                        'Shipment', 
                                                        'Local Purchase', 
                                                        'LC Purchase', 
                                                        'Stock Issue'
                                                    ].includes(transactionInfo?.refType) && (
                                                        <span>{transactionInfo?.refType}</span>
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Ref ID</td>
                                                <td>{transactionInfo?.idRef}</td>
                                            </tr>
                                            <tr>
                                                <td>Sales Representative</td>
                                                <td>{transactionInfo?.sales_representative ? transactionInfo?.sales_representative?.firstName + ' ' +transactionInfo?.sales_representative?.lastName : ''}</td>
                                            </tr>
                                            <tr>
                                                <td>Creator</td>
                                                <td>{transactionInfo?.creator.firstName + ' ' +transactionInfo?.creator.lastName + ' at ' + transactionInfo?.createdAt}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                            <div className='card card-flush overflow-hidden mt-3 ms-2'>
                                <div className='card-header py-5'>
                                    <h3 className="card-title">
                                        <KTSVG className='svg-icon-3 me-2' path="/media/svg/icons/General/Clip.svg"></KTSVG>
                                        Attach files
                                    </h3>
                                </div>


                                <div className='card-body'>
                                    <Table striped bordered hover size='sm'>
                                        <thead>
                                            <tr>
                                                <th style={{ width: '50%' }}>Attachment</th>
                                                <th style={{ width: '50%' }}>Uploaded By</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {transactionInfo?.files?.length === 0 &&
                                                <tr>
                                                    <td colSpan={2}>No File Attached</td>
                                                </tr>
                                            }
                                            {transactionInfo?.files?.map((attachment, index) => (
                                                <tr>
                                                    <td key={'attachment' + index}>
                                                        <a rel='noreferrer' href={process.env.REACT_APP_AWS_URL + "purchase-orders/" + attachment.attachmentPath}
                                                            className="fs-6 text-hover-primary fw-bold" target="_blank">{attachment.attachmentName}</a>
                                                        <div className="text-gray-400">{getReadableFileSizeString(attachment.fileSize)}</div>
                                                    </td>
                                                    <td>{attachment.creator.firstName + ' ' + attachment.creator.lastName + ' at ' + attachment.createdAt}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>

                                    <Dropzone
                                        styles={{ dropzone: { border: "none" } }}
                                        accept='image/*,audio/*,application/pdf'
                                        onSubmit={handleFileSubmit}
                                    />
                                </div>
                            </div>

                        </Col>
                        <Col md={8} className="pt-2">
                            <div className="row">
                                <div className="col">
                                    <div className="card card-flush overflow-hidden m-2 me-0">
                                        <div className="card-header py-5">
                                            <h3 className="card-title align-items-start flex-column">
                                                <span className="card-label fw-bold text-dark">Voucher Details</span>
                                            </h3>
                                        </div>
                                        <div className="card-body pt-0">
                                            <Table striped bordered hover size="sm">
                                                <thead>
                                                    <tr>
                                                        <th>SL No</th>
                                                        <th>Ledger Code</th>
                                                        <th>Ledger Name</th>
                                                        <th>Remarks</th>
                                                        <th className='text-center'>Dr. Amount</th>
                                                        <th className='text-center'>Cr. Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {transactionInfo?.voucher_rows?.map((row, index) => (
                                                        <tr key={'item' + index}>
                                                            <td>{index + 1}</td>
                                                            <td>{row?.ledger.ledger_code}</td>
                                                            <td>
                                                                {row?.ledger.ledger_name}<br />
                                                                <small>{row?.ledger.ledger_path}</small>
                                                            </td>
                                                            <td>{row?.remarks}</td>
                                                            <td className='text-end'>{row?.dr_amount? numberFormat(row?.dr_amount) : ''}</td>
                                                            <td className='text-end'>{row?.cr_amount ? numberFormat(row?.cr_amount) : ''}</td>
                                                        </tr>
                                                    ))}
                                                    <tr>
                                                        <td colSpan={4} className='text-end'><b>Total: </b></td>
                                                        <td className='text-end'><b>{numberFormat(totalDrAmount)}</b></td>
                                                        <td className='text-end'><b>{numberFormat(totalCrAmount)}</b></td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                            
                                            <div className='row mt-5'>
                                                <div className='col text-center'>

                                                    <Link className="btn btn-info btn-sm" to={`/transactions`}>
                                                        <KTSVG
                                                            path='/media/svg/icons/Media/Back.svg'
                                                            className='svg-icon-5 svg-icon'
                                                        />		Back to Voucher List
                                                    </Link>

                                                    <a className="btn btn-sm btn-primary ms-2" href={`${process.env.REACT_APP_BACKEND}voucher_print?id=${transactionInfo?.id_acc_vouchers}`} target="_blank" rel="noreferrer"><i className='fas fa-print'></i>Print</a>
                                                    
                                                </div>
                                            </div>                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </Col>
                    </Row>
            
                </>
            }
            {!hasPermission && <Error />}
            {
                transactionInfo?.refType === 'Shipment' && detailsShipmentModalShow && <ShipmentDetails show={detailsShipmentModalShow} handleClose={handleDetailsShipmentModalClose} shipmentId={Number(transactionInfo?.idRef)} refLink={'voucher-details'} />
            }
            
        </>
    )
}

export default TransactionDetails
