/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper';
import { Col, Row, Table } from 'react-bootstrap-v5';
import 'react-dropzone-uploader/dist/styles.css'
import { useParams } from 'react-router-dom'
import { KTSVG } from '../../_metronic/helpers'
import SalesPaymentCreate from '../components/SalesPaymentCreate'
import SalesInvoiceAdjustment from '../components/SalesInvoiceAdjustment';
import Dropzone from 'react-dropzone-uploader'
import clsx from 'clsx'
import Swal from 'sweetalert2'
import showLoader from '../helpers/ShowLoader'
import validationErrorMsg from '../helpers/ValidationErrorMsg'
import axios, { AxiosResponse, AxiosError } from 'axios'


interface SalesInvoice {
    id_sales_invoice: number;
    id_leads: number;
    idSalesInvoice: number;
    createdBy: number;
    invoiceId: string;
    idSalesOrders: number;
    sales_order: {
        idSalesOrder: number;
        idCompanies: number;
        project: any;
        lead: any;
        company: any;
    };
    invoiceDate: string;
    totalAmount: number;
    invoiceAmount: number;
    createdAt: any;
    vatAmount: string;
    discountAmount: string;
    advanceAdjustment: string;
    netAmount: string;
    voucher: any;
    creator: {
        name: string;
    };
    comments: { id_sales_invoice: number, comment: string, created_at: string, creator: any }[];
    attachments: { id_sales_invoice: number, attachment_name: string, attachment_path: string, file_size: number, created_at: string, creator: any }[];
    items: { id_sales_invoice: number, delivered_quantity: number, delivered_amount: number, sales_order_row: any, product_name: string, product: any }[];

    project: {
        projectName: string;
    };
    lead: {
        leadName: string;
    };
    quotation: {
        referenceNumber: string;
    };

    shipments: {
        idShipments: string,
        id_shipments: string
    }[];
    payments: {
        id_sales_payments: number, transaction_id: string, payment_amount: number, creator: any
        , payment_date: string, payment_method: string, payment_type: string, remarks: string, paymentInfo: string
    }[];
}

interface SalesOrder {
    id_sales_invoice: number;
    idSalesInvoice: number;
    idSalesOrders: number;
    id_sales_orders: number;
    id_projects: number;
    idProjects: number;
    idCompanies: number;
    projectName: string;

}

interface RouteParams {
    idSalesInvoice: string;
}

const getReadableFileSizeString = (fileSizeInBytes: number) => {
    return ((fileSizeInBytes / (1024 * 1024)).toFixed(4) + " MB");
}


export function SalesInvoiceDetails(props: any) {

    const [tab, setTab] = useState('Sidebar')
    const [salesInvoiceInfo, setSalesInvoiceInfo] = useState<SalesInvoice | null>(null);
    const [salesOrderInfo, setSalesOrderInfo] = useState<SalesOrder | null>(null);
    const { idSalesInvoice }: RouteParams = useParams();
    const [message, setMessage] = useState('')

    const [showSalesInvoiceAdjustment, setShowSalesInvoiceAdjustment] = useState(false);

    const [createPaymentModalShow, setCreatePaymentModalShow] = useState(false);
    const handleCreatePaymentModalClose = (isReload: boolean) => {
        setCreatePaymentModalShow(false);
        getSalesInvoiceInfo();
    }

    const makeAdjustment = (idSalesInvoice: number) => {
        setShowSalesInvoiceAdjustment(true);
    }

    const handleSalesInvoiceAdjustmentClose = (isReload: boolean) => {
        setShowSalesInvoiceAdjustment(false);
        getSalesInvoiceInfo()
    }

    const numberFormat = (value: any) => {

        value = parseFloat(value)
        if (typeof (value) !== 'number') {
            return value;
        }
        return parseFloat(value.toString()).toLocaleString('en-US', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }

    useEffect(() => {
        console.log(idSalesInvoice)
        if (idSalesInvoice !== undefined) {
            getSalesInvoiceInfo()
        }
    }, [idSalesInvoice])

    const getSalesInvoiceInfo = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'sales-invoice/' + idSalesInvoice,
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                const data: SalesInvoice = resp.data;
                setSalesInvoiceInfo(data);
                setSalesOrderInfo({
                    id_sales_invoice: parseInt(idSalesInvoice),
                    idSalesInvoice: parseInt(idSalesInvoice),
                    idSalesOrders: data.idSalesOrders,
                    id_sales_orders: data.idSalesOrders,
                    idProjects: data.sales_order?.project?.id_projects,
                    id_companies: data.sales_order?.id_companies,
                    id_projects: data.sales_order?.project?.id_projects,
                    projectName: data.sales_order?.project?.projectName,
                })
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const handlePaymentModalShow = (salesOrderInfo: any) => {
        setSalesOrderInfo(salesOrderInfo);
        setCreatePaymentModalShow(true);
    }

    const sendMessage = async () => {
        showLoader()
        const formData = {
            idSalesInvoice: idSalesInvoice,
            message: message
        };
        const requestOptions = postRequestOptions(formData)
        try {
            let response = await fetch(
                `${process.env.REACT_APP_API_URL}sales-invoice/comment`,
                requestOptions
            )
            let respData = await response.json()
            Swal.close();
            if (respData.success === true) {
                setMessage('');
                getSalesInvoiceInfo()
            } else {
                const errorsMessage = validationErrorMsg(respData.errors).join('<br>')
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    html: errorsMessage,
                })
            }

        } catch (error) {
            console.log(error, 'catch the hoop')
            Swal.close();
        }
    }

    const jwt = () => {
        const myToken = localStorage.getItem('MyToken');
        return (myToken != null) ? JSON.parse(myToken) : null;
    }

    const handleFileSubmit = (files: any, allFiles: []) => {
        showLoader()

        const postFormData = new FormData();
        postFormData.append('idSalesInvoice', idSalesInvoice.toString());
        for (let i = 0; i < files.length; i++) {
            postFormData.append('files[]', files[i].file);
        }

        const url: string = `${process.env.REACT_APP_API_URL}sales-invoice/file-upload`;

        axios({
            method: 'POST',
            url: url,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer ' + jwt(),
            },
            data: postFormData,
        })
            .then((response: AxiosResponse<any>) => {
                Swal.close();
                allFiles.forEach((f: any) => f.remove());
                getSalesInvoiceInfo();
            })
            .catch((error: AxiosError<any>) => {
                Swal.close();
                // const errorMessage: string = error.response?.data?.errorMessage;
                const errorsMessage: string = validationErrorMsg(error?.response?.data?.errors).join('<br>');
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    html: errorsMessage,
                });
            });

    }

    return (<>
        <div className='card card-custom'>
            <div className='card-header card-header-stretch overflow-auto'>
                <div className='card-title'>
                    <h3 className='card-label'>Sales Invoice Details</h3>
                    <small className='text-muted'>| Information about the sales Invoice</small>
                </div>
            </div>
            <div className="card-body">

            </div>
        </div>

        <Row>
            {/* <pre>{JSON.stringify(salesOrderInfo, null, 2)}</pre> */}
            <Col md={4} className="pe-0 pt-0">
                <div className="card card-flush overflow-hidden m-2 me-0">
                    <div className="card-header py-5">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bold text-dark">Summary</span>
                        </h3>
                    </div>
                    <div className="card-body pt-0">
                        <Table striped bordered hover size='sm'>
                            <tbody>
                                <tr>
                                    <td style={{ width: '50%' }}>ID</td>
                                    <td style={{ width: '50%' }}>{salesInvoiceInfo?.id_sales_invoice}</td>
                                </tr>
                                <tr>
                                    <td>Invoice ID</td>
                                    <td>{salesInvoiceInfo?.invoiceId}</td>
                                </tr>
                                <tr>
                                    <td>Invoice Date</td>
                                    <td>{salesInvoiceInfo?.invoiceDate}</td>
                                </tr>
                                <tr>
                                    <td>Sales Order ID</td>
                                    <td>{salesInvoiceInfo?.idSalesOrders}</td>
                                </tr>
                                <tr>
                                    <td>Project</td>
                                    <td>{salesInvoiceInfo?.sales_order?.project?.projectName}</td>
                                </tr>
                                <tr>
                                    <td>Shipments</td>
                                    <td>
                                        {salesInvoiceInfo?.shipments?.map((x, y) => (
                                            <span>{x.id_shipments}, </span>
                                        ))}
                                    </td>
                                </tr>
                                {/* <tr>
                                    <td>Acc Voucher</td>
                                    <td>

                                    </td>
                                </tr> */}
                                <tr>
                                    <td>Creator</td>
                                    <td>{salesInvoiceInfo?.creator.name + ' at ' + salesInvoiceInfo?.createdAt}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>


                <div className="card card-flush overflow-hidden m-2 me-0">
                    <div className="card-header py-5">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bold text-dark">Lead Details</span>
                        </h3>
                    </div>
                    <div className="card-body pt-0">
                        <Table striped bordered hover size='sm'>
                            <tbody>
                                <tr>
                                    <td style={{ width: '50%' }}>Lead Name</td>
                                    <td style={{ width: '50%' }}>{salesInvoiceInfo?.sales_order?.lead?.leadName}</td>
                                </tr>
                                <tr>
                                    <td>Client</td>
                                    <td>{salesInvoiceInfo?.sales_order?.company?.company_name}</td>
                                </tr>
                                <tr>
                                    <td>Category</td>
                                    <td>{salesInvoiceInfo?.sales_order?.company?.company_category?.category_name}</td>
                                </tr>
                                <tr>
                                    <td>Address</td>
                                    <td>{salesInvoiceInfo?.sales_order?.company?.company_address}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>

                <div className='card card-custom'>
                    <div className='card-header card-header-stretch overflow-auto'>
                        <ul
                            className='nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap'
                            role='tablist'
                        >
                            <li className='nav-item p-2'>
                                <a
                                    className={clsx(`nav-link cursor-pointer`, { active: tab === 'Sidebar' })}
                                    onClick={() => setTab('Sidebar')}
                                    role='tab'
                                >
                                    <KTSVG className='svg-icon-3 me-2' path="/media/svg/icons/Communication/Chat6.svg"></KTSVG>
                                    Add comment
                                </a>
                            </li>
                            <li className='nav-item p-2'>
                                <a
                                    className={clsx(`nav-link cursor-pointer`, { active: tab === 'Toolbar' })}
                                    onClick={() => setTab('Toolbar')}
                                    role='tab'
                                >
                                    <KTSVG className='svg-icon-3 me-2' path="/media/svg/icons/General/Clip.svg"></KTSVG>
                                    Attach files
                                </a>
                            </li>
                        </ul>
                    </div>


                    <div className='card-body'>
                        <div className='tab-content pt-3'>
                            <div className={clsx('tab-pane', { active: tab === 'Sidebar' })}>

                                <Table striped bordered hover size='sm'>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '50%' }}>Comment</th>
                                            <th style={{ width: '50%' }}>Comment By</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {salesInvoiceInfo?.comments?.length === 0 &&
                                            <tr>
                                                <td colSpan={2}>No comments available</td>
                                            </tr>
                                        }
                                        {salesInvoiceInfo?.comments?.map((comment, index) => (
                                            <tr>
                                                <td key={'comment' + index}>{comment.comment}</td>
                                                <td>{comment.creator.first_name + ' ' + comment.creator.last_name + ' at ' + comment.created_at}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                <textarea
                                    className='form-control  mb-3'
                                    rows={3}
                                    data-kt-element='input'
                                    placeholder='Type a message'
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                ></textarea>
                                <div className='d-flex flex-row justify-content-end'>

                                    <button
                                        className='btn-sm btn-success'
                                        type='button'
                                        onClick={sendMessage}
                                    >
                                        Send
                                    </button>
                                </div>
                            </div>

                            <div className={'p-0 ' + clsx('tab-pane', { active: tab === 'Toolbar' })}>

                                <Table striped bordered hover size='sm'>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '50%' }}>Attachment</th>
                                            <th style={{ width: '50%' }}>Uploaded By</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {salesInvoiceInfo?.attachments?.length === 0 &&
                                            <tr>
                                                <td colSpan={2}>No File Attached</td>
                                            </tr>
                                        }
                                        {salesInvoiceInfo?.attachments?.map((attachment, index) => (
                                            <tr>
                                                <td key={'attachment' + index}>
                                                    <a rel='noreferrer' href={process.env.REACT_APP_AWS_URL + "salesOrders/" + attachment.attachment_path}
                                                        className="fs-6 text-hover-primary fw-bold" target="_blank">{attachment.attachment_name}</a>
                                                    <div className="text-gray-400">{getReadableFileSizeString(attachment.file_size)}</div>
                                                </td>
                                                <td>{attachment.creator.first_name + ' ' + attachment.creator.last_name + ' at ' + attachment.created_at}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>

                                <Dropzone
                                    styles={{ dropzone: { border: "none" } }}
                                    accept='image/*,audio/*,application/pdf'
                                    onSubmit={handleFileSubmit}
                                />
                            </div>
                        </div>
                    </div>

                </div>



            </Col>
            <Col md={8} className="pt-2">

                <div className="card card-flush overflow-hidden m-2 me-0">
                    <div className="card-header py-5">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bold text-dark">Items</span>
                        </h3>
                    </div>
                    <div className="card-body pt-0">
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th>Product</th>
                                    <th>Packing</th>
                                    <th>Ordered Quantity</th>
                                    <th>Delivered Quantity</th>
                                    <th>Rate</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>

                                {salesInvoiceInfo?.items?.map((item, index) => (
                                    <>
                                        <tr key={'item' + index}>
                                            <td>{item?.sales_order_row?.product?.product_name}</td>
                                            <td>{item?.sales_order_row?.product?.packing}</td>
                                            <td>{item?.sales_order_row?.quantity}</td>
                                            <td>{item?.delivered_quantity}</td>
                                            <td className='text-end'>{numberFormat(item?.sales_order_row?.unit_rate)}</td>
                                            <td className='text-end'>{numberFormat(item?.delivered_amount)}</td>
                                        </tr>
                                    </>

                                ))}
                                <tr>
                                    <td colSpan={5} className='text-end'><b>Total Amount : </b></td>
                                    <td className='text-end'><b>{numberFormat(salesInvoiceInfo?.totalAmount)}</b></td>
                                </tr>
                                <tr>
                                    <td colSpan={5} className='text-end'><b>VAT Amount : </b></td>
                                    <td className='text-end'><b>{numberFormat(salesInvoiceInfo?.vatAmount)}</b></td>
                                </tr>
                                <tr>
                                    <td colSpan={5} className='text-end'><b>Discount Amount : </b></td>
                                    <td className='text-end'><b>{numberFormat(salesInvoiceInfo?.discountAmount)}</b></td>
                                </tr>
                                <tr>
                                    <td colSpan={5} className='text-end'><b>Adjustment : </b></td>
                                    <td className='text-end'><b>{numberFormat(salesInvoiceInfo?.advanceAdjustment)}</b></td>
                                </tr>
                                <tr>
                                    <td colSpan={5} className='text-end'><b>Net Amount : </b></td>
                                    <td className='text-end'><b>{numberFormat(salesInvoiceInfo?.invoiceAmount)}</b></td>
                                </tr>
                            </tbody>
                        </Table>

                        <div className='row'>
                            <div className='col text-center'>
                                <button className="btn btn-primary btn-sm" onClick={() => makeAdjustment(salesInvoiceInfo?.id_sales_invoice)}>
                                    <KTSVG
                                        path='/media/svg/icons/Design/Crop.svg'
                                        className='svg-icon-5 svg-icon'
                                    />		Adjustment
                                </button>
                                &nbsp;&nbsp;&nbsp;
                                <button className="btn btn-primary btn-sm" onClick={() => handlePaymentModalShow(salesOrderInfo)}>
                                    <KTSVG
                                        path='/media/svg/icons/Shopping/Cart3.svg'
                                        className='svg-icon-5 svg-icon'
                                    />		Make Payment
                                </button>
                                &nbsp;&nbsp;&nbsp;
                                <a className="btn btn-primary btn-sm" target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_BACKEND}invoiceDownload/${salesInvoiceInfo?.id_sales_invoice}?type=with_discount`}>
                                    <KTSVG
                                        path='/media/svg/icons/Files/Downloads-folder.svg'
                                        className='svg-icon-5 svg-icon'
                                    />		Download
                                </a>
                                &nbsp;&nbsp;&nbsp;
                                <a className="btn btn-primary btn-sm" target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_BACKEND}invoiceDownload/${salesInvoiceInfo?.id_sales_invoice}?type=without_discount`}>
                                    <KTSVG
                                        path='/media/svg/icons/Files/Downloads-folder.svg'
                                        className='svg-icon-5 svg-icon'
                                    />		Download Without Discount
                                </a>

                            </div>
                        </div>

                    </div>
                </div>

                <div className="card card-flush overflow-hidden m-2 me-0">
                    <div className="card-header py-5">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bold text-dark">Payments History</span>
                        </h3>
                    </div>
                    <div className="card-body pt-0">
                        <Row>
                            <Col>
                                <Table striped bordered hover size='sm'>
                                    <thead>
                                        <tr>
                                            <th className='text-center' style={{ width: '10%' }}>ID</th>
                                            <th className='text-center' style={{ width: '10%' }}>Type</th>
                                            <th className='text-center' style={{ width: '10%' }}>Method</th>
                                            <th className='text-center' style={{ width: '10%' }}>Date</th>
                                            <th className='text-center' style={{ width: '10%' }}>Amount</th>
                                            <th className='text-center' style={{ width: '10%' }}>Remarks</th>
                                            <th className='text-center' style={{ width: '10%' }}>Transaction ID</th>
                                            <th className='text-center' style={{ width: '10%' }}>Payment By</th>
                                            <th className='text-center' style={{ width: '10%' }}>Payment Info</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {salesInvoiceInfo?.payments?.length === 0 &&
                                            <tr>
                                                <td colSpan={10}>No Data Found</td>
                                            </tr>
                                        }
                                        {salesInvoiceInfo?.payments?.map((payment, index) => (
                                            <>
                                                <tr>
                                                    <td className='text-center'>{payment.id_sales_payments}</td>
                                                    <td className='text-center'>{payment.payment_type}</td>
                                                    <td className='text-center'>{payment.payment_method}</td>
                                                    <td className='text-center'>{payment.payment_date}</td>
                                                    <td className='text-center'>{numberFormat(payment.payment_amount)}</td>
                                                    <td className='text-center'>{payment.remarks}</td>
                                                    <td className='text-center'>{payment.transaction_id}</td>
                                                    <td className='text-center'>{payment.creator.first_name + ' ' + payment.creator.last_name}</td>
                                                    <td className='text-center'>{payment.paymentInfo}</td>
                                                </tr>
                                            </>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </div>
                </div>



            </Col>
        </Row>
        {/* <pre>{JSON.stringify(salesInvoiceInfo, null, 2)}</pre> */}

        <SalesPaymentCreate show={createPaymentModalShow} handleClose={handleCreatePaymentModalClose} salesOrderInfo={salesOrderInfo} />

        <SalesInvoiceAdjustment show={showSalesInvoiceAdjustment} handleClose={handleSalesInvoiceAdjustmentClose} id={parseInt(idSalesInvoice)} />


    </>
    )
}

export default SalesInvoiceDetails
