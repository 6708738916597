/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import { Modal, Button } from 'react-bootstrap'
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper'
import validationErrorMsg from '../helpers/ValidationErrorMsg'
import showLoader from "../helpers/ShowLoader"
import Select from 'react-select'


function AssignedTo({ show, handleClose, companyInfo }) {


	const [users, setUsers] = useState([]);
	const [assignedTo, setAssignedTo] = useState({label:'Select Assign To', value:''});
	const [assignedToFormData, setAssignedToFormData] = useState({idCompanies: companyInfo?.idCompanies, assignedTo: ''});

	useEffect(() => {
		getOwnerships();
	}, [])

	useEffect(() => {
		setAssignedTo({ label: companyInfo.assignedToName ? companyInfo.assignedToName : 'Select Assign To', value: companyInfo.assignedTo, id : companyInfo.assignedTo });
		setAssignedToFormData({ ...assignedToFormData, assignedTo: companyInfo.assignedTo });
	}, [companyInfo]);

	const handleAssignedToChange = user => {
		setAssignedTo(user);
		setAssignedToFormData({ ...assignedToFormData, assignedTo: user.id });
	}

	const getOwnerships = () => {
		fetch(
			process.env.REACT_APP_API_URL + 'company/assign-to',
			getRequestOptions()
		)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				let assignedToUsers = resp.data.map((item) => {
					item.label = item.name;
					item.value = item.id;
					return item
				})
				setUsers(assignedToUsers);
			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})
	}

	const assignedToSave = async (event) => {
		event.preventDefault()
		showLoader();
		var formData = {
			idCompanies: companyInfo.idCompanies,
			assignedTo: assignedToFormData.assignedTo,
		};
		const requestOptions = postRequestOptions(formData)
		try {
			let response = await fetch(
				`${process.env.REACT_APP_API_URL}company/assign-to-save`,
				requestOptions
			)
			let respData = await response.json()
			Swal.close();
			if (respData.success === true) {
				const successMsg = respData.successMessage
				Swal.fire({
					icon: 'success',
					title: 'Success',
					text: successMsg,
				})
				handleClose(respData.success)
			} else {
				const errorsMessage = validationErrorMsg(respData.errors).join('<br>')
				Swal.fire({
					icon: 'error',
					title: 'Error',
					html: errorsMessage,
				})
			}
		} catch (error) {
			Swal.close();
			console.log(error, 'catch the hoop')
		}
	}


	return (
		<Modal show={show} onHide={() => handleClose(false)} size="lg">
			<form onSubmit={assignedToSave}>
				<Modal.Header closeButton>
					<Modal.Title>Assign for {companyInfo.companyName}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="form-group row">
                        <label htmlFor="idCompanies" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Select Assign To <span className="required text-danger"></span></label>
                        <div className="col-lg-7">
							<Select
								value={assignedTo}
								onChange={handleAssignedToChange}
								options={users}
								menuPosition="fixed"
							/>
                        </div>
                    </div>
				</Modal.Body>
				<Modal.Footer>
				
					<button type="submit" className="btn btn-sm btn-primary me-2"><span>  Submit</span></button>
					<Button variant='secondary' size="sm" onClick={() => handleClose(false)}>
						Close
					</Button>
				</Modal.Footer>
			</form>
		</Modal>
	)
}

export default AssignedTo
