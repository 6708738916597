import { useState, useEffect } from "react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import { getRequestOptions, postRequestOptions } from "../helpers/Fetchwrapper";
import { KTSVG } from '../../_metronic/helpers';
import { useHistory } from "react-router-dom";

function PageManagement(props) {
	let history = useHistory();

	const initialState = () => ({
		pagesTitle: "",
		pagesLink: "",
		iconPath: "",
		pagesType: "menu",
		isShow: "yes",
		activeStatus: "1",
		sequence: "",
		parentId: "",
	});

	const [pageData, setPageData] = useState(initialState());
	const [menuOption, setMenuOption] = useState([]);
	const [idPages, setIdPages] = useState("");

	useEffect(() => {
		if (props.location.info !== undefined) {
			setIdPages(props.location.info.idPages);
			getPageInfo(props.location.info.idPages);
		}
		const getAllMenu = () => {
			fetch(process.env.REACT_APP_API_URL + "get_all_menu", getRequestOptions())
				.then((resp) => {
					return resp.json()
				})
				.then((resp) => {
					setMenuOption(resp.data);
				})
				.catch((error) => {
					console.log(error, "catch the hoop")
				});
		}
		getAllMenu();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getPageInfo = (id) => {
		fetch(process.env.REACT_APP_API_URL + "get_page_info/" + id, getRequestOptions())
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				setPageData(resp.data);
			})
			.catch((error) => {
				console.log(error, "catch the hoop")
			});
	}

	const handleInputOnChange = (e) => {
		setPageData({ ...pageData, [e.target.name]: e.target.value });
	}

	const handleSubmit = evt => {
		evt.preventDefault();
		if (idPages === "") {
			var msg = "Create";
			var apiEnd = "page_create";
			var text = "Created";
		}
		else {
			msg = "Edit";
			apiEnd = "page_update";
			text = "Updated";
		}
		Swal.fire({
			title: 'Are you sure?',
			text: `You want to ${msg} this page!`,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No',
			confirmButtonColor: '#ec008c'
		}).then((result) => {
			if (result.value) {
				const formData = { ...pageData, idPages };

				const requestOptions = postRequestOptions(formData);

				fetch(process.env.REACT_APP_API_URL + apiEnd, requestOptions)
					.then((resp) => {
						return resp.json()
					})
					.then((resp) => {
						console.log(resp);

						if (resp.success === true) {
							Swal.fire({
								icon: 'success',
								title: 'Success',
								text: `Page ${text} Successfully`,
							})
							setPageData(initialState());
							setIdPages("");
							history.push("/accesscontrol-page-list");
						}
						else {
							Swal.fire({
								icon: 'error',
								title: 'Error',
								html: resp.errorMessage
							})
						}
					})
					.catch((error) => {
						console.log(error, "catch the hoop")
					});

			}
		})
	}

	const pagetype = [
		{ label: 'Menu', value: 'menu' },
		{ label: 'Sub Menu', value: 'sub-menu' },
	]
	const show = [
		{ label: 'Yes', value: 'yes' },
		{ label: 'No', value: 'no' },
	]
	const status = [
		{ label: 'Active', value: '1' },
		{ label: 'Inactive', value: '0' },
	]

	return (
		<>
			<div className="card card-custom">
				<div className="card-header">
					<div className="card-title">
						<KTSVG
							path='/media/svg/icons/Files/Selected-file.svg'
							className='svg-icon-1 svg-icon-primary'
						/>
						<span className="text fw-bolder" style={{ marginLeft: "10px" }}>
							PAGE {idPages === "" ? "CREATE" : "EDIT"}
						</span>
					</div>
				</div>
				<div className="ml-12">
					<div className="card-body">
						<form>
							<div className="form-group row">
								<label className="col-lg-3 col-form-label" style={{ "textAlign": "right" }} >Page Title<span className="required text-danger"></span></label>
								<div className="col-lg-5">
									<input type="text" name="pagesTitle" id="pagesTitle" value={pageData.pagesTitle} className="form-control form-control-sm" onChange={handleInputOnChange} />
								</div>
							</div>
							<div className="form-group row">
								<label htmlFor="pagesLink" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }} >Page Link<span className="required text-danger"></span></label>
								<div className="col-lg-5">
									<input type="text" name="pagesLink" id="pagesLink" value={pageData.pagesLink} className="form-control form-control-sm" onChange={handleInputOnChange} />
								</div>
							</div>
							<div className="form-group row">
								<label htmlFor="iconPath" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }} >Icon Path<span className="required text-danger"></span></label>
								<div className="col-lg-5">
									<input type="text" name="iconPath" id="iconPath" value={pageData.iconPath} className="form-control form-control-sm" onChange={handleInputOnChange} />
								</div>
							</div>
							<div className="form-group row mt-2">
								<label htmlFor="pagesType" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }} >Page Type<span className="required text-danger"></span></label>
								<div className="col-lg-5">
									<select className="form-select form-select-sm" id="pagesType" name="pagesType" value={pageData.pagesType} onChange={handleInputOnChange} >
										{pagetype.map(function (item, id) {
											return <option key={id} value={item.value}>{item.label}</option>
										})
										}
									</select>
								</div>
							</div>
							{pageData.pagesType === "sub-menu" &&
								<div className="form-group row mt-2">
									<label htmlFor="parentId" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }} >Parent Title<span className="required text-danger"></span></label>
									<div className="col-lg-5">
										<select className="form-select form-select-sm" id="parentId" name="parentId" value={pageData.parentId} onChange={handleInputOnChange} >
											<option value="">Select Parent</option>
											{menuOption.map(function (item, id) {
												return <option key={id} value={item.idPages}>{item.pagesTitle}</option>
											})
											}
										</select>
									</div>
								</div>
							}
							<div className="form-group row mt-2">
								<label htmlFor="isShow" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }} >Show<span className="required text-danger"></span></label>
								<div className="col-lg-5">
									<select className="form-select form-select-sm" id="isShow" name="isShow" value={pageData.isShow} onChange={handleInputOnChange} >
										{show.map(function (item, id) {
											return <option key={id} value={item.value}>{item.label}</option>
										})
										}
									</select>
								</div>
							</div>
							<div className="form-group row mt-2">
								<label htmlFor="activeStatus" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }} >Active Status<span className="required text-danger"></span></label>
								<div className="col-lg-5">
									<select className="form-select form-select-sm" id="activeStatus" name="activeStatus" value={pageData.activeStatus} onChange={handleInputOnChange} >
										{status.map(function (item, id) {
											return <option key={id} value={item.value}>{item.label}</option>
										})
										}
									</select>
								</div>
							</div>
							<div className="form-group row">
								<label htmlFor="sequence" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }} >Sequence<span className="required text-danger"></span></label>
								<div className="col-lg-5">
									<input type="number" name="sequence" id="sequence" value={pageData.sequence} className="form-control form-control-sm" onChange={handleInputOnChange} />
								</div>
							</div>
							<div className="row">
								<div className="col-5">
								</div>
								<div className="col-6">
									<button type="submit" className="btn btn-success btn-sm mb-5" onClick={handleSubmit}>Submit</button>
									<button type="reset" className="btn btn-danger btn-sm mb-5" onClick={() => history.goBack()}>Cancel</button>
								</div>
							</div>
						</form>
					</div>
				</div>
				{/* <pre>{JSON.stringify(idPages, null,2)}</pre>
				<pre>{JSON.stringify(pageData, null,2)}</pre> */}
				<ToastContainer />
			</div>
		</>

	)

}

export default PageManagement;