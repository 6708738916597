import React, { useEffect, useState } from 'react'
import { getRequestOptions } from '../helpers/Fetchwrapper';
import CheckboxTree from 'react-checkbox-tree'
import 'react-checkbox-tree/lib/react-checkbox-tree.css'
import { KTSVG } from '../../_metronic/helpers'
import ChartOfAccountCreate from '../components/ChartOfAccountCreate';
import ChartOfAccountEdit from '../components/ChartOfAccountEdit';
import { Link } from 'react-router-dom';

// Define the shape of your nodes if you have a specific structure
interface Node {
  value: string
  label: string
  children?: Node[]
  icon?: string | React.ReactNode // Assuming the icon is just the class name string from the backend
  ledger_type?: string
}


function ManageChartOfAccount() {

  const [checked] = useState<string[]>([])
  const [expanded, setExpanded] = useState<string[]>(['Assets'])
  const [, setClicked] = useState({});
  const [ledgers, setLedgers] = useState<Node[]>([]);

  const [filterText, setFilterText] = useState('');
  const [filteredNodes, setFilteredNodes] = useState<Node[]>([]); // Cast to Node[] if necessary

  // Additional state for editing
  const [isEditing, setIsEditing] = useState(false);
  const [idAccLedgers, setIdAccLedgers] = useState(Number); // Cast to Node[] if necessary

  type BasicObject = {
    [key: string]: string | React.ReactNode;
  };

  const icons: BasicObject = {
    check: '',
    uncheck: '',
    halfCheck: <span className="rct-icon rct-icon-half-check" />,
    expandClose: <i className='fa fa-chevron-right'></i>,
    expandOpen: <i className='fa fa-chevron-down'></i>,
    expandAll: <span className="rct-icon rct-icon-expand-all"> Expand All</span>,
    collapseAll: <span className="rct-icon rct-icon-collapse-all"> Collapse All</span>,
    parentClose: <span className="rct-icon rct-icon-parent-close" />,
    parentOpen: <span className="rct-icon rct-icon-parent-open" />,
    leaf: <i className='fa fa-file'></i>,
    group: <i className='fa fa-folder text-warning'></i>,
    gl: <i className='fa fa-file text-success'></i>,
  };


  const renderTreeNodes = (nodes: Node[]): Node[] => {
    return nodes.map(node => ({
      ...node,
      icon: node.ledger_type && icons[node.ledger_type] ? icons[node.ledger_type] : <i className='fa fa-folder' />, // default to folder icon if none provided
      children: node.children ? renderTreeNodes(node.children) : undefined,
    }));
  };

  function getChartOfAccounts() {
    fetch(
      process.env.REACT_APP_API_URL + 'chart-of-accounts',
      getRequestOptions()
    )
      .then((resp) => {
        return resp.json()
      })
      .then((resp) => {
        const withIcons = renderTreeNodes(resp.data as Node[]); // Cast response data to Node[]
        setLedgers(withIcons);
        setFilteredNodes(withIcons);
      })
      .catch((error) => {
        console.log(error, 'catch the hoop')
      })
  }

  useEffect(() => {
    getChartOfAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onExpand = (expanded: string[]) => {
    setExpanded(expanded)
  }

  const onClick = (value: any) => {
    setIsEditing(true)
    setIdAccLedgers(value.value)
    setClicked(value);
  };

  const [createLedgerModalShow, setCreateLedgerModalShow] = useState(false);

  const handleCreateLedgerModalShow = () => {
    setCreateLedgerModalShow(true);
  }

  const handleCreateLedgerModalClose = () => {
    setCreateLedgerModalShow(false);
    getChartOfAccounts();
  }

  const [editLedgerModalShow, setEditLedgerModalShow] = useState(false);

  const handleEditLedgerModalShow = () => {
    setEditLedgerModalShow(true);
  }

  const handleEditLedgerModalClose = () => {
    setEditLedgerModalShow(false);
    getChartOfAccounts();
  }


  const onFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const filter = value.trim();
    setFilterText(filter);
    filterTree(filter);
  };
  
  const filterTree = (filterText: string) => {
    if (!filterText) {
      setFilteredNodes(ledgers);
      return;
    }
    const nodesFiltered = ledgers.reduce((filtered, node) => filterNodes(filtered, node, filterText), []);
    setFilteredNodes(nodesFiltered);
  };
  
  const filterNodes = (filtered: any, node: any, filterText: any) => {

    const children = (node.children || []).reduce((acc, child) => filterNodes(acc, child, filterText), []);
  
    if (
      node.label.toLowerCase().includes(filterText.toLowerCase()) ||
      children.length
    ) {
      // filtered.push({ ...node, children });
      filtered.push({ ...node, ...children.length && { children } });
    }
  
    return filtered;
  };

  return (
    <>
      <div className='card card-custom'>
        <div className='card-header'>
          <div className='card-title'>
            <KTSVG
              path='/media/svg/icons/Text/Bullet-list.svg'
              className='svg-icon-1 svg-icon-primary'
            />
            <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
              Manage Chart Of Account
            </span>
          </div>
          <div className='card-toolbar'>
            <div className='d-flex justify-content-end'>
              <button type="button" className="btn btn-sm btn-primary" onClick={() => handleCreateLedgerModalShow()}><i className="fa fa-plus"></i>Add Ledger</button>
            </div>
          </div>

        </div>

        <div className='card-body'>
          <div className="row mb-2">
            <div className="col-md-3 col-sm-4"><i className="fa fa-folder text-warning"></i> Group </div>
            <div className="col-md-3 col-sm-4"><i className="fa fa-file text-success"></i> General Ledger </div>
            {isEditing && <div className="col-md-3 col-sm-4" onClick={() => handleEditLedgerModalShow()}><button className='btn btn-sm btn-light-primary'><i className="fa fa-edit text-success"></i> Edit </button></div>}
            
          </div>
          <div className="row">
            <div className="col-md-12">
                <div className='bg-warning p-2 mb-3'>
                  <p className='p-0 m-0'>To create a general ledger under Accounts receivable please go to <Link to={`/setup-company-list`} className="text-primary"> Client management page.</Link></p>
                  <p className='p-0 m-0'>To create a general ledger under Accounts payable please go to <Link to={`/vendors`} className="text-primary"> vendor management page.</Link></p>
                  <p className='p-0 m-0'>To create a general ledger under Cash at Hand & Cash at Bank please go to <Link to={`/bank-accounts-cash`} className="text-primary"> Bank accounts & Cash Box page.</Link></p>
                  <p className='p-0 m-0'>Ledger will show up in trial balance and chart of accounts when a <Link to={`/po-create`} className="text-primary"> Purchase</Link> or <Link to={`/sales-order-create`} className="text-primary">Sales order</Link> is created.</p>
                  <p className='p-0 m-0'>To see the automatic voucher setup <a href="https://docs.google.com/spreadsheets/d/19fuQ3l1e1SOT20b9-HiYEYn8z1JXuEm5jagQJguYSUw/edit?pli=1#gid=0" className="text-primary" target='blank'> click here </a>.</p>
                </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">

              <div className="filter-container">
                <div className='form-group mb-2 col-6'>
                  <input
                    className="form-control form-control-sm"
                    placeholder="Search..."
                    type="text"
                    value={filterText}
                    onChange={onFilterChange}
                  />
                </div>
                

                <CheckboxTree
                  checked={checked}
                  expandOnClick
                  expanded={expanded}
                  nodes={filteredNodes as any} // if your Node interface does not match exactly with what CheckboxTree expects, you might need to assert it as any
                  showExpandAll
                  onClick={onClick}
                  onExpand={onExpand}
                  icons={icons}
                />
              </div>


            </div>
          </div>
          <ChartOfAccountCreate show={createLedgerModalShow} handleClose={handleCreateLedgerModalClose} id={Date.now()} />
          {isEditing && <ChartOfAccountEdit show={editLedgerModalShow} handleClose={handleEditLedgerModalClose} id={idAccLedgers} />}
        </div>
      </div>
    </>
  )
}

export default ManageChartOfAccount
