/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react'
import Swal from 'sweetalert2'
import { Modal, Button } from 'react-bootstrap'
import { postRequestOptions } from '../helpers/Fetchwrapper'
import validationErrorMsg from '../helpers/ValidationErrorMsg'
import Spinner from 'react-bootstrap/Spinner'
import { Table } from 'react-bootstrap-v5';


interface EditSalesOrderItemProps {
	show: boolean;
	handleClose: any;
	salesOrderInfo: any;
	id: number;
}

const EditSalesOrderItem: React.FC<EditSalesOrderItemProps> = ({ show, handleClose, salesOrderInfo, id }) => {

	const numberFormat = (value: number) => {
		return parseFloat(value.toString()).toLocaleString('en-US', {
			style: 'decimal',
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		});
	}

	const updatedRows = salesOrderInfo.rows.map((dataRow: any) => {

		return {
			...dataRow,
			unit_rate: parseFloat(dataRow.unit_rate),
			unit_rate_without_transportation: parseFloat(dataRow.unit_rate_without_transportation) || 0,
			transportation_cost: parseFloat(dataRow.transportation_cost),
			original_rate: parseFloat(dataRow.original_rate),
			discount_percent: parseFloat(dataRow.discount_percent),
			originalAmount: parseFloat(dataRow.original_rate) * parseFloat(dataRow.quantity),
			amount: parseFloat(dataRow.unit_rate) * parseFloat(dataRow.quantity),
		};
	});


	const [orderRows, setOrderRows] = useState(updatedRows);
	const [isSubmitDisable, setSubmitDisable] = useState(false);
	const [loading, setLoading] = useState(false);

	const salesOrderItemSave = async (event: any) => {
		setLoading(true);
		event.preventDefault()
		setSubmitDisable(true);
		const confirm = await Swal.fire({
			title: 'Are you sure?',
			text: "You want to update the Order Items!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#ec008c',
			cancelButtonText: 'Cancel',
			confirmButtonText: 'Yes, Submit it!',
		})
		if (confirm.value) {

			const requestOptions = postRequestOptions({ rows: orderRows, idSalesOrders: salesOrderInfo.id_sales_orders })
			try {
				let response = await fetch(
					`${process.env.REACT_APP_API_URL}sales-order/update-order-items`,
					requestOptions
				)
				let respData = await response.json()
				if (respData.success === true) {
					const successMsg = respData.successMessage
					Swal.fire({
						icon: 'success',
						title: 'Success',
						text: successMsg,
					})
					handleClose(respData.success)

				} else {
					const errorsMessage = validationErrorMsg(respData.errors).join('<br>')
					Swal.fire({
						icon: 'error',
						title: 'Error',
						html: errorsMessage,
					})
				}
				setSubmitDisable(false);
				setLoading(false);

			} catch (error) {
				setLoading(false);
				console.log(error, 'catch the hoop')
				setSubmitDisable(false);
			}
		}
	}

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
		const updatedOrderRows = [...orderRows];
		updatedOrderRows[index][e.target.name] = (e.target.type === 'number') ? parseFloat(e.target.value) : e.target.value;
		// if (updatedOrderRows[index].discount > parseFloat(updatedOrderRows[index].maxDiscount)) {
		// 	updatedOrderRows[index].discount = 0;
		// }
		// // // updatedOrderRows[index].rate = updatedOrderRows[index].rate - (updatedOrderRows[index].discount * updatedOrderRows[index].rate / 100);
		updatedOrderRows[index].unit_rate = parseFloat(updatedOrderRows[index].original_rate) + parseFloat(updatedOrderRows[index].transportation_cost);
		updatedOrderRows[index].originalAmount = parseFloat((updatedOrderRows[index].quantity * (updatedOrderRows[index].original_rate + updatedOrderRows[index].transportation_cost)).toFixed(2))
		updatedOrderRows[index].amount = parseFloat((updatedOrderRows[index].quantity * (updatedOrderRows[index].original_rate + updatedOrderRows[index].transportation_cost - (updatedOrderRows[index].discount_percent * updatedOrderRows[index].original_rate / 100))).toFixed(2))
		setOrderRows(updatedOrderRows);
	}

	const calculateTotalAmount = () => {
		let totalAmount = 0;

		for (const row of orderRows) {
			totalAmount += (row.originalAmount);
		}

		return totalAmount;
	};

	const calculateTotalDiscountAmount = () => {
		let totalDiscountAmount = 0;

		for (const row of orderRows) {
			totalDiscountAmount += ((row.originalAmount * row.discount_percent) / 100);
		}

		return totalDiscountAmount;
	};

	return (
		<Modal show={show} onHide={() => handleClose(false)} size="xl">
			<form onSubmit={salesOrderItemSave}>
				<Modal.Header closeButton>
					<h3>Edit Order Item</h3>
				</Modal.Header>
				<Modal.Body>

					<div className="form-group row">
						{/* <pre>{JSON.stringify(orderRows, null, 2)}</pre> */}
						<Table striped bordered hover size="sm">
							<thead>
								<tr>
									<th>Product</th>
									<th>Delivery Location</th>

									<th>Rate (Per Unit)</th>
									<th>Transportation Cost (Per Unit)</th>
									<th>Effective Rate (Rate + Transportation Cost)</th>
									<th>Order Quantity</th>
									<th>Discount(%)</th>
									<th>Amount</th>
									<th>Amount after Discount</th>
								</tr>
							</thead>
							<tbody>

								{orderRows?.map((item, index) => (
									<>
										<tr key={'item' + index}>
											<td>{item?.product.product_name + ' ' + item?.product.packing}</td>
											<td>
												<input type="text" name="delivery_location" value={item.delivery_location} onChange={(event) => handleChange(event, index)} className="form-control form-control-sm" />
											</td>
											<td>
												<input type="number" name="original_rate" onChange={(event) => handleChange(event, index)} value={item.original_rate} className="form-control form-control-sm" />
											</td>
											<td>
												<input type="number" name="transportation_cost" value={item.transportation_cost} onChange={(event) => handleChange(event, index)} className="form-control form-control-sm" />
											</td>
											<td>
												<input type="number" name="effective_rate" readOnly value={item.unit_rate} className="form-control form-control-sm" />
											</td>
											<td>
												<input type="number" name="quantity" value={item.quantity} onChange={(event) => handleChange(event, index)} className="form-control form-control-sm" />
											</td>
											<td>
												<input type="number" name="discount_percent" value={item.discount_percent} onChange={(event) => handleChange(event, index)} className="form-control form-control-sm" />
											</td>
											<td>
												<input type="number" name="originalAmount" readOnly value={item.amount} className="form-control form-control-sm" />
											</td>
											<td>
												<input type="number" name="amount" readOnly value={item.originalAmount} className="form-control form-control-sm" />
											</td>

										</tr>
									</>

								))}
								<tr>
									<td colSpan={8} className='text-end'><b>Total Order Amount:</b></td>
									<td><b>{numberFormat(calculateTotalAmount())}</b></td>
								</tr>
								<tr>
									<td colSpan={8} className='text-end'><b>Total Discount Amount:</b></td>
									<td><b>{numberFormat(calculateTotalDiscountAmount())}</b></td>
								</tr>
								<tr>
									<td colSpan={8} className='text-end'><b>Net Amount:</b></td>
									<td><b>{numberFormat(calculateTotalAmount() - calculateTotalDiscountAmount())}</b></td>
								</tr>


							</tbody>
						</Table>

					</div>

					{/* <pre>{JSON.stringify(orderData, null, 2)}</pre> */}
				</Modal.Body>
				<Modal.Footer>

					<button type="submit" disabled={isSubmitDisable || loading} className="btn btn-sm btn-primary me-2">
						{loading &&
							<Spinner
								as="span"
								animation="grow"
								size="sm"
								role="status"
								aria-hidden="true"
							/>
						}
						<span>  Update</span>
					</button>
					<Button variant='secondary' size="sm" onClick={() => handleClose(false)}>
						Close
					</Button>
				</Modal.Footer>
			</form>
		</Modal>
	)
}

export default EditSalesOrderItem
