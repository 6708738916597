/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react'
import Swal from 'sweetalert2'
import { Modal, Button, Form } from 'react-bootstrap'
import { getRequestOptions } from '../helpers/Fetchwrapper'
import validationErrorMsg from '../helpers/ValidationErrorMsg'
import Spinner from 'react-bootstrap/Spinner'
import { Editor } from '@tinymce/tinymce-react';
import { AppContext } from '../context/AppContext'
import axios from 'axios'

interface Props {
	show: boolean;
	handleClose: any;
	id: number;
}

interface InitialState {
	idProjects: number | null;
	projectName: string;
	projectShortName: string;
	printBackground: string;
	quotationHeader: string;
	quotationFooter: string;
	accountantApproval: string;
}

const ProjectCreate: React.FC<Props> = ({ show, handleClose, id }) => {

	const { token } = useContext(AppContext);
	const [file, setFile] = useState('');

	const handleFileChange = (e: any) => {
		if (e.target.files.length < 1) {
			return;
		}
		if (isValidFileUploaded(e.target.files[0])) {
			// setImage(URL.createObjectURL(e.target.files[0]));
			setFile(e.target.files[0]);
		} else {
			alert('File type not supported');
			setFile('');
		}
	}

	const isValidFileUploaded = (file: any) => {
		const validExtensions = ['png', 'jpeg', 'jpg']
		const fileExtension = file.type.split('/')[1]
		return validExtensions.includes(fileExtension)
	}


	const initialState: InitialState = {
		idProjects: null,
		projectName: '',
		projectShortName: '',
		printBackground: '',
		quotationHeader: '',
		quotationFooter: '',
		accountantApproval: 'No'
	};

	const [projectData, setProjectData] = useState(initialState);
	const [quotationHeader, setQuotationHeader] = useState('');
	const [quotationFooter, setQuotationFooter] = useState('');
	const [isSubmitDisable, setSubmitDisable] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleChange = (e: any) => {
		setProjectData({ ...projectData, [e.target.name]: e.target.value });
	};

	const editorConfig = {
		height: 500,
		menubar: false,
		plugins: [
			'advlist autolink lists link image charmap print preview anchor',
			'searchreplace visualblocks code fullscreen',
			'insertdatetime media table paste code help wordcount',
			'textalignjustify',
		],
		toolbar:
			'undo redo | formatselect | ' +
			'bold italic backcolor | alignleft aligncenter ' +
			'alignright alignjustify | bullist numlist outdent indent | ' +
			'removeformat | help'
	};

	const handleQuotationHeaderChange = (content: any) => {
		setProjectData({ ...projectData, quotationHeader: content });
	}

	const handleQuotationFooterChange = (content: any) => {
		setProjectData({ ...projectData, quotationFooter: content });
	}

	const projectSave = async (event: any) => {
		setLoading(true);
		event.preventDefault()
		setSubmitDisable(true);
		const confirm = await Swal.fire({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#ec008c',
			cancelButtonText: 'Cancel',
			confirmButtonText: 'Yes, Submit it!',
		})
		if (confirm.value) {
			// const requestOptions = postRequestOptions(projectData)

			const formData = new FormData();
			if (projectData.idProjects !== null) {
				formData.append('idProjects', projectData.idProjects);
			}
			formData.append('projectName', projectData.projectName);
			formData.append('projectShortName', projectData.projectShortName);
			formData.append('quotationHeader', projectData.quotationHeader);
			formData.append('quotationFooter', projectData.quotationFooter);
			formData.append('printBackground', file);
			formData.append('accountantApproval', projectData.accountantApproval);

			const jwt = token;

			var authOptions = {
				method: 'post',
				headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer ' + jwt },
				url: process.env.REACT_APP_API_URL + "projects/store",
				data: formData
			};

			try {

				axios(authOptions)
					.then(response => {
						const successMessage = response.data.successMessage;
						Swal.fire({
							icon: 'success',
							title: 'Success',
							text: successMessage
						})
						setProjectData(initialState);
						handleClose(response)
					})
					.catch((error) => {
						const errorsMessage = validationErrorMsg(error.response.data.errors).join("<br>");
						Swal.fire({
							icon: 'error',
							title: 'Error',
							html: errorsMessage,
						})
					});


				// let response = await fetch(
				// 	`${process.env.REACT_APP_API_URL}projects/store`,
				// 	// requestOptions,
				// 	{
				// 		method: 'POST',
				// 		body: formData,
				// 		headers: {
				// 			'Content-Type': 'multipart/form-data',
				// 			Authorization: 'Bearer ' + jwt(),
				// 		},
				// 	}
				// )
				// let respData = await response.json()
				// if (respData.success === true) {
				// 	const successMsg = respData.successMessage
				// 	Swal.fire({
				// 		icon: 'success',
				// 		title: 'Success',
				// 		text: successMsg,
				// 	})
				// 	setProjectData(initialState);
				// 	handleClose(respData.success)
				// } else {
				// 	const errorsMessage = validationErrorMsg(respData.errors).join('<br>')
				// 	Swal.fire({
				// 		icon: 'error',
				// 		title: 'Error',
				// 		html: errorsMessage,
				// 	})
				// }
				setSubmitDisable(false);
				setLoading(false);
			} catch (error) {
				setLoading(false);
				console.log(error, 'catch the hoop')
				setSubmitDisable(false);
			}
		}
	}

	useEffect(() => {

		if (id === 0) {
			setProjectData(initialState);
			setQuotationHeader('')
			setQuotationFooter('')
		} else {
			fetch(
				process.env.REACT_APP_API_URL + 'projects/' + id,
				getRequestOptions()
			)
				.then((resp) => {
					return resp.json()
				})
				.then((resp) => {
					setProjectData({
						idProjects: resp.project.id_projects, projectName: resp.project.project_name, projectShortName: resp.project.project_short_name,
						printBackground: '', quotationHeader: resp.project.quotation_header,
						quotationFooter: resp.project.quotation_footer,
						accountantApproval: resp.project.accountant_approval
					});
					setQuotationHeader(resp.project.quotation_header)
					setQuotationFooter(resp.project.quotation_footer)
				})
				.catch((error) => {
					console.log(error, 'catch the hoop')
				})
		}
	}, [id]);


	return (
		<Modal show={show} onHide={() => handleClose(false)} size="xl" backdrop="static" keyboard={false}>
			<form onSubmit={projectSave}>
				<Modal.Header closeButton>
					<Modal.Title>Create Project</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="form-group row">
						<label htmlFor="projectName" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Project Name <span className="required text-danger"></span></label>
						<div className="col-lg-5">
							<input type="text" name="projectName" id="projectName" value={projectData.projectName} onChange={handleChange} className="form-control form-control-sm" />
						</div>
					</div>
					<div className="form-group row">
						<label htmlFor="projectShortName" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Project Short Name <span className="required text-danger"></span></label>
						<div className="col-lg-5">
							<input type="text" name="projectShortName" id="projectShortName" value={projectData.projectShortName} onChange={handleChange} className="form-control form-control-sm" />
						</div>
					</div>
					<div className="form-group row">
						<label htmlFor="printBackground" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Print Background <span className="required text-danger"></span></label>
						<div className="col-lg-5">
							<input type="file" name="printBackground" id="printBackground" value={projectData.printBackground} onChange={handleFileChange} className="form-control form-control-sm" />
						</div>
					</div>
					<div className="form-group row">
						<label className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Accountant Approval <span className="required text-danger"></span></label>
						<div className="col-lg-5">
							<div className='fv-row mb-5 d-flex flex-start'>
								<Form.Check // prettier-ignore
									type={"radio"}
									id={`accountant-approval-yes`}
									label={`Yes`}
									value={"Yes"}
									className='me-10 form-check-solid'
									name="accountantApproval"
									checked={projectData.accountantApproval === 'Yes'}
									onChange={handleChange}
								/>
								<Form.Check // prettier-ignore
									type={"radio"}
									id={`accountant-approval-no`}
									label={`No`}
									value={"No"}
									className='me-10 form-check-solid'
									name="accountantApproval"
									checked={projectData.accountantApproval === 'No'}
									onChange={handleChange}
								/>
							</div>
						</div>
					</div>
					<div className="form-group row">
						<label htmlFor="quotationHeader" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Quotation Header <span className="required text-danger"></span></label>
						<div className="col-lg-9">
							<Editor
								apiKey="c86hkmb8gwenrnbdfmj540pulih521ihze9v44lv1sf9a80h"
								initialValue={quotationHeader}
								init={editorConfig}
								onEditorChange={handleQuotationHeaderChange}
							/>
						</div>
					</div>
					<div className="form-group row mt-2">
						<label htmlFor="quotationFooter" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Quotation Footer <span className="required text-danger"></span></label>
						<div className="col-lg-9">
							<Editor
								apiKey="c86hkmb8gwenrnbdfmj540pulih521ihze9v44lv1sf9a80h"
								initialValue={quotationFooter}
								init={editorConfig}
								onEditorChange={handleQuotationFooterChange}
							/>
						</div>
					</div>
					{/* <pre>{JSON.stringify(projectData, null, 2)}</pre> */}
				</Modal.Body>
				<Modal.Footer>

					<button type="submit" disabled={isSubmitDisable || loading} className="btn btn-sm btn-primary me-2">
						{loading &&
							<Spinner
								as="span"
								animation="grow"
								size="sm"
								role="status"
								aria-hidden="true"
							/>
						}
						<span>  Submit</span>
					</button>
					<Button variant='secondary' size="sm" onClick={() => handleClose(false)}>
						Close
					</Button>
				</Modal.Footer>
			</form>
		</Modal>
	)
}

export default ProjectCreate
