/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { getRequestOptions } from '../helpers/Fetchwrapper';
import { Col, Row, Table } from 'react-bootstrap-v5';
import 'react-dropzone-uploader/dist/styles.css'
import { useParams } from 'react-router-dom'
import { KTSVG } from '../../_metronic/helpers';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap-v5';

interface Manufacturing {
    idProductions: number;
    createdBy: number;
    referenceNo: string;
    productionDate: number;
    projectName: string;
    productionAt: string;
    createdAt: any;
    productionBy: string;
    products: { idProductionRows: number, productName: string, categoryName: string, unitName: string, quantity: number, cost: number }[];
}

interface RouteParams {
    idProduction: string;
}

// const getReadableFileSizeString = (fileSizeInBytes: number) => {
//     return ((fileSizeInBytes / (1024 * 1024)).toFixed(4) + " MB");
// }

export function ManufacturingDetails(props: any) {

    // const [tab, setTab] = useState('Sidebar')
    const [manufacturingInfo, setManufacturingInfo] = useState<Manufacturing | null>(null);
    const { idProduction }: RouteParams = useParams();
    // const [message, setMessage] = useState('')

    const [createPaymentModalShow, setCreatePaymentModalShow] = useState(false);
    const handleCreatePaymentModalClose = (isReload: boolean) => {
        setCreatePaymentModalShow(false);
    }

    useEffect(() => {
        console.log(idProduction)
        if (idProduction !== undefined) {
            getManufacturingInfo();
        }
    }, [idProduction])

    const getManufacturingInfo = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'manufacturing/' + idProduction,
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                const data: Manufacturing = resp.data;
                data.products = resp.products;
                setManufacturingInfo(data);

            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }



    // const sendMessage = async () => {
    //     showLoader()
    //     const formData = {
    //         idProduction: idProduction,
    //         message: message
    //     };
    //     const requestOptions = postRequestOptions(formData)
    //     try {
    //         let response = await fetch(
    //             `${process.env.REACT_APP_API_URL}sales-invoice/comment`,
    //             requestOptions
    //         )
    //         let respData = await response.json()
    //         Swal.close();
    //         if (respData.success === true) {
    //             setMessage('');
    //             getManufacturingInfo()
    //         } else {
    //             const errorsMessage = validationErrorMsg(respData.errors).join('<br>')
    //             Swal.fire({
    //                 icon: 'error',
    //                 title: 'Error',
    //                 html: errorsMessage,
    //             })
    //         }

    //     } catch (error) {
    //         console.log(error, 'catch the hoop')
    //         Swal.close();
    //     }
    // }

    // const jwt = () => {
    //     const myToken = localStorage.getItem('MyToken');
    //     return (myToken != null) ? JSON.parse(myToken) : null;
    // }

    // const handleFileSubmit = (files: any, allFiles: []) => {
    //     showLoader()

    //     const postFormData = new FormData();
    //     postFormData.append('idProduction', idProduction.toString());
    //     for (let i = 0; i < files.length; i++) {
    //         postFormData.append('files[]', files[i].file);
    //     }

    //     const url: string = `${process.env.REACT_APP_API_URL}sales-invoice/file-upload`;

    //     axios({
    //         method: 'POST',
    //         url: url,
    //         headers: {
    //             'Content-Type': 'multipart/form-data',
    //             Authorization: 'Bearer ' + jwt(),
    //         },
    //         data: postFormData,
    //     })
    //         .then((response: AxiosResponse<any>) => {
    //             Swal.close();
    //             allFiles.forEach((f: any) => f.remove());
    //             getManufacturingInfo();
    //         })
    //         .catch((error: AxiosError<any>) => {
    //             Swal.close();
    //             // const errorMessage: string = error.response?.data?.errorMessage;
    //             const errorsMessage: string = validationErrorMsg(error?.response?.data?.errors).join('<br>');
    //             Swal.fire({
    //                 icon: 'error',
    //                 title: 'Error',
    //                 html: errorsMessage,
    //             });
    //         });

    // }

    const [configurationProduct, setConfigurationProduct] = useState<ProductionRows>();

    const configureProduct = (idProductionRows: number) => {
        fetch(
            process.env.REACT_APP_API_URL + 'manufacturing/rate-details/' + idProductionRows,
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                const data: Manufacturing = resp.data;
                data.products = resp.products;
                setConfigurationProduct(data);
                setCreatePaymentModalShow(true)

            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }



    return (<>
        <div className='card card-custom'>
            <div className='card-header card-header-stretch overflow-auto'>
                <div className='card-title'>
                    <h3 className='card-label'>Manufacturing Details</h3>
                    <small className='text-muted'>| Information about the manufacturing</small>
                </div>
            </div>
            <div className="card-body">

            </div>
        </div>

        <Row>
            {/* <pre>{JSON.stringify(configurationProduct, null, 2)}</pre> */}
            <Col md={4} className="pe-0 pt-0">
                <div className="card card-flush overflow-hidden m-2 me-0">
                    <div className="card-header py-5">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bold text-dark">Summary</span>
                        </h3>
                    </div>
                    <div className="card-body pt-0">
                        <Table striped bordered hover size='sm'>
                            <tbody>
                                <tr>
                                    <td style={{ width: '30%' }}>ID</td>
                                    <td style={{ width: '70%' }}>{manufacturingInfo?.idProductions}</td>
                                </tr>
                                <tr>
                                    <td>Reference ID</td>
                                    <td>{manufacturingInfo?.referenceNo}</td>
                                </tr>
                                <tr>
                                    <td>Invoice Date</td>
                                    <td>{manufacturingInfo?.productionDate}</td>
                                </tr>
                                <tr>
                                    <td>Project</td>
                                    <td>{manufacturingInfo?.projectName}</td>
                                </tr>
                                <tr>
                                    <td>Creator</td>
                                    <td>{manufacturingInfo?.productionBy + ' at ' + manufacturingInfo?.productionAt}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>


                {/* <div className="card card-flush overflow-hidden m-2 me-0">
                    <div className="card-header py-5">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bold text-dark">Lead Details</span>
                        </h3>
                    </div>
                    <div className="card-body pt-0">
                        <Table striped bordered hover size='sm'>
                            <tbody>
                                <tr>
                                    <td style={{ width: '50%' }}>Lead Name</td>
                                    <td style={{ width: '50%' }}>{manufacturingInfo?.sales_order?.lead?.leadName}</td>
                                </tr>
                                <tr>
                                    <td>Client</td>
                                    <td>{manufacturingInfo?.sales_order?.lead?.company?.company_name}</td>
                                </tr>
                                <tr>
                                    <td>Category</td>
                                    <td>{manufacturingInfo?.sales_order?.lead?.company?.company_category?.category_name}</td>
                                </tr>
                                <tr>
                                    <td>Address</td>
                                    <td>{manufacturingInfo?.sales_order?.lead?.company?.company_address}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>

                <div className='card card-custom'>
                    <div className='card-header card-header-stretch overflow-auto'>
                        <ul
                            className='nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap'
                            role='tablist'
                        >
                            <li className='nav-item p-2'>
                                <a
                                    className={clsx(`nav-link cursor-pointer`, { active: tab === 'Sidebar' })}
                                    onClick={() => setTab('Sidebar')}
                                    role='tab'
                                >
                                    <KTSVG className='svg-icon-3 me-2' path="/media/svg/icons/Communication/Chat6.svg"></KTSVG>
                                    Add comment
                                </a>
                            </li>
                            <li className='nav-item p-2'>
                                <a
                                    className={clsx(`nav-link cursor-pointer`, { active: tab === 'Toolbar' })}
                                    onClick={() => setTab('Toolbar')}
                                    role='tab'
                                >
                                    <KTSVG className='svg-icon-3 me-2' path="/media/svg/icons/General/Clip.svg"></KTSVG>
                                    Attach files
                                </a>
                            </li>
                        </ul>
                    </div>


                    <div className='card-body'>
                        <div className='tab-content pt-3'>
                            <div className={clsx('tab-pane', { active: tab === 'Sidebar' })}>

                                <Table striped bordered hover size='sm'>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '50%' }}>Comment</th>
                                            <th style={{ width: '50%' }}>Comment By</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {manufacturingInfo?.comments?.length === 0 &&
                                            <tr>
                                                <td colSpan={2}>No comments available</td>
                                            </tr>
                                        }
                                        {manufacturingInfo?.comments?.map((comment, index) => (
                                            <tr>
                                                <td key={'comment' + index}>{comment.comment}</td>
                                                <td>{comment.creator.first_name + ' ' + comment.creator.last_name + ' at ' + comment.created_at}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                <textarea
                                    className='form-control  mb-3'
                                    rows={3}
                                    data-kt-element='input'
                                    placeholder='Type a message'
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                ></textarea>
                                <div className='d-flex flex-row justify-content-end'>

                                    <button
                                        className='btn-sm btn-success'
                                        type='button'
                                        onClick={sendMessage}
                                    >
                                        Send
                                    </button>
                                </div>
                            </div>

                            <div className={'p-0 ' + clsx('tab-pane', { active: tab === 'Toolbar' })}>

                                <Table striped bordered hover size='sm'>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '50%' }}>Attachment</th>
                                            <th style={{ width: '50%' }}>Uploaded By</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {manufacturingInfo?.attachments?.length === 0 &&
                                            <tr>
                                                <td colSpan={2}>No File Attached</td>
                                            </tr>
                                        }
                                        {manufacturingInfo?.attachments?.map((attachment, index) => (
                                            <tr>
                                                <td key={'attachment' + index}>
                                                    <a rel='noreferrer' href={process.env.REACT_APP_AWS_URL + "salesOrders/" + attachment.attachment_path}
                                                        className="fs-6 text-hover-primary fw-bold" target="_blank">{attachment.attachment_name}</a>
                                                    <div className="text-gray-400">{getReadableFileSizeString(attachment.file_size)}</div>
                                                </td>
                                                <td>{attachment.creator.first_name + ' ' + attachment.creator.last_name + ' at ' + attachment.created_at}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>

                                <Dropzone
                                    styles={{ dropzone: { border: "none" } }}
                                    accept='image/*,audio/*,application/pdf'
                                    onSubmit={handleFileSubmit}
                                />
                            </div>
                        </div>
                    </div>

                </div> */}



            </Col>
            <Col md={8} className="pt-2">

                <div className="card card-flush overflow-hidden m-2 me-0">
                    <div className="card-header py-5">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bold text-dark">Items</span>
                        </h3>
                    </div>
                    <div className="card-body pt-0">
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th>Product</th>
                                    <th>Packing</th>
                                    <th>Category</th>
                                    <th>Unit</th>
                                    <th>Quantity</th>
                                    <th>Rate</th>
                                    <th>Amount</th>
                                    {/* <th>Action</th> */}
                                </tr>
                            </thead>
                            <tbody>

                                {manufacturingInfo?.products?.map((item, index) => (
                                    <>
                                        <tr key={'item' + index}>
                                            <td>{item?.productName}</td>
                                            <td>{item?.packing}</td>
                                            <td>{item?.categoryName}</td>
                                            <td>{item?.unitName}</td>
                                            <td>{item?.quantity}</td>
                                            <td className='text-end'>{item?.cost}</td>
                                            <td className='text-end'>{item?.cost * item?.quantity}</td>
                                            <td className='text-center'>
                                                <button className="btn btn-primary btn-sm" onClick={() => configureProduct(item?.idProductionRows)}>
                                                    <KTSVG
                                                        path='/media/svg/icons/Layout/Layout-grid.svg'
                                                        className='svg-icon-5 svg-icon'
                                                    />
                                                </button>
                                            </td>
                                        </tr>
                                    </>

                                ))}
                                {/* <tr>
                                    <td colSpan={4} className='text-end'><b>Total Amount : </b></td>
                                    <td className='text-end'><b>{manufacturingInfo?.totalAmount}</b></td>
                                </tr> */}
                            </tbody>
                        </Table>

                        {/* <div className='row'>
                            <div className='col text-center'>

                                <button className="btn btn-primary btn-sm" onClick={() => handlePaymentModalShow(salesOrderInfo)}>
                                    <KTSVG
                                        path='/media/svg/icons/Shopping/Cart3.svg'
                                        className='svg-icon-5 svg-icon'
                                    />		Make Payment
                                </button>

                            </div>
                        </div> */}

                        <div className='row'>
                            <div className='col text-center'>

                                <Link className="btn btn-info btn-sm" to={`/manufacturing`}>
                                    <KTSVG
                                        path='/media/svg/icons/Media/Back.svg'
                                        className='svg-icon-5 svg-icon'
                                    />		Back to Manufacture List
                                </Link>

                            </div>
                        </div>

                    </div>
                </div>

                {/* <div className="card card-flush overflow-hidden m-2 me-0">
                    <div className="card-header py-5">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bold text-dark">Payments History</span>
                        </h3>
                    </div>
                    <div className="card-body pt-0">
                        <Row>
                            <Col>
                                <Table striped bordered hover size='sm'>
                                    <thead>
                                        <tr>
                                            <th className='text-center' style={{ width: '10%' }}>ID</th>
                                            <th className='text-center' style={{ width: '10%' }}>Type</th>
                                            <th className='text-center' style={{ width: '10%' }}>Method</th>
                                            <th className='text-center' style={{ width: '10%' }}>Date</th>
                                            <th className='text-center' style={{ width: '10%' }}>Amount</th>
                                            <th className='text-center' style={{ width: '10%' }}>Remarks</th>
                                            <th className='text-center' style={{ width: '10%' }}>Transaction ID</th>
                                            <th className='text-center' style={{ width: '10%' }}>Payment By</th>
                                            <th className='text-center' style={{ width: '10%' }}>Payment Info</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {manufacturingInfo?.payments?.length === 0 &&
                                            <tr>
                                                <td colSpan={10}>No Data Found</td>
                                            </tr>
                                        }
                                        {manufacturingInfo?.payments?.map((payment, index) => (
                                            <>
                                                <tr>
                                                    <td className='text-center'>{payment.id_sales_payments}</td>
                                                    <td className='text-center'>{payment.payment_type}</td>
                                                    <td className='text-center'>{payment.payment_method}</td>
                                                    <td className='text-center'>{payment.payment_date}</td>
                                                    <td className='text-center'>{payment.payment_amount}</td>
                                                    <td className='text-center'>{payment.remarks}</td>
                                                    <td className='text-center'>{payment.transaction_id}</td>
                                                    <td className='text-center'>{payment.creator.first_name + ' ' + payment.creator.last_name}</td>
                                                    <td className='text-center'>{payment.paymentInfo}</td>
                                                </tr>
                                            </>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </div>
                </div> */}



            </Col>
        </Row>
        {/* <pre>{JSON.stringify(manufacturingInfo, null, 2)}</pre> */}

        {/* <SalesPaymentCreate show={createPaymentModalShow} handleCreatePaymentModalClose={handleCreatePaymentModalClose} salesOrderInfo={salesOrderInfo} /> */}

        {configurationProduct != null &&
            <>
                <Modal show={createPaymentModalShow} onHide={() => handleCreatePaymentModalClose(false)} size="xl">
                    <Modal.Header closeButton>
                        <Modal.Title>Product Cost Breakdown</Modal.Title>
                    </Modal.Header>
                    {/* <Form onSubmit={handleSubmit}> */}
                    <Modal.Body>


                        <Table size='sm' bordered hover>
                            <tr>
                                <td width={'30%'}>Product Name </td>
                                <td width={'70%'}> : {configurationProduct.product.product_name}</td>
                            </tr>
                            <tr>
                                <td>Product Code </td>
                                <td> : {configurationProduct.product.item.item_code}</td>
                            </tr>
                            <tr>
                                <td>Pack Size </td>
                                <td> : {configurationProduct.product.size + ' ' + configurationProduct.product.item.unit.unit_name}</td>
                            </tr>
                        </Table>


                        <Table size='sm' bordered hover>
                            <thead>
                                <tr>
                                    <th className="col-2">Item</th>
                                    <th className="col-2">Category</th>
                                    <th className="col-1">Unit</th>
                                    <th className="col-1">Weighted Loss (%)</th>
                                    <th className="col-1">Quantity</th>
                                    <th className="col-1">Effective Quantity</th>
                                    <th className="col-2">Rate</th>
                                    <th className="col-2">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {configurationProduct.materials.map((productItem: any, index: number) => (
                                    <tr key={'item' + index}>
                                        <td className='text-left'>{productItem.item.item_name}</td>
                                        <td>{productItem.item.item_category.category_name}</td>
                                        <td>{productItem.item.unit.unit_name}</td>
                                        <td>{productItem.weighted_loss}</td>
                                        <td>{productItem.without_loss_quantity}</td>
                                        <td>{productItem.quantity}</td>
                                        <td>{productItem.rate}</td>
                                        <td>{productItem.amount}</td>
                                    </tr>
                                ))}
                                <tr>
                                    <td className='text-end' colSpan={7}><b>Total Item Cost : </b></td>
                                    <td>{configurationProduct.materials_sum_amount}</td>
                                </tr>
                            </tbody>
                        </Table>

                        <Table size='sm' bordered hover>
                            <thead>
                                <tr>
                                    <th className="col-9">Ohverhead Name</th>
                                    <th className="col-3">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {configurationProduct.overheads.map((productOverhead: any, index: number) => (
                                    <tr key={'overhead' + index}>
                                        <td className='text-left'>{productOverhead.overhead.overhead_name}</td>
                                        <td className='text-left'>{productOverhead.amount}</td>
                                    </tr>
                                ))}
                                <tr>
                                    <td className='text-end'><b>Total Overhead Cost : </b></td>
                                    <td>{configurationProduct.overheads_sum_amount}</td>
                                </tr>
                            </tbody>
                        </Table>

                        <Table size='sm' bordered hover>

                            <tbody>
                                <tr>
                                    <td className='text-end col-9'><b>Production Cost : </b></td>
                                    <td className='col-3'>{configurationProduct.production_cost}</td>
                                </tr>
                                <tr>
                                    <td className='text-end col-9'><b>VAT : </b></td>
                                    <td className='col-3'>{configurationProduct.vat}</td>
                                </tr>
                                <tr>
                                    <td className='text-end col-9'><b>Total Production Cost with VAT : </b></td>
                                    <td className='col-3'><b>{parseFloat(configurationProduct.production_cost) + parseFloat(configurationProduct.vat)}</b></td>
                                </tr>
                            </tbody>


                        </Table>
                    </Modal.Body>

                    <Modal.Footer>

                        <Button variant='secondary' size="sm" onClick={() => handleCreatePaymentModalClose(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

            </>
        }

    </>
    )
}

export default ManufacturingDetails
