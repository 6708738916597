import { useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap-v5";
import { KTSVG } from '../../_metronic/helpers';
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper';
import validationErrorMsg from '../helpers/ValidationErrorMsg'
import Swal from 'sweetalert2'
import EmailForm from './EmailForm';
import PhoneForm from './PhoneForm';
import CreatableSelect from 'react-select/creatable';


interface FormDataType {
    idLeads?: number,
    name?: any,
    idCompanyContactPersons?: any,
    contactPersonName: any,
    emails?: any,
    phones?: any,
    address?: any,
}

interface LeadUseProps {
    showContactPersonEdit: boolean,
    handleClose: any,
    leadInfo: any,
    getLeadInfo: any,
}

interface Option {
    readonly label: string;
    readonly value: string;
}


export default function LeadContactPersonEdit({ showContactPersonEdit, handleClose, leadInfo, getLeadInfo }: LeadUseProps) {

    const initialState: FormDataType = {
		idLeads: leadInfo?.idLeads,
		idCompanyContactPersons: leadInfo?.contact_person?.idCompanyContactPersons ? leadInfo?.contact_person?.idCompanyContactPersons : null, 
        contactPersonName: leadInfo?.contact_person?.name,
		name: leadInfo?.contact_person?.name, 
		emails: leadInfo?.contact_person?.emails, 
		phones: leadInfo?.contact_person?.phones,
        address: leadInfo?.contact_person?.location
	};

    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState<FormDataType>(initialState);
    const [contactPersonOptions, setContactPersonOptions] = useState([] as Option[]);
    const [selectedValue, setSelectedValue] = useState<Option | null>({
        label: leadInfo?.contact_person?.name,
        value: leadInfo?.contact_person?.idCompanyContactPersons
    });


    const getContactPersonByCompany = () => {
		fetch(`${process.env.REACT_APP_API_URL}lead/contact-persons/`+leadInfo?.idCompanies, getRequestOptions())
		.then((resp) => resp.json())
		.then((resp) => {
			let contactPersons: Option[] = resp.data.map((item: any) => {
                item.label = item.name;
                item.value = item.idCompanyContactPersons;
                return item;
			});
			setContactPersonOptions(contactPersons);
		})
		.catch((error) => {
			console.log(error, 'catch the hoop');
		});
	};

    useEffect(() => {
		getContactPersonByCompany()
        setFormData(initialState)
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [leadInfo]);

    const getEmailsData = (data: any) => {
		setFormData({ ...formData, emails: data });
	}

	const getPhonesData = (data: any) => {
		setFormData({ ...formData, phones: data });
	}

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setIsLoading(true);
       
        const requestOptions = postRequestOptions(formData)
        try {
            let response = await fetch(
                `${process.env.REACT_APP_API_URL}lead/update-contact-person`,
                requestOptions
            )
            let respData = await response.json()
            setIsLoading(false);
            if (respData.success === true) {
                getLeadInfo();
            } else {
                const errorsMessage = validationErrorMsg(respData.errors).join('<br>')
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    html: errorsMessage,
                })
            }

        } catch (error) {
            setIsLoading(false);
        }

    }

    const handleContactPersonChange = (value:any, actionMeta: any) => {
        
        setSelectedValue(value);
        if(actionMeta.action==='clear'){
            setFormData({ ...formData, idCompanyContactPersons:null, contactPersonName: null, emails: [], phones: [], address: null });
        }else if(actionMeta.action==='create-option'){
            setFormData({ ...formData, idCompanyContactPersons:null, contactPersonName: value.label, emails: [], phones: [], address: null });
        }else{
            setFormData({ ...formData, idCompanyContactPersons: value.value, contactPersonName:value.label, emails: value.emails, phones: value.phones, address: value.address });
        }
    
    }

    const handleChange = (e: any) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};
    
    return (<>
       
        <Modal show={showContactPersonEdit} onHide={handleClose} className="contact-person">
            <Form onSubmit={handleSubmit}>
                <Modal.Header className="py-3" closeButton>
                    <Modal.Title>Edit Contact Person</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3" controlId="leadName">
                        <Form.Label>Contact Person<span className="required text-danger"></span></Form.Label>
                        <CreatableSelect 
                            isClearable
                            onChange={handleContactPersonChange}
                            options={contactPersonOptions} 
                            value={selectedValue}
                            // style={{ zIndex: '99999' }}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="email">
                        <Form.Label>Email</Form.Label>
                        <EmailForm getEmailsData={getEmailsData} emails={formData.emails}></EmailForm>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="phone">
                        <Form.Label>Phone</Form.Label>
                        <PhoneForm getPhonesData={getPhonesData} phones={formData.phones}></PhoneForm>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="address">
                        <Form.Label>Address</Form.Label>
                        <Form.Control type="textarea" name="address" placeholder="Enter address" value={formData?.address} onChange={handleChange} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size="sm" onClick={handleClose}>
                        Close
                    </Button>

                    <Button disabled={isLoading} variant="success" size="sm" type="submit" onClick={handleClose}>
                        {isLoading ? <i className='fa fa-spin fa-spinner'></i> : <><KTSVG className="svg-icon-3" path="/media/svg/icons/General/Save.svg" /> Update</>}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    </>)
}