import React from 'react';
import { Table, Form } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import Select from 'react-select';
import { postRequestOptions, getRequestOptions } from "../helpers/Fetchwrapper";
import { Card } from 'react-bootstrap-v5';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { numberFormat, unitNameByProject } from '../helpers/GlobalHelper';
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom"

// Define a type for the sales data
interface SalespersonActiviteData {
    id: number;
    name: string;
    opBalance: number;
    totalInvoicedAmount: number;
    totalPaymentAmount: number;
    totalBalance: number;
    totalInvoiceQuantity: number;
};

interface SalespersonActivitesReportProps {

}

const SalespersonActivitesReport: React.FC<SalespersonActivitesReportProps> = (props) => {

    const location = useLocation();
    const query = new URLSearchParams(location.search);

    const [loading, setLoading] = useState(false);
    const [internalData, setInternalData] = useState({});
    const [salespersonActivites, setSalespersonActivites] = useState<SalespersonActiviteData[]>([]);
    const [unknownData, setUnknownData] = useState({});
    const [formData, setFormData] = useState({
        idProjects: query.get('idProjects') ?? '',
        fromDate: query.get('fromDate') ?? '',
        toDate: query.get('toDate') ?? '',
    })

    const [projects, setProjects] = useState([]);
    const [project, setProject] = useState({ label: 'Select Project', value: '' });

    useEffect(() => {
        getProjects();
    }, [])

    useEffect(() => {

        if (projects.length > 0) {
            const projectObj = projects.find((project: any) => project.value === Number(query.get('idProjects')));
            setProject({
                label: projectObj?.label,
                value: projectObj?.value
            });
        } else {
            setProject({ label: 'Select Project', value: '' });
        }

        if (formData.fromDate && formData.toDate) {
            handleViewReport();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projects]);

    const getProjects = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'lead/projects',
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let projectObj = resp.data.map((item: any) => {
                    item.label = item.name;
                    item.value = item.id;
                    return item
                })
                setProjects(projectObj);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const handleProjectChange = (project: any) => {
        setProject(project);
        if (project) {
            setFormData({ ...formData, idProjects: project.value });
        } else {
            setFormData({ ...formData, idProjects: '' });
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleViewReport = async () => {
        setLoading(true);

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + 'reports/salesperson-activites', postRequestOptions(formData));
            const data = await response.json();
            if (data.success) {
                setInternalData(data.data.internal);
                setSalespersonActivites(data.data.salesPersons);
                setUnknownData(data.data.unknown);
            } else {
                setInternalData({});
                setSalespersonActivites([]);
                setUnknownData({});
                data.errors.forEach((error: string) => toast.error(error, { position: 'bottom-right', autoClose: 4000 }));
            }
        } catch (error) {
            console.error('Error submitting data:', error);
        } finally {
            setLoading(false);
        }
    };

    // Outside your component's return statement, initialize a serial number counter
    const queryParams = new URLSearchParams(formData);
    let slNo = 1;

    return (
        <>
            <Card className='mb-4'>
                <Card.Body>
                    <Card.Title></Card.Title>
                    <Card.Text>
                        <div className='row'>
                            <div className='col-md-3'>
                                <Form.Group className="mb-3" controlId="project">
                                    <Form.Label>Project<span className='required'></span></Form.Label>
                                    <Select
                                        value={project}
                                        onChange={handleProjectChange}
                                        options={projects}
                                        menuPosition="fixed"
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        isClearable={true}
                                    />
                                </Form.Group>
                            </div>
                            <div className='col-md-2'>
                                <Form.Group className="mb-3" controlId="project">
                                    <Form.Label>From Date<span className='required'></span></Form.Label>
                                    <Form.Control type="date" placeholder="From Date" name='fromDate' value={formData.fromDate} onChange={handleChange} />
                                </Form.Group>
                            </div>
                            <div className='col-md-2'>
                                <Form.Group className="mb-3" controlId="project">
                                    <Form.Label>To Date<span className='required'></span></Form.Label>
                                    <Form.Control type="date" placeholder="To Date" name='toDate' value={formData.toDate} onChange={handleChange} />
                                </Form.Group>
                            </div>
                            <div className='col-md-2'>
                                <div className="form-control-sm ps-0 mt-7">
                                    <button className='btn btn-sm btn-primary w-100 p-3' onClick={handleViewReport} disabled={loading}>
                                        {loading && (
                                            <span className="spinner-border spinner-border-sm"></span>
                                        )}
                                        <span>View Report</span>
                                    </button>
                                </div>
                            </div>
                            <div className='col-md-2'>
                                <div className="form-control-sm ps-0 mt-7">
                                    <a className="btn btn-sm btn-primary w-100 p-3 mb-2" href={`${process.env.REACT_APP_BACKEND}salesperson_activite_report_download?` + queryParams.toString()}><i className='fas fa-download'></i>Excel Download</a>
                                </div>
                            </div>
                        </div>
                    </Card.Text>
                </Card.Body>
            </Card>

            <div className='row'>
                <div className='col-12'>
                    <Table responsive bordered hover>
                        <thead>
                            <tr>
                                <th className='text-center'>Sl.</th>
                                <th className='text-center'>Sales Person</th>
                                <th className='text-center'>SR Category</th>
                                <th className='text-center'>Op. Balance</th>
                                <th className='text-center'>Sales</th>
                                <th className='text-center'>Collecton</th>
                                <th className='text-center'>Balance</th>
                                <th className='text-center'>Qty( {unitNameByProject(project?.value)} )</th>
                            </tr>
                        </thead>
                        <tbody>
                            {internalData && Object.keys(internalData).length > 0 && (
                                <tr key={internalData.id}>
                                    <td className='text-center'>{slNo++}</td>
                                    <td>{internalData.name}</td>
                                    <td></td>
                                    <td className='text-end'>{numberFormat(Number(internalData.opBalance))}</td>
                                    <td className='text-end'>{numberFormat(Number(internalData.totalInvoicedAmount))}</td>
                                    <td className='text-end'>{numberFormat(Number(internalData.totalPaymentAmount))}</td>
                                    <td className='text-end'>{numberFormat(Number(internalData.totalBalance))}</td>
                                    <td className='text-end'>{numberFormat(internalData.totalInvoiceQuantity)}</td>
                                </tr>
                            )}

                            {unknownData && Object.keys(unknownData).length > 0 && (
                                <tr key={unknownData.id}>
                                    <td className='text-center'>{slNo++}</td>
                                    <td><strong>{unknownData.name}</strong></td>
                                    <td></td>
                                    <td className='text-end'>{numberFormat(Number(unknownData.opBalance))}</td>
                                    <td className='text-end'>{numberFormat(Number(unknownData.totalInvoicedAmount))}</td>
                                    <td className='text-end'>{numberFormat(Number(unknownData.totalPaymentAmount))}</td>
                                    <td className='text-end'>{numberFormat(Number(unknownData.totalBalance))}</td>
                                    <td className='text-end'>{numberFormat(unknownData.totalInvoiceQuantity)}</td>
                                </tr>
                            )}
                           

                            {salespersonActivites.length>0 ? salespersonActivites.map((salesPerson, sl) => (
                               
                                <tr key={salesPerson.id}>
                                    <td className='text-center'>{slNo++}</td>
                                    <td><Link to={`/sr-details/${salesPerson.id}`}>{salesPerson.name}</Link></td>
                                    <td><strong>{salesPerson.srCategoryName}</strong></td>
                                    <td className='text-end'>{numberFormat(salesPerson.opBalance)}</td>
                                    <td className='text-end'>{numberFormat(salesPerson.totalInvoicedAmount)}</td>
                                    <td className='text-end'>{numberFormat(salesPerson.totalPaymentAmount)}</td>
                                    <td className='text-end'>{numberFormat(salesPerson.totalBalance)}</td>
                                    <td className='text-end'>{numberFormat(salesPerson.totalInvoiceQuantity)}</td>
                                </tr>

                            )) : null}

                            {salespersonActivites.length === 0 
                                && unknownData && Object.keys(unknownData).length > 0  
                                && internalData && Object.keys(internalData).length > 0 
                                && (
                                <tr>
                                    <td colSpan={8} className='text-center'>No Salesperson Activities Found</td>
                                </tr>
                            )}
           
                        </tbody>
                        {salespersonActivites.length > 0 || internalData ? (
                            <tfoot>
                                <tr>
                                    <td colSpan={3} className='text-end'><strong>Total</strong></td>
                                    <td className='text-end'><strong>{numberFormat(salespersonActivites.reduce((a, b) => a + Number(b.opBalance), 0) + Number(internalData.opBalance) +Number(unknownData.opBalance))}</strong></td>
                                    <td className='text-end'><strong>{numberFormat(salespersonActivites.reduce((a, b) => a + Number(b.totalInvoicedAmount), 0) + Number(internalData.totalInvoicedAmount) + Number(unknownData.totalInvoicedAmount))}</strong></td>
                                    <td className='text-end'><strong>{numberFormat(salespersonActivites.reduce((a, b) => a + Number(b.totalPaymentAmount), 0) + Number(internalData.totalPaymentAmount) + Number(unknownData.totalPaymentAmount))}</strong></td>
                                    <td className='text-end'><strong>{numberFormat(salespersonActivites.reduce((a, b) => a + Number(b.totalBalance), 0) + Number(internalData.totalBalance) + Number(unknownData.totalBalance))}</strong></td>
                                    <td className='text-end'><strong>{numberFormat(salespersonActivites.reduce((a, b) => a + Number(b.totalInvoiceQuantity), 0) + Number(internalData.totalInvoiceQuantity) + Number(unknownData.totalInvoiceQuantity))}</strong></td>
                                </tr>
                            </tfoot>
                        ) : null}
                    </Table>
                </div>
            </div>
        </>
    );
};

export default SalespersonActivitesReport;