/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { KTSVG } from '../../_metronic/helpers';
import LeadCreate from '../components/LeadCreate';
import BackendTable from 'react-backend-table';
import Swal from 'sweetalert2'
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper';
import showLoader from "../helpers/ShowLoader"
import { Link } from "react-router-dom"
import Select from 'react-select';

export function Leads() {

    const [showLeadModal, setShowLeadModal] = useState(false);
    const [selectProjectOptions, setSelectProjectOptions] = useState([])
    const [selectedProject, setSelectedProject] = useState<{ value: string; label: string; } | null>(null);

    const getProjectList = async () => {
        const url = process.env.REACT_APP_API_URL + 'projects';

        const response = await fetch(url, getRequestOptions());

        const data = await response.json();

        data.data.map((element: any) => {
            element.value = element.id_projects;
            element.label = element.project_name;
            return element;
        })

        if (data.data.length === 1) {
            setSelectProjectOptions(data.data)

        } else {
            var allProjectsOpt = [{ id: '', name: '', label: 'All Project', value: '' }]
            let allProjects = data.data.concat(allProjectsOpt);
            setSelectProjectOptions(allProjects);
        }

    }

    useEffect(() => {
        getProjectList();
    }, [])

    const handleClose = (isShow: any) => {
        setShowLeadModal(false);
        options.reloadMyTable();
    }


    let columns = [
        { title: 'ID', field: 'idLeads', sortable: true, searchable: true, thStyle: { textAlign: "center" }, tdStyle: { textAlign: "center" } },
        {
            title: 'Lead Name',
            field: 'leadName',
            sortable: true,
            searchable: true,
            hasComponent: true,
            componentValue: ((rowValue: any) => {
                return (
                    <>
                        <a href={"/#/lead-details?idLeads=" + rowValue.idLeads}>{rowValue.leadName}</a>
                    </>
                )
            })
        },
        {
            title: 'Client',
            field: 'companyName',
            sortable: true,
            searchable: true,
            hasComponent: true,
            componentValue: ((rowValue: any) => {
                return (
                    <>
                        <Link to={"company-details/" + rowValue.idCompanies}>{rowValue.companyName}</Link>
                    </>
                )
            })
        },
        { title: 'Project', field: 'projectName', sortable: true, searchable: true },
        { title: 'Contact Person', field: 'contactPersonName', searchable: true, sortable: true },
        {
            title: 'Status',
            field: 'idSalesOrders',
            sortable: true,
            searchable: true,
            hasComponent: true,
            thStyle: { textAlign: "center" },
            tdStyle: { textAlign: "center" },
            componentValue: ((rowValue: any) => {
                return (
                    <>
                        {rowValue.idSalesOrders ? <span className='badge badge-success'>Won</span> : ''}
                    </>
                );
            }),
        },
        {
            title: 'Last Activity',
            field: 'lastActivity',
            sortable: true,
            searchable: true,
            hasComponent: true,
            componentValue: ((rowValue: any) => {
                return (
                    <>
                        {rowValue.lastActivity ? <span> {rowValue.lastActivityType.slice(0, 1).toUpperCase() + rowValue.lastActivityType.slice(1, rowValue.lastActivityType.length) + ' - ' + rowValue.lastActivity}</span> : ''}
                    </>
                );
            }),
        },
        {
            title: 'Next Activity',
            field: 'nextActivity',
            sortable: true,
            searchable: true,
            hasComponent: true,
            componentValue: ((rowValue: any) => {
                return (
                    <>
                        {rowValue.nextActivity ? <span>{rowValue.nextActivity.slice(0, 1).toUpperCase() + rowValue.nextActivity.slice(1, rowValue.nextActivity.length) + ' - ' + rowValue.nextActivity}</span> : ''}
                    </>
                );
            }),
        },
        {
            title: 'Assigned To',
            field: 'assignedTo',
            sortable: true,
            searchable: true,
            hasComponent: true,
            componentValue: ((rowValue: any) => {
                return (
                    <>
                        <Link to={`/sr-details/${rowValue.assignedTo}`}>{rowValue.assignedToName}</Link>
                    </>
                );
            }),
        },

        {
            title: 'Priority',
            field: 'priority',
            sortable: true,
            searchable: true,
            hasComponent: true,
            componentValue: ((rowValue: any) => {
                let labelClass;
                if (rowValue.priority === "high") {
                    labelClass = "success";
                } else if (rowValue.priority === "medium") {
                    labelClass = "cyan";
                } else {
                    labelClass = "warning";
                }

                return (
                    <>
                        <span style={{ cursor: "pointer" }} className={'w-100 badge badge-' + labelClass} title='Change Lead Priority' onClick={() => changePriority(rowValue)}>{rowValue.priority?.charAt(0).toUpperCase() + rowValue.priority?.slice(1)}</span>
                    </>
                );
            }),
            thStyle: { textAlign: "center" }, tdStyle: { textAlign: "center" }
        },
        { title: 'Sold Amount', field: 'soldAmount', sortable: true, searchable: false, thStyle: { textAlign: "center" }, tdStyle: { textAlign: "right" } },
    ]

    const jwt = () => {
        const myToken = localStorage.getItem('MyToken');
        return (myToken != null) ? JSON.parse(myToken) : null;
    }

    let options = {
        title: '',
        url: process.env.REACT_APP_API_URL + 'leads',
        authorization: 'Bearer ' + jwt(),
        perPage: [10, 20, 50, 100, 500],
        orderBy: 'idLeads',
        orderType: 'desc',
        columnSearch: true,
        reloadMyTable: () => { },
        extraData: { idProjects: selectedProject?.value }
    }


    const changePriority = (rowValue: any) => {

        Swal.fire({
            title: 'Change Lead Priority',
            input: 'select',
            inputAttributes: {
                autocapitalize: 'off',
                placeholder: 'Select Priority',
                defaultValue: rowValue.priority,
            },
            inputOptions: {
                High: 'High',
                Medium: 'Medium',
                Low: 'Low',
            },
            inputValue: rowValue.priority,
            showCancelButton: true,
            confirmButtonText: 'Submit',
            showLoaderOnConfirm: true,
            preConfirm: (content: any) => {
                let postData = { idLeads: rowValue.idLeads, priority: content };
                console.log(postData);
                updatePriority(postData);
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                console.log("result.value=", result.value);
            }
        })
    }

    const updatePriority = async (postData: any) => {
        showLoader();
        const requestOptions = postRequestOptions(postData);
        try {
            let response = await fetch(
                `${process.env.REACT_APP_API_URL}lead/update-priority`,
                requestOptions
            )
            let respData = await response.json()
            Swal.close();

            if (respData.success === true) {
                options.reloadMyTable();
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    html: respData.errorMessage,
                })
            }

        } catch (error) {
            Swal.close();
        }
    }

    useEffect(() => {
        if (selectProjectOptions.length === 1) {
            setSelectedProject({ label: selectProjectOptions[0]['label'], value: selectProjectOptions[0]['value'] })
        }
    }, [selectProjectOptions])

    useEffect(() => {
        options.reloadMyTable();
    }, [selectedProject])


    return (
        <>
            <div className='card card-custom'>
                <div className='card-header'>
                    <div className='card-title'>
                        <h3 className='card-label'>Leads</h3>
                        <small className='text-muted'>| Manage and track your leads</small>
                    </div>
                    <div className="card-toolbar">
                        <div className='d-flex justify-content-end'>
                            <Select
                                // defaultValue={selectProjectOptions}
                                onChange={setSelectedProject}
                                options={selectProjectOptions}
                                placeholder={selectedProject?.label}
                            />
                            &nbsp;
                            <button type="button" onClick={() => { setShowLeadModal(true) }} className="btn btn-success btn-sm float-start">
                                <KTSVG
                                    path='/media/svg/icons/Code/Plus.svg'
                                    className='svg-icon-sm svg-icon-danger'
                                />
                                Add Lead
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="data-table col-md-12">
                            <BackendTable columns={columns} options={options} />
                        </div>
                    </div>
                    <LeadCreate show={showLeadModal} handleClose={handleClose} id={Date.now()}></LeadCreate>
                </div>
            </div>
        </>

    )
}

export default Leads
