/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper'
import { Table } from 'react-bootstrap-v5'
import showLoader from "../helpers/ShowLoader"
import Swal from 'sweetalert2';


interface StockIssueInfo {
    id_inv_issues: number;
    id_projects: number;
    id_inv_consumptions: number;
    id_acc_ledgers: number;
    issueDate: string;
    referenceNo: string;
    remarks: string;
    totalAmount: string;
    created_by: number;
    createdAt: string;
    project: {
        id_projects: number;
        projectName: string;
    };
    consumption: {id_inv_consumptions: number, consumptionTitle:string};
    rows: { id_inv_issue_rows: number, id_inv_issues:number, id_items: number, rate: number, quantity: number, item: any }[];
    ledger: { id_acc_ledgers: number, ledgerName: string, ledgerCode: string, ledgerType: string, ledgerPath:string };
    creator:any;
}

interface IssueProps {
	show: boolean;
	handleClose: any;
	idInvIssues: any;
	refLink?: any;
}



const IssueEdit: React.FC<IssueProps> = ({ show, handleClose, idInvIssues, refLink='shipment-list' }) => {


	const [stockIssueInfo, setStockIssueInfo] = useState<StockIssueInfo | null>(null);

	const numberFormat = (value: any) => {

		value = parseFloat(value)
		if (typeof (value) !== 'number') {
			return value;
		}
		return parseFloat(value.toString()).toLocaleString('en-US', {
			style: 'decimal',
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		});
	}

	useEffect(() => {

		console.log('ddddd', idInvIssues)
		getStockIssueInfo(idInvIssues)

	}, [idInvIssues]);

	const getStockIssueInfo = (idInvIssues: any) => {
        fetch(
            process.env.REACT_APP_API_URL + 'inventory-stock-issue/' + idInvIssues,
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                const data: StockIssueInfo = resp.data;
                setStockIssueInfo(data);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

	const handleRateChange = (index: number, newRate: number) => {
		if(isNaN(newRate)) {
			Swal.fire({
				icon: 'error',
				title: 'Invalid Rate',
				text: 'Please enter a valid rate',
			});
			return;
		}

        if (stockIssueInfo) {
            const updatedRows = stockIssueInfo.rows.map((item, i) =>
                i === index ? { ...item, rate: newRate } : item
            );
            
            const newTotalAmount = updatedRows.reduce((sum, item) => sum + item.quantity * item.rate, 0);
            
            setStockIssueInfo({
                ...stockIssueInfo,
                rows: updatedRows,
                totalAmount: newTotalAmount.toString(),
            });
        }
    };


	const stockIssueStatusChange = (rowValue: any, status: any) => {

        Swal.fire({
            title: 'Are you sure you want to approve this stock issue? ',
            text: "This action cannot be undone!",
			howCancelButton: true,
			confirmButtonText: 'Yes, approve it!',
            cancelButtonText: 'No, keep it',
            showLoaderOnConfirm: true,
			preConfirm: () => {


                let postData = { 
					idInvIssues: idInvIssues, 
					status: status,
					items : rowValue.rows.map((item: any) => {
						return {
							idInvIssueRows: item.id_inv_issue_rows,
							idItems: item.id_items,
							quantity: item.quantity,
							rate: item.rate
						}
					})
				};
                console.log(postData);
                stockIssueStatusUpdate(postData);

            }
        }).then((result) => {
            if (result.isConfirmed) {
                // Swal.fire(
				// 	'Approved!',
				// 	'Stock issue has been approved.',
				// 	'success'
				// )
				// setNewData({ id: Date.now() });
            }
        })
    }

    const stockIssueStatusUpdate = async (postData: any) => {
        showLoader();
        const requestOptions = postRequestOptions(postData);
        try {
            let response = await fetch(
                `${process.env.REACT_APP_API_URL}inventory-stock-issue/status-change`,
                requestOptions
            )
            let respData = await response.json()
            Swal.close();

            if (respData.success === true) {
                Swal.fire(
					'Approved!',
					'Stock issue has been approved.',
					'success'
				)
				handleClose(false);
				// setNewData({ id: Date.now() });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    html: respData.errorMessage,
                })
            }

        } catch (error) {
            Swal.close();
        }
    }

	return (
		<Modal show={show} onHide={() => handleClose(false)} size="xl">

			<Modal.Header closeButton>

				<Modal.Title>Issue Details (ID # {stockIssueInfo?.id_inv_issues})</Modal.Title>

			</Modal.Header>
			<Modal.Body>

				<div className="form-group row">
					<div className="col-lg-3" style={{ "textAlign": "right" }}>Project : </div>
					<div className="col-lg-5">{stockIssueInfo?.project?.projectName}</div>
				</div>
				<div className="form-group row">
					<div className="col-lg-3" style={{ "textAlign": "right" }}>Consumption Type : </div>
					<div className="col-lg-5"><span className="badge badge-primary">{stockIssueInfo?.consumption?.consumptionTitle}</span></div>
				</div>
				<div className="form-group row">
					<div className="col-lg-3" style={{ "textAlign": "right" }}>Issue Date : </div>
					<div className="col-lg-5">{stockIssueInfo?.issueDate}</div>
				</div>
				<div className="form-group row">
					<div className="col-lg-3" style={{ "textAlign": "right" }}>Issue No : </div>
					<div className="col-lg-5">{stockIssueInfo?.referenceNo}</div>
				</div>
				<div className="form-group row">
					<div className="col-lg-3" style={{ "textAlign": "right" }}>Ledger : </div>
					<div className="col-lg-5">
						<strong>{stockIssueInfo?.ledger?.ledgerCode}</strong> {stockIssueInfo?.ledger?.ledgerName}
						<br />
						<small><i className='fas fa-arrow-right'></i> {stockIssueInfo?.ledger?.ledgerPath}</small>
					</div>
				</div>
				<div className="form-group row">
					<div className="col-lg-3" style={{ "textAlign": "right" }}>Remarks : </div>
					<div className="col-lg-5">{stockIssueInfo?.remarks}</div>
				</div>
				
				<br></br>
				<Table size='sm' bordered hover>
					<thead>
						<tr>
							<th>SL No</th>
							<th>Item Name</th>
							<th>Item Code</th>
							<th>Item Category</th>
							<th className='text-center'>Quantity</th>
							<th className='text-center'>Rate</th>
							<th className='text-center'>Amount</th>
						</tr>
					</thead>


					<tbody>

						{stockIssueInfo?.rows?.map((stockItem, index) => (
							<tr key={'item' + index}>
								<td>{index + 1}</td>
								<td>{stockItem?.item.itemName}</td>
								<td>{stockItem?.item.itemCode}</td>
								<td>{stockItem?.item?.item_category?.categoryName}</td>
								<td className='text-center'>
									<span className="badge badge-light-danger"> {Number(stockItem?.quantity) + ' ' + stockItem?.item?.unit?.unitName}</span>
								</td>
								<td className='text-end'>
									<input
											type="number"
											className="form-control form-control-sm"
											value={stockItem?.rate}
											onChange={(e) => handleRateChange(index, parseFloat(e.target.value))}
										/>
								</td>
								<td className='text-end'>{numberFormat(stockItem?.quantity * stockItem?.rate)}</td>
							</tr>
						))}
						<tr>
							<td colSpan={6} className='text-end'><b>Total Amount : </b></td>
							<td className='text-end'><b>{numberFormat(Number(stockIssueInfo?.totalAmount))}</b></td>
						</tr>
					
					</tbody>



				</Table>
				{/* <pre>{JSON.stringify(stockIssueInfo, null, 2)}</pre> */}
			</Modal.Body>
			<Modal.Footer>

				<Button variant='secondary' size="sm" onClick={() => stockIssueStatusChange(stockIssueInfo, 'approved')}>
					Update & Approve
				</Button>
				<Button variant='secondary' size="sm" onClick={() => handleClose(false)}>
					Close
				</Button>
			</Modal.Footer>

		</Modal>
	)
}

export default IssueEdit
