/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper';
import clsx from 'clsx'
import { KTSVG } from '../../_metronic/helpers';
import { Col, Row, Table } from 'react-bootstrap-v5';
import validationErrorMsg from '../helpers/ValidationErrorMsg'
import Swal from 'sweetalert2'
import showLoader from '../helpers/ShowLoader'
import 'react-dropzone-uploader/dist/styles.css'
import axios, { AxiosResponse, AxiosError } from 'axios'
import Dropzone from 'react-dropzone-uploader'
import LeadActivities from '../components/LeadActivities';
import LeadAssignedTo from '../components/LeadAssignedTo';
import AddEvent from '../components/AddEvent';
import CurrentProductUse from '../components/CurrentProductUse';
import LeadEdit from '../components/LeadEdit';
import LeadContactPersonEdit from '../components/LeadContactPersonEdit';

interface Lead {
    idLeads: number;
    idCompanies: number;
    leadName: string;
    idCompanyContactPersons: number;
    idProjects: number;
    idProjectStages: number | null;
    estimatedValue: number;
    closingValue: number | null;
    createdAt: any;
    createdBy: number;
    sales_orders_count: number;
    leadStageStatus: string | null;
    assigned_to: {
        id: number;
        name: string;
        fileName: string;
        id_roles: number;
        role: any;
    };
    creator: {
        id: number;
        name: string;
        id_roles: number;
        role: any;
    };
    company: {
        idCompanies: number;
        companyName: string;
        companyAddress: string | null;
        companyWebsite: string | null;
        idCompanyCategories: number;
        assigned_to: {
            id: number;
            name: string;
        };
        phones: { phoneType: string, phoneNumber: string }[];
        emails: { email: string, emailType: string }[];
        company_category: {
            id: number;
            idCompanyCategories: number;
            categoryName: string;
        };
    };
    project: {
        idProjects: number;
        projectName: string;
        projectStatus: string;
    };
    contact_person: ContactPerson | null;
    activities: any;
    lead_products: any;
}

interface ContactPerson {
    // Define the properties of the contact person object here
    // Based on the structure of the contact_person property in the JSON object
    // Add any additional properties if needed
    idCompanyContactPersons: number;
    name: string;
    location: string;
    phones: { phoneType: string, phoneNumber: string }[];
    emails: { email: string, emailType: string }[];
}

interface Activity {
    idLeadActivities: number;
    idLeads: number;
    activityType: 'call' | 'meeting' | 'sms' | 'email' | 'deadline' | 'other' | 'created' | 'comment' | 'attachment';
    activityAt: any;
    activityDescription?: string;
    content: string;
    activityStatus: 'scheduled' | 'cancelled' | 'done';
    createdBy: number;
    activityDate: any;
    activityTime: any;
}
// Parse the JSON object and assign it to the converted TypeScript object
//   const data: Lead = JSON.parse(jsonString);
const activitiesArr: Activity[] = [
    // Add your activity objects here
];

export function LeadDetails(props: any) {

    const [tab, setTab] = useState('Sidebar')
    const [leadInfo, setLeadInfo] = useState<Lead | null>(null);
    const [activities, setActivities] = useState<Activity[]>(activitiesArr);
    const [leadProducts, setLeadProducts] = useState<[]>([]);
    // Assuming the variable `activities` is declared but intentionally unused
    // const _activities = activities;

    const query = new URLSearchParams(props.location.search);
    const idLeads: number = Number(query.get('idLeads'));

    const [message, setMessage] = useState('')
    const [assignedToModalShow, setAssignedToModalShow] = useState<boolean>(false);

    useEffect(() => {
        if (idLeads !== undefined) {
            getLeadInfo();
        }
    }, [idLeads])

    const getLeadInfo = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'lead/details/' + idLeads,
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                const data: Lead = resp.data;
                setLeadInfo(data);
                setActivities(data.activities);
                setLeadProducts(data.lead_products);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const sendMessage = async () => {
        showLoader()
        const formData = {
            idLeads: idLeads,
            message: message
        };
        const requestOptions = postRequestOptions(formData)
        try {
            let response = await fetch(
                `${process.env.REACT_APP_API_URL}lead/comment`,
                requestOptions
            )
            let respData = await response.json()
            Swal.close();
            if (respData.success === true) {
                setMessage('');
                setActivities(respData.data);
                // getLeadInfo();
            } else {
                const errorsMessage = validationErrorMsg(respData.errors).join('<br>')
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    html: errorsMessage,
                })
            }

        } catch (error) {
            console.log(error, 'catch the hoop')
            Swal.close();
        }
    }

    const jwt = () => {
        const myToken = localStorage.getItem('MyToken');
        return (myToken != null) ? JSON.parse(myToken) : null;
    }


    const handleFileSubmit = (files: any, allFiles: []) => {
        showLoader()

        const postFormData = new FormData();
        postFormData.append('idLeads', idLeads.toString());
        for (let i = 0; i < files.length; i++) {
            postFormData.append('files[]', files[i].file);
        }

        const url: string = `${process.env.REACT_APP_API_URL}lead/file-upload`;

        axios({
            method: 'POST',
            url: url,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer ' + jwt(), // Replace with your actual authorization token
            },
            data: postFormData,
        })
            .then((response: AxiosResponse<any>) => {
                Swal.close();
                allFiles.forEach((f: any) => f.remove());
                getLeadInfo();
            })
            .catch((error: AxiosError<any>) => {
                Swal.close();
                // const errorMessage: string = error.response?.data?.errorMessage;
                const errorsMessage: string = validationErrorMsg(error?.response?.data?.errors).join('<br>');
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    html: errorsMessage,
                });
            });

    }

    //Assign TO Modal
    const assignedToAction = (leadInfo: any) => {
        setAssignedToModalShow(true);
    }

    const markAsLost = async (leadInfo: any) => {

        const confirm = await Swal.fire({
            title: 'Are you sure?',
            text: "You want to mark this Lead as Lost?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#ec008c',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Yes, Sure!',
        })
        if (confirm.value) {

            const requestOptions = postRequestOptions({ idLeads: leadInfo.idLeads })
            try {
                let response = await fetch(
                    `${process.env.REACT_APP_API_URL}mark-lead-as-lost`,
                    requestOptions
                )
                let respData = await response.json()
                if (respData.success === true) {
                    const successMsg = respData.successMessage
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: successMsg,
                    })

                    getLeadInfo()
                } else {
                    const errorsMessage = validationErrorMsg(respData.errors).join('<br>')
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        html: errorsMessage,
                    })
                }

            } catch (error) {
                console.log(error, 'catch the hoop')

            }
        }
    }

    const handleAssignedToModalClose = (isReload: any) => {
        setAssignedToModalShow(false);
        if (isReload) {
            getLeadInfo();
        }
    }

    const [show, setShow] = useState(false);
    const [showContactPersonEdit, setShowContactPersonEdit] = useState(false);

    const leadEditAction = (leadInfo: any) => {
        setShow(true);
    }

    const handleClose = (isReload: any) => {
        setShow(false);
        if (isReload) {
            getLeadInfo();
        }
    }

    const contactPersonEdit = (contactPerson: any) => {
        setShowContactPersonEdit(true);
    }

    const handleContactPersonEditClose = (isReload: any) => {
        setShowContactPersonEdit(false);
        if (isReload) {
            getLeadInfo();
        }
    }

    return (<>
        <div className='card card-custom'>
            <div className='card-header card-header-stretch overflow-auto'>
                <div className='card-title'>
                    <h3 className='card-label'>Lead details</h3>
                    <small className='text-muted'>| Update lead events and view details</small>
                </div>
            </div>
            <div className="card-body">
                <Row>
                    <Col xs={12} md={9} className="pt-2">
                        <div className="d-flex flex-column">
                            <h3 className='ps-1'>{leadInfo?.leadName}</h3>
                            <span className="d-flex flex-row">
                                <KTSVG path='/media/svg/icons/Layout/Layout-top-panel-6.svg' className='svg-icon-3 svg-icon-success ms-1' />
                                <p>{leadInfo?.createdAt}</p>
                            </span>

                        </div>
                    </Col>
                    <Col xs={12} md={1} className="pt-2">
                        {leadInfo?.sales_orders_count > 0 &&
                            <div className="d-flex align-items-center">
                                <button className="btn btn-sm btn-success font-weight-bold"><i className='fa fa-star'></i> Won </button>
                            </div>
                        }
                        {(leadInfo?.sales_orders_count === 0) && (leadInfo?.leadStageStatus !== 'Lost') &&
                            <div className="d-flex align-items-center">
                                <button onClick={() => markAsLost(leadInfo)} className="btn btn-sm btn-danger font-weight-bold"><i className='fa fa-thumbs-down'></i> Mark as Lost </button>
                            </div>
                        }

                    </Col>
                    <Col className='d-flex justify-content-end' xs={12} md={2}>
                        <div className="d-flex align-items-center">
                            <div className="symbol symbol-60 symbol-xxl-100 me-3 align-self-start align-self-xxl-center">
                                <div className="symbol-label" style={{ backgroundImage: "url('/media/users/300_21.jpg')" }}>
                                    {leadInfo?.assigned_to !== null && leadInfo?.assigned_to !== undefined &&
                                        <div>
                                            {leadInfo?.assigned_to?.fileName !== null ?
                                                <img src={`${process.env.REACT_APP_AWS_URL}next-crm/profile_picture/${leadInfo?.assigned_to?.fileName}`} alt="pic" style={{ width: "60px", height: "60px" }} />
                                                :
                                                <img src={`media/avatars/default.jpg`} alt="pic" style={{ width: "60px", height: "60px" }} />

                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                            <div>
                                <a className="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary">
                                    {leadInfo?.assigned_to?.name}
                                </a>
                                <div className="text-muted">
                                    {leadInfo?.assigned_to?.role?.roleName}
                                </div>
                                <div>
                                    <a onClick={() => assignedToAction(leadInfo)} className="btn btn-sm btn-success font-weight-bold"><i className='fa fa-edit'></i> Re-assign</a>
                                    <LeadAssignedTo show={assignedToModalShow} handleClose={handleAssignedToModalClose} leadInfo={leadInfo} />
                                </div>
                            </div>
                        </div>
                    </Col>


                    {/* <Col className='d-flex justify-content-end pe-4 pt-2' xs={12} md={6}>
                        <div className="d-flex justify-content-start flex-column text-right">
                            <a className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6" onClick={() => assignedToAction(leadInfo)}><i className='fa fa-edit'></i> {leadInfo?.assigned_to?.name}</a>
                            <span className="text-gray-400 fw-semibold d-block fs-7" style={{ paddingLeft: '20px' }}>{leadInfo?.assigned_to?.role?.roleName}</span>
                        </div>
                        <LeadAssignedTo show={assignedToModalShow} handleClose={handleAssignedToModalClose} leadInfo={leadInfo} />
                    </Col> */}
                </Row>
            </div>
        </div>
        <Row>
            <Col md={4} className="pe-0 pt-0">
                <div className="card card-flush overflow-hidden m-2 me-0">
                    <div className="card-header py-5">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bold text-dark">Summary</span>
                        </h3>
                        <a className="text-gray-800 fw-bold text-hover-primary" title='Lead Info Edit' onClick={() => leadEditAction(leadInfo)}><i className='fa fa-edit'></i></a>
                        {leadInfo && <LeadEdit show={show} handleClose={handleClose} leadInfo={leadInfo} getLeadInfo={getLeadInfo}></LeadEdit>}
                    </div>
                    <div className="card-body pt-0">
                        <Table striped bordered hover size='sm'>
                            <tbody>
                                <tr>
                                    <td>Project</td>
                                    <td>{leadInfo?.project.projectName}</td>
                                </tr>
                                <tr>
                                    <td>Client</td>
                                    <td>{leadInfo?.company?.companyName}</td>
                                </tr>
                                <tr>
                                    <td>Category</td>
                                    <td>{leadInfo?.company?.company_category?.categoryName}</td>
                                </tr>
                                <tr>
                                    <td>Address</td>
                                    <td>{leadInfo?.company?.companyAddress}</td>
                                </tr>
                                <tr>
                                    <td>Phone</td>
                                    <td>
                                        <div>
                                            {leadInfo?.company?.phones.map((phone, index) => (
                                                <span key={index}>{phone?.phoneType} : {phone?.phoneNumber}<br /></span>
                                            ))}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Email</td>
                                    <td>
                                        <div>
                                            {leadInfo?.company?.emails.map((email, index) => (
                                                <span key={index}>{email?.emailType} : {email?.email}<br /></span>
                                            ))}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Website</td>
                                    <td>{leadInfo?.company?.companyWebsite}</td>
                                </tr>

                            </tbody>
                        </Table>
                    </div>
                </div>
                <div className="card card-flush overflow-hidden m-2 me-0">
                    <div className="card-header py-5">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bold text-dark">Contact person</span>
                        </h3>
                        <a className="text-gray-800 fw-bold text-hover-primary fs-6" title='Lead Contact Person Edit' onClick={() => contactPersonEdit(leadInfo)}><i className='fa fa-edit'></i></a>
                        {leadInfo && <LeadContactPersonEdit showContactPersonEdit={showContactPersonEdit} handleClose={handleContactPersonEditClose} leadInfo={leadInfo} getLeadInfo={getLeadInfo}></LeadContactPersonEdit>}
                    </div>
                    <div className="card-body pt-0">
                        <Table striped bordered hover size="sm">
                            <tbody>
                                <tr>
                                    <td>Name</td>
                                    <td>{leadInfo?.contact_person?.name}</td>
                                </tr>
                                <tr>
                                    <td>Phone</td>
                                    <td>
                                        <div>
                                            {leadInfo?.contact_person?.phones.map((phone, index) => (
                                                <span key={index}>{phone?.phoneType} : {phone?.phoneNumber}<br /></span>
                                            ))}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Email</td>
                                    <td>
                                        <div>
                                            {leadInfo?.contact_person?.emails.map((email, index) => (
                                                <span key={index}>{email?.emailType} : {email?.email}<br /></span>
                                            ))}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Address</td>
                                    <td>{leadInfo?.contact_person?.location}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
                <div className="card card-flush overflow-hidden m-2 me-0">
                    {leadInfo && <CurrentProductUse idLeads={leadInfo!.idLeads} getLeadInfo={getLeadInfo} leadProducts={leadProducts}></CurrentProductUse>}
                </div>
            </Col>
            <Col md={8} className="pt-2">
                <div className='card card-custom'>
                    <div className='card-header card-header-stretch overflow-auto'>
                        <ul
                            className='nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap'
                            role='tablist'
                        >
                            <li className='nav-item p-2'>
                                <a
                                    className={clsx(`nav-link cursor-pointer`, { active: tab === 'Sidebar' })}
                                    onClick={() => setTab('Sidebar')}
                                    role='tab'
                                >
                                    <KTSVG className='svg-icon-3 me-2' path="/media/svg/icons/Communication/Chat6.svg"></KTSVG>
                                    Add comment
                                </a>
                            </li>
                            <li className='nav-item p-2'>
                                <a
                                    className={clsx(`nav-link cursor-pointer`, { active: tab === 'Header' })}
                                    onClick={() => setTab('Header')}
                                    role='tab'
                                >
                                    <KTSVG className='svg-icon-3 me-2' path="/media/svg/icons/Communication/Sending.svg"></KTSVG>
                                    Add Event
                                </a>
                            </li>
                            <li className='nav-item p-2'>
                                <a
                                    className={clsx(`nav-link cursor-pointer`, { active: tab === 'Toolbar' })}
                                    onClick={() => setTab('Toolbar')}
                                    role='tab'
                                >
                                    <KTSVG className='svg-icon-3 me-2' path="/media/svg/icons/General/Clip.svg"></KTSVG>
                                    Attach files
                                </a>
                            </li>
                        </ul>
                    </div>


                    <div className='card-body'>
                        <div className='tab-content pt-3'>
                            <div className={clsx('tab-pane', { active: tab === 'Sidebar' })}>
                                <textarea
                                    className='form-control  mb-3'
                                    rows={3}
                                    data-kt-element='input'
                                    placeholder='Type a message'
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                // onKeyDown={onEnterPress}
                                ></textarea>
                                <div className='d-flex flex-row justify-content-end'>

                                    <button
                                        className='btn-sm btn-success'
                                        type='button'
                                        onClick={sendMessage}
                                    >
                                        Send
                                    </button>
                                </div>
                            </div>

                            <div className={clsx('tab-pane', { active: tab === 'Header' })}>
                                {leadInfo && <AddEvent idLeads={idLeads} getLeadInfo={getLeadInfo}></AddEvent>}
                            </div>

                            <div className={'p-0 ' + clsx('tab-pane', { active: tab === 'Toolbar' })}>
                                <Dropzone
                                    styles={{ dropzone: { border: "none" } }}
                                    accept='image/*,audio/*,application/pdf'
                                    onSubmit={handleFileSubmit}
                                // inputContent="Attachment Files"
                                />
                            </div>
                        </div>
                    </div>

                </div>
                <div className={`card card-custom my-2`}>
                    {/* begin::Header */}
                    <div className='card-header align-items-center border-0'>
                        <h3 className='card-title align-items-start flex-row'>
                            <span className='fw-bold mb-2 text-dark me-2'>Lead activities</span>
                            <small className='mt-1'>Total {activities.length} activities</small>
                        </h3>
                        <div className='card-toolbar'>
                            {/* begin::Menu */}
                            <button
                                type='button'
                                className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                                data-kt-menu-trigger='click'
                                data-kt-menu-placement='bottom-end'
                                data-kt-menu-flip='top-end'
                            >
                                <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
                            </button>
                            {/* <Dropdown1 /> */}
                            {/* end::Menu */}
                        </div>
                    </div>
                    {/* end::Header */}
                    {/* begin::Body */}
                    <div className='card-body pt-5'>
                        {/* <div className='timeline-label'>
                            {activities.map((item, index) => (

                                <div className='timeline-item' key={index}>
                                    <div className='timeline-label fw-bold text-gray-800 fs-6'>{item.activityTime}</div>

                                    <div className='timeline-badge'>
                                        <i className='fa fa-genderless text-warning fs-1'></i>
                                    </div>

                                    <div className='fw-mormal timeline-content text-muted ps-3'>
                                        {item?.content}
                                    </div>
                                </div>
                            ))}
                        </div> */}
                        <LeadActivities activities={activities} />
                    </div>
                    {/* end: Card Body */}
                </div>
            </Col>
        </Row>
    </>
    )
}

export default LeadDetails
