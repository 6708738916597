/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { getRequestOptions } from '../helpers/Fetchwrapper'
import { Table } from 'react-bootstrap-v5'

interface ItemCreateProps {
	show: boolean;
	handleClose: any;
	shipmentId: any;
	refLink?: any;
}

interface ShipmentData {
	id_shipments: number;
	id_sales_orders: number;
	sales_order: any;
	shipping_address: string;
	shipment_date: string;
	chalan_no: string;
	vehicle_no: string;
	driver_name: string;
	driver_phone_no: string;
	remarks: string;
	rows: any;
	shipment_amount: number;
	production_amount: number;
}


const initialShipmentData: ShipmentData = {
	id_shipments: 0,
	id_sales_orders: 0,
	sales_order: {},
	shipping_address: '',
	shipment_date: '',
	chalan_no: '',
	vehicle_no: '',
	driver_name: '',
	driver_phone_no: '',
	remarks: '',
	shipment_amount: 0,
	production_amount: 0,
	rows: []
}

const ShipmentDetails: React.FC<ItemCreateProps> = ({ show, handleClose, shipmentId, refLink='shipment-list' }) => {


	const [shipmentData, setShipmentData] = useState<ShipmentData>(initialShipmentData);

	const numberFormat = (value: any) => {

		value = parseFloat(value)
		if (typeof (value) !== 'number') {
			return value;
		}
		return parseFloat(value.toString()).toLocaleString('en-US', {
			style: 'decimal',
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		});
	}

	useEffect(() => {

		console.log('ddddd', shipmentId)
		getShipmentData(shipmentId)

	}, [shipmentId]);

	const getShipmentData = (shipmentId: any) => {
		fetch(
			process.env.REACT_APP_API_URL + 'shipment/' + shipmentId,
			getRequestOptions()
		)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				setShipmentData(resp.data);
			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})
	};

	return (
		<Modal show={show} onHide={() => handleClose(false)} size="xl">

			<Modal.Header closeButton>

				<Modal.Title>Shipment Details (ID # {shipmentData?.id_shipments})</Modal.Title>

			</Modal.Header>
			<Modal.Body>

				<div className="form-group row">
					<div className="col-lg-3" style={{ "textAlign": "right" }}>Client : </div>
					<div className="col-lg-5">{shipmentData?.sales_order?.company?.company_name}</div>
				</div>
				<div className="form-group row">
					<div className="col-lg-3" style={{ "textAlign": "right" }}>Lead : </div>
					<div className="col-lg-5">{shipmentData?.sales_order?.lead?.lead_name}</div>
				</div>
				<div className="form-group row">
					<div className="col-lg-3" style={{ "textAlign": "right" }}>Sales Order : </div>
					<div className="col-lg-5">{shipmentData?.id_sales_orders}</div>
				</div>
				<div className="form-group row">
					<div className="col-lg-3" style={{ "textAlign": "right" }}>Shipment Date : </div>
					<div className="col-lg-5">{shipmentData?.shipment_date}</div>
				</div>
				<div className="form-group row">
					<div className="col-lg-3" style={{ "textAlign": "right" }}>Shipping Address : </div>
					<div className="col-lg-5">{shipmentData?.shipping_address}</div>
				</div>
				<div className="form-group row">
					<div className="col-lg-3" style={{ "textAlign": "right" }}>Chalan No : </div>
					<div className="col-lg-5">{shipmentData?.chalan_no}</div>
				</div>
				<div className="form-group row">
					<div className="col-lg-3" style={{ "textAlign": "right" }}>Vehicle No : </div>
					<div className="col-lg-5">{shipmentData?.vehicle_no}</div>
				</div>
				<div className="form-group row">
					<div className="col-lg-3" style={{ "textAlign": "right" }}>Driver Name : </div>
					<div className="col-lg-5">{shipmentData?.driver_name}</div>
				</div>
				<div className="form-group row">
					<div className="col-lg-3" style={{ "textAlign": "right" }}>Driver Phone No : </div>
					<div className="col-lg-5">{shipmentData?.driver_phone_no}</div>
				</div>

				<div className="form-group row">
					<div className="col-lg-3" style={{ "textAlign": "right" }}>Remarks : </div>
					<div className="col-lg-5">{shipmentData?.remarks}</div>
				</div>

				<br></br>
				<Table size='sm' bordered hover>
					<thead>
						<tr>
							<th style={{ width: '10%' }} className='text-center'>SL</th>
							<th style={{ width: '30%' }}>Product Name</th>
							<th style={{ width: '15%' }}>Packing</th>
							<th style={{ width: '15%' }} className='text-center'>Delivered Quantity</th>
							<th style={{ width: '15%' }} className='text-center'>Unit Rate</th>
							<th style={{ width: '15%' }} className='text-center'>Amount</th>
						</tr>
					</thead>


					<tbody>

						{shipmentData?.rows.map((row, index) => (
							<tr key={index}>
								<td className='text-center'>{index + 1}</td>
								<td>{row.sales_order_row.product.product_name}</td>
								<td>{row.sales_order_row.product.packing}</td>
								<td className='text-center'>{row.quantity}</td>
								<td className='text-center'>
									{refLink==='voucher-details' ? numberFormat(row.production_rate) : numberFormat(row.sales_order_row.unit_rate)}
								</td>
								<td className='text-center'>
									{refLink==='voucher-details' ? numberFormat(row.quantity * row.production_rate) : numberFormat(row.quantity * row.sales_order_row.unit_rate)}
								</td>
							</tr>
						))}

						<tr>
							<td className='text-end' colSpan={5}><b>Net Total : </b></td>
							<td className='text-center'><b>{refLink==='voucher-details' ? numberFormat(shipmentData?.production_amount) : numberFormat(shipmentData?.shipment_amount)}</b></td>
						</tr>



					</tbody>



				</Table>
				{/* <pre>{JSON.stringify(shipmentData, null, 2)}</pre> */}
			</Modal.Body>
			<Modal.Footer>


				<Button variant='secondary' size="sm" onClick={() => handleClose(false)}>
					Close
				</Button>
			</Modal.Footer>

		</Modal>
	)
}

export default ShipmentDetails
