import React, {useState} from 'react'
import { Button, Form, Row, Col } from "react-bootstrap-v5";
import { postRequestOptions } from '../helpers/Fetchwrapper';
import validationErrorMsg from '../helpers/ValidationErrorMsg'
import { KTSVG } from '../../_metronic/helpers';
import Swal from 'sweetalert2'

const ChangePasswordForm: React.FC = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [currentPassword, setCurrentPassword] = useState('')
	const [newPassword, setNewPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault()

		// Perform validation
		if (newPassword !== confirmPassword) {
			Swal.fire({
				icon: 'error',
				title: 'Password Mismatch',
				html: "Passwords do not match.",
			})
			return
		}

		// Make API call to update the password
		// Replace the following code with your actual API call
		setIsLoading(true);
		// return false;
		const formData = {
			currentPassword: currentPassword,
			newPassword: newPassword,
			confirmPassword: confirmPassword,
		}

		const requestOptions = postRequestOptions(formData)
		try {
			let response = await fetch(
				`${process.env.REACT_APP_API_URL}user/change-password`,
				requestOptions
			)
			let respData = await response.json()
			setIsLoading(false);
			if (respData.success === true) {
				Swal.fire({
					icon: 'success',
					title: 'Success',
					html: respData.successMessage,
				})
				// Reset the form
				setCurrentPassword('')
				setNewPassword('')
				setConfirmPassword('')
			}else if(respData.success === false && respData.errorMessage){
				Swal.fire({
					icon: 'error',
					title: 'Password Mismatch',
					html: respData.errorMessage,
				})
			} else {
				const errorsMessage = validationErrorMsg(respData.errors).join('<br>')
				Swal.fire({
					icon: 'error',
					title: 'Error',
					html: errorsMessage,
				})
			}

		} catch (error) {
			setIsLoading(false);
		}
		
	}

  return (
    <Form onSubmit={handleSubmit}>
		<Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
			<Form.Label column sm="2">
				Current Password<span className="required text-danger"></span>
			</Form.Label>
			<Col sm="4">
				<Form.Control type="password" onChange={(e) => setCurrentPassword(e.target.value)} name="currentPassword" placeholder="Enter Current Password" value={currentPassword} />
			</Col>
		</Form.Group>
		<Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
			<Form.Label column sm="2">
				New Password<span className="required text-danger"></span>
			</Form.Label>
			<Col sm="4">
				<Form.Control type="password" onChange={(e) => setNewPassword(e.target.value)} name="newPassword" placeholder="Enter New Password" value={newPassword} />
			</Col>
		</Form.Group>
		<Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
			<Form.Label column sm="2">
				Confirm Password<span className="required text-danger"></span>
			</Form.Label>
			<Col sm="4">
				<Form.Control type="password" onChange={(e) => setConfirmPassword(e.target.value)} name="newPassword" placeholder="Enter Confirm Password" value={confirmPassword} />
			</Col>
		</Form.Group>
		<Button variant="success" type="submit" disabled={isLoading}>
			{isLoading ? <i className='fa fa-spin fa-spinner'></i> : <><KTSVG className="svg-icon-3" path="/media/svg/icons/General/Save.svg" /> Change Password</>}
		</Button>
    </Form>
  )
}

export default ChangePasswordForm
