import { useEffect, useState } from 'react'
import DataTable from '../components/DataTable'
import UserLogInfo from '../components/UserLogInfo'
import { KTSVG } from '../../_metronic/helpers'
import { Modal } from 'react-bootstrap';
import 'react-loading-skeleton/dist/skeleton.css'

function UserLog() {
    const [logModalShow, setLogModalShow] = useState(false);
    const [ip, setIp] = useState('');
    const [userAgent, setUserAgent] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [additionalPostData, setAdditionalPostData] = useState({});

    useEffect(() => {
        setAdditionalPostData({ fromDate: fromDate, toDate: toDate });
    }, [fromDate, toDate])

    const columns = [
        { value: 'id', title: 'Id', style: { textAlign: 'center' } },
        { value: 'activityUser', title: 'Activity User', style: { textAlign: 'left' } },
        { value: 'activity', title: 'Activity', style: { textAlign: 'left' } },
        { value: 'referenceId', title: 'Reference Id', style: { textAlign: 'left' } },
        { value: 'referenceType', title: 'Reference Type', style: { textAlign: 'left' } },
        { value: 'time', title: 'Time', style: { textAlign: 'left' } },
        { value: 'ip', title: 'IP', style: { textAlign: 'left' } },
        {
            value: 'action', title: 'Action', style: { textAlign: 'left', whiteSpace: 'nowrap' }, hasActionsComponent: true, actionsComponent: ((row) => {
                return (
                    <>

                        <button className="btn btn-light-primary btn-sm" onClick={() => onShowLogModal(row)}>
                            <i className='fa fa-eye'></i>
                        </button>
                    </>
                )
            })
        }
    ]

    const filters = {
        id: {
            filterType: 'like',
            filterValue: '',
        },
        activityUser: {
            filterType: 'like',
            filterValue: '',
        },
        activity: {
            filterType: 'like',
            filterValue: '',
        },
        referenceId: {
            filterType: 'like',
            filterValue: '',
        },
        referenceType: {
            filterType: 'like',
            filterValue: '',
        },
        time: {
            filterType: 'like',
            filterValue: '',
        },
        ip: {
            filterType: 'like',
            filterValue: '',
        }

    }

    const url = process.env.REACT_APP_API_URL + 'user_log'

    const onShowLogModal = (row) => {
        setLogModalShow(true);
        setIp(row.ip);
        setUserAgent(row.userAgent);
    }

    const logModalClose = () => {
        setLogModalShow(false);
    };

    const fromDateChange = (e) => {
        setFromDate(e.target.value);
    }

    const toDateChange = (e) => {
        setToDate(e.target.value);
    }

    return (
        <>
            <div className='card card-custom'>
                <div className='card-header'>
                    <div className="card-title">
                        <KTSVG path='/media/svg/icons/Communication/Clipboard-list.svg' className='svg-icon-1 svg-icon-primary' />
                        <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
                            User Log
                        </span>
                    </div>
                </div>

                <div className='card-body'>
                    <div className='form-group row mb-5'>
                        <label
                            htmlFor='fromDate'
                            className='col-lg-2 col-form-label'
                            style={{ textAlign: 'right' }}
                        >
                            From Date
                        </label>
                        <div className='col-lg-6'>
                            <div className='row'>
                                <div className='col-lg-5'>
                                    <input
                                        type='date'
                                        name='fromDate'
                                        id='fromDate'
                                        value={fromDate}
                                        onChange={fromDateChange}
                                        className={`form-control form-control-sm`}
                                    />
                                </div>
                                <div className='col-lg-7'>
                                    <div className='form-group row'>
                                        <label
                                            htmlFor='toDate'
                                            className='col-lg-3 col-form-label'
                                            style={{ textAlign: 'right' }}
                                        >
                                            To Date
                                        </label>
                                        <div className='col-lg-9'>
                                            <input
                                                type='date'
                                                name='toDate'
                                                id='toDate'
                                                value={toDate}
                                                onChange={toDateChange}
                                                className={`form-control form-control-sm`}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DataTable url={url} columns={columns} filters={filters} additionalPostData={additionalPostData} />

                </div>
            </div>
            <Modal size="lg" show={logModalShow} onHide={logModalClose} aria-labelledby="example-modal-sizes-title-lg">
                <Modal.Body style={{ paddingTop: "0.75rem" }}>
                    <UserLogInfo ip={ip} userAgent={userAgent} />
                </Modal.Body>

                <Modal.Footer>
                    <button className='btn btn-light btn-sm' onClick={logModalClose}>Cancel</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default UserLog