/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom'
import { postRequestOptions } from '../helpers/Fetchwrapper';
import Swal from 'sweetalert2';
import Spinner from 'react-bootstrap/Spinner';

function Login() {
    let history = useHistory();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const jwt = JSON.parse(localStorage.getItem("MyToken")) ? JSON.parse(localStorage.getItem("MyToken")) : null;

    useEffect(() => {
        if (jwt === null)
            return;
        history.push('/');

    }, []);

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    }

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    }

    const handleSubmit = (evt) => {
        setLoading(true);
        evt.preventDefault();
        const formData = {
            email: email,
            password: password
        };
        const requestOptions = postRequestOptions(formData)

        fetch(process.env.REACT_APP_API_URL + "auth/login", requestOptions)
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                setLoading(false);
                if (resp.success === true) {

                    localStorage.setItem("MyToken", JSON.stringify(resp.token));
                    window.location.reload();
                    //this.props.history.push('/home');
                }
                else {
                    const errorsMessage = resp.errorMessage;
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        html: errorsMessage,
                    })
                }


            })
            .catch((error) => {
                setLoading(false);
                console.log(error, "catch the hoop")
            });
    }

    return (
        <>
            {(jwt === null && loading === false) &&

                <div className="login login-4 login-signin-on d-flex flex-row-fluid" id="kt_login">
                    <div className="d-flex flex-center flex-row-fluid bgi-size-cover bgi-position-top bgi-no-repeat" style={{ 'backgroundColor': '#fff' }}>
                        <div className="login-form text-center p-7 position-relative overflow-hidden">

                            <div className="d-flex flex-center mb-15">
                                <a href="/">
                                    <img src="/media/logos/logo-new.png" className="max-h-75px" alt="" />
                                </a>
                            </div>

                            <div className="login-signin">
                                <div className="mb-20">
                                    <h3>Sign In To Next CRM</h3>
                                    <div className="text-muted font-weight-bold">Enter your details to login to your account:</div>
                                </div>
                                <form onSubmit={handleSubmit} className="form" id="kt_login_signin_form">
                                    <div className="form-group mb-5">
                                        <input className="form-control h-auto form-control-solid py-4 px-8" type="text" placeholder="Email" name="email" value={email} onChange={handleEmailChange} autoComplete="off" />
                                    </div>
                                    <div className="form-group mb-5">
                                        <input className="form-control h-auto form-control-solid py-4 px-8" type="password" placeholder="Password" name="password" value={password} onChange={handlePasswordChange} />
                                    </div>

                                    <button id="kt_login_signin_submit" className="btn border-active btn-primary font-weight-bold px-9 py-4 my-3 mx-1">Sign In</button>
                                </form>

                            </div>


                        </div>
                    </div>
                </div>
            }
            {loading === true &&
                <div className="d-flex flex-center" style={{ marginTop: "250px" }}>
                    {/* <ReactLoading type={"bars"} color={"#ec008c"} height={'3%'} width={'5%'} /> */}
                    <Spinner animation="grow" variant="primary" size="lg" />
                    <span style={{ marginLeft: '10px' }}></span>
                    <Spinner animation="grow" variant="primary" size="lg" />
                    <span style={{ marginLeft: '10px' }}></span>
                    <Spinner animation="grow" variant="primary" size="lg" />
                </div>
            }
        </>
    )
}

export default Login