/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { Modal, Button } from 'react-bootstrap';
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper';
import validationErrorMsg from '../helpers/ValidationErrorMsg';
import showLoader from '../helpers/ShowLoader';
import Select from 'react-select';

interface User {
  id: string;
  name: string;
  label: string;
  value: string;
}

interface AssignedToFormData {
	idLeads: string;
  	assignedTo: string;
}

interface LeadAssignedToProps {
  show: boolean;
  handleClose: any;
  leadInfo: any;
}


function LeadAssignedTo({ show, handleClose, leadInfo }: LeadAssignedToProps) {
	const [users, setUsers] = useState<User[]>([]);
	const [assignedTo, setAssignedTo] = useState<any>({ label: 'Select Assign To', value: '' });
	const [assignedToFormData, setAssignedToFormData] = useState<AssignedToFormData>({
		idLeads: leadInfo?.idLeads,
		assignedTo: '',
	});

	useEffect(() => {
		getAllUsers();
	}, []);

	useEffect(() => {
		setAssignedTo({ label: leadInfo?.assigned_to?.name ? leadInfo?.assigned_to?.name : 'Select Assign To', value: leadInfo?.assigned_to?.id, id: leadInfo?.assigned_to?.id });
		setAssignedToFormData({ ...assignedToFormData, assignedTo: leadInfo?.assigned_to?.id });
	}, [leadInfo]);

	const handleAssignedToChange = (user: any) => {
		setAssignedTo(user);
		setAssignedToFormData({ ...assignedToFormData, assignedTo: user?.id ?? '' });
	};

	const getAllUsers = () => {
		fetch(`${process.env.REACT_APP_API_URL}company/assign-to`, getRequestOptions())
		.then((resp) => resp.json())
		.then((resp) => {
			let assignedToUsers: User[] = resp.data.map((item: User) => {
			item.label = item.name;
			item.value = item.id;
			return item;
			});
			setUsers(assignedToUsers);
		})
		.catch((error) => {
			console.log(error, 'catch the hoop');
		});
	};

	const assignedToSave = async (event: any) => {
		event.preventDefault();
		showLoader();
		var formData: AssignedToFormData = {
			idLeads: leadInfo.idLeads,
			assignedTo: assignedToFormData.assignedTo,
		};
		const requestOptions = postRequestOptions(formData);
		try {
			let response = await fetch(`${process.env.REACT_APP_API_URL}lead/assign-to-save`, requestOptions);
			let respData = await response.json();
			Swal.close();
			if (respData.success === true) {
				// const successMsg = respData.successMessage;
				// Swal.fire({
				// 	icon: 'success',
				// 	title: 'Success',
				// 	text: successMsg,
				// });
				handleClose(respData.success);
			} else {
				const errorsMessage = validationErrorMsg(respData.errors).join('<br>');
				Swal.fire({
					icon: 'error',
					title: 'Error',
					html: errorsMessage,
				});
			}
		} catch (error) {
			Swal.close();
			console.log(error);
		}
	};


	return (
		<Modal show={show} onHide={() => handleClose(false)} size="lg">
			<form onSubmit={assignedToSave}>
				<Modal.Header closeButton className="p-3">
					<Modal.Title>Assign for {leadInfo?.leadName}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="form-group row">
                        <label htmlFor="idCompanies" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Select Assign To <span className="required text-danger"></span></label>
                        <div className="col-lg-7">
                          <Select
                            value={assignedTo}
                            onChange={handleAssignedToChange}
                            options={users}
                            menuPosition="fixed"
                          />
                        </div>
                    </div>
				</Modal.Body>
				<Modal.Footer>
				
					<button type="submit" className="btn btn-sm btn-success me-2"><span><i className='fa fa-save'></i>  Submit</span></button>
					<Button variant='secondary' size="sm" onClick={() => handleClose(false)}>
						<i className='fa fa-window-close'></i>Close
					</Button>
				</Modal.Footer>
			</form>
		</Modal>
	)
}

export default LeadAssignedTo