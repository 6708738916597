/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button } from 'react-bootstrap'
import Spinner from 'react-bootstrap/Spinner'
import { Col, Form, Row } from 'react-bootstrap-v5'
import Select from 'react-select'
import { getRequestOptions, postRequestOptions } from "../helpers/Fetchwrapper";


interface Bank {
    idBanks: number;
    bankNameShort: string;
    bankNameFull: string;
}

interface SubmitData {
    category: 'bank_account' | 'cash_box' | 'mobile_wallet';
    idProjects?: number;
    idBanks?: number;
    accountNumber?: string;
    routingNumber?: string;
    cashBoxName?: string;
    cashBoxLocation?: string;
    serviceName?: string;
    walletName?: string;
    walletNumber?: string;
}

function CashBankCreate({ show, handleClose, id, entryType }: { show: boolean, handleClose: any, id: number, entryType: 'bank_account' | 'cash_box' | 'mobile_wallet' }) {

    const [loading, setLoading] = useState(false);
    const [bankList, setBankList] = useState<Bank[]>([]);
    const [projects, setProjects] = useState([]);
	const [project, setProject] = useState({ label: 'Select Project', value: '' });

    const intialFormData: SubmitData = {
        category: 'bank_account',
    };
    const [formData, setFormData] = useState(intialFormData);

    useEffect(() => {
        getProjects();
    }, []);

    const getBankList = async () => {
        const response = await fetch(process.env.REACT_APP_API_URL + 'banks', getRequestOptions())
        const data = await response.json();
        setBankList(data.data);
    }

    const getProjects = () => {
		fetch(
			process.env.REACT_APP_API_URL + 'lead/projects',
			getRequestOptions()
		)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				let projects = resp.data.map((item: any) => {
					item.label = item.name;
					item.value = item.id;
					return item
				})
				setProjects(projects);
			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})
	}

    const handleProjectChange = (project: any) => {
		setProject(project);
		setFormData({ ...formData, idProjects: project.id });
	}


    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true);
        const response = await fetch(process.env.REACT_APP_API_URL + 'bank-cash', postRequestOptions(formData));
        const data = await response.json();

        if (data.success) {
            handleClose(false);
            toast(<strong>{formData.category.split('_').join(' ')} created successfully</strong>, { type: 'success', position: 'bottom-right', autoClose: 3000 });
        } else {
            for (const i in data.errors) {
                toast(<strong>{data.errors[i].join(' ')}</strong>, { type: 'error', position: 'bottom-right', autoClose: 3000 });
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        getBankList();
    }, []);

    useEffect(() => {
        setFormData({ ...intialFormData, category: entryType });
    }, [entryType]);

    return (<Modal show={show} onHide={() => handleClose(false)} size="lg">
        <Modal.Header className='p-2 px-5' closeButton>
            <Modal.Title>Create {formData.category.split('_').join(' ')}</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
            <Modal.Body>
                <div className="form-group row">
                    <label htmlFor="project" className="col-lg-3 col-form-label">Select Project <span className="required text-danger"></span></label>
                    <div className="col-lg-9">
                        <Select
                            value={project}
                            onChange={handleProjectChange}
                            options={projects}
                            menuPosition="fixed"
                        />
                    </div>
                </div>
                {
                    entryType === 'bank_account' && <>
                        <Form.Group as={Row} controlId="bankName">
                            <Form.Label column sm={3}>Bank Name</Form.Label>
                            <Col sm={9}>
                                <Form.Select size='sm' aria-label="Bank Name" onChange={(e) => setFormData({ ...formData, idBanks: parseInt(e.currentTarget.value) })}>
                                    <option value="">Select Bank <span className="required text-danger"></span></option>
                                    {bankList.map((bank) => <option key={bank.idBanks} value={bank.idBanks}>{bank.bankNameFull}</option>)}
                                </Form.Select>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="accountNumber">
                            <Form.Label column sm={3}>Account Number <span className="required text-danger"></span></Form.Label>
                            <Col sm={9}>
                                <Form.Control size='sm' onChange={(e) => setFormData({ ...formData, accountNumber: e.currentTarget.value })} type="text" placeholder="Account Number" />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="routingNumber">
                            <Form.Label column sm={3}>Routing Number</Form.Label>
                            <Col sm={9}>
                                <Form.Control size='sm' onChange={(e) => setFormData({ ...formData, routingNumber: e.currentTarget.value })} type="text" placeholder="Routing Number" />
                            </Col>
                        </Form.Group>
                    </>
                }
                {

                    entryType === 'cash_box' && <>
                        <Form.Group as={Row} controlId="cashBoxName">
                            <Form.Label column sm={3}>Cash Box Name <span className="required text-danger"></span></Form.Label>
                            <Col sm={9}>
                                <Form.Control size='sm' onChange={(e) => setFormData({ ...formData, cashBoxName: e.currentTarget.value })} type="text" placeholder="Cash Box Name" />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="cashBoxLocation">
                            <Form.Label column sm={3}>Cash Box Location</Form.Label>
                            <Col sm={9}>
                                <Form.Control size='sm' onChange={(e) => setFormData({ ...formData, cashBoxLocation: e.currentTarget.value })} type="text" placeholder="Cash Box Location" />
                            </Col>
                        </Form.Group>

                    </>
                }
                {
                    entryType === 'mobile_wallet' && <>
                        <Form.Group as={Row} controlId="serviceName">
                            <Form.Label column sm={3}>Service Name <span className="required text-danger"></span></Form.Label>
                            <Col sm={9}>
                                <Form.Select size='sm' aria-label="Service Name" onChange={(e) => setFormData({ ...formData, serviceName:e.currentTarget.value})}>
                                    <option value="">Select Service</option>
                                    <option value="bkash">Bkash</option>
                                    <option value="rocket">Rocket</option>
                                    <option value="nagad">Nagad</option>
                                    <option value="upay">Upay</option>
                                </Form.Select>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="walletName">
                            <Form.Label column sm={3}>Wallet Name <span className="required text-danger"></span></Form.Label>
                            <Col sm={9}>
                                <Form.Control size='sm' onChange={(e) => setFormData({ ...formData, walletName: e.currentTarget.value })} type="text" placeholder="Wallet Name" />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="walletNumber">
                            <Form.Label column sm={3}>Wallet Number <span className="required text-danger"></span></Form.Label>
                            <Col sm={9}>
                                <Form.Control size='sm' onChange={(e) => setFormData({ ...formData, walletNumber: e.currentTarget.value })} type="text" placeholder="Wallet Number" />
                            </Col>
                        </Form.Group>
                    </>
                }

            </Modal.Body>
            <Modal.Footer>

                <button type="submit" disabled={loading} className="btn btn-sm btn-primary me-2">
                    {loading &&
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    }
                    <span>  Submit</span>
                </button>
                <Button variant='secondary' size="sm" onClick={() => handleClose(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Form>
    </Modal>)
}

export default CashBankCreate;