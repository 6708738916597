/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext } from 'react'
import Swal from 'sweetalert2'
import { Modal, Button } from 'react-bootstrap'
import { Col, Row } from 'react-bootstrap-v5';
import validationErrorMsg from '../helpers/ValidationErrorMsg'
import showLoader from "../helpers/ShowLoader"
import Badge from 'react-bootstrap/Badge';
import { AppContext } from '../context/AppContext'
import axios from 'axios'


interface LeadCreateProps {
	show: boolean;
	handleClose: any;
	id: number;
	product: any
}

const ProductImageUploader: React.FC<LeadCreateProps> = ({ show, handleClose, id, product }) => {

	const { token } = useContext(AppContext);
	const [file, setFile] = useState();
	const [image, setImage] = useState(null);
	const fileSelectRef = useRef();

	const handleChange = (e: any) => {
		if (e.target.files.length < 1) {
			return;
		}
		if (isValidFileUploaded(e.target.files[0])) {
			setImage(URL.createObjectURL(e.target.files[0]));
			setFile(e.target.files[0]);
		} else {
			alert('File type not supported');
			setFile();
		}
	}

	const isValidFileUploaded = (file: any) => {
		const validExtensions = ['png', 'jpeg', 'jpg']
		const fileExtension = file.type.split('/')[1]
		return validExtensions.includes(fileExtension)
	}

	useEffect(() => {

	}, [])

	const handleSubmit = (event: any) => {
		event.preventDefault();
		Swal.fire({
			title: 'Are you sure?',
			text: "You want to upload this image!",
			icon: 'warning',
			showCancelButton: true,
			cancelButtonText: 'No',
			confirmButtonColor: '#ec008c',
			confirmButtonText: 'Yes'
		}).then((result) => {
			if (result.value) {
				showLoader();
				const mewFormData = new FormData();
				mewFormData.append("myFile", file);
				mewFormData.append("idProducts", product.idProducts);

				const jwt = token;

				var authOptions = {
					method: 'post',
					headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer ' + jwt },
					url: process.env.REACT_APP_API_URL + "productImageUpload",
					data: mewFormData
				};

				axios(authOptions)
					.then(response => {
						const successMessage = response.data.successMessage;
						Swal.fire({
							icon: 'success',
							title: 'Success',
							text: successMessage
						})
						fileSelectRef.current.value = "";
						handleClose(true)

					})
					.catch((error) => {
						const errorsMessage = validationErrorMsg(error.response.data.errors).join("<br>");
						Swal.fire({
							icon: 'error',
							title: 'Error',
							html: errorsMessage,
						})
					});

			}
			else {
				fileSelectRef.current.value = "";
			}
		})

	}


	return (
		<Modal show={show} onHide={() => handleClose()} size="lg">

			<Modal.Header className="py-2" closeButton>
				<Modal.Title>Upload Image - {product.productName + ' - Size : ' + product.size + ' - Packing : ' + product.packing}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row>
					<Col className='border-end' md={6}>
						{/* <pre>{JSON.stringify(product, null, 2)}</pre> */}
						<h2>Upload Product Image:</h2>
						{image !== null &&
							<div>
								<img src={image} alt="pic" style={{ width: "200px", height: "150px" }} />
							</div>
						}


						<div>
							{/* {file !== 'default.jpg' ?
									<img src={`${process.env.REACT_APP_AWS_URL}next-crm/profile_picture/${currentUser.fileName}`} alt="pic" style={{ width: "200px", height: "150px" }} />
									:
									<img src={`media/avatars/${currentUser.fileName}`} alt="pic" style={{ width: "200px", height: "150px" }} />

								} */}
						</div>

						<div className='form-group row mt-2'>
							<div className='col-6'>
								<input name="file" type="file" id="file" className="form-control form-control-sm" onChange={handleChange} ref={fileSelectRef} />
							</div>
						</div>
						<div className='mt-2'><Badge bg="danger">
							Note!
						</Badge> Image Size must be less than 5MB. Only PNG, JPG and JPEG files are allowed.</div>


					</Col>

				</Row>
			</Modal.Body>
			<Modal.Footer className='py-1'>
				<button className="btn btn-sm btn-primary me-2 mt-2" onClick={handleSubmit}>
					Save
				</button>
				<Button variant='secondary' size="sm" onClick={() => handleClose()}>
					Cancel
				</Button>
			</Modal.Footer>

		</Modal>
	)
}

export default ProductImageUploader
