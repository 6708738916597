/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext } from 'react';
import Skeleton from 'react-loading-skeleton';
// import 'react-loading-skeleton/dist/skeleton.css';
import { userPermissionContext } from '../../../../app/context/UserPagePermission';
import { AsideMenuItem } from './AsideMenuItem';
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub';

export function AsideMenuMain() {

	const { pageList, loading } = useContext(userPermissionContext);

	return (
		<>
			{!loading &&
				<AsideMenuItem
					to='/dashboard'
					icon='/media/icons/duotune/abstract/abs014.svg'
					title={'Dashboard'}
				/>
			}
			{pageList.length > 0 && pageList.map((item: any, index: number) => (
				item.totalSubMenu > 0 ?

					<AsideMenuItemWithSub to={`/${item.pagesLink}`} icon={item.iconPath} title={item.pagesTitle} key={index}>
						{item.subMenu.map((subItem: any, subIndex: number) => (
							<AsideMenuItem to={`/${subItem.pagesLink}`} title={subItem.pagesTitle} icon={subItem.iconPath} key={subIndex} />
						))}
					</AsideMenuItemWithSub>
					:
					<AsideMenuItem to={`/${item.pagesLink}`} icon={item.iconPath} title={item.pagesTitle} key={index} ></AsideMenuItem>

			))}

		</>

	)
}
