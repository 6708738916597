/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import { Modal, Button } from 'react-bootstrap'
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper'
import validationErrorMsg from '../helpers/ValidationErrorMsg'
import Select from 'react-select'
import Spinner from 'react-bootstrap/Spinner'

interface EditSrProps {
	show: boolean;
	handleClose: any;
	salesOrderInfo: any;
	id: number;
}

interface OrderData {
	idSalesOrders: number;
	idProjects: number;
	idSr: number;
}

const EditSr: React.FC<EditSrProps> = ({ show, handleClose, salesOrderInfo, id }) => {

	const [srs, setSrs] = useState([]);
	const [sr, setSr] = useState({ label: 'Select SR', value: '' });
	const [orderData, setOrderData] = useState<OrderData>({
		idSalesOrders: salesOrderInfo.idSalesOrders,
		idProjects: salesOrderInfo.idProjects,
		idSr: 0,
	});

	useEffect(() => {
		getProjectSrs(orderData.idProjects)
	}, []);

	const getProjectSrs = (idProjects: number) => {
		fetch(
			process.env.REACT_APP_API_URL + 'getAllSr?idProjects=' + idProjects,
			getRequestOptions()
		)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				let srsObj = resp.map((item: any) => {
					item.label = item.first_name + ' ' + item.last_name;
					item.value = item.id;
					return item
				})
				setSrs(srsObj);
			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})
	}

	const [isSubmitDisable, setSubmitDisable] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleSrChange = (sr: any) => {
		setSr(sr);
		setOrderData({ ...orderData, idSr: sr.id });
	}

	const shipmentSave = async (event: any) => {
		setLoading(true);
		event.preventDefault()
		setSubmitDisable(true);
		const confirm = await Swal.fire({
			title: 'Are you sure?',
			text: "You want to update this Sales Order SR!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#ec008c',
			cancelButtonText: 'Cancel',
			confirmButtonText: 'Yes, Submit it!',
		})
		if (confirm.value) {

			const requestOptions = postRequestOptions(orderData)
			try {
				let response = await fetch(
					`${process.env.REACT_APP_API_URL}sales-order/update-sr`,
					requestOptions
				)
				let respData = await response.json()
				if (respData.success === true) {
					const successMsg = respData.successMessage
					Swal.fire({
						icon: 'success',
						title: 'Success',
						text: successMsg,
					})
					handleClose(respData.success)

				} else {
					const errorsMessage = validationErrorMsg(respData.errors).join('<br>')
					Swal.fire({
						icon: 'error',
						title: 'Error',
						html: errorsMessage,
					})
				}
				setSubmitDisable(false);
				setLoading(false);

			} catch (error) {
				setLoading(false);
				console.log(error, 'catch the hoop')
				setSubmitDisable(false);
			}
		}
	}

	return (
		<Modal show={show} onHide={() => handleClose(false)} size="xl">
			<form onSubmit={shipmentSave}>
				<Modal.Header closeButton>
					Edit SR
				</Modal.Header>
				<Modal.Body>

					<div className="form-group row">
						<label htmlFor="companyName" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Select SR <span className="required text-danger"></span></label>
						<div className="col-lg-5">
							<Select
								value={sr}
								onChange={handleSrChange}
								options={srs}
								menuPosition="fixed"
							/>

						</div>
					</div>

					{/* <pre>{JSON.stringify(orderData, null, 2)}</pre> */}
				</Modal.Body>
				<Modal.Footer>

					<button type="submit" disabled={isSubmitDisable || loading} className="btn btn-sm btn-primary me-2">
						{loading &&
							<Spinner
								as="span"
								animation="grow"
								size="sm"
								role="status"
								aria-hidden="true"
							/>
						}
						<span>  Submit</span>
					</button>
					<Button variant='secondary' size="sm" onClick={() => handleClose(false)}>
						Close
					</Button>
				</Modal.Footer>
			</form>
		</Modal>
	)
}

export default EditSr
