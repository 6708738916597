import DataTable from '../components/DataTable';
import { KTSVG } from '../../_metronic/helpers'
import 'react-loading-skeleton/dist/skeleton.css';
import { Link } from 'react-router-dom';
import GetPagePermission from "../components/GetPagePermission";

function InventoryOpening() {

    const hasPermission = GetPagePermission("/inventory-opening");
    const numberFormat = (value: any) => {

        value = parseFloat(value)
        if (typeof (value) !== 'number') {
            return value;
        }
        return parseFloat(value.toString()).toLocaleString('en-US', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }


    const columns = [
        { value: 'id', title: 'ID', style: { textAlign: 'center' } },
        { value: 'projectName', title: 'Project', style: { textAlign: 'left' } },
        { value: 'stockType', title: 'Type', style: { textAlign: 'left' } },
        { value: 'referenceNo', title: 'Reference No', style: { textAlign: 'left' } },
        { value: 'openingDate', title: 'Opening Date', style: { textAlign: 'left' } },
        { 
            value: 'totalAmount', title: 'Amount', style: { textAlign: 'center' },
            hasHtmlComponent: true,
            htmlComponent: (row: any) => {
                return (
                    <>
                        {numberFormat(row.totalAmount)}
                    </>
                )
             },
        },
        { value: 'createdBy', title: 'Created By', style: { textAlign: 'left' } },
        {
            value: 'action', title: 'Action', style: { textAlign: 'left', whiteSpace: 'nowrap' }, hasActionsComponent: true, actionsComponent: ((row: any) => {
                return (
                    <>
                        <Link className="btn btn-light-primary btn-sm px-2 py-1" to={`/inventory-opening-details/${row.idInvOpeningStocks}`}>
                            <KTSVG
                                path='/media/svg/icons/Layout/Layout-grid.svg'
                                className='svg-icon-5 svg-icon'
                            />		Details
                        </Link>
                    </>
                )
            })
        }
    ]

    const filters = {
        id: {
            filterType: 'like',
            filterValue: '',
        },
        projectName: {
            filterType: 'like',
            filterValue: '',
        },
        stockType: {
            filterType: 'like',
            filterValue: '',
        },
        referenceNo: {
            filterType: 'like',
            filterValue: '',
        },
        openingDate: {
            filterType: 'like',
            filterValue: '',
        },
        totalAmount: {
            filterType: 'like',
            filterValue: '',
        },
        createdBy: {
            filterType: 'like',
            filterValue: '',
        },
    }

    const url = process.env.REACT_APP_API_URL + 'inventory-opening/dt'

    return (
        <>
            {hasPermission === 1 &&
                <>
                    <div className='card card-custom'>
                        <div className='card-header'>
                            <div className="card-title">
                                <KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-1 svg-icon-primary' />
                                <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
                                    Inventory Opening List
                                </span>
                            </div>
                            <div className="card-toolbar">
                                <div className='d-flex justify-content-end'>
                                    <Link to={`/inventory-opening-create`} className="btn btn-sm btn-primary"><i className="fa fa-plus"></i>Create New Opening Entry</Link>
                                </div>
                            </div>
                        </div>

                        <div className='card-body'>
                            <DataTable url={url} columns={columns} filters={filters} />
                        </div>
                    </div >
                </>
            }

        </>
    )
}

export default InventoryOpening
