import { Button, Col, Row, Table } from "react-bootstrap-v5";
import { getRequestOptions, postRequestOptions } from "../helpers/Fetchwrapper";
import { useEffect, useState } from "react";
import Select from "react-select";
import { Link } from 'react-router-dom';
import showLoader from "../helpers/ShowLoader";
import Swal from "sweetalert2";
import React from 'react';
import {numberFormat } from '../helpers/GlobalHelper';  

interface LedgerData {
    ledgerName: string;
    ledgerCode: string;
    ledgerType: string;
    category: string;
    openingDr: number;
    openingCr: number;
    transactionDr: number;
    transactionCr: number;
    closingDr: number;
    closingCr: number;
}

export default function TrialBalance() {

    const [trialBalanceData, setTrialBalance] = useState<LedgerData[]>([]);
    const [selectProjectOptions, setSelectProjectOptions] = useState([])
    const [selectedProject, setSelectedProject] = useState<{ value: string; label: string; } | null>(null);
    const [startDate, setStartDate] = useState<string | null>(null);
    const [endDate, setEndDate] = useState<string | null>(null);


    async function getProjectList() {
        const url = process.env.REACT_APP_API_URL + 'projects';

        const response = await fetch(url, getRequestOptions());

        const data = await response.json();

        data.data.map((element: any) => {
            element.value = element.id_projects;
            element.label = element.project_name;
            return element;
        })

        console.log(data);
        setSelectProjectOptions(data.data);
    }

    async function getTrialBalance() {
        showLoader();
        const response = await fetch(process.env.REACT_APP_API_URL + 'reports/trial-balance', postRequestOptions(
            {
                idProjects: selectedProject?.value,
                startDate: startDate,
                endDate: endDate
            }
        ))
        const data = await response.json();
        if (data.success) {
            Swal.close();
            setTrialBalance(data.data);
        } else {
            Swal.close();
            // toast(data.errors, { type: 'error' });
        }
    }

    useEffect(() => {
        getTrialBalance();
        getProjectList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const totalOpeningDr = trialBalanceData.reduce((acc, item) => {
        return acc + Number(item.openingDr);
    }, 0);

    const totalOpeningCr = trialBalanceData.reduce((acc, item) => {
        return acc + Number(item.openingCr);
    }, 0);

    const totalTransactionDr = trialBalanceData.reduce((acc, item) => {
        return acc + Number(item.transactionDr);
    }, 0);

    const totalTransactionCr = trialBalanceData.reduce((acc, item) => {
        return acc + Number(item.transactionCr);
    }, 0);

    const totalClosingDr = trialBalanceData.reduce((acc, item) => {
        return acc + Number(item.closingDr);
    }, 0);

    const totalClosingCr = trialBalanceData.reduce((acc, item) => {
        return acc + Number(item.closingCr);
    }, 0);

    const queryParams = new URLSearchParams([
        ['idProjects', selectedProject?.value || ''],
        ['startDate', startDate || ''],
        ['endDate', endDate || '']
    ]);


    const calculateGroupSums = (data, category) => {
        const groupSums = {};
    
        data.forEach(item => {
            if (item.category === category) {
                if (item.ledgerType === "gl") {
                    const parentCode = item.ledgerCode.split(".").slice(0, -1).join("."); // Get parent group code
                    if (!groupSums[parentCode]) {
                        groupSums[parentCode] = {
                            openingDr: 0,
                            openingCr: 0,
                            transactionDr: 0,
                            transactionCr: 0,
                            closingDr: 0,
                            closingCr: 0
                        };
                    }
                    // Sum up the values for the parent group
                    groupSums[parentCode].openingDr += Number(item.openingDr) || 0;
                    groupSums[parentCode].openingCr += Number(item.openingCr) || 0;
                    groupSums[parentCode].transactionDr += Number(item.transactionDr) || 0;
                    groupSums[parentCode].transactionCr += Number(item.transactionCr) || 0;
                    groupSums[parentCode].closingDr += Number(item.closingDr) || 0;
                    groupSums[parentCode].closingCr += Number(item.closingCr) || 0;
                }
            }
        });
    
        return groupSums;
    };

    const assetGroupSums = calculateGroupSums(trialBalanceData, 'asset');
    const expenseGroupSums = calculateGroupSums(trialBalanceData, 'expense');
    const liabilityGroupSums = calculateGroupSums(trialBalanceData, 'liability');
    const revenueGroupSums = calculateGroupSums(trialBalanceData, 'revenue');

    return <div className='card card-custom'>
        <div className='card-header'>
            <div className="card-title">
                <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
                    Trial Balance
                </span>
            </div>
            <div className='card-toolbar'>
                <div className='d-flex justify-content-end align-items-center'>
                    <Select
                        className="form-control-sm h-50px w-250px me-3"
                        defaultValue={selectProjectOptions}
                        onChange={setSelectedProject}
                        options={selectProjectOptions}
                        placeholder="Select project"
                    />
                    <Row>
                        <Col className="d-flex align-items-center text-end pe-0">
                            From:
                        </Col>
                        <Col>
                            <input onChange={e => { setStartDate(e.target.value) }} type="date" className="d-inline form-control form-control-sm" />
                        </Col>
                        <Col className="d-flex align-items-center text-end pe-0">
                            To:
                        </Col>
                        <Col>
                            <input onChange={e => { setEndDate(e.target.value) }} type="date" className="d-inline form-control form-control-sm" />
                        </Col>
                    </Row>
                    <Button onClick={() => getTrialBalance()} variant="primary" className="ms-2 p-2 me-2" size="sm">Refresh</Button>
                    <a className="btn btn-sm btn-primary p-2 ml-2" href={`${process.env.REACT_APP_BACKEND}trial_balance_download?` + queryParams.toString()}><i className='fas fa-download'></i>Excel Download</a>
                </div>
            </div>
        </div>
        <div className='card-body pt-0'>
            <div className="trial-balance-container table-responsive">
                <Table size="sm" bordered hover>
                    <thead className="sticky-top bg-white" style={{zIndex: 0}}>
                        <tr>
                            <th rowspan="2" width="10%" className="text-end pe-3">Ledger Code</th>
                            <th rowspan="2" width="60%">Group / Ledger</th>
                            <th className="text-center" colspan="2">Opening Balance</th>
                            <th className="text-center" colspan="2">Transaction Balance</th>
                            <th className="text-center" colspan="2">Closing Balance</th>
                        </tr>
                        <tr>
                            <th className="text-right">Debit</th>
                            <th className="text-right">Credit</th>
                            <th className="text-right">Debit</th>
                            <th className="text-right">Credit</th>
                            <th className="text-right">Debit</th>
                            <th className="text-right">Credit</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr><td></td><th colSpan={4}><strong>Asset</strong></th></tr>
                        {
                            trialBalanceData.map((item, index) => {

                                if (item.category === "asset") {
                                    const parentCode = item.ledgerType === "group" ? item.ledgerCode : item.ledgerCode.split(".").slice(0, -1).join(".");
                                    const groupSum = assetGroupSums[parentCode];
        
                                    return (
                                        <React.Fragment key={index}>
                                            {item.ledgerType === "group" && (
                                                <tr className="table-light">
                                                    <td className="text-end pe-3">{item.ledgerCode}</td>
                                                    <td style={{ paddingLeft: (20 * item.ledgerCode.split(".").length) + "px" }}><strong>{item.ledgerName}</strong></td>
                                                    <td className="text-end"><strong>{groupSum?.openingDr>= 0 ? numberFormat(groupSum?.openingDr) : ''}</strong></td>
                                                    <td className="text-end"><strong>{groupSum?.openingCr >=0 ? numberFormat(groupSum?.openingCr) : ''}</strong></td>
                                                    <td className="text-end"><strong>{groupSum?.transactionDr >=0 ? numberFormat(groupSum?.transactionDr) : ''}</strong></td>
                                                    <td className="text-end"><strong>{groupSum?.transactionCr >=0 ? numberFormat(groupSum?.transactionCr) : ''}</strong></td>
                                                    <td className="text-end"><strong>{groupSum?.closingDr >=0 ? numberFormat(groupSum?.closingDr) : ''}</strong></td>
                                                    <td className="text-end"><strong>{groupSum?.closingCr >=0 ? numberFormat(groupSum?.closingCr) : ''}</strong></td>
                                                </tr>
                                            )}
                                            {item.ledgerType === "gl" && (
                                                <tr>
                                                    <td className="text-end pe-3">{item.ledgerCode}</td>
                                                    <td style={{ paddingLeft: (20 * item.ledgerCode.split(".").length) + "px" }}>
                                                        <Link to={`reports?type=ledgerStatement&idProjects=${selectedProject?.value}&idAccLedgers=${item.idAccLedgers}&fromDate=${startDate}&toDate=${endDate}`}>
                                                            {item.ledgerName}
                                                        </Link>
                                                    </td>
                                                    <td className="text-end">{numberFormat(item.openingDr)}</td>
                                                    <td className="text-end">{numberFormat(item.openingCr)}</td>
                                                    <td className="text-end">{numberFormat(item.transactionDr)}</td>
                                                    <td className="text-end">{numberFormat(item.transactionCr)}</td>
                                                    <td className="text-end">{numberFormat(item.closingDr)}</td>
                                                    <td className="text-end">{numberFormat(item.closingCr)}</td>
                                                </tr>
                                            )}
                                        </React.Fragment>
                                    );
                                }
                                return null;
                            })
                        }

                        <tr><td></td><th colSpan={4}><strong>Expense</strong></th></tr>
                        {

                            trialBalanceData.map((item, index) => {
                                if (item.category === "expense") {
                                    const parentCode = item.ledgerType === "group" ? item.ledgerCode : item.ledgerCode.split(".").slice(0, -1).join(".");
                                    const groupSum = expenseGroupSums[parentCode];
        
                                    return (
                                        <React.Fragment key={index}>
                                            {item.ledgerType === "group" && (
                                                <tr className="table-light">
                                                    <td className="text-end pe-3">{item.ledgerCode}</td>
                                                    <td style={{ paddingLeft: (20 * item.ledgerCode.split(".").length) + "px" }}><strong>{item.ledgerName}</strong></td>
                                                    <td className="text-end"><strong>{groupSum?.openingDr>= 0 ? numberFormat(groupSum?.openingDr) : ''}</strong></td>
                                                    <td className="text-end"><strong>{groupSum?.openingCr >=0 ? numberFormat(groupSum?.openingCr) : ''}</strong></td>
                                                    <td className="text-end"><strong>{groupSum?.transactionDr >=0 ? numberFormat(groupSum?.transactionDr) : ''}</strong></td>
                                                    <td className="text-end"><strong>{groupSum?.transactionCr >=0 ? numberFormat(groupSum?.transactionCr) : ''}</strong></td>
                                                    <td className="text-end"><strong>{groupSum?.closingDr >=0 ? numberFormat(groupSum?.closingDr) : ''}</strong></td>
                                                    <td className="text-end"><strong>{groupSum?.closingCr >=0 ? numberFormat(groupSum?.closingCr) : ''}</strong></td>
                                                </tr>
                                            )}
                                            {item.ledgerType === "gl" && (
                                                <tr>
                                                    <td className="text-end pe-3">{item.ledgerCode}</td>
                                                    <td style={{ paddingLeft: (20 * item.ledgerCode.split(".").length) + "px" }}>
                                                        <Link to={`reports?type=ledgerStatement&idProjects=${selectedProject?.value}&idAccLedgers=${item.idAccLedgers}&fromDate=${startDate}&toDate=${endDate}`}>
                                                            {item.ledgerName}
                                                        </Link>
                                                    </td>
                                                    <td className="text-end">{numberFormat(item.openingDr)}</td>
                                                    <td className="text-end">{numberFormat(item.openingCr)}</td>
                                                    <td className="text-end">{numberFormat(item.transactionDr)}</td>
                                                    <td className="text-end">{numberFormat(item.transactionCr)}</td>
                                                    <td className="text-end">{numberFormat(item.closingDr)}</td>
                                                    <td className="text-end">{numberFormat(item.closingCr)}</td>
                                                </tr>
                                            )}
                                        </React.Fragment>
                                    );
                                }
                                return null;
                            })
                        }
                        <tr><td></td><th colSpan={4}><strong>Liability</strong></th></tr>
                        {
                            trialBalanceData.map((item, index) => {
                                if (item.category === "liability") {
                                    const parentCode = item.ledgerType === "group" ? item.ledgerCode : item.ledgerCode.split(".").slice(0, -1).join(".");
                                    const groupSum = liabilityGroupSums[parentCode];
        
                                    return (
                                        <React.Fragment key={index}>
                                            {item.ledgerType === "group" && (
                                                <tr className="table-light">
                                                    <td className="text-end pe-3">{item.ledgerCode}</td>
                                                    <td style={{ paddingLeft: (20 * item.ledgerCode.split(".").length) + "px" }}><strong>{item.ledgerName}</strong></td>
                                                    <td className="text-end"><strong>{groupSum?.openingDr>= 0 ? numberFormat(groupSum?.openingDr) : ''}</strong></td>
                                                    <td className="text-end"><strong>{groupSum?.openingCr >=0 ? numberFormat(groupSum?.openingCr) : ''}</strong></td>
                                                    <td className="text-end"><strong>{groupSum?.transactionDr >=0 ? numberFormat(groupSum?.transactionDr) : ''}</strong></td>
                                                    <td className="text-end"><strong>{groupSum?.transactionCr >=0 ? numberFormat(groupSum?.transactionCr) : ''}</strong></td>
                                                    <td className="text-end"><strong>{groupSum?.closingDr >=0 ? numberFormat(groupSum?.closingDr) : ''}</strong></td>
                                                    <td className="text-end"><strong>{groupSum?.closingCr >=0 ? numberFormat(groupSum?.closingCr) : ''}</strong></td>
                                                </tr>
                                            )}
                                            {item.ledgerType === "gl" && (
                                                <tr>
                                                    <td className="text-end pe-3">{item.ledgerCode}</td>
                                                    <td style={{ paddingLeft: (20 * item.ledgerCode.split(".").length) + "px" }}>
                                                        <Link to={`reports?type=ledgerStatement&idProjects=${selectedProject?.value}&idAccLedgers=${item.idAccLedgers}&fromDate=${startDate}&toDate=${endDate}`}>
                                                            {item.ledgerName}
                                                        </Link>
                                                    </td>
                                                    <td className="text-end">{numberFormat(item.openingDr)}</td>
                                                    <td className="text-end">{numberFormat(item.openingCr)}</td>
                                                    <td className="text-end">{numberFormat(item.transactionDr)}</td>
                                                    <td className="text-end">{numberFormat(item.transactionCr)}</td>
                                                    <td className="text-end">{numberFormat(item.closingDr)}</td>
                                                    <td className="text-end">{numberFormat(item.closingCr)}</td>
                                                </tr>
                                            )}
                                        </React.Fragment>
                                    );
                                }
                                return null;
                            })
                        }

                        <tr><td></td><th colSpan={4}><strong>Revenue</strong></th></tr>
                        {
                            trialBalanceData.map((item, index) => {
                                if (item.category === "revenue") {
                                    const parentCode = item.ledgerType === "group" ? item.ledgerCode : item.ledgerCode.split(".").slice(0, -1).join(".");
                                    const groupSum = revenueGroupSums[parentCode];
        
                                    return (
                                        <React.Fragment key={index}>
                                            {item.ledgerType === "group" && (
                                                <tr className="table-light">
                                                    <td className="text-end pe-3">{item.ledgerCode}</td>
                                                    <td style={{ paddingLeft: (20 * item.ledgerCode.split(".").length) + "px" }}><strong>{item.ledgerName}</strong></td>
                                                    <td className="text-end"><strong>{groupSum?.openingDr>= 0 ? numberFormat(groupSum?.openingDr) : ''}</strong></td>
                                                    <td className="text-end"><strong>{groupSum?.openingCr >=0 ? numberFormat(groupSum?.openingCr) : ''}</strong></td>
                                                    <td className="text-end"><strong>{groupSum?.transactionDr >=0 ? numberFormat(groupSum?.transactionDr) : ''}</strong></td>
                                                    <td className="text-end"><strong>{groupSum?.transactionCr >=0 ? numberFormat(groupSum?.transactionCr) : ''}</strong></td>
                                                    <td className="text-end"><strong>{groupSum?.closingDr >=0 ? numberFormat(groupSum?.closingDr) : ''}</strong></td>
                                                    <td className="text-end"><strong>{groupSum?.closingCr >=0 ? numberFormat(groupSum?.closingCr) : ''}</strong></td>
                                                </tr>
                                            )}
                                            {item.ledgerType === "gl" && (
                                                <tr>
                                                    <td className="text-end pe-3">{item.ledgerCode}</td>
                                                    <td style={{ paddingLeft: (20 * item.ledgerCode.split(".").length) + "px" }}>
                                                        <Link to={`reports?type=ledgerStatement&idProjects=${selectedProject?.value}&idAccLedgers=${item.idAccLedgers}&fromDate=${startDate}&toDate=${endDate}`}>
                                                            {item.ledgerName}
                                                        </Link>
                                                    </td>
                                                    <td className="text-end">{numberFormat(item.openingDr)}</td>
                                                    <td className="text-end">{numberFormat(item.openingCr)}</td>
                                                    <td className="text-end">{numberFormat(item.transactionDr)}</td>
                                                    <td className="text-end">{numberFormat(item.transactionCr)}</td>
                                                    <td className="text-end">{numberFormat(item.closingDr)}</td>
                                                    <td className="text-end">{numberFormat(item.closingCr)}</td>
                                                </tr>
                                            )}
                                        </React.Fragment>
                                    );
                                }
                                return null;
                            })
                        }
                    </tbody>
                    {/* <tfoot style={{ position: 'absolute', bottom: '0px', right: '15px', height:'20px' }}>
                        <tr>
                            <td></td>
                            <td><strong>Total</strong></td>
                            <td className="text-end"><strong>{numberFormat(totalOpeningDr)}</strong></td>
                            <td className="text-end"><strong>{numberFormat(totalOpeningCr)}</strong></td>
                            <td className="text-end"><strong>{numberFormat(totalTransactionDr)}</strong></td>
                            <td className="text-end"><strong>{numberFormat(totalTransactionCr)}</strong></td>
                            <td className="text-end"><strong>{numberFormat(totalClosingDr)}</strong></td>
                            <td className="text-end"><strong>{numberFormat(totalClosingCr)}</strong></td>
                        </tr>
                    </tfoot> */}
                </Table>
            </div>
            <Table className="trial-balance">
                <tfoot className="bg-white sticky-bottom">
                    <tr>
                        <td width="10%"></td>
                        <td width="60%"><strong>Total</strong></td>
                        <td className="text-end"><strong>{numberFormat(totalOpeningDr)}</strong></td>
                        <td className="text-end"><strong>{numberFormat(totalOpeningCr)}</strong></td>
                        <td className="text-end"><strong>{numberFormat(totalTransactionDr)}</strong></td>
                        <td className="text-end"><strong>{numberFormat(totalTransactionCr)}</strong></td>
                        <td className="text-end"><strong>{numberFormat(totalClosingDr)}</strong></td>
                        <td className="text-end"><strong>{numberFormat(totalClosingCr)}</strong></td>
                    </tr>
                </tfoot>
            </Table>
        </div>
    </div >
}