/* eslint-disable react-hooks/exhaustive-deps */
import { KTSVG } from '../../_metronic/helpers'
import React, { useState, useEffect } from 'react'
import { Table } from 'react-bootstrap-v5';
import Swal from 'sweetalert2'
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper'
import Select from 'react-select'
import { Link } from 'react-router-dom';
import validationErrorMsg from '../helpers/ValidationErrorMsg'
import { useHistory } from "react-router-dom";

interface OpeningRows {
    idItems: number;
    idProducts: number;
    quantity: number;
    rate: number;
    amount: number;
    [key: string]: any; // Index signature
}

interface Item {
    idItems: number;
    itemName: string;
}

interface Product {
    id_products: number;
    product_name: string;
}

interface OpeningData {
    stockType: string;
    idProjects: number;
    openingDate: string;
    remarks: string;
    totalAmount: number;
}


const InventoryOpeningCreate = () => {

    let history = useHistory();

    const openingData: OpeningData = {
        stockType: "Item Stock",
        idProjects: 0,
        openingDate: '',
        remarks: '',
        totalAmount: 0
    };

    const [projects, setProjects] = useState([]);
    const [project, setProject] = useState({ label: 'Select Project', value: '' });

    const [openingEntry, setOpeningEntry] = useState(openingData);
    const [openingRows, setOpeningRows] = useState<OpeningRows[]>([{
        idItems: 0,
        idProducts: 0,
        quantity: 0,
        rate: 0,
        amount: 0,
    }]);
    
    const [items, setItems] = useState<Item[]>([]);
    const [products, setProducts] = useState<Product[]>([]);
    
    useEffect(() => {
        getProjects();
        getItems();
    }, [])


    const getProjects = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'lead/projects',
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let projectObj = resp.data.map((item: any) => {
                    item.label = item.name;
                    item.value = item.id;
                    return item
                })
                setProjects(projectObj);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const handleProjectChange = (project: any) => {
        setProject(project);
        getProducts(project.value);
        setOpeningRows([{
            idItems: 0,
            idProducts: 0,
            quantity: 0,
            rate: 0,
            amount: 0,
        }])
        setOpeningEntry({ ...openingEntry, idProjects: project.value });
    }


    const handleItemChange = (data: any, index: number) => {
        const updatedOpeningRows = [...openingRows];
        updatedOpeningRows[index].idItems = data.value;
        updatedOpeningRows[index].idProducts = 0;
        updatedOpeningRows[index].rate = 0;
        setOpeningRows(updatedOpeningRows);
    }

    const handleProductChange = (data: any, index: number) => {
        const updatedProductOpeningRows = [...openingRows];
        updatedProductOpeningRows[index].idItems = 0;
        updatedProductOpeningRows[index].idProducts = data.value;
        updatedProductOpeningRows[index].rate = 0;
        setOpeningRows(updatedProductOpeningRows);
    }

    const getItems = () => {

        fetch(
            process.env.REACT_APP_API_URL + 'production-items',
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {

                let items = resp.data.map((item: any) => {
                    item.label = item.itemName;
                    item.value = item.idItems;
                    return item
                })
                setItems(items);

            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const getProducts = (project: number) => {

        fetch(
            process.env.REACT_APP_API_URL + 'manufacturing/products?idProjects=' + project,
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {

                let products = resp.data.map((item: any) => {
                    item.label = item.product_name + ' (Size:' + item.packing + ')';
                    item.value = item.id_products;
                    item.unitRate = item.production_cost;
                    return item
                })
                setProducts(products);

            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const updatedOpeningRows = [...openingRows];
        updatedOpeningRows[index][e.target.name] = e.target.value;

        const quantity = parseFloat(updatedOpeningRows[index].quantity);
        const rate = parseFloat(updatedOpeningRows[index].rate);
        updatedOpeningRows[index].amount = parseFloat((quantity * rate).toFixed(2));
       
        setOpeningRows(updatedOpeningRows);
    }

    const deleteItem = (index: number) => {
        const updatedOpeningRows = [...openingRows];
        if (updatedOpeningRows && updatedOpeningRows[index]) {
            updatedOpeningRows.splice(index, 1);
            setOpeningRows(updatedOpeningRows);
        }
    }

    const addItem = (index: number) => {
        const newRow: OpeningRows = {
            idItems: 0,
            idProducts: 0,
            quantity: 0,
            rate: 0,
            amount: 0,
        };

        const updatedRows = [...openingRows];
        updatedRows.push(newRow);

        setOpeningRows(updatedRows);
    }

    const handleInputOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOpeningEntry({ ...openingEntry, [event.target.name]: event.target.value });
    }

    const handleSelectOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        
        setOpeningEntry({ ...openingEntry, [event.target.id]: event.target.value });
        setOpeningRows([{
            idItems: 0,
            idProducts: 0,
            quantity: 0,
            rate: 0,
            amount: 0,
        }])

        if (event.target.value === 'Item Stock') {
            getItems();
        } else {
            getProducts(project?.value);
        }
    };

    const calculateTotalAmount = () => {
        let totalAmount = 0;

        for (const row of openingRows) {
            totalAmount += row.amount;
        }
        return totalAmount;
    };

    
    const numberFormat = (value: any) => {

        value = parseFloat(value)
        if (typeof (value) !== 'number') {
            return value;
        }
        return parseFloat(value.toString()).toLocaleString('en-US', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }
    
    const openingEntrySave = async (event: any) => {
        event.preventDefault()
      
        setOpeningEntry({ ...openingEntry, totalAmount: calculateTotalAmount() });

        const confirm = await Swal.fire({
            title: 'Are you sure?',
            text: "You want to create this Opening Entry?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#ec008c',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Yes, Create it!',
        })
        if (confirm.value) {
            const postData = {
                openingEntry: openingEntry,
                openingRows: openingRows,
            }
            const requestOptions = postRequestOptions(postData)
            try {
                let response = await fetch(
                    `${process.env.REACT_APP_API_URL}inventory-opening`,
                    requestOptions
                )
                let respData = await response.json()
                if (respData.success === true) {
                    const successMsg = respData.successMessage
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: successMsg,
                    })

                    history.push("/inventory-opening");
                } else {
                    const errorsMessage = validationErrorMsg(respData.errors).join('<br>')
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        html: errorsMessage,
                    })
                }
              
            } catch (error) {
                console.log(error, 'catch the hoop')
            } 
        }
    }

    return (

        <div className="card card-custom">
            <div className="card-header">
                <div className="card-title">
                    <KTSVG
                        path='/media/svg/icons/Home/Book-open.svg'
                        className='svg-icon-1 svg-icon-primary'
                    />
                    <span className="text fw-bolder" style={{ marginLeft: "10px" }}>
                        Inventory Opening Entry
                    </span>
                </div>
            </div>

            <div className="ml-12">
                <div className="card-body">
                    
                    <form onSubmit={openingEntrySave}>
                        <div className="form-group row">
                            <label htmlFor="stockType" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Stock Type <span className="required text-danger"></span></label>
                            <div className="col-lg-5">
                                <select name="stockType" id="stockType" onChange={handleSelectOnChange} className="form-control form-control-sm">
                                    <option value={'Item Stock'}>Item Stock</option>
                                    <option value={'Product Stock'}>Product Stock</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="idProjects" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Select Project <span className="required text-danger"></span></label>
                            <div className="col-lg-5">
                                <Select
                                    value={project}
                                    onChange={handleProjectChange}
                                    options={projects}
                                    menuPosition="fixed"
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                />
                            </div>
                        </div>
                        
                        <div className="form-group row">
                            <label htmlFor="openingDate" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Opening Date <span className="required text-danger"></span></label>
                            <div className="col-lg-5">
                                <input type='date' name="openingDate" id="openingDate" onChange={handleInputOnChange} className="form-control form-control-sm" />
                            </div>
                        </div>
                        
                        <div className="form-group row mb-2">
                            <label htmlFor="remarks" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Remarks</label>
                            <div className="col-lg-5">
                                <input name="remarks" id="remarks" onChange={handleInputOnChange} className="form-control form-control-sm" />
                            </div>
                        </div>

                        <br></br>
                        <Table size='sm' bordered hover>
                            <thead>
                                <tr>
                                    <th style={{ width: '5%' }} className='text-center'>SL</th>
                                    <th style={{ width: '35%' }}>{openingEntry.stockType==='Item Stock' ? 'Item Name' : 'Product Name'} <span className='text-danger'>*</span> </th>
                                    <th style={{ width: '10%' }}>Rate</th>
                                    <th style={{ width: '10%' }}>Quantity</th>
                                    <th style={{ width: '10%' }}>Amount</th>
                                    <th style={{ width: '10%' }}></th>
                                </tr>
                            </thead>

                            <tbody>

                                {openingRows.map((row, index) => (
                                    <tr key={'item-'+index}>
                                        <td className='text-center'>{index + 1}</td>
                                        <td>
                                            {openingEntry.stockType === 'Item Stock' ? (
                                            <Select
                                                placeholder={row.itemName}
                                                onChange={(selectedOption) => handleItemChange(selectedOption, index)}
                                                options={items}
                                                className='sm'
                                                menuPosition="fixed"
                                                styles={{
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        height: '32px',
                                                        minHeight: '32px',
                                                    }),
                                                    container: (provided, state) => ({
                                                        ...provided,
                                                        height: '32px',
                                                    }),
                                                }}
                                            />
                                            ) : (
                                                <Select
                                                    placeholder={row.product_name}
                                                    onChange={(selectedOption) => handleProductChange(selectedOption, index)}
                                                    options={products}
                                                    className='sm'
                                                    menuPosition="fixed"
                                                    styles={{
                                                        control: (provided, state) => ({
                                                            ...provided,
                                                            height: '32px',
                                                            minHeight: '32px',
                                                        }),
                                                        container: (provided, state) => ({
                                                            ...provided,
                                                            height: '32px',
                                                        }),
                                                    }}
                                                />
                                            )}

                                            
                                        </td>
                                        <td>
                                            <input type="number" name="rate" value={row.rate} onChange={(event) => handleChange(event, index)} className="form-control form-control-sm" />
                                        </td>
                                        <td>
                                            <input type="number" name="quantity" value={row.quantity} onChange={(event) => handleChange(event, index)} className="form-control form-control-sm" />
                                        </td>
                                        <td>
                                            <input type="number" name="amount" readOnly value={row.amount} className="form-control form-control-sm" />
                                        </td>
                                        <td>
                                            {
                                                openingRows.length > 1 &&
                                                <button type='button' className="btn btn-light-primary btn-sm px-2 py-1" onClick={() => deleteItem(index)}>
                                                    <KTSVG path='/media/svg/icons/General/Trash.svg' className='svg-icon-5 svg-icon-danger me-0' />
                                                </button>
                                            }
                                            
                                            {index === openingRows.length - 1 && (
                                                <button type='button' className="btn btn-success btn-sm px-2 py-1" onClick={() => addItem(index)}>
                                                    <KTSVG path='/media/svg/icons/Navigation/Plus.svg' className='svg-icon-5 svg-icon-danger me-0' /> Add
                                                </button>
                                            )}
                                        </td>
                                    </tr>
                                ))}

                                <tr>
                                    <td colSpan={4} className='text-end'><b>Total Amount:</b></td>
                                    <td><b>{numberFormat(calculateTotalAmount())}</b></td>
                                </tr>
                               
                            </tbody>

                        </Table>

                        <div className="row mt-4">
                            <div className="col-12 text-center">
                                <button type="submit" className="btn btn-sm btn-primary me-2">
                                    <span>  Submit</span>
                                </button>
                                <Link to='/inventory-opening' type="reset" className="btn btn-sm btn-secondary">Cancel</Link>
                            </div>
                        </div>
                        {/* <pre>{JSON.stringify(openingEntry, null, 2)}</pre>
                        <pre>{JSON.stringify(openingRows, null, 2)}</pre> */}
                    </form>

                </div>
            </div>
        </div>

    )
}

export default InventoryOpeningCreate