export default function scrollToSmoothly(scrollTargetY: number, speed: number) {
    var currentY = window.scrollY || window.pageYOffset;
    var distance = Math.abs(currentY - scrollTargetY);

    var stepSize = Math.ceil(distance / speed);
    var direction = currentY < scrollTargetY ? 1 : -1;

    function scrollStep() {
        currentY = window.scrollY || window.pageYOffset;

        if (
            (direction === 1 && currentY < scrollTargetY) ||
            (direction === -1 && currentY > scrollTargetY)
        ) {
            window.scrollBy(0, stepSize * direction);
            requestAnimationFrame(scrollStep);
        }
    }

    scrollStep();
}
