import { useState } from 'react'
import DataTable from '../components/DataTable'
import { KTSVG } from '../../_metronic/helpers'
import { Link } from "react-router-dom";
import Swal from 'sweetalert2'
import CompanyCreate from '../components/CompanyCreate';
import AssignedTo from '../components/AssignedTo';
import GetPagePermission from "../components/GetPagePermission";

function CompanyList() {

    const hasPermissionCompanyEdit = GetPagePermission("/setup-company-edit");

    const [newData, setNewData] = useState('');

    const [createCompanyModalShow, setCreateCompanyModalShow] = useState(null);
    const [assignedToModalShow, setAssignedToModalShow] = useState(null);
    const [companyInfo, setCompanyInfo] = useState({});

    const handleCreateCompanyModalShow = () => {
        setCreateCompanyModalShow(true);
    }

    const handleCreateCompanyModalClose = (isReload) => {
        setCreateCompanyModalShow(false);
        if (isReload) {
            setNewData({ id: Date.now() });
        }
    }


    const deleteCompany = (id) => {
        if (window.confirm("Are you sure you want to delete this company?")) {
            let jwt = JSON.parse(localStorage.getItem('MyToken'));
            fetch(process.env.REACT_APP_API_URL + 'companies/' + id, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt }
            })
                .then((resp) => {
                    return resp.json()
                })
                .then((resp) => {
                    if (resp.success) {
                        const successMsg = resp.successMessage
                        Swal.fire({
                            icon: 'success',
                            title: 'Deleted!',
                            text: successMsg,
                        })
                        setNewData({ id: Date.now() });
                    }
                })
                .catch((error) => {
                    console.log(error, 'catch the hoop')
                })
        }
    }

    const columns = [
        { value: 'id', title: 'Id', style: { textAlign: 'center' } },
        { value: 'companyName', title: 'Name', style: { textAlign: 'left' } },
        { value: 'internal', title: 'Internal', style: { textAlign: 'center' } },
        { value: 'categoryName', title: 'Category', style: { textAlign: 'left' } },
        { value: 'companyAddress', title: 'Address', style: { textAlign: 'left' } },
        { value: 'assignedToName', title: 'Assigned To', style: { textAlign: 'left' } },
        {
            value: 'action', title: 'Action', style: { textAlign: 'center', whiteSpace: 'nowrap' }, hasActionsComponent: true, actionsComponent: ((row) => {
                return (
                    <>
                        {hasPermissionCompanyEdit === 1 &&
                            <Link to={`/setup-company-edit/${row.idCompanies}`}>
                                <button className="btn btn-light-primary btn-sm px-2 py-1">
                                    <KTSVG
                                        path='/media/svg/icons/Communication/Write.svg'
                                        className='svg-icon-5 svg-icon'
                                    />		Edit
                                </button>
                            </Link>
                        }
                        <button className="btn btn-light-danger btn-sm px-2 py-1" onClick={() => deleteCompany(row.idCompanies)}>
                            <KTSVG
                                path='/media/svg/icons/General/Trash.svg'
                                className='svg-icon-5 svg-icon'
                            />		Delete
                        </button>
                        <button className="btn btn-light-info btn-sm px-2 py-1" onClick={() => assignedToAction(row)}>
                            <KTSVG
                                path='/media/svg/icons/General/User.svg'
                                className='svg-icon-5 svg-icon'
                            />		Assign User
                        </button>
                        <Link className="btn btn-light-primary btn-sm" to={`company-details/${row.idCompanies}`}>
                            <KTSVG
                                path='/media/svg/icons/Layout/Layout-grid.svg'
                                className='svg-icon-5 svg-icon'
                            />		Details
                        </Link>
                    </>
                )
            })
        }
    ]

    const filters = {
        id: {
            filterType: 'like',
            filterValue: '',
        },
        companyName: {
            filterType: 'like',
            filterValue: '',
        },
        internal: {
            filterType: 'like',
            filterValue: '',
        },
        categoryName: {
            filterType: 'like',
            filterValue: '',
        },
        companyAddress: {
            filterType: 'like',
            filterValue: '',
        },
        assignedToName: {
            filterType: 'like',
            filterValue: '',
        }
    }

    const url = process.env.REACT_APP_API_URL + 'companies'

    //Assign TO Modal
    const assignedToAction = (companyInfo) => {
        // console.log(companyInfo);
        setCompanyInfo(companyInfo);
        setAssignedToModalShow(true);
    }

    const handleAssignedToModalClose = (isReload) => {
        setAssignedToModalShow(false);
        if (isReload) {
            setNewData({ id: Date.now() });
        }
    }


    return (
        <>
            <div className='card card-custom'>
                <div className='card-header'>
                    <div className="card-title">
                        <KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-1 svg-icon-primary' />
                        <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
                            Client List
                        </span>
                    </div>
                    <div className="card-toolbar">
                        <div className='d-flex justify-content-end'>
                            <button type="button" className="btn btn-sm btn-primary" onClick={() => handleCreateCompanyModalShow()}><i className="fa fa-plus"></i>Add Client</button>
                        </div>
                    </div>
                </div>

                <div className='card-body'>
                    <DataTable url={url} columns={columns} filters={filters} newData={newData} />
                </div>
            </div>

            <CompanyCreate show={createCompanyModalShow} handleClose={handleCreateCompanyModalClose} id={Date.now()} />
            <AssignedTo show={assignedToModalShow} handleClose={handleAssignedToModalClose} companyInfo={companyInfo} />
        </>
    )
}

export default CompanyList
