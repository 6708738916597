import React, { useState } from 'react'
import DataTable from '../components/DataTable'
import { KTSVG } from '../../_metronic/helpers'
import Error from '../components/Error500'
import GetPagePermission from "../components/GetPagePermission";
import 'react-loading-skeleton/dist/skeleton.css';
import { useParams } from "react-router-dom"
import StageCreate from '../components/StageCreate'

interface MyParams {
	idProject: string;
}

const ProjectStageManagement: React.FC = () => {

	const hasPermission = GetPagePermission("/setup-category");
	const [newData, setNewData] = useState({});
	// const [id, setId] = useState('');
	// let history = useHistory();
    const { idProject }: MyParams = useParams();
	const [editData, setEditData] = useState({});
	const [operation, setOperation] = useState('create')

	const onCreate = (data: any) => {
		setNewData({ id: data });
		// setId('');
		setEditData({});
	}

	const columns = [
		{ value: 'id', title: 'Id', style: { textAlign: 'center' } },
		{ value: 'stageName', title: 'Name', style: { textAlign: 'left' } },
		{ value: 'remarks', title: 'Remarks', style: { textAlign: 'left' } },
		{ value: 'serialNumber', title: 'Serial Number', style: { textAlign: 'center' } },
		{ value: 'stageStatus', title: 'Status', style: { textAlign: 'left' } },
		{
			value: 'action', title: 'Action', style: { textAlign: 'center' }, hasActionsComponent: true, actionsComponent: ((row: any) => {
				return (
					<>
						<button className="btn btn-light-primary btn-sm" style={{ whiteSpace: 'nowrap' }} onClick={() => onAction(row)}>
							<KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-1 svg-icon-3' />
							Edit
						</button>

						<button className="btn btn-light-danger btn-sm">
							<KTSVG
								path='/media/svg/icons/General/Trash.svg'
								className='svg-icon-5 svg-icon'
							/>		Delete
						</button>

					</>)

			})
		}
	]

	const filters = {
		id: {
			filterType: 'like',
			filterValue: '',
		},
		stageName: {
			filterType: 'like',
			filterValue: '',
		},
		remarks: {
			filterType: 'like',
			filterValue: '',
		},
		serialNumber: {
			filterType: 'like',
			filterValue: '',
		},
		stageStatus: {
			filterType: 'like',
			filterValue: '',
		}

	}

	const url = process.env.REACT_APP_API_URL + 'project-stage/' + idProject

	const onAction = (data: any) => {
		setEditData(data);
		setOperation('update')
	}

	return (
		<>
			{hasPermission === 1 &&
				<div className='card card-custom'>
					<div className='card-header'>
						<div className='card-title'>
							<KTSVG
								path='/media/svg/icons/Files/File-plus.svg'
								className='svg-icon-1 svg-icon-primary'
							/>
							<span className='text fw-bolder' style={{ marginLeft: '10px' }}>
								Manage Stage
							</span>
						</div>
					</div>

					<div className='card-body'>
						{/* <CompanyCategoryManage onCreate={onCreate} id={id} /> */}
						<StageCreate idProjects={Number(idProject)} onCreate={onCreate} operation={operation} editData={editData} />
						<div className="card-header">
							<div className="card-title">
								<KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-1 svg-icon-primary' />
								<span className='text fw-bolder' style={{ marginLeft: '10px' }}>
									Stages
								</span>
							</div>
						</div>
						<div className='card-body'>
							<DataTable url={url} columns={columns} filters={filters} newData={newData} />
						</div>
					</div>
				</div>
			}
			{hasPermission === 0 &&
				<Error />
			}
			{/* {hasPermission === '' &&
				<div className='card card-custom' style={{ padding: "15px" }} >
					<SkeletonTheme>
						<p>
							<Skeleton
								containerClassName="avatar-skeleton"
								count={40} ></Skeleton>
						</p>
					</SkeletonTheme>
				</div>
			} */}
		</>
	)
}

export default ProjectStageManagement
