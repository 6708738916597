import { useState, useEffect } from 'react'
import DataTable from '../components/DataTable';
import { KTSVG } from '../../_metronic/helpers'
import 'react-loading-skeleton/dist/skeleton.css';
import { Link } from 'react-router-dom';
import ShipmentCreate from '../components/ShipmentCreate';
import { getRequestOptions } from '../helpers/Fetchwrapper';
import Select from 'react-select';

function SalesOrder() {

    const [selectProjectOptions, setSelectProjectOptions] = useState([])
    const [project, setProject] = useState({ label: 'Select Project', value: '' });

    const handleProjectChange = (project: any) => {
        setProject(project);
    }

    const getProjectList = async () => {

        const url = process.env.REACT_APP_API_URL + 'projects';
        const response = await fetch(url, getRequestOptions());
        const data = await response.json();

        data.data.map((element: any) => {
            element.value = element.id_projects;
            element.label = element.project_name;
            return element;
        })

        if (data.data.length === 1) {
            setSelectProjectOptions(data.data)

        } else {
            var allProjectsOpt = [{ id: '', name: '', label: 'All Project', value: '' }]
            let allProjects = data.data.concat(allProjectsOpt);
            setSelectProjectOptions(allProjects);
        }

    }

    useEffect(() => {
        getProjectList();
    }, [])

    useEffect(() => {
        if (selectProjectOptions.length === 1) {
            setProject({ label: selectProjectOptions[0]['label'], value: selectProjectOptions[0]['value'] })
        }
    }, [selectProjectOptions])

    useEffect(() => {
        setAdditionalPostData({ idProjects: project.value })
    }, [project])

    const [additionalPostData, setAdditionalPostData] = useState({ idProjects: '' });
    const [createShipmentModalShow, setCreateShipmentModalShow] = useState(false);
    const [salesOrderInfo, setSalesOrderInfo] = useState({});

    const handleCreateShipmentModalClose = (isReload: boolean) => {
        setCreateShipmentModalShow(false);
    }

    const handleShipmentModalShow = (salesOrderInfo: any) => {
        setSalesOrderInfo(salesOrderInfo);
        setCreateShipmentModalShow(true);
    }

    const numberFormat = (value: number) => {
        return parseFloat(value.toString()).toLocaleString('en-US', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }

    const hasPermission = 1; //parseInt(GetPagePermission("/setup-company-list"));

    const columns = [
        { value: 'id', title: 'ID', style: { textAlign: 'center' } },
        { value: 'salesOrderId', title: 'Sales Order ID', style: { textAlign: 'center' } },
        { value: 'salesQuotation', title: 'Quotation', style: { textAlign: 'center' } },
        { value: 'projectName', title: 'Project', style: { textAlign: 'center' } },
        { value: 'srName', title: 'SR', style: { textAlign: 'left' } },
        { value: 'companyName', title: 'Client', style: { textAlign: 'left' } },
        { value: 'orderDate', title: 'Date', style: { textAlign: 'left' } },
        {
            value: 'orderAmount', title: 'Amount', style: { textAlign: 'center' }, hasActionsComponent: true, actionsComponent: ((row: any) => {
                return (
                    <>{numberFormat(row.orderAmount)}</>
                )
            })
        },
        { value: 'creditDays', title: 'Credit Days', style: { textAlign: 'left' } },
        { value: 'createdBy', title: 'Created By', style: { textAlign: 'left' } },
        {
            value: 'action', title: 'Action', style: { textAlign: 'left', whiteSpace: 'nowrap' }, hasActionsComponent: true, actionsComponent: ((row: any) => {
                return (
                    <>
                        <Link className="btn btn-light-primary btn-sm" to={`sales-order/${row.idSalesOrders}`}>
                            <KTSVG
                                path='/media/svg/icons/Layout/Layout-grid.svg'
                                className='svg-icon-5 svg-icon'
                            />		Details
                        </Link>
                        <button className="btn btn-light-primary btn-sm" onClick={() => handleShipmentModalShow(row)}>
                            <KTSVG
                                path='/media/svg/icons/Shopping/Cart3.svg'
                                className='svg-icon-5 svg-icon'
                            />		Shipment
                        </button>

                    </>
                )
            })
        }
    ]

    const filters = {
        id: {
            filterType: 'like',
            filterValue: '',
        },
        salesOrderId: {
            filterType: 'like',
            filterValue: '',
        },
        projectName: {
            filterType: 'like',
            filterValue: '',
        },
        salesQuotation: {
            filterType: 'like',
            filterValue: '',
        },
        srName: {
            filterType: 'like',
            filterValue: '',
        },
        companyName: {
            filterType: 'like',
            filterValue: '',
        },
        orderDate: {
            filterType: 'like',
            filterValue: '',
        },
        orderAmount: {
            filterType: 'like',
            filterValue: '',
        },
        creditDays: {
            filterType: 'like',
            filterValue: '',
        },
        createdBy: {
            filterType: 'like',
            filterValue: '',
        },
    }

    const url = process.env.REACT_APP_API_URL + 'sales-order/dt'

    return (
        <>

            {hasPermission === 1 &&
                <>
                    <div className='card card-custom'>
                        <div className='card-header'>
                            <div className="card-title">
                                <KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-1 svg-icon-primary' />
                                <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
                                    Sales Orders
                                </span>
                            </div>
                            {/* <pre>{JSON.stringify(project, null, 2)}</pre> */}
                            <div className="card-toolbar">
                                <div className='d-flex justify-content-end'>
                                    <Select
                                        // defaultValue={selectProjectOptions}
                                        onChange={handleProjectChange}
                                        options={selectProjectOptions}
                                        placeholder={project.label}
                                    />
                                    &nbsp;
                                    <Link to={`/sales-order-create`} className="btn btn-sm btn-primary"><i className="fa fa-plus"></i>Create New Sales Order</Link>
                                </div>
                            </div>
                        </div>

                        <div className='card-body'>
                            <DataTable url={url} columns={columns} filters={filters} additionalPostData={additionalPostData} />
                        </div>
                    </div >

                    <ShipmentCreate show={createShipmentModalShow} handleClose={handleCreateShipmentModalClose} salesOrderInfo={salesOrderInfo} />
                </>
            }


            {/* {hasPermission === 0 &&
                <Error />
            }
            {hasPermission === 0 &&
                <div className='card card-custom' style={{ padding: "15px" }} >
                    <SkeletonTheme>
                        <p>
                            <Skeleton
                                containerClassName="avatar-skeleton"
                                count={40} ></Skeleton>
                        </p>
                    </SkeletonTheme>
                </div>
            } */}

        </>
    )
}

export default SalesOrder
