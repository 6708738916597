/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, FormEvent, ChangeEvent } from 'react'
import Swal from 'sweetalert2'
import { Modal, Button, Form } from 'react-bootstrap'
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper'
import validationErrorMsg from '../helpers/ValidationErrorMsg'
import Select from 'react-select'
import Spinner from 'react-bootstrap/Spinner'



function CompanyCreate({ show, handleClose, id }: { show: boolean, handleClose: any, id: number }) {

	const initialState = {
		companyName: '',
		companyAddress: '',
		companyWebsite: '',
		companyLogo: '',
		idCompanyCategories: '',
		internal: 'No',
	}

	const [categories, setCategories] = useState([]);
	const [category, setCategory] = useState({ label: 'Select Category', value: '' });
	const [company, setCompany] = useState(initialState);


	useEffect(() => {
		getAllCompanyCategories();
	}, [])

	useEffect(() => {
		setCompany(initialState);
		setCategory({ label: 'Select Category', value: '' });
	}, [id]);


	const [isSubmitDisable, setSubmitDisable] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.name === 'internal') {
			setCompany({ ...company, [e.target.name]: (e.target.value === 'No' ? 'Yes' : 'No') });
		} else {
			setCompany({ ...company, [e.target.name]: e.target.value });
		}

	};

	const handleCategoryChange = (category: any) => {
		setCategory(category);
		setCompany({ ...company, idCompanyCategories: category.id });
	}

	const getAllCompanyCategories = () => {
		fetch(
			process.env.REACT_APP_API_URL + 'companies/categories',
			getRequestOptions()
		)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				let companyCategories = resp.data.map((item: any) => {
					item.label = item.name;
					item.value = item.id;
					return item
				})
				setCategories(companyCategories);
			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})
	}

	const companySave = async (event: FormEvent<HTMLFormElement>) => {
		setLoading(true);
		event.preventDefault()
		setSubmitDisable(true);
		const confirm = await Swal.fire({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#ec008c',
			cancelButtonText: 'Cancel',
			confirmButtonText: 'Yes, Submit it!',
		})
		if (confirm.value) {
			const requestOptions = postRequestOptions(company)
			try {
				let response = await fetch(
					`${process.env.REACT_APP_API_URL}companies/store`,
					requestOptions
				)
				let respData = await response.json()
				if (respData.success === true) {
					const successMsg = respData.successMessage
					Swal.fire({
						icon: 'success',
						title: 'Success',
						text: successMsg,
					})
					handleClose(respData.success)
				} else {
					const errorsMessage = validationErrorMsg(respData.errors).join('<br>')
					Swal.fire({
						icon: 'error',
						title: 'Error',
						html: errorsMessage,
					})
				}
				setSubmitDisable(false);
				setLoading(false);
			} catch (error) {
				setLoading(false);
				console.log(error, 'catch the hoop')
				setSubmitDisable(false);
			}
		}
	}


	return (
		<Modal show={show} onHide={() => handleClose(false)} size="lg">
			<form onSubmit={companySave}>
				<Modal.Header closeButton>
					<Modal.Title>Create Client</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="form-group row">
						<label htmlFor="companyName" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Name <span className="required text-danger"></span></label>
						<div className="col-lg-5">
							<input type="text" name="companyName" id="companyName" value={company.companyName} onChange={handleChange} className="form-control form-control-sm" />
						</div>
					</div>
					<div className="form-group row">
						<label htmlFor="idCompanyCategories" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Category <span className="required text-danger"></span></label>
						<div className="col-lg-5">
							<Select
								value={category}
								onChange={handleCategoryChange}
								options={categories}
								menuPosition="fixed"
							/>
						</div>
					</div>
					<div className="form-group row">
						<label htmlFor="companyAddress" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Address</label>
						<div className="col-lg-5">
							<input type="text" name="companyAddress" id="companyAddress" value={company.companyAddress} onChange={handleChange} className="form-control form-control-sm" />
						</div>
					</div>
					<div className="form-group row">
						<label htmlFor="companyWebsite" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Website</label>
						<div className="col-lg-5">
							<input type="text" name="companyWebsite" id="companyWebsite" value={company.companyWebsite} onChange={handleChange} className="form-control form-control-sm" />
						</div>
					</div>
					<div className="form-group row">
						<label htmlFor="internal" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}></label>
						<div className="col-lg-5">
							<Form.Check // prettier-ignore
								type={"checkbox"}
								id={`default-checkbox-sc`}
								label={`Internal`}
								value={company.internal}
								className='me-10 form-check-solid'
								name="internal"
								checked={company.internal === 'Yes'}
								onChange={handleChange}
							/>
						</div>
					</div>
					{/* <pre>{JSON.stringify(company, null, 2)}</pre> */}
				</Modal.Body>
				<Modal.Footer>

					<button type="submit" disabled={isSubmitDisable || loading} className="btn btn-sm btn-primary me-2">
						{loading &&
							<Spinner
								as="span"
								animation="grow"
								size="sm"
								role="status"
								aria-hidden="true"
							/>
						}
						<span>  Submit</span>
					</button>
					<Button variant='secondary' size="sm" onClick={() => handleClose(false)}>
						Close
					</Button>
				</Modal.Footer>
			</form>
		</Modal>
	)
}

export default CompanyCreate
