/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { postRequestOptions } from "../helpers/Fetchwrapper";
import validationErrorMsg from "../helpers/ValidationErrorMsg";

interface Props {
	idProjects: number;
	operation: string;
	onCreate: any;
	editData?: any;
}

interface FormData {
	id?: number;
	stageName: string;
	remarks: string;
	serial: number;
	stageStatus: string;
}

const initialFormData: FormData = {
	stageName: '',
	remarks: '',
	serial: 0,
	stageStatus: ''
}

const StageCreate: React.FC<Props> = ({ idProjects, operation, onCreate, editData }) => {
	const [formData, setFormData] = useState<FormData>(initialFormData);

	useEffect(() => {
		if (Object.keys(editData).length !== 0) {
			// console.log(editData);
			setFormData({
				id: editData.id,
				stageName: editData.stageName,
				remarks: editData.remarks,
				serial: editData.serialNumber,
				stageStatus: editData.serialNumber
			})
		}
	}, [editData]);

	const handleSubmit = (evt: any) => {
		evt.preventDefault()

		let text = '';

		if (!formData.id) {
			text = 'You want to update this Category!'
		} else {
			text = 'You want to add this Category!'
		}
		Swal.fire({
			title: 'Are you sure?',
			text: text,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No',
			confirmButtonColor: '#ec008c'
		}).then((result) => {
			if (result.value) {
				const requestData = {
					idProjects,
					stageName: formData.stageName,
					remarks: formData.remarks,
					serialNumber: formData.serial
				}

				const requestOptions = postRequestOptions(requestData);

				if (formData.id) {
					var apiEnd = 'project-stage/update/' + formData.id
				} else {
					apiEnd = 'project-stage/create'
				}

				fetch(process.env.REACT_APP_API_URL + apiEnd, requestOptions)
					.then((resp) => {
						return resp.json()
					})
					.then((resp) => {
						if (resp.success === true) {
							if (formData.id) {
								var successMsg = `Stage ${resp.data.category_name} updated`
							} else {
								successMsg = `Stage ${resp.data.category_name} added`
							}

							Swal.fire({
								icon: 'success',
								title: 'Success',
								text: successMsg,
							})
							setFormData(initialFormData);
							onCreate(Math.random() * 100)
						} else {

							const errorsMessage = validationErrorMsg(resp.errors).join("<br>");
							Swal.fire({
								icon: 'error',
								title: 'Error',
								html: errorsMessage,
							})
						}
					})
					.catch((error) => {
						console.log(error, 'catch the hoop')
					})
			}
		})
	}

	return (
		<div className='card-body'>
			<div className='ml-12' >
				<form onSubmit={handleSubmit}>
					<div className='form-group row'>
						<label
							htmlFor='name'
							className='col-lg-3 col-form-label'
							style={{ textAlign: 'right' }}
						>
							Stage Name <span className='required text-danger'></span>
						</label>
						<div className='col-lg-5'>
							<input
								type='text'
								name='stageName'
								value={formData.stageName}
								onChange={(e) => setFormData({ ...formData, stageName: e.target.value })}
								className='form-control form-control-sm'
							/>
						</div>
					</div>


					<div className='form-group row'>
						<label
							htmlFor='remarks'
							className='col-lg-3 col-form-label'
							style={{ textAlign: 'right' }}
						>
							Remarks <span className='required text-danger'></span>
						</label>
						<div className='col-lg-5'>
							<input
								type='text'
								name='remarks'
								id='remarks'
								value={formData.remarks}
								onChange={(e) => setFormData({ ...formData, remarks: e.target.value })}
								className='form-control form-control-sm'
							/>
						</div>
					</div>

					<div className='form-group row'>
						<label
							htmlFor='remarks'
							className='col-lg-3 col-form-label'
							style={{ textAlign: 'right' }}
						>
							Serial <span className='required text-danger'></span>
						</label>
						<div className='col-lg-5'>
							<input
								type='number'
								name='serial'
								id='serial'
								value={formData.serial}
								onChange={(e) => setFormData({ ...formData, serial: Number(e.target.value) })}
								className='form-control form-control-sm'
							/>
						</div>
					</div>

					<div className='row'>
						{/* <div className='col-5'></div> */}
						<div className='col-8' style={{ textAlign: 'right' }}>
							<input
								type='submit'
								className='btn btn-sm btn-primary mt-2'
								value={operation !== 'create' ? 'Update' : 'Save'}
							/>

						</div>
					</div>
					{/* <pre>
            {JSON.stringify(name, null, 2)}
            {JSON.stringify(status, null, 2)}
          </pre> */}
				</form>
			</div>
			<ToastContainer />
		</div>
	)
}

export default StageCreate
