import { useState, useEffect, useCallback, useContext } from 'react'
import { AppContext } from '../context/AppContext';
import { KTSVG } from '../../_metronic/helpers'
import Swal from 'sweetalert2'
import BackendTable from 'react-backend-table';
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper';
import { Calendar, momentLocalizer, EventPropGetter } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Select from 'react-select'
import AddEvent from '../components/ActivityListAddEvent';
import { Form } from 'react-bootstrap';


interface MyEvent {
    id: number;
    title: string;
    start: Date;
    end: Date;
    backgroundColor: string;
    idLeads: number;
}


const Activities: React.FC = () => {

    // const { currentUser } = useContext(AppContext);

    useEffect(() => {
        getActivityCompanyUserList();
    }, [])

    const localizer = momentLocalizer(moment)

    const hasPermission = 1; //parseInt(GetPagePermission("/setup-company-list"));

    const [events, setEvents] = useState<MyEvent[]>([]);
    const [projects, setProjects] = useState([]);
    const [project, setProject] = useState({ label: 'All Project', value: '' });
    const [users, setUsers] = useState([]);
    const [user, setUser] = useState({ label: 'All SR', value: '' });
    let dd = new Date();
    const [date, setDate] = useState(dd.toISOString().split('T')[0]);

    const handleActivityStatus = async (idLeadActivities: number, status: string) => {

        if (status === 'done') {
            Swal.fire({
                title: 'Outcome / Discussion',
                input: 'textarea',
                inputAttributes: {
                    autocapitalize: 'off',
                    placeholder: 'Meeting minutes, discussion points, email body etc.'
                },
                showCancelButton: true,
                confirmButtonText: 'Submit',
                showLoaderOnConfirm: true,
                preConfirm: (content) => {
                    let postData = { idLeadActivities: idLeadActivities, status: status, content: content };
                    updateActivityStatus(postData);
                },
                allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
                if (result.isConfirmed) {
                    console.log("result.value = ", result.value);
                }
            })
        } else {
            updateActivityStatus({ idLeadActivities: idLeadActivities, status: status });
        }

    }

    const updateActivityStatus = async (postData: any) => {
        const requestOptions = postRequestOptions(postData);
        try {
            let response = await fetch(
                `${process.env.REACT_APP_API_URL}sr/dashboard/update-activity-status`,
                requestOptions
            )
            let respData = await response.json()
            Swal.close();

            if (respData.success === true) {
                options.reloadMyTable();
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    html: respData.errorMessage,
                })
            }

        } catch (error) {
            Swal.close();
        }
    }

    const getAllActivityEvents = useCallback((date: string) => {
        fetch(process.env.REACT_APP_API_URL + "activities?date=" + date + "&projectId=" + project.value + "&userId=" + user.value, getRequestOptions())
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                setEvents(resp.data);
            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });
    }, [project.value, user.value]);

    const getActivityCompanyUserList = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'getActivityCompanyUserList',
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {

                let projects = resp.projects.map((item: any) => {
                    item.label = item.project_name;
                    item.value = item.id_projects;
                    return item
                })

                let users = resp.users.map((item: any) => {
                    item.label = item.first_name + ' ' + item.last_name;
                    item.value = item.id;
                    return item
                })

                var dd = [{ id: '', first_name: '', last_name: '', label: 'All SR', value: '' }]
                let allUsers = users.concat(dd);

                if (resp.projects.length === 1) {
                    setProjects(resp.projects);

                } else {
                    var ff = [{ id: '', name: '', label: 'All Project', value: '' }]
                    let allProjects = projects.concat(ff);
                    setProjects(allProjects);
                }

                setUsers(allUsers);

            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const handleProjectChange = (project: any) => {
        setProject(project);
    }

    const handleUserChange = (user: any) => {
        setUser(user);
    }

    useEffect(() => {
        getAllActivityEvents(date);
    }, [project, user, getAllActivityEvents, date])

    useEffect(() => {
        if (projects.length === 1) {
            setProject({ label: projects[0]['label'], value: projects[0]['value'] })
        }
    }, [projects])


    const [activityListFormData, setActivityListFormData] = useState({
        fromDate: '',
        toDate: ''
    });

    let columns = [
        {
            title: 'Event',
            field: 'eventDescription',
            sortable: true,
            searchable: true,
            hasComponent: true,
            componentValue: ((rowValue: any) => {
                return (
                    <>
                        <a href={"/#/lead-details?idLeads=" + rowValue.idLeads}>
                            <div className='d-flex align-items-center'>
                                {/* <div className='symbol symbol-50px me-5'>
                                    <KTSVG path={iconArray[rowValue.activityType].icon} className='svg-icon-danger svg-icon svg-icon-2'></KTSVG>
                                </div> */}
                                <div className='d-flex justify-content-start flex-column'>
                                    <div className='text-dark fw-bold text-hover-primary fs-6'>
                                        {rowValue.activityDescription}
                                    </div>
                                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                        {(new Date(rowValue.createdAt)).toDateString()}, {(new Date(rowValue.createdAt)).toLocaleTimeString()}
                                    </span>
                                </div>
                            </div>
                        </a>
                    </>
                )
            })
        },
        {
            title: 'Type',
            field: 'activityType',
            sortable: true,
            searchable: true,
            hasComponent: true,
            componentValue: ((rowValue: any) => {
                return (
                    <div className='text-capitalize'>{rowValue.activityType}</div>
                )
            })
        },
        {
            title: 'Location',
            field: 'location',
            sortable: true,
            searchable: true,
            hasComponent: true,
            componentValue: ((rowValue: any) => {
                return (
                    <div>{rowValue.location !== null ? JSON.parse(rowValue.location).label : ''}</div>
                )
            })
        },
        {
            title: 'Lead',
            field: 'leadName',
            sortable: true,
            searchable: true,
            hasComponent: true,
            componentValue: ((rowValue: any) => {
                return (
                    <>
                        <a href={"/#/lead-details?idLeads=" + rowValue.idLeads}>
                            <div className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                {rowValue.leadName}
                            </div>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>{rowValue.companyCategoryName}</span>
                        </a>
                    </>
                )
            })
        },
        {
            title: 'Contact Person',
            field: 'contactPersonName',
            sortable: true,
            searchable: true,
            hasComponent: true,
            componentValue: ((rowValue: any) => {
                return (
                    <>
                        <div className='text-dark fw-bold text-hover-primary d-block fs-6'>
                            {rowValue.contactPersonName}
                        </div>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>{rowValue.contactPersonPhone}</span>
                    </>
                )
            })
        },
        {
            title: 'Assigned To',
            field: 'assignedToName',
            sortable: true,
            searchable: true,
            hasComponent: true,
            componentValue: ((rowValue: any) => {
                return (
                    <div>{rowValue.assignedToName}</div>
                );
            }),
        },
        {
            title: 'Action',
            field: 'idLeadActivities',
            sortable: false,
            searchable: false,
            hasComponent: true,
            componentValue: ((rowValue: any) => {

                if (rowValue.activityStatus === 'scheduled') {
                    return (
                        <>
                            <button type="button" className='btn btn-success  btn-sm fw-800 me-2' onClick={() => handleActivityStatus(rowValue.idLeadActivities, 'done')}>
                                Done
                                <KTSVG path='/media/svg/icons/Navigation/Double-check.svg' className='svg-icon-3 ms-2 me-0' />
                            </button>
                            <button type="button" className='btn btn-danger btn-sm fw-800' onClick={() => handleActivityStatus(rowValue.idLeadActivities, 'cancelled')}>
                                Cancel
                                <KTSVG path='/media/svg/icons/Code/Error-circle.svg' className='svg-icon-3 ms-2 me-0' />
                            </button>
                        </>
                    )
                } else {
                    return ''
                }
            })
        },
        // { title: 'Activity', field: 'activityDescription', sortable: true, searchable: true },
        // { title: 'Content', field: 'content', searchable: true, sortable: true },
        // { title: 'Project', field: 'lastActivity', sortable: true, searchable: true },
        // { title: 'Assigned To', field: 'assignedToName', sortable: true, searchable: true },
        // { title: 'Estimated Value', field: 'estimatedValue', sortable: true, searchable: false, thStyle: { textAlign: "center" }, tdStyle: { textAlign: "right" } },
    ]

    // if (currentUser && currentUser.id_roles !== 1) {
    //     // Define the additional column object
    //     const additionalColumn = {
    //         title: 'Assigned To',
    //         field: 'assignedToName',
    //         sortable: true,
    //         searchable: true,
    //         hasComponent: true,
    //         componentValue: ((rowValue: any) => {
    //             return (
    //                 <div>{rowValue.assignedToName}</div>
    //             );
    //         }),
    //     };

    //     // Find the index of the "Project" column
    //     const projectColumnIndex = columns.findIndex(column => column.title === 'Contact Person');

    //     // Insert the additional column after the "Project" column
    //     if (projectColumnIndex !== -1) {
    //         columns.splice(projectColumnIndex + 1, 0, additionalColumn);
    //     }
    // }

    const jwt = () => {
        const myToken = localStorage.getItem('MyToken');
        return (myToken != null) ? JSON.parse(myToken) : null;
    }

    let options = {
        title: '',
        url: process.env.REACT_APP_API_URL + 'activities',
        authorization: 'Bearer ' + jwt(),
        perPage: [10, 20, 50, 100, 500],
        orderBy: 'activityAt',
        orderType: 'desc',
        columnSearch: true,
        reloadMyTable: () => { },
        extraData: { fromDate: activityListFormData.fromDate ?? '', toDate: activityListFormData.toDate ?? '', pageName: 'activityList' },
    }

    const eventPropGetter: EventPropGetter<MyEvent> = (
        event,
        start,
        end,
        isSelected
    ) => {
        return {
            style: {
                backgroundColor: event.backgroundColor,
            },
        };
    };

    const [createEventModalShow, setCreateEventModalShow] = useState(false);

    const handleCreateEventModalShow = () => {
        setCreateEventModalShow(true);
    }

    const handleCreateEventModalClose = () => {
        setCreateEventModalShow(false);
        options.reloadMyTable();
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setActivityListFormData({ ...activityListFormData, [name]: value });
    };

    const showActivityDateFilter = async () => {
        options.reloadMyTable();
    }

    const { currentUser } = useContext(AppContext);

    return (
        <>

            {hasPermission === 1 &&
                <div className='card card-custom'>
                    <div className='card-header card-header-stretch overflow-auto'>
                        <div className='card-title'>
                            <h3 className='card-label'>Activities</h3>
                        </div>
                    </div>
                    <div className="card-body">
                        <Tabs
                            defaultActiveKey="calendar"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                        >
                            <Tab eventKey="calendar" title="Calendar View">

                                <div className='row'>
                                    {/* <pre>{JSON.stringify(currentUser.id_roles, null, 2)}</pre> */}
                                    <div className={(currentUser && currentUser.id_roles === 1) ? 'col-9' : 'col-6'}></div>
                                    <div className='col-3'>
                                        <Select
                                            value={project}
                                            onChange={handleProjectChange}
                                            options={projects}
                                        />
                                    </div>
                                    {(currentUser && currentUser.id_roles !== 1) &&
                                        <div className='col-3'>
                                            <Select
                                                value={user}
                                                onChange={handleUserChange}
                                                options={users}
                                            />
                                        </div>
                                    }
                                </div>

                                {/* <pre>{JSON.stringify(events, null, 2)}</pre> */}
                                <Calendar<MyEvent>
                                    localizer={localizer}
                                    events={events}
                                    startAccessor="start"
                                    endAccessor="end"
                                    style={{ height: 700 }}
                                    views={{ month: true }}
                                    popup
                                    eventPropGetter={eventPropGetter}
                                    onSelectEvent={(event) => {
                                        window.location.href = '#/lead-details?idLeads=' + event.idLeads;
                                    }}
                                    onNavigate={(date, view) => {
                                        setDate(date.toISOString().split('T')[0])
                                        getAllActivityEvents(date.toISOString().split('T')[0]);
                                    }}
                                />
                                <div className='row'>
                                    <div className='col-md-6' style={{ marginTop: "10px" }}>
                                        <span style={{ backgroundColor: '#2196F3', paddingLeft: '30px', marginRight: '5px' }}></span>
                                        <span>   Scheduled</span>
                                        <span style={{ backgroundColor: '#006a52', paddingLeft: '30px', marginLeft: '20px' }}></span>
                                        <span>  Done</span>
                                        <span style={{ backgroundColor: '#FF9800', paddingLeft: '30px', marginLeft: '20px' }}></span>
                                        <span>  Cancelled</span>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="datalist" title="List View">
                                <div className='card card-custom'>
                                    <div className='card-header'>
                                        <div className="card-title">
                                            <KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-1 svg-icon-primary' />
                                            <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
                                                Activity List
                                            </span>
                                        </div>
                                        <div className="card-toolbar">
                                            <button type="button" className="btn btn-sm btn-primary" onClick={() => handleCreateEventModalShow()}><i className="fa fa-plus"></i>Add Event</button>
                                        </div>
                                    </div>

                                    <div className='card-body'>
                                        {/* <pre>{JSON.stringify(columns, null, 2)}</pre> */}
                                        <div className="row">
                                            <div className="data-table col-md-12">
                                                <div className="row">
                                                    <div className='col-md-2'>
                                                        <Form.Group className="mb-3" controlId="project">
                                                            <Form.Label>From Date<span className='required'></span></Form.Label>
                                                            <Form.Control type="date" placeholder="From Date" name='fromDate' onChange={handleChange} />
                                                        </Form.Group>
                                                    </div>
                                                    <div className='col-md-2'>
                                                        <Form.Group className="mb-3" controlId="project">
                                                            <Form.Label>To Date<span className='required'></span></Form.Label>
                                                            <Form.Control type="date" placeholder="To Date" name='toDate' onChange={handleChange} />
                                                        </Form.Group>
                                                    </div>
                                                    <div className='col-md-2'>
                                                        <div className="form-control-sm ps-0 mt-7">
                                                            <button className='btn btn-sm btn-primary w-100 p-3' onClick={showActivityDateFilter}>
                                                                <span>Go</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <BackendTable columns={columns} options={options} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <AddEvent show={createEventModalShow} handleClose={handleCreateEventModalClose} id={Date.now()} />
                            </Tab>


                        </Tabs>
                    </div>
                </div>
            }

            {/* {hasPermission === 0 &&
                <Error />
            }
            {hasPermission === 0 &&
                <div className='card card-custom' style={{ padding: "15px" }} >
                    <SkeletonTheme>
                        <p>
                            <Skeleton
                                containerClassName="avatar-skeleton"
                                count={40} ></Skeleton>
                        </p>
                    </SkeletonTheme>
                </div>
            } */}

        </>
    )
}

export default Activities
