import React, { useState, useEffect } from 'react';
import { KTSVG } from '../../_metronic/helpers'

const PhoneForm = (props) => {

    const [phones, setPhones] = useState(props.phones?.length ? props.phones : [{  id: '', phoneNumber: '', phoneType: '' }]);
    useEffect(()=>{
        setPhones(props.phones?.length ? props.phones : [{  id: '', phoneNumber: '', phoneType: '' }])
    },[props.phones])
    const handlePhoneChange = (index, event) => {
      const { name, value } = event.target;
      const updatedPhones = [...phones];
      updatedPhones[index][name] = value;
      setPhones(updatedPhones);
      props.getPhonesData(updatedPhones);
    };
  
    const addPhoneField = () => {
        setPhones(phones.concat({  id: '', phoneNumber: '', phoneType: '' }));
        props.getPhonesData(phones.concat({  id: '', phoneNumber: '', phoneType: '' }));
    };
  
    const removePhoneField = (index) => {        
        const updatedPhones = [...phones];
        updatedPhones.splice(index, 1);
        setPhones(updatedPhones);
        props.getPhonesData(updatedPhones);
    };

    return (
        <>
            {phones.map((phone, index) => (
            <div key={index}>
                <div className="input-group mb-3">
                    <input type="text" className="form-control form-control-sm" name='phoneNumber' value={phone.phoneNumber == null ? '' : phone.phoneNumber} onChange={(event) => handlePhoneChange(index, event)} />
                    <select className="form-select form-select-sm" name='phoneType' value={phone.phoneType == null ? '' : phone.phoneType} onChange={(event) => handlePhoneChange(index, event)}>
                        <option value="">Select Type</option>
                        <option value="Home">Home</option>
                        <option value="Work">Work</option>
                        <option value="Mobile">Mobile</option>
                        <option value="Other">Other</option>
                    </select>
                    {index === (phones.length - 1) &&
                        <button type="button" className="btn btn-icon btn-light btn-active-light-primary  btn-sm me-1" onClick={addPhoneField}>
                            <KTSVG path='/media/svg/icons/Navigation/Plus.svg' className='svg-icon svg-icon-5' />
                        </button>
                    }

                    {phones.length > 1 &&
                        <button type="button" className="btn btn-icon btn-light btn-active-light-danger btn-sm" onClick={() => removePhoneField(index)}>
                            <KTSVG path='/media/svg/icons/General/Trash.svg' className='svg-icon svg-icon-5' />
                        </button>
                    }
                </div>
            </div>
           
            ))}
       </>
    );
  };
  
  export default PhoneForm;
  
