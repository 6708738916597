/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import { Modal, Button } from 'react-bootstrap'
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper'
import validationErrorMsg from '../helpers/ValidationErrorMsg'
import Spinner from 'react-bootstrap/Spinner'
import Select from 'react-select'

interface TargetCreateProps {
	show: boolean;
	handleClose: any;
	id: number;
}

interface InitialState {
	idTargets: number | null;
	idProjects: number | null;
	idSrCategories: number | null;
	idSr: number | null;
	idProducts: number | null;
	targetBy: string;
	targetType: string;
	year: number;
	month: number;
	quarter: number;
	target: number | null;
}


const TargetCreate: React.FC<TargetCreateProps> = ({ show, handleClose, id }) => {

	const [products, setProducts] = useState([]);
	const [product, setProduct] = useState({ label: 'Select Product', value: '' });
	const [projects, setProjects] = useState([]);
	const [project, setProject] = useState({ label: 'Select Project', value: '' });
	const [salesRepresentatives, setSalesRepresentatives] = useState([]);
	const [salesRepresentative, setSalesRepresentative] = useState({ label: 'All SR', value: '' });
	const [categories, setCategories] = useState([]);
	const [category, setCategory] = useState({ label: 'Select SR Category', value: '' });
	const [targetBy, setTargetBy] = useState('Amount');
	const initialState: InitialState = {
		idTargets: null,
		idProjects: null,
		idSr: null,
		idSrCategories: null,
		idProducts: null,
		targetBy: '',
		targetType: '',
		year: 0,
		month: 0,
		quarter: 0,
		target: null,
	};

	const [item, setItem] = useState(initialState);

	useEffect(() => {
		getProjects()
	}, [])

	useEffect(() => {
		if (id === 0) {
			setItem({ idTargets: null, idProjects: null, idSr: null, idSrCategories: null, idProducts: null, targetBy: 'Amount', targetType: 'Yearly', year: getCurrentYear(), month: 1, quarter: 1, target: null });
			setProject({ label: 'Select Project', value: '' })
			setSalesRepresentative({ label: 'All SR', value: '' })
			setProduct({ label: 'Any Product', value: '' })
		} else {
			fetch(
				process.env.REACT_APP_API_URL + 'targets/' + id,
				getRequestOptions()
			)
				.then((resp) => {
					return resp.json()
				})
				.then((resp) => {
					setItem({ idTargets: resp.data.id_targets, idSrCategories: resp.data.id_sr_categories, idProjects: resp.data.id_projects, idSr: resp.data.id_sr, idProducts: resp.data.id_products, targetBy: resp.data.target_by, targetType: resp.data.target_type, year: resp.data.year, month: resp.data.month, quarter: resp.data.quarter, target: resp.data.target });
					setProject({ label: resp.data.projectName, value: resp.data.id_projects })
					setSalesRepresentative({ label: resp.data.srName, value: resp.data.id_sr })
					setProduct({ label: resp.data.productName, value: resp.data.id_products })
					setTargetBy(resp.data.target_by);
				})
				.catch((error) => {
					console.log(error, 'catch the hoop')
				})
		}
	}, [id]);

	const handleProductChange = (product: any) => {
		setProduct(product);
		if (product.id_products === null) {
			setTargetBy('Amount');
		}
		setItem({ ...item, idProducts: product.id_products, targetBy: (product.id_products === null) ? 'Amount' : 'Quantity' });
	}

	const getAllProducts = (idProjects: any) => {

		fetch(
			process.env.REACT_APP_API_URL + 'getAllProducts?idProjects=' + idProjects,
			getRequestOptions()
		)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {

				let products = resp.map((item: any) => {
					item.label = item.product_name + ' (Size:' + item.packing + ')';
					item.value = item.id_products;
					return item
				})

				var allProductOpt = [{ id_products: null, name: '', label: 'Any Product', value: null }]
				let allProducts = allProductOpt.concat(products);
				setProducts(allProducts);
			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})
	}

	const getProjects = () => {
		fetch(
			process.env.REACT_APP_API_URL + 'lead/projects',
			getRequestOptions()
		)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				let projectObj = resp.data.map((item: any) => {
					item.label = item.name;
					item.value = item.id;
					return item
				})
				setProjects(projectObj);
			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})
	}

	const getAllSr = (idProjects: any) => {
		fetch(
			process.env.REACT_APP_API_URL + 'getAllSr?idProjects=' + idProjects,
			getRequestOptions()
		)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				let userObj = resp.map((item: any) => {
					item.label = item.first_name + ' ' + item.last_name;
					item.value = item.id;
					return item
				})

				var allSrOpt = [{ id: null, name: '', label: 'All SR', value: null }]
				let allSr = allSrOpt.concat(userObj);
				setSalesRepresentatives(allSr);

				// setSalesRepresentatives(userObj);
			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})
	}

	const getAllSrCategory = (idProjects: any) => {
		fetch(
			process.env.REACT_APP_API_URL + 'allSrCategory?idProjects=' + idProjects,
			getRequestOptions()
		)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				let itemObj = resp.map((item: any) => {
					item.label = item.sr_category_name;
					item.value = item.id_sr_categories;
					return item
				})

				var allCategoryOpt = [{ id: null, name: '', label: '--Select SR Category--', value: null }]
				let allCategory = allCategoryOpt.concat(itemObj);
				setCategories(allCategory);

				// setSalesRepresentatives(userObj);
			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})
	}

	const [isSubmitDisable, setSubmitDisable] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleChange = (e: any) => {
		setItem({ ...item, [e.target.name]: e.target.value });
	};

	const itemSave = async (event: any) => {
		event.preventDefault()
		setSubmitDisable(true);
		setLoading(true);
		const confirm = await Swal.fire({
			title: 'Are you sure?',
			text: "You want to " + (id === 0 ? "set" : "update") + " this Target?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#ec008c',
			cancelButtonText: 'Cancel',
			confirmButtonText: 'Yes, Submit it!',
		})
		if (confirm.value) {
			const requestOptions = postRequestOptions(item)
			try {
				// let url = (id === 0) ? `${process.env.REACT_APP_API_URL}targets/store` : `${process.env.REACT_APP_API_URL}targets/update`
				let url = `${process.env.REACT_APP_API_URL}targets/store`
				let response = await fetch(
					url,
					requestOptions
				)
				let respData = await response.json()
				if (respData.success === true) {
					const successMsg = respData.successMessage
					Swal.fire({
						icon: 'success',
						title: 'Success',
						text: successMsg,
					})
					handleClose(respData.success)
				} else {
					const errorsMessage = validationErrorMsg(respData.errors).join('<br>')
					Swal.fire({
						icon: 'error',
						title: 'Error',
						html: errorsMessage,
					})
				}
				setSubmitDisable(false);
				setLoading(false);
			} catch (error) {
				console.log(error, 'catch the hoop')
				setSubmitDisable(false);
			}
		}
	}

	const handleProjectChange = (project: any) => {
		// console.log(project)
		setProject(project);
		setItem({ ...item, idProjects: project.id });
		getAllProducts(project.id)
		getAllSr(project.id)
		getAllSrCategory(project.id)
	}

	const handleSRChange = (sr: any) => {
		setSalesRepresentative(sr);
		setItem({ ...item, idSr: sr.id });
	}

	const handleCategoryChange = (category: any) => {
		setCategory(category);
		setItem({ ...item, idSrCategories: category.id_sr_categories });
	}

	const handleTargetByChange = (e: any) => {
		setTargetBy(e.target.value);
		setItem({ ...item, [e.target.name]: e.target.value });
	};

	// Define a type for the year
	type YearOption = {
		value: string;
		name: string;
	};

	const getCurrentYear = (): number => {
		return new Date().getFullYear();
	};

	const generateYearOptions = (): YearOption[] => {
		const currentYear = getCurrentYear();
		const yearOptions: YearOption[] = [];
		for (let i = 0; i < 5; i++) {
			const year = currentYear + i;
			yearOptions.push({ value: year.toString(), name: year.toString() });
		}
		return yearOptions;
	};

	const yearOptions: YearOption[] = generateYearOptions();


	return (
		<Modal show={show} onHide={() => handleClose(false)} size="lg">
			<form onSubmit={itemSave}>
				<Modal.Header closeButton>
					<Modal.Title>{id === 0 ? 'Set New ' : 'Edit'} Target</Modal.Title>
				</Modal.Header>
				<Modal.Body>

					<div className="form-group row">
						<label htmlFor="targetType" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Target Type <span className="required text-danger"></span></label>
						<div className="col-lg-5">
							<select id='targetType' name='targetType' className='form-control' onChange={handleChange} >
								<option value={'Yearly'}>Yearly</option>
								<option value={'Quarterly'}>Quarterly</option>
								<option value={'Monthly'}>Monthly</option>
							</select>
						</div>
					</div>

					<div className="form-group row">
						<label htmlFor="year" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Select Year <span className="required text-danger"></span></label>
						<div className="col-lg-5">
							<select id='year' name='year' className='form-control' onChange={handleChange} >
								{yearOptions.map((year) => (
									<option key={year.value} value={year.value}>
										{year.name}
									</option>
								))}
							</select>
						</div>
					</div>

					{item.targetType === 'Monthly' &&
						<div className="form-group row">
							<label htmlFor="month" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Select Month <span className="required text-danger"></span></label>
							<div className="col-lg-5">
								<select id='month' name='month' className='form-control' onChange={handleChange} >
									<option value={'1'}>January</option>
									<option value={'2'}>February</option>
									<option value={'3'}>March</option>
									<option value={'4'}>April</option>
									<option value={'5'}>May</option>
									<option value={'6'}>June</option>
									<option value={'7'}>July</option>
									<option value={'8'}>August</option>
									<option value={'9'}>September</option>
									<option value={'10'}>October</option>
									<option value={'11'}>November</option>
									<option value={'12'}>December</option>
								</select>
							</div>
						</div>
					}
					{item.targetType === 'Quarterly' &&
						<div className="form-group row">
							<label htmlFor="quarter" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Select Month <span className="required text-danger"></span></label>
							<div className="col-lg-5">
								<select id='quarter' name='quarter' className='form-control' onChange={handleChange} >
									<option value={'1'}>First Quarter (January - March)</option>
									<option value={'2'}>Second Quarter (April - June)</option>
									<option value={'3'}>Third Quarter (July - September)</option>
									<option value={'4'}>Fourth Quarter (October - December)</option>
								</select>
							</div>
						</div>
					}

					<div className="form-group row">
						<label htmlFor="idProjects" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Select Project <span className="required text-danger"></span></label>
						<div className="col-lg-5">
							<Select
								value={project}
								onChange={handleProjectChange}
								options={projects}
								menuPosition="fixed"
							/>
						</div>
					</div>

					<div className="form-group row">
						<label htmlFor="idSrCategories" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Select SR Category</label>
						<div className="col-lg-5">
							<Select
								value={category}
								onChange={handleCategoryChange}
								options={categories}
								menuPosition="fixed"
							/>
						</div>
					</div>

					{(item.idSrCategories === undefined || item.idSrCategories === null) &&
						<>
							<div className="form-group row">
								<label htmlFor="idSr" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Select SR</label>
								<div className="col-lg-5">
									<Select
										value={salesRepresentative}
										onChange={handleSRChange}
										options={salesRepresentatives}
										menuPosition="fixed"
									/>
								</div>
							</div>

							<div className="form-group row">
								<label htmlFor="idProducts" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Product </label>
								<div className="col-lg-5">
									<Select
										value={product}
										onChange={handleProductChange}
										options={products}
										menuPosition="fixed"
									/>
								</div>
							</div>
						</>
					}

					{item.idProducts !== null &&
						<div className="form-group row">
							<label htmlFor="targetBy" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Target By <span className="required text-danger"></span></label>
							<div className="col-lg-5">

								<label>
									<input
										name="targetBy"
										type="radio"
										value="Amount"
										checked={targetBy === 'Amount'}
										onChange={handleTargetByChange}
									/>
									&nbsp;Amount
								</label>
								&nbsp;&nbsp;&nbsp;&nbsp;

								<label>
									<input
										name="targetBy"
										type="radio"
										value="Quantity"
										checked={targetBy === 'Quantity'}
										onChange={handleTargetByChange}
									/>
									&nbsp;Quantity
								</label>
							</div>
						</div>
					}
					<div className="form-group row">
						<label htmlFor="target" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Target {targetBy === 'Amount' ? 'Amount' : 'Quantity'} <span className="required text-danger"></span></label>
						<div className="col-lg-5">
							<input type="number" name="target" id="target" value={item.target} onChange={handleChange} className="form-control form-control-sm" />
						</div>
					</div>

					<pre>{JSON.stringify(item, null, 2)}</pre>
				</Modal.Body>
				<Modal.Footer>

					<button type="submit" disabled={isSubmitDisable || loading} className="btn btn-sm btn-primary me-2">
						{loading &&
							<Spinner
								as="span"
								animation="grow"
								size="sm"
								role="status"
								aria-hidden="true"
							/>
						}
						<span>  Submit</span>
					</button>
					<Button variant='secondary' size="sm" onClick={() => handleClose(false)}>
						Close
					</Button>
				</Modal.Footer>
			</form>
		</Modal>
	)
}

export default TargetCreate
