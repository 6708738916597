import React from 'react';
import { Table, Form } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import Select from 'react-select';
import { postRequestOptions, getRequestOptions } from "../helpers/Fetchwrapper";
import { Card } from 'react-bootstrap-v5';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { numberFormat } from '../helpers/GlobalHelper';

interface SalesData {
    months: {
        [month: string]: {
            items: {
                productName: string;
                packing: string;
                unitName: string;
                deliveredQuantity: number;
                deliveredAmount: number;
                remarks: string;
            }[];
            totalSales: number;
        };
    };
    grandTotalSales: number;
}

const ProductWiseSalesReport: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [salesData, setSalesData] = useState<SalesData | null>(null);  // Initialize as null
    const [formData, setFormData] = useState({
        idProjects: '',
        fromDate: '',
        toDate: '',
    });

    const [projects, setProjects] = useState([]);
    const [project, setProject] = useState({ label: 'Select Project', value: '' });

    useEffect(() => {
        getProjects();
    }, []);

    const getProjects = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'lead/projects',
            getRequestOptions()
        )
            .then((resp) => resp.json())
            .then((resp) => {
                let projectObj = resp.data.map((item: any) => ({
                    label: item.name,
                    value: item.id,
                }));
                setProjects(projectObj);
            })
            .catch((error) => {
                console.error('Error fetching projects:', error);
            });
    };

    const handleProjectChange = (project: any) => {
        setProject(project);
        setFormData({ ...formData, idProjects: project ? project.value : '' });
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleViewReport = async () => {
        setLoading(true);
        try {
            const response = await fetch(
                process.env.REACT_APP_API_URL + 'reports/product-wise-sales',
                postRequestOptions(formData)
            );
            const data = await response.json();
            if (data.success) {
                setSalesData(data.data);
            } else {
                setSalesData(null);
                for (const i in data.errors) {
                    toast(<strong>{data.errors[i].join(' ')}</strong>, {
                        type: 'error',
                        position: 'bottom-right',
                        autoClose: 4000,
                    });
                }
            }
        } catch (error) {
            console.error('Error submitting data:', error);
        } finally {
            setLoading(false);
        }
    };

    const queryParams = new URLSearchParams(formData);

    
    return (
        <>
            <Card className='mb-4'>
                <Card.Body>
                    <Card.Text>
                        <div className='row'>
                            <div className='col-md-3'>
                                <Form.Group className="mb-3" controlId="project">
                                    <Form.Label>Project<span className='required'></span></Form.Label>
                                    <Select
                                        value={project}
                                        onChange={handleProjectChange}
                                        options={projects}
                                        menuPosition="fixed"
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        isClearable={true}
                                    />
                                </Form.Group>
                            </div>
                            <div className='col-md-2'>
                                <Form.Group className="mb-3">
                                    <Form.Label>From Date<span className='required'></span></Form.Label>
                                    <Form.Control type="date" placeholder="From Date" name='fromDate' onChange={handleChange} />
                                </Form.Group>
                            </div>
                            <div className='col-md-2'>
                                <Form.Group className="mb-3">
                                    <Form.Label>To Date<span className='required'></span></Form.Label>
                                    <Form.Control type="date" placeholder="To Date" name='toDate' onChange={handleChange} />
                                </Form.Group>
                            </div>
                            <div className='col-md-3'>
                                <div className="form-control-sm ps-0 mt-7">
                                    <button className='btn btn-sm btn-primary p-3' onClick={handleViewReport} disabled={loading}>
                                        {loading && <span className="spinner-border spinner-border-sm"></span>}
                                        <span><i className='fas fa-eye'></i> View Report</span>
                                    </button>
                                    <a className="btn btn-sm btn-primary p-3 ms-3" href={`${process.env.REACT_APP_BACKEND}product_wise_sales_report_download?${queryParams.toString()}`}><i className='fas fa-download'></i> Excel Download</a>
                                    <a className="btn btn-sm btn-primary p-3 ms-3" href={`${process.env.REACT_APP_BACKEND}product_wise_sales_report_print?${queryParams.toString()}`}><i className='fas fa-print'></i> Print</a>
                                </div>
                            </div>
                        </div>
                    </Card.Text>
                </Card.Body>
            </Card>

            <div className='row'>
                <div className='col-12'>
                    {salesData ? (
                        <Table responsive striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Month</th>
                                    <th>Product</th>
                                    <th>Packing</th>
                                    <th>Sales Qty</th>
                                    <th>Sales Value</th>
                                    <th>Remarks</th>
                                </tr>
                            </thead>
                            <tbody>
                                
                                {Object.keys(salesData.months).map((month, monthIndex) => (
                                    <React.Fragment key={monthIndex}>
                                        {salesData.months[month].items.map((item, itemIndex) => (
                                            <tr key={`${monthIndex}-${itemIndex}`}>
                                                {itemIndex === 0 && (
                                                    <td rowSpan={salesData.months[month].items.length}>
                                                        {month}
                                                    </td>
                                                )}
                                                <td>{item.productName}</td>
                                                <td>{item.packing}</td>
                                                <td className='text-end'>{numberFormat(item.deliveredQuantity)}</td>
                                                <td className='text-end'>{numberFormat(item.deliveredAmount)}</td>
                                                <td>{item.remarks ?? ''}</td>
                                            </tr>
                                        ))}
                                        {salesData.months[month].items.length>0 && (
                                            <tr key={`monthly-total-${monthIndex}`}>
                                                <td colSpan={4} className='text-end'><strong>Monthly Total:</strong></td>
                                                <td className='text-end'><strong>{numberFormat(salesData.months[month].totalSales)}</strong></td>
                                                <td></td>
                                            </tr>
                                        )}
                                    </React.Fragment>
                                ))}
                                <tr key={`grand-total`}>
                                    <td colSpan={4} className='text-end'><strong>Grand Total:</strong></td>
                                    <td className='text-end'><strong>{numberFormat(salesData.grandTotalSales)}</strong></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </Table>

                    ) : (
                        <div>No data available</div>
                    )}
                </div>
            </div>
        </>
    );
};

export default ProductWiseSalesReport;