/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react'
import { AppContext } from '../context/AppContext'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ProductGroupList from '../components/ProductGroupList';
import ProductCategoryList from '../components/ProductCategoryList';
import UnitList from '../components/UnitList';
import ProductList from '../components/ProductList';
import CompetitorList from '../components/CompetitorList';
import CompetitorProduct from '../components/CompetitorProduct';
import Error from '../components/Error500'

const ManageProductsView = () => {
    const { currentUser } = useContext(AppContext);

    useEffect(() => {

    }, [currentUser])


    return (
        <>
            {(currentUser?.id === 3 || currentUser?.id === 4 || currentUser?.id === 5 || currentUser?.id === 21) &&
                <div className='card card-custom'>
                    <div className='card-header card-header-stretch overflow-auto'>
                        <div className='card-title'>
                            <h3 className='card-label'>Manage Products</h3>
                        </div>
                    </div>
                    <div className="card-body">

                        <Tabs
                            defaultActiveKey="product"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                        >
                            <Tab eventKey="product" title="Product">
                                <ProductList></ProductList>
                            </Tab>
                            <Tab eventKey="item-category" title="Product Group">
                                <ProductGroupList></ProductGroupList>
                            </Tab>
                            <Tab eventKey="product-category" title="Product Category">
                                <ProductCategoryList></ProductCategoryList>
                            </Tab>
                            <Tab eventKey="unit" title="Unit">
                                <UnitList></UnitList>
                            </Tab>
                            <Tab eventKey="competitor" title="Competitor">
                                <CompetitorList></CompetitorList>
                            </Tab>
                            <Tab eventKey="competitor-product" title="Competitor Product">
                                <CompetitorProduct></CompetitorProduct>
                            </Tab>

                        </Tabs>
                    </div>
                </div>
            }
            {(currentUser?.id !== 3 && currentUser?.id !== 4 && currentUser?.id !== 5 && currentUser?.id !== 21) &&
                <Error />
            }

            <style>
                {`
    .react-bootstrap-tabs .nav-link {
      padding: 1000px;
    }
  `}
            </style>
        </>

    )
}

export default ManageProductsView
