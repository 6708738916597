import { KTSVG } from "../../_metronic/helpers"

interface IconArrayType {
    [key: string]: {
        icon: string,
        text: string
    }
}

export const iconArray: IconArrayType = {
    'call': {
        icon: "/media/svg/icons/Communication/Active-call.svg",
        text: "Call"
    },
    'meeting': {
        icon: "/media/svg/icons/Home/Clock.svg",
        text: "Meeting"
    },
    'sms': {
        icon: "/media/svg/icons/Communication/Chat6.svg",
        text: "SMS"
    },
    'email': {
        icon: "media/svg/icons/Communication/Urgent-mail.svg",
        text: "Email",
    },
    'deadline': {
        icon: "/media/svg/icons/Communication/Urgent-mail.svg",
        text: "Deadline",
    },
    'other': {
        icon: "/media/svg/icons/Communication/Dial-numbers.svg",
        text: "Other",
    },
    'created': {
        icon: "/media/svg/icons/General/Thunder-move.svg",
        text: "Created",
    },
    'comment': {
        icon: "/media/svg/icons/Communication/Chat6.svg",
        text: "Comment",
    },
    'attachment': {
        icon: "/media/svg/icons/General/Attachment1.svg",
        text: "Attachment",
    },
    'visit': {
        icon: "/media/svg/icons/Navigation/Angle-double-right.svg",
        text: "Visit",
    },
}

const getExtensionName = (fileName: string) => {
    return fileName.split('.').pop();
}

const getReadableFileSizeString = (fileSizeInBytes: number) => {
    return (fileSizeInBytes / (1024 * 1024)).toFixed(2) + " MB";
}

export default function LeadActivities({ activities }: { activities: any[] }) {
    return (<>
        <div id="kt_activity_today" className="card-body p-0 tab-pane fade active show" role="tabpanel"
            aria-labelledby="kt_activity_today_tab">
            <div className="timeline">
                {
                    activities?.map((activity, index) => {
                        return <div key={'la_' + index} className="timeline-item">
                            <div className="timeline-line w-40px"></div>
                            <div className="timeline-icon symbol symbol-circle symbol-40px me-4">
                                <div className="symbol-label bg-light p-1">
                                    <span className="svg-icon svg-icon-primary svg-icon-2x">
                                        <KTSVG path={iconArray[activity.activityType.toString()].icon} />
                                    </span>
                                </div>
                            </div>
                            <div className="timeline-content mb-10 mt-n1">
                                <div className="pe-3 mb-5">
                                    <div className="fs-5 fw-semibold mb-2">{activity.activityDescription} </div>
                                    {activity.content &&
                                        <div className="86-semibold text-gray-700 ps-3 fs-7">
                                            <KTSVG path="media/svg/icons/Design/Join-1.svg" className="svg-icon-4 svg-icon-success me-1" />
                                            {activity.content}
                                        </div>
                                    }
                                    {activity.location &&
                                        <div className="86-semibold text-gray-700 ps-3 fs-7">
                                            <KTSVG path="media/svg/icons/Map/Marker1.svg" className="svg-icon-4 svg-icon-success me-1" />
                                            {JSON.parse(activity.location).label}
                                        </div>
                                    }
                                    <div className="d-flex align-items-center mt-1 fs-6 border-left">
                                        <div className="text-muted mt-2 fs-7">Added on {new Date(activity.activityAt).toDateString()}, {new Date(activity.activityAt).toLocaleTimeString()} by <b><a href="/">{activity.user.name}</a></b></div>
                                    </div>

                                    {
                                        activity.activityType === 'attachment' && Array.isArray(activity.attachments) && <div className="overflow-auto">
                                            <div className="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-5">
                                                {
                                                    activity.attachments.map((attachment: any) => {
                                                        return <div className="d-flex flex-aligns-center pe-10 pe-lg-20">
                                                            <img alt="" className="w-30px me-3" src={"/media/svg/files/" + getExtensionName(attachment.attachmentName) + ".svg"} />
                                                            <div className="ms-1 fw-semibold">
                                                                <a href={process.env.REACT_APP_AWS_URL + "/leads/" + attachment.attachmentPath}
                                                                    className="fs-6 text-hover-primary fw-bold">{attachment.attachmentName}</a>
                                                                <div className="text-gray-400">{getReadableFileSizeString(attachment.fileSize)}</div>
                                                            </div>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
    </>)
}