import { useEffect, useState } from 'react'
import { KTSVG } from '../../_metronic/helpers'
import BankCreate from '../components/BankCreate';
import { deleteRequestOptions, getRequestOptions } from "../helpers/Fetchwrapper";
import { ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';

interface Bank {
    idBanks: number;
    bankNameShort: string;
    bankNameFull: string;
}

function BankList() {
    const [createBankModalShow, setCreateBankModalShow] = useState(false);
    const [bankList, setBankList] = useState<Bank[]>([]);
    const [bankSearch, setBankSearch] = useState("");

    const handleCreateBankModalShow = () => {
        setCreateBankModalShow(true);
    }

    const handleCreateBankModalClose = () => {
        setCreateBankModalShow(false);
    }

    const getBankList = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + 'banks', getRequestOptions())

            const data = await response.json();

            if (data.success) {
                setBankList(data.data);
            } else {
                // toast(data.errors, { type: 'error' });
            }
        } catch (error) {
            console.error('Error getting bank list:', error);
        }
    }

    const deleteBank = (id: number) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You will not be able to recover this bank!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it',
            showLoaderOnConfirm: true,
            customClass: {
                confirmButton: 'btn btn-danger me-2',
                cancelButton: 'btn btn-secondary'
            },
            preConfirm: () => {
                return fetch(process.env.REACT_APP_API_URL + 'banks/' + id, deleteRequestOptions())
                    .then(response => {
                        if (!response.ok) {
                            throw new Error(response.statusText)
                        }
                        return response.json()
                    })
                    .catch(error => {
                        Swal.showValidationMessage(
                            `Request failed: ${error}`
                        )
                    })
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.value.success) {
                    Swal.fire(
                        'Deleted!',
                        'Bank has been deleted.',
                        'success'
                    )
                    getBankList();
                } else {
                    Swal.fire(
                        'Error!',
                        result.value.errors,
                        'error'
                    )
                }
            }
        })
    }

    useEffect(() => {
        getBankList();
    }, [])


    return (
        <>
            <div className='card card-custom'>
                <div className='card-header'>
                    <div className="card-title">
                        <KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-1 svg-icon-primary' />
                        <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
                            Bank List
                        </span>
                    </div>
                    <div className="card-toolbar">
                        <div className='d-flex justify-content-end align-items-center'>
                            <input style={{ height: "26px" }} type="text" className="form-control form-control-sm me-2" placeholder="Search banks..." onChange={(e) => setBankSearch(e.target.value)} />
                        </div>
                        <div className='d-flex justify-content-end'>
                            <button type="button" className="btn btn-sm btn-primary" onClick={() => handleCreateBankModalShow()}><i className="fa fa-plus"></i>Add Bank</button>
                        </div>
                    </div>
                </div>

                <div className='card-body pt-0'>

                    <table className='table table-hover table-striped table-bordered'>
                        <thead>
                            <tr>
                                <th>SN</th>
                                <th>Bank Name (Short)</th>
                                <th>Bank Name (Full)</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {bankList.map((bank) => {
                                if (!bankSearch ||
                                    bank.bankNameShort.toLowerCase().indexOf(bankSearch.toLowerCase()) !== -1 ||
                                    bank.bankNameFull.toLowerCase().indexOf(bankSearch.toLowerCase()) !== -1
                                ) {
                                    return (
                                        <tr key={bank.idBanks}>
                                            <td>{bank.idBanks}</td>
                                            <td>{bank.bankNameShort}</td>
                                            <td>{bank.bankNameFull}</td>
                                            <td>
                                                <div className='d-flex justify-content-center'>
                                                    <button type="button" className="btn btn-sm btn-primary me-2"><i className="fa fa-edit"></i> Edit</button>
                                                    <button onClick={() => { deleteBank(bank.idBanks) }} type="button" className="btn btn-sm btn-secondary"><i className="fa fa-trash"></i>Delete</button>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }
                                return null;
                            })}
                        </tbody>
                    </table>
                </div>
            </div>

            <BankCreate getBankList={getBankList} show={createBankModalShow} handleClose={handleCreateBankModalClose} id={Date.now()} />
            <ToastContainer />
        </>
    )
}

export default BankList