import React from 'react';
import { Table, Form  } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import Select from 'react-select';
import { postRequestOptions, getRequestOptions} from "../helpers/Fetchwrapper";
import { Card } from 'react-bootstrap-v5';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Define a type for the sales data
interface SalesData {
  name: string;
  monthlySales: Record<string, number>;
  total: number;
  monthlyAverage: number;
};

interface ExpenseReportProps {

}

const ExpenseReport: React.FC<ExpenseReportProps> = (props) => {

    const [loading, setLoading] = useState(false);
    const [salesData, setSalesData] = useState<SalesData[]>([]);
    const [formData, setFormData] = useState({
        idProjects: '',
        expenseTypes: [],
        fromDate: '',
        toDate: '',
    });

    const [projects, setProjects] = useState([]);
	const [project, setProject] = useState({ label: 'Select Project', value: '' });

    const [expenseCategories, setExpenseCategories] = useState([]);
	const [expenseCategory, setExpenseCategory] = useState(null);
    const [months, setMonths] = useState([]);
    
    const generateMonths = () => {
        const start = new Date(formData.fromDate);
        const end = new Date(formData.toDate);
        let current = new Date(start);
        let monthList: string[] = [];

        while (current <= end) {
            monthList.push(current.toLocaleDateString('en-US', { month: 'long', year: 'numeric' }));
            current.setMonth(current.getMonth() + 1);
        }

        return monthList;
    };

    useEffect(() => {
        getProjects();
        getExpenseCategory();
    }, [])

    const getProjects = () => {
		fetch(
			process.env.REACT_APP_API_URL + 'lead/projects',
			getRequestOptions()
		)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				let projectObj = resp.data.map((item: any) => {
					item.label = item.name;
					item.value = item.id;
					return item
				})
				setProjects(projectObj);
			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})
	}

    const getExpenseCategory = () => {
		fetch(
			process.env.REACT_APP_API_URL + 'expense/expense-category',
			getRequestOptions()
		)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				let expenseCategoryObj = resp.data.map((item: any) => {
					item.label = item.expenseCategory;
					item.value = item.expenseCategory;
					return item
				})
				setExpenseCategories(expenseCategoryObj);
			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})
	}

    const handleProjectChange = (project: any) => {
		setProject(project);
        if(project){
            setFormData({ ...formData, idProjects: project.value });
        }else{
		    setFormData({ ...formData, idProjects: '' });
        }
	}

    const handleExpenseCategoryChange = (selectedOptions: any[]) => {
        setExpenseCategory(selectedOptions);
        const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
        setFormData({ ...formData, expenseTypes: selectedValues });
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const numberFormat = (value: number) => {
        return parseFloat(value.toString()).toLocaleString('en-US', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }
 

    const handleViewReport = async () => {
        setLoading(true);
        setMonths(generateMonths());

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + 'reports/expense', postRequestOptions(formData));
            const data = await response.json();
           
            if (data.success) {
                setSalesData(data.data);
            } else {
                setSalesData([]);
                for (const i in data.errors) {
                    toast(<strong>{data.errors[i].join(' ')}</strong>, { type: 'error', position: 'bottom-right', autoClose: 4000 });
                }
            }
        } catch (error) {
            console.error('Error submitting data:', error);
        } finally {
            setLoading(false);
        }
    };

    const queryParams = new URLSearchParams(formData);

    return (
        <>
            <Card className='mb-4'>
                <Card.Body>
                    <Card.Title></Card.Title>
                    <Card.Text>
                        <div className='row'>
                            <div className='col-md-3'>
                                <Form.Group className="mb-3" controlId="project">
                                    <Form.Label>Project<span className='required'></span></Form.Label>
                                    <Select
                                        value={project}
                                        onChange={handleProjectChange}
                                        options={projects}
                                        menuPosition="fixed"
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        isClearable={true}
                                    />
                                </Form.Group>
                            </div>
                            <div className='col-md-3'>
                                <Form.Group className="mb-3" controlId="project">
                                    <Form.Label>Expense Type</Form.Label>
                                    <Select
                                        value={expenseCategory}
                                        onChange={handleExpenseCategoryChange}
                                        options={expenseCategories}
                                        menuPosition="fixed"
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        isClearable={true}
                                        isMulti={true}
                                    />
                                </Form.Group>
                            </div>
                            <div className='col-md-2'>
                                <Form.Group className="mb-3" controlId="project">
                                    <Form.Label>From Date<span className='required'></span></Form.Label>
                                    <Form.Control type="date" placeholder="From Date" name='fromDate' onChange={handleChange}/>
                                </Form.Group>
                            </div>
                            <div className='col-md-2'>
                                <Form.Group className="mb-3" controlId="project">
                                    <Form.Label>To Date<span className='required'></span></Form.Label>
                                    <Form.Control type="date" placeholder="To Date" name='toDate' onChange={handleChange}/>
                                </Form.Group>
                            </div>
                            <div className='col-md-1'>
                                <div className="form-control-sm ps-0 mt-7">
                                    <button className='btn btn-sm btn-primary w-100 p-3' onClick={handleViewReport} disabled={loading}>
                                        {loading && (
                                            <span className="spinner-border spinner-border-sm"></span>
                                        )}
                                        <span>View Report</span>
                                    </button>
                                </div>                                
                            </div>
                            <div className='col-md-1'>
                                <div className="form-control-sm ps-0 mt-7">
                                    <a className="btn btn-sm btn-primary w-100 p-3 mb-2" href={`${process.env.REACT_APP_BACKEND}expense_report_download?` + queryParams.toString()}><i className='fas fa-download'></i>Download</a>
                                </div>                                
                            </div>
                        </div>
                    </Card.Text>
                </Card.Body>
            </Card>
           
            <div className='row'>
                <div className='col-12'>
                    {months && months.length > 0 && (
                        <Table responsive striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Sl.</th>
                                    <th>Sales Person</th>
                                    {months.map((month, index) => (
                                        <th key={index}>{month}</th>
                                    ))}
                                    <th>Total</th>
                                    <th>Monthly Average</th>
                                </tr>
                            </thead>
                            <tbody>
                            {salesData && salesData.length > 0 ? (
                                salesData.map((data, key) => (
                                <tr key={key}>
                                    <td>{key+1}</td>
                                    <td>{data.name}</td>
                                    {Object.entries(data.monthlySales || {}).map(([month, sale], index) => (
                                        <td key={index} className='text-end'>{numberFormat(sale)}</td>
                                    ))}
                                    <td className='text-end'>{numberFormat(data.total)}</td>
                                    <td className='text-end'>{numberFormat(data.monthlyAverage)}</td>
                                </tr>
                                ))
                            ) : (
                              <tr>
                                <td colSpan={months.length + 4} className='text-center'>No data found</td>
                              </tr>
                            )}
                            </tbody>
                        </Table>
                    )}
                </div>
            </div>
        </>
    );
};

export default ExpenseReport;