/* eslint-disable react-hooks/exhaustive-deps */
import { KTSVG } from '../../_metronic/helpers'
import React, { useState, useEffect } from 'react'
import { Modal, Table, Button } from 'react-bootstrap-v5';
import Swal from 'sweetalert2'
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper'
import Select from 'react-select'
import { Link } from 'react-router-dom';
import validationErrorMsg from '../helpers/ValidationErrorMsg'
import { useHistory, useParams } from "react-router-dom";

interface RouteParams {
    idProductions: string;
}

interface ProductionRows {
    indexKey: number | null;
    idProducts: number;
    quantity: number;
    rate: number;
    amount: number;
    cost: any;
    costItems: any;
    costOverheads: any;
    [key: string]: any; // Index signature
}

interface Product {
    id_products: number;
    product_name: string;
}

interface ProductionData {
    idProductions: number | null;
    idProjects: number;
    projectName: string;
    date: string;
    referenceNo: string;
}

const ManufacturingCreate = () => {

    const { idProductions }: RouteParams = useParams();

    let history = useHistory();
    const productionData: ProductionData = {
        idProductions: null,
        projectName: '',
        idProjects: 0,
        date: '',
        referenceNo: '',
    };

    const [configurationProduct, setConfigurationProduct] = useState<ProductionRows>();
    const [production, setProduction] = useState(productionData);
    const [products, setProducts] = useState<Product[]>([]);
    const [projects, setProjects] = useState([]);
    const [project, setProject] = useState({ label: 'Select Project', value: '' });
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
    }

    const handleProjectChange = (project: any) => {
        setProject(project);
        setProduction({ ...production, idProjects: project.value });
        getProducts(project.value);
        setProductionRows([{
            indexKey: null,
            idProducts: 0,
            quantity: 0,
            rate: 0,
            amount: 0,
            cost: null,
            costItems: null,
            costOverheads: null,
        }]);
    }

    useEffect(() => {
        // getProducts()
        getProjects()

        if (idProductions) {
            fetch(
                process.env.REACT_APP_API_URL + 'manufacturing/edit/' + idProductions,
                getRequestOptions()
            )
                .then((resp) => {
                    return resp.json()
                })
                .then((resp) => {

                    console.log(resp.data)
                    setProductionRows(resp.data)
                    setProduction(resp.production)
                    setProject({ value: resp.production.idProjects, label: resp.production.projectName })
                    getProducts(resp.production.idProjects)
                })
                .catch((error) => {
                    console.log(error, 'catch the hoop')
                })
        }

    }, [])

    const handleItemChange = (data: any, index: number) => {
        const updatedProductionRows = [...productionRows];
        updatedProductionRows[index].idProducts = data.value;
        updatedProductionRows[index].productName = data.product_name;
        updatedProductionRows[index].packing = data.packing;
        updatedProductionRows[index].size = data.size;
        updatedProductionRows[index].item = data.item;
        updatedProductionRows[index].rate = data.production_cost;
        updatedProductionRows[index].cost = data.cost;
        updatedProductionRows[index].costItems = data.cost_items;
        updatedProductionRows[index].costOverheads = data.cost_overheads;
        updatedProductionRows[index].amount = (updatedProductionRows[index].quantity * updatedProductionRows[index].rate).toFixed(2);
        setProductionRows(updatedProductionRows);
    }

    const getProducts = (project: number) => {

        fetch(
            process.env.REACT_APP_API_URL + 'manufacturing/products?idProjects=' + project,
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {

                let products = resp.data.map((item: any) => {
                    item.label = item.product_name + ' (Size:' + item.packing + ')';
                    item.value = item.id_products;
                    item.unitRate = item.production_cost;
                    return item
                })
                setProducts(products);

            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const getProjects = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'lead/projects',
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let projectObj = resp.data.map((item: any) => {
                    item.label = item.name;
                    item.value = item.id;
                    return item
                })
                setProjects(projectObj);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    // const [isSubmitDisable, setSubmitDisable] = useState(false);
    // const [loading, setLoading] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const updatedProductionRows = [...productionRows];
        updatedProductionRows[index][e.target.name] = e.target.value;
        updatedProductionRows[index].amount = parseFloat((updatedProductionRows[index].quantity * updatedProductionRows[index].rate).toFixed(2))
        setProductionRows(updatedProductionRows);
    }

    const deleteProduct = (index: number) => {
        const updatedProductionRows = [...productionRows];
        if (updatedProductionRows && updatedProductionRows[index]) {
            updatedProductionRows.splice(index, 1);
            setProductionRows(updatedProductionRows);
        }
    }

    const configureProduct = (index: number) => {
        const updatedProductionRows = [...productionRows];
        if (updatedProductionRows && updatedProductionRows[index]) {
            updatedProductionRows[index].indexKey = index;
            updatedProductionRows[index].rate = parseFloat(updatedProductionRows[index].rate);
            updatedProductionRows[index].cost.weighted_loss = parseFloat(updatedProductionRows[index].cost.weighted_loss);
            updatedProductionRows[index].cost.vat = parseFloat(updatedProductionRows[index].cost.vat);
            updatedProductionRows[index].cost.item_cost = parseFloat(updatedProductionRows[index].cost.item_cost);
            updatedProductionRows[index].cost.overhead_cost = parseFloat(updatedProductionRows[index].cost.overhead_cost);
            updatedProductionRows[index].cost.total_cost = parseFloat(updatedProductionRows[index].cost.total_cost);
            setConfigurationProduct(updatedProductionRows[index]);
            setShow(true)
        }
    }

    const productionSave = async (event: any) => {
        event.preventDefault()
        // setSubmitDisable(true);
        // setLoading(true);
        const confirm = await Swal.fire({
            title: 'Are you sure?',
            text: (idProductions === undefined) ? "You want to manufacture this Production?" : "You want to update this Production?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#ec008c',
            cancelButtonText: 'Cancel',
            confirmButtonText: (idProductions === undefined) ? 'Yes, Manufacture it!' : 'Yes, Update',
        })

        if (confirm.value) {
            const postData = {
                production: production,
                productionRows: productionRows,
            }
            const requestOptions = postRequestOptions(postData)
            try {

                let url = (idProductions === undefined) ? `${process.env.REACT_APP_API_URL}manufacturing` : `${process.env.REACT_APP_API_URL}manufacturing/update/${idProductions}`
                let response = await fetch(
                    url,
                    requestOptions
                )
                let respData = await response.json()
                if (respData.success === true) {
                    const successMsg = respData.successMessage
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: successMsg,
                    })

                    history.push("/manufacturing");
                } else {
                    const errorsMessage = validationErrorMsg(respData.errors).join('<br>')
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        html: errorsMessage,
                    })
                }
                // setSubmitDisable(false);
                // setLoading(false);
            } catch (error) {
                console.log(error, 'catch the hoop')
                // setSubmitDisable(false);
                // setLoading(false);
            }
        }
    }

    const addProduct = () => {
        const newRow: ProductionRows = {
            indexKey: null,
            idProducts: 0,
            quantity: 0,
            rate: 0,
            amount: 0,
            cost: null,
            costItems: null,
            costOverheads: null,
        };

        const updatedRows = [...productionRows];
        updatedRows.push(newRow);

        setProductionRows(updatedRows);
    }

    const handleInputOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setProduction({ ...production, [event.target.name]: event.target.value });
    }


    const handleProductInputOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        if (configurationProduct) {
            const updatedCost = {
                ...configurationProduct.cost,
                [name]: parseFloat(value)
            };

            const updatedProduct = {
                ...configurationProduct,
                cost: {
                    ...configurationProduct.cost,
                    ...updatedCost
                }
            };

            var netAmount = updatedProduct.cost.item_cost + updatedProduct.cost.overhead_cost + updatedProduct.cost.vat;
            updatedProduct.cost.total_cost = netAmount;
            updatedProduct.rate = netAmount;
            updatedProduct.amount = parseFloat((updatedProduct.quantity * netAmount).toFixed(2));

            setConfigurationProduct(updatedProduct);

            const updatedProductionRows = [...productionRows];
            updatedProductionRows[updatedProduct.indexKey] = updatedProduct
            setProductionRows(updatedProductionRows);
        }
    }

    const [productionRows, setProductionRows] = useState<ProductionRows[]>([{
        indexKey: null,
        idProducts: 0,
        quantity: 0,
        rate: 0,
        amount: 0,
        cost: null,
        costItems: null,
        costOverheads: null,
    }]);

    const handleItemDataChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {

        if (configurationProduct) {

            let totalAmount = 0;
            const updatedProduct = {
                ...configurationProduct,
                costItems: configurationProduct.costItems.map((item: any, i: number) => {

                    if (i === index) {

                        let amount;
                        let quantity;
                        if (e.target.name === 'weighted_loss') {
                            quantity = item.without_loss_quantity + (item.without_loss_quantity * parseFloat(e.target.value)) / 100;
                            amount = item.rate * quantity
                        } else if (e.target.name === 'without_loss_quantity') {
                            quantity = parseFloat(e.target.value) + (parseFloat(e.target.value) * item.weighted_loss) / 100;
                            amount = item.rate * quantity;
                        } else {
                            quantity = item.quantity;
                            amount = item.quantity * parseFloat(e.target.value);
                        }
                        totalAmount += amount;
                        return {
                            ...item,
                            [e.target.name]: e.target.value,
                            amount: amount.toFixed(2),
                            quantity: quantity,
                        };

                    } else {
                        totalAmount += parseFloat(item.amount);
                    }
                    return item;
                })
            };

            updatedProduct.cost.item_cost = totalAmount;
            var netAmount = updatedProduct.cost.item_cost + updatedProduct.cost.overhead_cost + updatedProduct.cost.vat;
            updatedProduct.cost.total_cost = netAmount;
            updatedProduct.rate = netAmount;
            updatedProduct.amount = parseFloat((updatedProduct.quantity * netAmount).toFixed(2));
            setConfigurationProduct(updatedProduct);

            const updatedProductionRows = [...productionRows];
            updatedProductionRows[updatedProduct.indexKey] = updatedProduct
            setProductionRows(updatedProductionRows);
        }
    }

    const handleOverheadDataChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {

        if (configurationProduct) {

            let totalAmount = 0;
            const updatedProduct = {
                ...configurationProduct,
                costOverheads: configurationProduct.costOverheads.map((item: any, i: number) => {

                    if (i === index) {
                        totalAmount += parseFloat(e.target.value);
                        return {
                            ...item,
                            [e.target.name]: e.target.value
                        };
                    } else {
                        totalAmount += parseFloat(item.amount);
                    }
                    return item;
                })
            };
            updatedProduct.cost.overhead_cost = totalAmount;
            var netAmount = updatedProduct.cost.item_cost + updatedProduct.cost.overhead_cost + updatedProduct.cost.vat;
            updatedProduct.cost.total_cost = netAmount;
            updatedProduct.rate = netAmount;
            updatedProduct.amount = parseFloat((updatedProduct.quantity * netAmount).toFixed(2));
            setConfigurationProduct(updatedProduct);
            const updatedProductionRows = [...productionRows];
            updatedProductionRows[updatedProduct.indexKey] = updatedProduct
            setProductionRows(updatedProductionRows);
        }
    }



    return (

        <div className="card card-custom">
            <div className="card-header">
                <div className="card-title">
                    <KTSVG
                        path='/media/svg/icons/Home/Book-open.svg'
                        className='svg-icon-1 svg-icon-primary'
                    />
                    <span className="text fw-bolder" style={{ marginLeft: "10px" }}>
                        {idProductions === undefined &&
                            <span>Create New Manufacturing Process</span>
                        }
                        {idProductions !== undefined &&
                            <span>Edit Manufacturing Data (ID # {idProductions})</span>
                        }
                    </span>
                </div>
            </div>

            <div className="ml-12">
                <div className="card-body">
                    {/* <pre>{JSON.stringify(production, null, 2)}</pre> */}
                    <form onSubmit={productionSave}>

                        <div className="form-group row">
                            <label htmlFor="idProjects" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Select Project <span className="required text-danger"></span></label>
                            <div className="col-lg-5">
                                <Select
                                    // placeholder={production.projectName}
                                    value={project}
                                    onChange={handleProjectChange}
                                    options={projects}
                                    menuPosition="fixed"
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="date" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Manufacturing Date  <span className="required text-danger"></span></label>
                            <div className="col-lg-5">
                                <input type='date' name="date" id="date" value={production.date} onChange={handleInputOnChange} className="form-control form-control-sm" />
                            </div>
                        </div>
                        <div className="form-group row mb-2">
                            <label htmlFor="referenceNo" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Reference No  <span className="required text-danger"></span></label>
                            <div className="col-lg-5">
                                <input name="referenceNo" id="referenceNo" value={production.referenceNo} onChange={handleInputOnChange} className="form-control form-control-sm" />
                            </div>
                        </div>

                        <div className="form-group row mb-2">
                            <div className="col text-end">
                                <button type='button' className="btn btn-success btn-sm px-2 py-1" onClick={() => addProduct()}>
                                    <KTSVG path='/media/svg/icons/Navigation/Plus.svg' className='svg-icon-5 svg-icon-danger me-0' /> Add More Product
                                </button>
                            </div>
                        </div>
                        <br></br>
                        <Table size='sm' bordered hover>
                            <thead>
                                <tr>
                                    <th style={{ width: '5%' }} className='text-center'>SL</th>
                                    <th style={{ width: '35%' }}>Product Name <span className="text-danger">*</span></th>
                                    <th style={{ width: '15%' }}>Production Cost/Per Unit</th>
                                    <th style={{ width: '15%' }}>Production Quantity</th>
                                    <th style={{ width: '15%' }}>Total Cost</th>
                                    <th style={{ width: '15%' }}></th>
                                </tr>
                            </thead>

                            <tbody>

                                {productionRows.map((row, index) => (
                                    <tr key={index}>
                                        <td className='text-center'>{index + 1}</td>
                                        <td>
                                            <Select
                                                // value={product.idItems}
                                                // defaultValue={['label': 'ddd', 'value': 0]} 
                                                placeholder={row.product_name + ' (Size: ' + row.packing + ')'}
                                                onChange={(selectedOption) => handleItemChange(selectedOption, index)}
                                                options={products}
                                                className='sm'
                                                menuPosition="fixed"
                                                styles={{
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        height: '32px',
                                                        minHeight: '32px',
                                                    }),
                                                    container: (provided, state) => ({
                                                        ...provided,
                                                        height: '32px',
                                                    }),
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <input type="number" name="rate" readOnly value={row.rate} onChange={(event) => handleChange(event, index)} className="form-control form-control-sm" />
                                        </td>
                                        <td>
                                            <input type="number" name="quantity" value={row.quantity} onChange={(event) => handleChange(event, index)} className="form-control form-control-sm" />
                                        </td>
                                        <td>
                                            <input type="number" name="amount" readOnly value={row.amount} className="form-control form-control-sm" />
                                        </td>
                                        <td>
                                            <button type='button' className="btn btn-light-primary btn-sm px-2 py-1" onClick={() => deleteProduct(index)}>
                                                <KTSVG path='/media/svg/icons/General/Trash.svg' className='svg-icon-5 svg-icon-danger me-0' />
                                            </button>
                                            {row.idProducts !== 0 &&
                                                <button type='button' className="btn btn-primary btn-sm px-2 py-1" onClick={() => configureProduct(index)}>
                                                    <KTSVG path='/media/svg/icons/General/Settings-2.svg' className='svg-icon-5 svg-icon-danger me-0' /> Cost Configuration
                                                </button>
                                            }

                                        </td>
                                    </tr>
                                ))}

                            </tbody>

                        </Table>
                        <div className="row mt-4">
                            <div className="col-12 text-center">
                                <button type="submit" className="btn btn-sm btn-success me-2">
                                    {idProductions === undefined &&
                                        <><KTSVG path='/media/svg/icons/Navigation/Right-2.svg' className='svg-icon-5 svg-icon-danger me-0' />Start Manufacturing</>
                                    }
                                    {idProductions !== undefined &&
                                        <><KTSVG path='/media/svg/icons/Navigation/Right-2.svg' className='svg-icon-5 svg-icon-danger me-0' />Update Manufacturing Data</>
                                    }
                                </button>
                                <Link to='/manufacturing' type="reset" className="btn btn-sm btn-secondary">Cancel</Link>
                            </div>
                        </div>

                    </form>

                    {/* <pre>{JSON.stringify(productionRows, null, 2)}</pre> */}

                </div>
            </div>

            {configurationProduct != null &&
                <>
                    <Modal show={show} onHide={() => handleClose(false)} size="xl">
                        <Modal.Header closeButton>
                            <Modal.Title>Configure Product Cost</Modal.Title>
                        </Modal.Header>
                        {/* <Form onSubmit={handleSubmit}> */}
                        <Modal.Body>


                            <Table size='sm' bordered hover>
                                <tr>
                                    <td width={'30%'}>Product Name </td>
                                    <td width={'70%'}> : {configurationProduct.productName}</td>
                                </tr>
                                <tr>
                                    <td>Product Code </td>
                                    <td> : {configurationProduct.item.item_code}</td>
                                </tr>
                                <tr>
                                    <td>Pack Size </td>
                                    <td> : {configurationProduct.size + ' ' + configurationProduct.item.unit.unit_name}</td>
                                </tr>
                                <tr>

                                </tr>

                            </Table>


                            <Table size='sm' bordered hover>
                                <thead>
                                    <tr>
                                        <th className="col-1">Item</th>
                                        <th className="col-1">Category</th>
                                        <th className="col-1">Unit</th>
                                        <th className="col-1">Weighted Loss (%)</th>
                                        <th className="col-1">Quantity</th>
                                        <th className="col-1">Effective Quantity</th>
                                        <th className="col-2">Rate</th>
                                        <th className="col-2">Amount</th>
                                        <th className="col-1">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {configurationProduct.costItems.map((productItem: any, index: number) => (
                                        <tr key={'item' + index}>
                                            <td className='text-left'>{productItem.item_name}</td>
                                            <td>{productItem.category_name}</td>
                                            <td>{productItem.unit_name}</td>
                                            <td>
                                                <input type="number" name="weighted_loss" value={productItem.weighted_loss} onChange={(event) => handleItemDataChange(event, index)} className="form-control form-control-sm" />
                                            </td>
                                            <td>
                                                <input type="number" name="without_loss_quantity" value={productItem.without_loss_quantity} onChange={(event) => handleItemDataChange(event, index)} className="form-control form-control-sm" />
                                            </td>
                                            <td>
                                                <input readOnly type="number" name="quantity" value={productItem.quantity} onChange={(event) => handleItemDataChange(event, index)} className="form-control form-control-sm" />
                                            </td>
                                            <td>
                                                <input type="number" name="rate" value={productItem.rate} onChange={(event) => handleItemDataChange(event, index)} className="form-control form-control-sm" />
                                            </td>
                                            <td>
                                                <input type="number" name="amount" value={productItem.amount} readOnly className="form-control form-control-sm" />
                                            </td>
                                            <td>
                                                <button type='button' className="btn btn-light-primary btn-sm px-2 py-1" onClick={() => deleteItem(index)}>
                                                    <KTSVG path='/media/svg/icons/General/Trash.svg' className='svg-icon-5 svg-icon-danger me-0' />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <td className='text-end' colSpan={7}><b>Total Item Cost : </b></td>
                                        <td>{configurationProduct.cost.item_cost}</td>
                                    </tr>
                                </tbody>
                            </Table>

                            <Table size='sm' bordered hover>
                                <thead>
                                    <tr>
                                        <th className="col-9">Ohverhead Name</th>
                                        <th className="col-2">Amount</th>
                                        <th className="col-1">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {configurationProduct.costOverheads.map((productOverhead: any, index: number) => (
                                        <tr key={'overhead' + index}>
                                            <td className='text-left'>
                                                {productOverhead.overhead_name}
                                            </td>
                                            <td>
                                                <input type="number" name="amount" value={productOverhead.amount} onChange={(event) => handleOverheadDataChange(event, index)} className="form-control form-control-sm" />
                                            </td>
                                            <td>
                                                <button type='button' className="btn btn-light-primary btn-sm px-2 py-1" onClick={() => deleteOverhead(index)}>
                                                    <KTSVG path='/media/svg/icons/General/Trash.svg' className='svg-icon-5 svg-icon-danger me-0' />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <td className='text-end'><b>Total Overhead Cost : </b></td>
                                        <td>{configurationProduct.cost.overhead_cost}</td>
                                    </tr>
                                </tbody>
                            </Table>

                            <Table size='sm' bordered hover>

                                <tbody>
                                    <tr>
                                        <td className='text-end col-9'><b>VAT : </b></td>
                                        <td className='col-3'>
                                            <input type='number' name="vat" value={configurationProduct.cost.vat} onChange={handleProductInputOnChange} className="form-control form-control-sm" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-end col-9'><b>Total Production Cost with VAT : </b></td>
                                        <td className='col-3'><b>{configurationProduct.cost.item_cost + configurationProduct.cost.overhead_cost + configurationProduct.cost.vat}</b></td>
                                    </tr>
                                </tbody>


                            </Table>
                        </Modal.Body>

                        <Modal.Footer>

                            {/* <button type="submit" className="btn btn-sm btn-primary me-2">
                                <span>  Submit</span>
                            </button> */}
                            <Button variant='secondary' size="sm" onClick={() => handleClose(false)}>
                                Update
                            </Button>
                        </Modal.Footer>
                    </Modal>

                </>
            }

            {/* <Modal show={show} onHide={() => handleClose(false)} size="lg">
                <form onSubmit={assignedToSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>Configure Cost for Product</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group row">
                            <label htmlFor="idCompanies" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Select Assign To <span className="required text-danger"></span></label>
                            <div className="col-lg-7">

                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>

                        <button type="submit" className="btn btn-sm btn-primary me-2"><span>  Submit</span></button>
                        <Button variant='secondary' size="sm" onClick={() => handleClose(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal> */}

        </div>

    )
}

export default ManufacturingCreate
