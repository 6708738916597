/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react'
import { AppContext } from '../context/AppContext'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import AssignSrCategory from '../components/AssignSrCategory';
import SrCategoryList from '../components/SrCategoryList';

const ManageSrCategory = () => {
    const { currentUser } = useContext(AppContext);

    useEffect(() => {

    }, [currentUser])


    return (
        <>

            <div className='card card-custom'>
                <div className='card-header card-header-stretch overflow-auto'>
                    <div className='card-title'>
                        {/* <h3 className='card-label'>Manage Products</h3> */}
                    </div>
                </div>
                <div className="card-body">

                    <Tabs
                        defaultActiveKey="srCategory"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                    >

                        <Tab eventKey="srCategory" title="SR Category">
                            <SrCategoryList></SrCategoryList>
                        </Tab>
                        <Tab eventKey="assignSr" title="Assign SR">
                            <AssignSrCategory></AssignSrCategory>
                        </Tab>

                    </Tabs>
                </div>
            </div>


            <style>
                {`
    .react-bootstrap-tabs .nav-link {
      padding: 1000px;
    }
  `}
            </style>
        </>

    )
}

export default ManageSrCategory
