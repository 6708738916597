/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { getRequestOptions } from '../helpers/Fetchwrapper';
import { KTSVG } from '../../_metronic/helpers';
import { Col, Row, Button } from 'react-bootstrap-v5';
import 'react-dropzone-uploader/dist/styles.css'
import { useParams } from 'react-router-dom'
import Select from "react-select";
import DataTable from '../components/DataTable'
import { Link } from 'react-router-dom';


interface RouteParams {
    idCompanies: string;
}

export function CompanyDetails(props: any) {

    const { idCompanies }: RouteParams = useParams();

    const [companyData, setCompanyData] = useState({
        companyName: '',
        totalSalesOrder: 0,
        totalDelivered: 0,
        totalInvoiced: 0,
        totalPayment: 0,
        totalDue: 0,
        totalOverdue: 0,
    });
    const [selectProjectOptions, setSelectProjectOptions] = useState([])
    const [selectedProject, setSelectedProject] = useState<{ value: string; label: string; } | null>({ value: '', label: 'All Project' });
    const [startDate, setStartDate] = useState<string | null>('');
    const [endDate, setEndDate] = useState<string | null>('');
    const [additionalPostData, setAdditionalPostData] = useState({ idCompanies: idCompanies, idProjects: '', startDate: '', endDate: '' });


    useEffect(() => {
        getCompanyInfo()
        getProjectList()
    }, [idCompanies])

    const columns = [
        { value: 'id', title: 'ID', style: { textAlign: 'center' } },
        { value: 'projectName', title: 'Project', style: { textAlign: 'center' } },
        { value: 'orderDate', title: 'Sales Order Date', style: { textAlign: 'left' } },
        { value: 'orderAmount', title: 'Order Amount', style: { textAlign: 'center' } },
        { value: 'shipmentAmount', title: 'Shipment Amount', style: { textAlign: 'center' } },
        { value: 'invoiceAmount', title: 'Invoiced Amount', style: { textAlign: 'center' } },
        { value: 'paymentAmount', title: 'Paid Amount', style: { textAlign: 'center' } },
        { value: 'creditDays', title: 'Credit Days', style: { textAlign: 'left' } },
        { value: 'createdBy', title: 'Created By', style: { textAlign: 'left' } },
        {
            value: 'action', title: 'Action', style: { textAlign: 'left', whiteSpace: 'nowrap' }, hasActionsComponent: true, actionsComponent: ((row: any) => {
                return (
                    <>
                        <Link className="btn btn-light-primary btn-sm" to={`/sales-order/${row.idSalesOrders}`}>
                            <KTSVG
                                path='/media/svg/icons/Layout/Layout-grid.svg'
                                className='svg-icon-5 svg-icon'
                            />		Details
                        </Link>
                        {/* <button className="btn btn-light-primary btn-sm" onClick={() => handleShipmentModalShow(row)}>
                            <KTSVG
                                path='/media/svg/icons/Shopping/Cart3.svg'
                                className='svg-icon-5 svg-icon'
                            />		Shipment
                        </button> */}

                    </>
                )
            })
        }
    ]

    const filters = {
        id: {
            filterType: 'like',
            filterValue: '',
        },
        projectName: {
            filterType: 'like',
            filterValue: '',
        },
        orderDate: {
            filterType: 'like',
            filterValue: '',
        },
        orderAmount: {
            filterType: 'like',
            filterValue: '',
        },
        shipmentAmount: {
            filterType: 'like',
            filterValue: '',
        },
        paymentAmount: {
            filterType: 'like',
            filterValue: '',
        },
        invoiceAmount: {
            filterType: 'like',
            filterValue: '',
        },
        creditDays: {
            filterType: 'like',
            filterValue: '',
        },
        createdBy: {
            filterType: 'like',
            filterValue: '',
        },
    }

    const url = process.env.REACT_APP_API_URL + 'sales-order/dt'

    const [newData, setNewData] = useState({});

    const refreshData = () => {
        getCompanyInfo()
        setAdditionalPostData({
            idCompanies: idCompanies,
            idProjects: selectedProject === null ? '' : selectedProject.value,
            startDate: startDate,
            endDate: endDate,
        })
        setNewData(prevData => ({ ...prevData, id: Date.now() }));
    }

    const getCompanyInfo = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'company/' + idCompanies + '/?idProjects=' + (selectedProject === null ? '' : selectedProject?.value) + '&startDate=' + startDate + '&endDate=' + endDate,
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                // console.log(resp, 'resp.data')
                setCompanyData({
                    ...companyData,
                    companyName: resp.data.company.company_name,
                    totalSalesOrder: resp.data.totalSalesOrder,
                    totalDelivered: resp.data.totalDelivered,
                    totalInvoiced: resp.data.totalInvoiced,
                    totalPayment: resp.data.totalPayment,
                    totalDue: resp.data.totalDue,
                    totalOverdue: resp.data.totalOverdue,
                });

            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const numberFormat = (value: number) => {
        return parseFloat(value.toString()).toLocaleString('en-US', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }

    async function getProjectList() {
        const url = process.env.REACT_APP_API_URL + 'projects';

        const response = await fetch(url, getRequestOptions());

        const data = await response.json();

        data.data.map((element: any) => {
            element.value = element.id_projects;
            element.label = element.project_name;
            return element;
        })

        const newItem = {
            "id_projects": '',
            "project_name": "All Project",
            "project_status": "active",
            "created_by": null,
            "updated_by": null,
            "created_at": null,
            "updated_at": null,
            "value": '',
            "label": "-- All Projects --"
        };

        // Push the new item to the array
        data.data.unshift(newItem);

        // console.log('mmmmmm', data.data)

        setSelectProjectOptions(data.data);
    }

    return (
        <>
            <div style={{ background: "none" }} className='card card-custom'>
                <div className='card-header'>
                    <div className='card-title'>
                        <h3 className='card-label'>{companyData.companyName} - Details Info</h3>
                    </div>
                    <div className='card-toolbar'>
                        <div className='d-flex justify-content-end align-items-center'>
                            <Select
                                className="form-control-sm h-50px w-250px me-3"
                                defaultValue={selectProjectOptions}
                                onChange={setSelectedProject}
                                options={selectProjectOptions}
                            // placeholder="Select project"
                            />
                            <Row>
                                <Col className="d-flex align-items-center text-end pe-0">
                                    From:
                                </Col>
                                <Col>
                                    <input onChange={e => { setStartDate(e.target.value) }} type="date" className="d-inline form-control form-control-sm" />
                                </Col>
                                <Col className="d-flex align-items-center text-end pe-0">
                                    To:
                                </Col>
                                <Col>
                                    <input onChange={e => { setEndDate(e.target.value) }} type="date" className="d-inline form-control form-control-sm" />
                                </Col>
                            </Row>
                            <Button onClick={() => refreshData()} variant="primary" className="ms-2 p-2" size="sm">Refresh</Button>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row  gy-5 g-xl-2">
                        <div className="col-sm-6 col-xl-2 mb-xl-10">
                            <div className="card h-lg-100">
                                <div className="card-body d-flex justify-content-between align-items-start flex-column">
                                    <div className="m-2">
                                        <KTSVG className='svg-icon-primary svg-icon svg-icon-2' path='/media/svg/icons/Tools/Taka.svg'></KTSVG>
                                    </div>
                                    <div className="d-flex flex-column my-4">
                                        <span className="fw-semibold fs-2x text-gray-800 lh-1 ls-n2">{numberFormat(companyData.totalSalesOrder)}</span>
                                        <div className="my-1">
                                            <span className="fw-semibold fs-4 text-gray-400"> Total Sales Order </span>
                                        </div>
                                    </div>
                                    {/* <span className="badge badge-light-success fs-base">
                                        <i className="ki-outline ki-arrow-up fs-5 text-success ms-n1"></i>
                                        2.1%
                                    </span> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xl-2 mb-xl-10">
                            <div className="card h-lg-100">
                                <div className="card-body d-flex justify-content-between align-items-start flex-column">
                                    <div className="m-2">
                                        <KTSVG className='svg-icon-success svg-icon svg-icon-2' path='/media/svg/icons/Communication/Sending.svg'></KTSVG>
                                    </div>
                                    <div className="d-flex flex-column my-4">
                                        <span className="fw-semibold fs-2x text-gray-800 lh-1 ls-n2">{numberFormat(companyData.totalDelivered)}</span>
                                        <div className="my-1">
                                            <span className="fw-semibold fs-4 text-gray-400"> Total Delivered </span>
                                        </div>
                                    </div>
                                    {/* <span className="badge badge-light-success fs-base">
                                        <i className="ki-outline ki-arrow-up fs-5 text-success ms-n1"></i>
                                        2.1%
                                    </span> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xl-2 mb-xl-10">
                            <div className="card h-lg-100">
                                <div className="card-body d-flex justify-content-between align-items-start flex-column">
                                    <div className="m-2">
                                        <KTSVG className='svg-icon-danger svg-icon svg-icon-2' path='/media/svg/icons/Communication/Clipboard-list.svg'></KTSVG>
                                    </div>
                                    <div className="d-flex flex-column my-4">
                                        <span className="fw-semibold fs-2x text-gray-800 lh-1 ls-n2">{numberFormat(companyData.totalInvoiced)}</span>
                                        <div className="my-1">
                                            <span className="fw-semibold fs-4 text-gray-400"> Total Invoiced </span>
                                        </div>
                                    </div>
                                    {/* <span className="badge badge-light-danger fs-base">
                                        <i className="ki-outline ki-arrow-up fs-5 text-error ms-n1"></i>
                                        2.1%
                                    </span> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xl-2 mb-xl-10">
                            <div className="card h-lg-100">
                                <div className="card-body d-flex justify-content-between align-items-start flex-column">
                                    <div className="m-2">
                                        <KTSVG className='svg-icon-info svg-icon svg-icon-2' path='/media/svg/icons/Communication/Clipboard-check.svg'></KTSVG>
                                    </div>
                                    <div className="d-flex flex-column my-4">
                                        <span className="fw-semibold fs-2x text-gray-800 lh-1 ls-n2">{numberFormat(companyData.totalPayment)}</span>
                                        <div className="my-1">
                                            <span className="fw-semibold fs-4 text-gray-400"> Payment Received </span>
                                        </div>
                                    </div>
                                    {/* <span className="badge badge-light-info fs-base">
                                        <i className="ki-outline ki-arrow-up fs-5 text-error ms-n1"></i>
                                        2.1%
                                    </span> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xl-2 mb-xl-10">
                            <div className="card h-lg-100">
                                <div className="card-body d-flex justify-content-between align-items-start flex-column">
                                    <div className="m-2">
                                        <KTSVG className='svg-icon-success svg-icon svg-icon-2' path='/media/svg/icons/Design/Difference.svg'></KTSVG>
                                    </div>
                                    <div className="d-flex flex-column my-4">
                                        <span className="fw-semibold fs-2x text-gray-800 lh-1 ls-n2">{numberFormat(companyData.totalDue)}</span>
                                        <div className="my-1">
                                            <span className="fw-semibold fs-4 text-gray-400"> Total Due </span>
                                        </div>
                                    </div>
                                    {/* <span className="badge badge-light-success fs-base">
                                        <i className="ki-outline ki-arrow-up fs-5 text-error ms-n1"></i>
                                        2.1%
                                    </span> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xl-2 mb-xl-10">
                            <div className="card h-lg-100">
                                <div className="card-body d-flex justify-content-between align-items-start flex-column">
                                    <div className="m-2">
                                        <KTSVG className='svg-icon-danger svg-icon svg-icon-2' path='/media/svg/icons/Map/Position.svg'></KTSVG>
                                    </div>
                                    <div className="d-flex flex-column my-4">
                                        <span className="fw-semibold fs-2x text-gray-800 lh-1 ls-n2">{numberFormat(companyData.totalOverdue)}</span>
                                        <div className="my-1">
                                            <span className="fw-semibold fs-4 text-gray-400"> Total Overdue </span>
                                        </div>
                                    </div>
                                    {/* <span className="badge badge-light-warning fs-base">
                                        <i className="ki-outline ki-arrow-up fs-5 text-error ms-n1"></i>
                                        2.1%
                                    </span> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <pre>{JSON.stringify(companyData, null, 2)}</pre> */}

                    <Row>
                        <Col md={12}>
                            <div className='card card-custom'>
                                <div className='card-header'>
                                    <div className="card-title">
                                        <KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-1 svg-icon-primary' />
                                        <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
                                            Sales Order List of {companyData.companyName}
                                        </span>
                                    </div>
                                    <div className="card-toolbar">

                                    </div>
                                </div>

                                <div className='card-body'>
                                    <DataTable url={url} columns={columns} filters={filters} newData={newData} additionalPostData={additionalPostData} />
                                </div>
                            </div>
                        </Col>
                    </Row>


                </div>
            </div>

        </>
    )
}

export default CompanyDetails
