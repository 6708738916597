/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import { Modal, Button } from 'react-bootstrap'
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper'
import validationErrorMsg from '../helpers/ValidationErrorMsg'
import Select from 'react-select'
import Spinner from 'react-bootstrap/Spinner'

interface ItemCreateProps {
	show: boolean;
	handleClose: any;
	salesOrderInfo: any;
	id: number;
}

interface PaymentData {
	paymentType: string;
	idSalesOrders: number;
	idSalesInvoice: number;
	idProjects: number;
	idCompanies: number;
	projectName: string;
	remarks: string;
	paymentDate: string;
	paymentMethod: string;
	paymentAmount: number;
	transactionId: string;
	idBanks: number;
	idBankCash: number;
	idSr: number;
	salesType: string;
}

interface ProjectAmount {
	invoice: number;
	payment: number;
	shipment: number;
}

const initialPaymentData: PaymentData = {
	paymentType: 'advance',
	idSalesOrders: 0,
	idSalesInvoice: 0,
	idProjects: 0,
	idCompanies: 0,
	idBanks: 0,
	idBankCash: 0,
	idSr: 0,
	paymentAmount: 0,
	projectName: '',
	remarks: '',
	paymentDate: '',
	paymentMethod: 'bank_transfer',
	transactionId: '',
	salesType: '',
}

const initialProjectAmount: ProjectAmount = {
	invoice: 0,
	payment: 0,
	shipment: 0
}

const SalesPaymentCreate: React.FC<ItemCreateProps> = ({ show, handleClose, salesOrderInfo, id }) => {

	const [maxPayableAmount, setMaxPayableAmount] = useState(0);
	const [paymentType, setPaymentType] = useState('advance');
	const [paymentMethod, setPaymentMethod] = useState('bank_transfer');
	const [projects, setProjects] = useState([]);
	const [project, setProject] = useState({ label: 'Select Project', value: '' });
	const [banks, setBanks] = useState([]);
	const [bank, setBank] = useState({ label: 'Select Bank', value: '' });
	const [mobileWallets, setMobileWallets] = useState([]);
	const [mobileWallet, setMobileWallet] = useState({ label: 'Select Mobile Wallet', value: '' });
	const [cashBoxes, setCashBoxes] = useState([]);
	const [cashBox, setCashBox] = useState({ label: 'Select Cash Box', value: '' });
	const [accounts, setAccounts] = useState([]);
	const [account, setAccount] = useState({ label: 'Select Account Number', value: '' });
	const [chequeAccounts, setChequeAccounts] = useState([]);
	const [chequeAccount, setChequeAccount] = useState({ label: 'Select Account Number', value: '' });
	const [salesOrders, setSalesOrders] = useState([]);
	const [salesOrder, setSalesOrder] = useState({ label: 'Select Sales Order', value: '' });
	const [salesInvoices, setSalesInvoices] = useState([]);
	const [salesInvoice, setSalesInvoice] = useState({ label: 'Select Sales Invoice', value: '' });
	const [srs, setSrs] = useState([]);
	const [sr, setSr] = useState({ label: 'Select SR', value: '' });
	const [companies, setCompanies] = useState([]);
	const [company, setCompany] = useState({ label: 'Select Client', value: '' });
	const [paymentData, setPaymentData] = useState<PaymentData>(initialPaymentData);
	const [projectAmount, setProjectAmount] = useState<ProjectAmount>(initialProjectAmount);

	useEffect(() => {

		setPaymentData(initialPaymentData);
		setProjectAmount(initialProjectAmount)
		setSalesInvoice({ label: 'Select Sales Invoice', value: '' })
		setSalesOrder({ label: 'Select Sales Order', value: '' })
		setChequeAccount({ label: 'Select Account Number', value: '' })
		setAccount({ label: 'Select Account Number', value: '' })
		setCashBox({ label: 'Select Cash Box', value: '' })
		setSr({ label: 'Select SR', value: '' })
		setMobileWallet({ label: 'Select Mobile Wallet', value: '' })
		setBank({ label: 'Select Bank', value: '' })
		setProject({ label: 'Select Project', value: '' })
		setPaymentMethod('bank_transfer')
		setPaymentType('advance')
		setMaxPayableAmount(0)

		getProjects()
		getCompanies()

		if (salesOrderInfo && salesOrderInfo.idSalesOrders !== undefined) {

			getProjectAmountInfo(salesOrderInfo.id_projects)

			if (salesOrderInfo.idSalesInvoice === undefined) {
				getSalesInvoices(salesOrderInfo.idSalesOrders)
			}

			if (salesOrderInfo.idSalesInvoice !== 0) {
				setPaymentType('regular')
				setPaymentData({ ...paymentData, paymentType: 'regular', idCompanies: salesOrderInfo.id_companies, idSalesOrders: salesOrderInfo.idSalesOrders, idProjects: salesOrderInfo.id_projects, idSalesInvoice: salesOrderInfo.idSalesInvoice });
			} else {
				setPaymentData({ ...paymentData, paymentType: 'advance', idSalesOrders: salesOrderInfo.idSalesOrders, idProjects: salesOrderInfo.id_projects, idSalesInvoice: salesOrderInfo.idSalesInvoice });
			}
		}

	}, [salesOrderInfo, id]);

	const [isSubmitDisable, setSubmitDisable] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {

		if (paymentData.idProjects !== 0) {
			getAllSalesRepresentative(paymentData.idProjects)
			getMobileWallets(paymentData.idProjects)
			getCashBoxes(paymentData.idProjects)
			getChequeAccounts(paymentData.idProjects)
			getBanks(paymentData.idProjects)
			setBank({ label: 'Select Bank', value: '' })
			setAccount({ label: 'Select Account Number', value: '' })
			setCashBox({ label: 'Select Cash Box', value: '' })
			setMobileWallet({ label: 'Select Mobiel Wallet', value: '' })
			setChequeAccount({ label: 'Select Account Number', value: '' })
			setSr({ label: 'Select SR', value: '' })
		}


	}, [paymentData.idProjects]);

	const handleChange = (e: any) => {
		if (paymentType === 'regular' && e.target.name === 'paymentAmount') {
			if (parseFloat(e.target.value) > maxPayableAmount) {
				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: 'Max Payable Amount Exceed!',
				})
				e.target.value = '';
				return
			}
		}
		setPaymentData({ ...paymentData, [e.target.name]: e.target.value });
	};

	const handleProjectChange = (project: any) => {
		setProject(project);
		getOrdersByProjects(project.value);

		setSalesOrder({ label: 'Select Sales Order', value: '' })
		setSalesInvoice({ label: 'Select Sales Invoice', value: '' })
		setPaymentData({ ...paymentData, idProjects: project.id, idSr: 0, idSalesOrders: 0, idSalesInvoice: 0, idBanks: 0, idBankCash: 0, paymentAmount: 0 });
	}

	const getOrdersByProjects = (idProjects: number) => {
		fetch(
			process.env.REACT_APP_API_URL + 'sales-order-by-project/' + idProjects + '?type=' + paymentType,
			getRequestOptions()
		)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				let salesOrders = resp.data.map((item: any) => {
					item.label = 'Order # ' + item.id + '; Amount:' + item.order_amount + '; Client:' + item.company_name;
					item.value = item.id;
					return item
				})
				setSalesOrders(salesOrders);
				setProjectAmount({ ...projectAmount, invoice: parseFloat(resp.total.invoice), payment: parseFloat(resp.total.payment), shipment: parseFloat(resp.total.shipment) });
			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})
	}

	const getAllSalesRepresentative = (idProjects: number) => {

		fetch(
			process.env.REACT_APP_API_URL + 'getAllSr?idProjects=' + idProjects,
			getRequestOptions()
		)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {

				let users = resp.map((item: any) => {
					item.label = item.first_name + ' ' + item.last_name;
					item.value = item.id;
					return item
				})
				setSrs(users);

			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})
	}

	const getProjects = () => {
		fetch(
			process.env.REACT_APP_API_URL + 'lead/projects',
			getRequestOptions()
		)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				let projects = resp.data.map((item: any) => {
					item.label = item.name;
					item.value = item.id;
					return item
				})
				setProjects(projects);
			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})
	}

	const getCompanies = () => {
		fetch(
			process.env.REACT_APP_API_URL + 'lead/companies',
			getRequestOptions()
		)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				let companiesObj = resp.data.map((item: any) => {
					item.label = item.name;
					item.value = item.id;
					return item
				})
				setCompanies(companiesObj);
			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})
	}

	const getMaxPaymentAmount = () => {

		fetch(
			process.env.REACT_APP_API_URL + 'sales-payment/get-max-payable-amount',
			postRequestOptions({ paymentType: 'advance', idSalesOrders: paymentData.idSalesOrders, idSalesInvoice: paymentData.idSalesInvoice })
		)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				setMaxPayableAmount(resp.maxPayableAmount);
			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})
	}

	const getChequeAccounts = (idProjects: number) => {
		fetch(
			process.env.REACT_APP_API_URL + 'chequeAccounts?idProjects=' + idProjects,
			getRequestOptions()
		)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				let accounts = resp.data.map((item: any) => {
					item.label = item.accountNumber;
					item.value = item.idBankCash;
					return item
				})
				setChequeAccounts(accounts);
			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})
	}

	const getBanks = (idProjects: number) => {
		fetch(
			process.env.REACT_APP_API_URL + 'cashBanks?idProjects=' + idProjects,
			getRequestOptions()
		)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				let banks = resp.data.map((item: any) => {
					item.label = item.bankNameShort;
					item.value = item.idBanks;
					return item
				})
				setBanks(banks);
			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})
	}

	const getProjectAmountInfo = (idProjects: number) => {

	}

	const getSalesInvoices = (idSalesOrders: number) => {
		fetch(
			process.env.REACT_APP_API_URL + 'sales-invoice-by-orders/' + idSalesOrders,
			getRequestOptions()
		)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				let invoices = resp.data.map((item: any) => {
					item.label = 'Invoice # ' + item.invoice_id + '(ID # ' + item.id + ')';
					item.value = item.id;
					return item
				})
				setSalesInvoices(invoices);
			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})
	}

	const getMobileWallets = (idProjects: number) => {
		fetch(
			process.env.REACT_APP_API_URL + 'mobileWallets?idProjects=' + idProjects,
			getRequestOptions()
		)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				let wallets = resp.data.map((item: any) => {
					item.label = item.walletName + ' - ' + item.walletNumber;
					item.value = item.idBankCash;
					return item
				})
				setMobileWallets(wallets);
			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})
	}

	const getCashBoxes = (idProjects: number) => {
		fetch(
			process.env.REACT_APP_API_URL + 'cashboxes?idProjects=' + idProjects,
			getRequestOptions()
		)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				let boxes = resp.data.map((item: any) => {
					item.label = item.cashBoxName + ' - ' + item.cashBoxLocation;
					item.value = item.idBankCash;
					return item
				})
				setCashBoxes(boxes);
			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})
	}

	const salesPaymentSave = async (event: any) => {
		setLoading(true);
		event.preventDefault()
		setSubmitDisable(true);
		const confirm = await Swal.fire({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#ec008c',
			cancelButtonText: 'Cancel',
			confirmButtonText: 'Yes, Submit it!',
		})
		if (confirm.value) {

			const postData = {
				salesPayment: paymentData,
			}
			const requestOptions = postRequestOptions(postData)
			try {
				let response = await fetch(
					`${process.env.REACT_APP_API_URL}sales-payment`,
					requestOptions
				)
				let respData = await response.json()
				if (respData.success === true) {

					const successMsg = respData.successMessage
					Swal.fire({
						icon: 'success',
						title: 'Success',
						text: successMsg,
					})
					handleClose(true)

				} else {
					const errorsMessage = validationErrorMsg(respData.errors).join('<br>')
					Swal.fire({
						icon: 'error',
						title: 'Error',
						html: errorsMessage,
					})
				}
				setSubmitDisable(false);
				setLoading(false);

			} catch (error) {
				setLoading(false);
				console.log(error, 'catch the hoop')
				setSubmitDisable(false);
			}
		}
	}

	const handleSalesOrderChange = (salesOrder: any) => {
		setSalesOrder(salesOrder);
		setPaymentData({ ...paymentData, idSalesOrders: salesOrder.value, idCompanies: salesOrder.id_companies });
		getSalesInvoices(salesOrder.value);
	}

	useEffect(() => {
		getMaxPaymentAmount();
	}, [paymentData.idSalesOrders, paymentData.idSalesInvoice]);

	const handleSalesInvoiceChange = (salesInvoice: any) => {
		setSalesInvoice(salesInvoice);
		setPaymentData({ ...paymentData, idSalesInvoice: salesInvoice.value });
	}

	const handleBankAccountChange = (account: any) => {
		setAccount(account);
		setPaymentData({ ...paymentData, idBankCash: account.value });
	}

	const handleCompanyChange = (comapny: any) => {
		setCompany(comapny);
		setPaymentData({ ...paymentData, idCompanies: comapny?.value });
	}

	const handleChequeAccountChange = (account: any) => {
		setChequeAccount(account);
		setPaymentData({ ...paymentData, idBankCash: account.value });
	}

	const handleSrChange = (sr: any) => {
		setSr(sr);
		setPaymentData({ ...paymentData, idSr: sr.value });
	}

	const handleBankChange = (bank: any) => {
		setBank(bank);
		setPaymentData({ ...paymentData, idBanks: bank.value });
		fetch(
			process.env.REACT_APP_API_URL + 'bankAccounts/' + bank.value + '?idProjects=' + paymentData.idProjects,
			getRequestOptions()
		)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				setAccount({ label: 'Select Account Number', value: '' })
				let accounts = resp.data.map((item: any) => {
					item.label = item.accountNumber;
					item.value = item.idBankCash;
					return item
				})
				setAccounts(accounts);
			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})
	}

	const handlePaymentTypeChange = (e: any) => {
		setPaymentType(e.target.value);
		setProject({ label: 'Select Project', value: '' })
		setSalesOrder({ label: 'Select Sales Order', value: '' })
		setSalesInvoice({ label: 'Select Sales Invoice', value: '' })
		setSr({ label: 'Select SR', value: '' })
		setPaymentData({ ...paymentData, [e.target.name]: e.target.value, idProjects: 0, idSalesOrders: 0, idSalesInvoice: 0 });
	};

	const handleMobileWalletChange = (mobileWallet: any) => {
		setMobileWallet(mobileWallet);
		setPaymentData({ ...paymentData, idBankCash: mobileWallet.value });
	};

	const handleCashboxChange = (cashBox: any) => {
		setCashBox(cashBox);
		setPaymentData({ ...paymentData, idBankCash: cashBox.value });
	};

	const handleSelectOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setPaymentData({ ...paymentData, [event.target.id]: event.target.value });
		setPaymentMethod(event.target.value)
	};


	return (
		<Modal show={show} onHide={() => handleClose(false)} size="xl">
			<form onSubmit={salesPaymentSave}>
				<Modal.Header closeButton>
					{paymentData.idSalesOrders !== 0 &&
						<Modal.Title>Sales Order Payment (Order No # {paymentData.idSalesOrders})</Modal.Title>
					}
					{paymentData.idSalesOrders === 0 &&
						<Modal.Title>Create New Sales Payment</Modal.Title>
					}
				</Modal.Header>
				<Modal.Body>
					<div className="form-group row">
						<label htmlFor="paymentType" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Payment Type <span className="required text-danger"></span></label>
						<div className="col-lg-5">
							{salesOrderInfo === null &&
								<>
									<label>

										<input
											name="paymentType"
											type="radio"
											value="advance"
											checked={paymentType === 'advance'}
											onChange={handlePaymentTypeChange}
										/>
										&nbsp;Advance/Bulk Payment
									</label>
									&nbsp;&nbsp;&nbsp;&nbsp;
								</>
							}
							<label>
								<input
									name="paymentType"
									type="radio"
									value="regular"
									checked={paymentType === 'regular'}
									onChange={handlePaymentTypeChange}
								/>
								&nbsp;Regular
							</label>

						</div>
					</div>
					<div className="form-group row">
						<label htmlFor="project" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Select Project <span className="required text-danger"></span></label>
						<div className="col-lg-5">

							{salesOrderInfo === null &&
								<Select
									value={project}
									onChange={handleProjectChange}
									options={projects}
									menuPosition="fixed"
								/>
							}
							{(salesOrderInfo !== null) &&
								<span>{salesOrderInfo.projectName}</span>
							}


						</div>
					</div>

					{paymentType === 'advance' &&
						<>
							<div className="form-group row">
								<label htmlFor="project" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Select Client <span className="required text-danger"></span></label>
								<div className="col-lg-5">

									{salesOrderInfo === null &&
										<Select
											value={company}
											onChange={handleCompanyChange}
											options={companies}
											menuPosition="fixed"
											isClearable={true}
										/>
									}
									{(salesOrderInfo !== null) &&
										<span>{salesOrderInfo.projectName}</span>
									}

								</div>
							</div>
							<div className="form-group row">
								<label htmlFor="project" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Select SR </label>
								<div className="col-lg-5">
									<Select
										value={sr}
										onChange={handleSrChange}
										options={srs}
										menuPosition="fixed"
										isClearable={true}
									/>
								</div>
							</div>
							<div className="form-group row">
								<label htmlFor="salesType" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Sales Type</label>
								<div className="col-lg-5">
									<label>
										<input
											name="salesType"
											type="radio"
											value="Inter Business Unit"
											onChange={handleChange}
										/>
										&nbsp;Inter Business Unit
									</label>
									&nbsp;&nbsp;&nbsp;&nbsp;

									<label>
										<input
											name="salesType"
											type="radio"
											value="Corporate"
											onChange={handleChange}
										/>
										&nbsp;Corporate
									</label>
								</div>
							</div>
						</>
					}

					{/* <div className="form-group row">
						<label htmlFor="companyName" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}></label>
						<div className="col-lg-5">Total Shipment Amount : {projectAmount.shipment}; Total Invoice Amount : {projectAmount.invoice}; Total Payment Made: {projectAmount.payment}</div>
					</div> */}

					{paymentType === 'regular' &&
						<>
							<div className="form-group row">
								<label htmlFor="idCompanyProjects" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Select Sales Order <span className="required text-danger"></span></label>
								<div className="col-lg-5">
									{salesOrderInfo === null &&
										<Select
											value={salesOrder}
											onChange={handleSalesOrderChange}
											options={salesOrders}
											menuPosition="fixed"
										/>
									}
									{salesOrderInfo !== null &&
										<div>Order # {salesOrderInfo.idSalesOrders}</div>
									}
								</div>
							</div>

							<div className="form-group row">
								<label htmlFor="idCompanyProjects" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Select Sales Invoice <span className="required text-danger"></span></label>
								<div className="col-lg-5">
									{(salesOrderInfo === null || salesOrderInfo.idSalesInvoice === undefined) &&
										<Select
											value={salesInvoice}
											onChange={handleSalesInvoiceChange}
											options={salesInvoices}
											menuPosition="fixed"
										/>
									}
									{(salesOrderInfo !== null && salesOrderInfo.idSalesInvoice !== undefined) &&
										<div>Invoice # {paymentData.idSalesInvoice}</div>
									}
								</div>
							</div>
						</>
					}
					<div className="form-group row">
						<label htmlFor="idCompanyProjects" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Payment Method <span className="required text-danger"></span></label>
						<div className="col-lg-5">
							<select name='paymentMethod' id="paymentMethod" onChange={handleSelectOnChange} className="form-control form-control-sm">
								<option value={'bank_transfer'}>Bank Transfer</option>
								<option value={'cash'}>Cash</option>
								<option value={'cheque'}>Cheque</option>
								<option value={'mobile_wallet'}>Mobile Wallet</option>
							</select>
						</div>
					</div>
					{paymentMethod === 'bank_transfer' &&
						<>
							<div className="form-group row">
								<label htmlFor="idBanks" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Deposit To Bank <span className="required text-danger"></span></label>
								<div className="col-lg-5">
									<Select
										value={bank}
										onChange={handleBankChange}
										options={banks}
										menuPosition="fixed"
									/>
								</div>
							</div>
							<div className="form-group row">
								<label htmlFor="idBanks" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Deposit Account Number <span className="required text-danger"></span></label>
								<div className="col-lg-5">
									<Select
										value={account}
										onChange={handleBankAccountChange}
										options={accounts}
										menuPosition="fixed"
									/>
								</div>
							</div>
						</>
					}

					{paymentMethod === 'cheque' &&
						<div className="form-group row">
							<label htmlFor="idBanks" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Select Account Number <span className="required text-danger"></span></label>
							<div className="col-lg-5">
								<Select
									value={chequeAccount}
									onChange={handleChequeAccountChange}
									options={chequeAccounts}
									menuPosition="fixed"
								/>
							</div>
						</div>
					}

					{paymentMethod === 'mobile_wallet' &&
						<div className="form-group row">
							<label htmlFor="idMobileWallet" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Select Mobile Wallet <span className="required text-danger"></span></label>
							<div className="col-lg-5">
								<Select
									value={mobileWallet}
									onChange={handleMobileWalletChange}
									options={mobileWallets}
									menuPosition="fixed"
								/>
							</div>
						</div>
					}

					{paymentMethod === 'cash' &&
						<div className="form-group row">
							<label htmlFor="idCashBoxes" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Select Cash Box <span className="required text-danger"></span></label>
							<div className="col-lg-5">
								<Select
									value={cashBox}
									onChange={handleCashboxChange}
									options={cashBoxes}
									menuPosition="fixed"
								/>
							</div>
						</div>
					}

					<div className="form-group row">
						<label htmlFor="paymentDate" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Payment Date <span className="required text-danger"></span></label>
						<div className="col-lg-5">
							<input type="date" name="paymentDate" id="paymentDate" value={paymentData.paymentDate} onChange={handleChange} className="form-control form-control-sm" />
						</div>
					</div>
					<div className="form-group row">
						<label htmlFor="paymentAmount" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Payment Amount <span className="required text-danger"></span></label>
						<div className="col-lg-5">
							<input type="number" name="paymentAmount" step={'any'} id="paymentAmount" onChange={handleChange} className="form-control form-control-sm" />
							{paymentType === 'regular' &&
								<>Max Payable Amount : {maxPayableAmount}</>
							}
						</div>
					</div>

					{paymentMethod !== 'cash' &&
						<div className="form-group row">
							<label htmlFor="transactionId" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Transaction ID <span className="required text-danger"></span></label>
							<div className="col-lg-5">
								<input type="text" name="transactionId" id="transactionId" value={paymentData.transactionId} onChange={handleChange} className="form-control form-control-sm" />
							</div>
						</div>
					}

					<div className="form-group row">
						<label htmlFor="remarks" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Remarks</label>
						<div className="col-lg-5">
							<textarea rows={4} name="remarks" id="remarks" onChange={handleChange} className="form-control form-control-sm"></textarea>
						</div>
					</div>


					{/* <pre>{JSON.stringify(paymentData, null, 2)}</pre>
					<pre>{JSON.stringify(salesOrderInfo, null, 2)}</pre> */}
				</Modal.Body>
				<Modal.Footer>

					<button type="submit" disabled={isSubmitDisable} className="btn btn-sm btn-primary me-2">
						{loading &&
							<Spinner
								as="span"
								animation="grow"
								size="sm"
								role="status"
								aria-hidden="true"
							/>
						}
						<span>  Submit</span>
					</button>
					<Button variant='secondary' size="sm" onClick={() => handleClose(false)}>
						Close
					</Button>
				</Modal.Footer>
			</form>
		</Modal >
	)
}

export default SalesPaymentCreate