import React, { useState, useEffect } from 'react'
import { Table } from 'react-bootstrap-v5';
import { getRequestOptions } from '../helpers/Fetchwrapper'
import Select from 'react-select'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


interface Product {
    productName: string;
    idItems: number;
    unitName: string;
    packing: number;
    size: number;
    productionCost: number;
    previousCost: number;
    salePerUnit: number;
    costPerUnit: number;
    profitMargin: number;
    discountPercentAdmin: number;
    discountPercentSr: number;
    [key: string]: any; // Index signature
}

interface Category {
    id_item_categoriess: number;
    category_name: string;
    products: Product[];
}

interface Item {
    idItems: number;
    itemName: string;
    itemCode: string;
    categoryName: string;
    unitName: string;
}

export default function Products() {
    const [categories, setCategories] = useState<Category[]>([]);
    const [items, setItems] = useState<Item[]>([]);
    const [searchTerm, setSearchTerm] = useState('');

    const [projects, setProjects] = useState([]);
    const [project, setProject] = useState({ label: 'Select Project', value: '' });

    useEffect(() => {
        getAllProjects();
        getProducts(null);
        getItems();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getAllProjects = async () => {

        const url = process.env.REACT_APP_API_URL + 'projects';
        const response = await fetch(url, getRequestOptions());
        const data = await response.json();

        data.data.map((element: any) => {
            element.value = element.id_projects;
            element.label = element.project_name;
            return element;
        })

        if (data.data.length === 1) {
            setProjects(data.data)

        } else {
            var allProjectsOpt = [{ id: '', name: '', label: 'All Project', value: '' }]
            let allProjects = data.data.concat(allProjectsOpt);
            setProjects(allProjects);
        }

    }

    const handleProjectChange = (project: any) => {
        setProject(project);
        getProducts(project);
    }

    const getProducts = (project: any) => {
        //get Categories
        fetch(
            process.env.REACT_APP_API_URL + 'allProductCategories?idProjects=' + ((project === null) ? '' : project.value),
            getRequestOptions()
        )
            .then((resp) => resp.json())
            .then((resp) => {
                let categories = resp.map((item: any) => {
                    item.label = item.category_name;
                    item.value = item.id_item_categoriess;
                    return item
                });
                setCategories(categories);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const getItems = () => {
        //get Categories
        fetch(
            process.env.REACT_APP_API_URL + 'production-items?idProjects=' + ((project === null) ? '' : project.value),
            getRequestOptions()
        )
            .then((resp) => resp.json())
            .then((resp) => {
                let items = resp.data.map((item: any) => {
                    return item
                });
                setItems(items);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    }

    const filterProducts = (category: any) => {
        const filteredProducts = category.products?.filter((product: any) => {
            const name = product.productName.toLowerCase();
            const searchTermLower = searchTerm.toLowerCase();
            return name.includes(searchTermLower);
        }) || [];
        return filteredProducts;
    }

    // const filterProducts = (category: any) => {
    //     const filteredProducts = category.products.filter((product:any) => {
    //         const name = product.productName.toLowerCase();
    //         const searchTermLower = searchTerm.toLowerCase();
    //         return name.includes(searchTermLower);
    //     });
    //     return filteredProducts;
    // }

    useEffect(() => {
        if (projects.length === 1) {
            setProject({ label: projects[0]['label'], value: projects[0]['value'] })
        }
    }, [projects])

    return (
        <>
            <div className='card card-custom'>
                <div className='card-header card-header-stretch overflow-auto'>
                    <div className='card-title'>
                        <h3 className='card-label'>All Product List</h3>
                    </div>
                </div>
                <div className="card-body">

                    <Tabs
                        defaultActiveKey="product"
                        id="uncontrolled-tab-example"
                        className="mb-3 ml-3 mr-3"
                    >
                        <Tab eventKey="product" title="Product">

                            <div className="form-group row">
                                <label htmlFor="project" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Project</label>
                                <div className="col-lg-5">
                                    <Select
                                        value={project}
                                        onChange={handleProjectChange}
                                        options={projects}
                                        menuPosition="fixed"
                                        placeholder={project.label}
                                    />
                                </div>
                            </div>

                            <div className='row mt-6'>
                                <div className='col-md-8'></div>
                                <div className='col-md-4 mb-3'>
                                    <div className='d-flex align-items-center'>
                                        <input
                                            type='text'
                                            className='form-control form-control-sm'
                                            placeholder='Search...'
                                            value={searchTerm}
                                            onChange={handleSearch}
                                        />
                                    </div>
                                </div>
                            </div>




                            <Table size='sm' striped bordered hover>
                                <thead>
                                    <tr>
                                        <th style={{ width: '36%' }}>Category/Product Name</th>
                                        <th className='text-center' style={{ width: '8%' }}>Packing</th>
                                        <th className='text-center' style={{ width: '8%' }}>Size</th>
                                        {/* <th className='text-center' style={{ width: '8%' }}>Product Cost/Unit</th> */}
                                        <th className='text-end' style={{ width: '8%' }}>Sale/Unit</th>
                                        {/* <th className='text-end' style={{ width: '8%' }}>Sale/Pack</th> */}
                                        <th className='text-end' style={{ width: '8%' }}>SR Discount (%)</th>
                                    </tr>
                                </thead>

                                {categories?.map((category, catIndex) => (
                                    <tbody key={catIndex}>
                                        <tr>
                                            <td colSpan={8}><b>{category.category_name}</b></td>
                                        </tr>

                                        {filterProducts(category).map((product, productIndex) => (
                                            <tr key={catIndex + '.' + productIndex}>
                                                <td className='ps-5'>{product.productName}</td>
                                                <td className='text-center'>{product.packing}</td>
                                                <td className='text-center'>{product.size + ' ' + product.unitName}</td>
                                                {/* <td className='text-center'>{product.productionCost}</td> */}
                                                <td className='text-end'>{Number(product.salePerUnit).toLocaleString('en-US', {
                                                    style: 'decimal',
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                })}</td>
                                                {/* <td className='text-end'>{(product.salePerUnit * product.size).toLocaleString()}</td> */}
                                                <td className='text-end'>{product.discountPercentSr ? product.discountPercentSr : 0.00}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                ))}
                            </Table>


                        </Tab>
                        <Tab eventKey="item" title="Item">
                            {/* <pre>{JSON.stringify(items, null, 2)}</pre> */}

                            <Table size='sm' striped bordered hover>
                                <thead>
                                    <tr>
                                        <th style={{ width: '25%' }}>Item Name</th>
                                        <th className='text-center' style={{ width: '25%' }}>Item Code</th>
                                        <th className='text-center' style={{ width: '25%' }}>Item Category</th>
                                        <th className='text-center' style={{ width: '25%' }}>Unit</th>
                                    </tr>
                                </thead>

                                {items?.map((item, itemIndex) => (
                                    <tbody key={itemIndex}>
                                        <tr>
                                            <td>{item.itemName}</td>
                                            <td className='text-center'>{item.itemCode}</td>
                                            <td className='text-center'>{item.categoryName}</td>
                                            <td className='text-center'>{item.unitName}</td>
                                        </tr>
                                    </tbody>
                                ))}
                            </Table>
                        </Tab>


                    </Tabs>
                </div>



            </div>

        </>
    )
}
