/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import { Modal, Button } from 'react-bootstrap'
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper'
import validationErrorMsg from '../helpers/ValidationErrorMsg'
import Spinner from 'react-bootstrap/Spinner'
import Select from 'react-select'

interface ProductGroupCreateProps {
	show: boolean;
	handleClose: any;
	id: number;
}

interface InitialState {
	idItems: number | null;
	itemName: string;
	itemCode: string;
	idUnits: number | null;
	isProduct: string;
}

const ProductGroupCreate: React.FC<ProductGroupCreateProps> = ({ show, handleClose, id }) => {

	const [units, setUnits] = useState([]);
	const [unit, setUnit] = useState({ label: 'Select Unit', value: '' });
	const initialState: InitialState = {
		idItems: null,
		itemName: '',
		itemCode: '',
		idUnits: null,
		isProduct: 'Yes',
	};

	const [item, setItem] = useState(initialState);

	useEffect(() => {
		getAllUnits()
	}, [])

	useEffect(() => {
		if (id === 0) {
			setItem({ idItems: null, itemName: '', itemCode: '', idUnits: null, isProduct: 'Yes' });
			setUnit({ label: 'Select Unit', value: '' })
		} else {
			fetch(
				process.env.REACT_APP_API_URL + 'items/' + id,
				getRequestOptions()
			)
				.then((resp) => {
					return resp.json()
				})
				.then((resp) => {
					setItem({ idItems: resp.data.idItems, itemName: resp.data.itemName, itemCode: resp.data.itemCode, idUnits: resp.data.idUnits, isProduct: resp.data.isProduct });
					setUnit({ label: resp.data.unitName, value: resp.data.idUnits })
				})
				.catch((error) => {
					console.log(error, 'catch the hoop')
				})
		}
	}, [id]);

	const handleUnitChange = (unit: any) => {
		setUnit(unit);
		setItem({ ...item, idUnits: unit.id_units });
	}

	const getAllUnits = () => {
		fetch(
			process.env.REACT_APP_API_URL + 'allUnits',
			getRequestOptions()
		)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {

				let units = resp.map((item: any) => {
					item.label = item.unit_name;
					item.value = item.id_units;
					return item
				})
				setUnits(units);
			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})
	}

	const [isSubmitDisable, setSubmitDisable] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleChange = (e: any) => {
		setItem({ ...item, [e.target.name]: e.target.value });
	};

	const itemSave = async (event: any) => {
		event.preventDefault()
		setSubmitDisable(true);
		setLoading(true);
		const confirm = await Swal.fire({
			title: 'Are you sure?',
			text: "You want to " + (id === 0 ? "create" : "update") + " this Product Group?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#ec008c',
			cancelButtonText: 'Cancel',
			confirmButtonText: 'Yes, Submit it!',
		})
		if (confirm.value) {
			const requestOptions = postRequestOptions(item)
			try {
				let url = (id === 0) ? `${process.env.REACT_APP_API_URL}items/store` : `${process.env.REACT_APP_API_URL}items/update`
				let response = await fetch(
					url,
					requestOptions
				)
				let respData = await response.json()
				if (respData.success === true) {
					const successMsg = respData.successMessage
					Swal.fire({
						icon: 'success',
						title: 'Success',
						text: successMsg,
					})
					handleClose(respData.success)
				} else {
					const errorsMessage = validationErrorMsg(respData.errors).join('<br>')
					Swal.fire({
						icon: 'error',
						title: 'Error',
						html: errorsMessage,
					})
				}
				setSubmitDisable(false);
				setLoading(false);
			} catch (error) {
				console.log(error, 'catch the hoop')
				setSubmitDisable(false);
			}
		}
	}


	return (
		<Modal show={show} onHide={() => handleClose(false)} size="lg">
			<form onSubmit={itemSave}>
				<Modal.Header closeButton>
					<Modal.Title>{id === 0 ? 'Create' : 'Edit'} Product Group</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="form-group row">
						<label htmlFor="itemName" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Name <span className="required text-danger"></span></label>
						<div className="col-lg-5">
							<input type="text" name="itemName" id="itemName" value={item.itemName} onChange={handleChange} className="form-control form-control-sm" />
						</div>
					</div>
					<div className="form-group row">
						<label htmlFor="itemCode" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Code <span className="required text-danger"></span></label>
						<div className="col-lg-5">
							<input type="text" name="itemCode" id="itemCode" value={item.itemCode} onChange={handleChange} className="form-control form-control-sm" />
						</div>
					</div>
					<div className="form-group row">
						<label htmlFor="idUnits" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Unit <span className="required text-danger"></span></label>
						<div className="col-lg-5">
							<Select
								value={unit}
								onChange={handleUnitChange}
								options={units}
								menuPosition="fixed"
							/>
						</div>
					</div>

					{/* <pre>{JSON.stringify(item, null, 2)}</pre> */}
				</Modal.Body>
				<Modal.Footer>

					<button type="submit" disabled={isSubmitDisable || loading} className="btn btn-sm btn-primary me-2">
						{loading &&
							<Spinner
								as="span"
								animation="grow"
								size="sm"
								role="status"
								aria-hidden="true"
							/>
						}
						<span>  Submit</span>
					</button>
					<Button variant='secondary' size="sm" onClick={() => handleClose(false)}>
						Close
					</Button>
				</Modal.Footer>
			</form>
		</Modal>
	)
}

export default ProductGroupCreate
