/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { KTSVG } from '../../_metronic/helpers';
import { Modal, Button, Form } from 'react-bootstrap';
import { iconArray } from "./LeadActivities";
import Select from 'react-select';
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper';
// import { AppContext } from '../context/AppContext';
import Swal from 'sweetalert2'
import validationErrorMsg from '../helpers/ValidationErrorMsg'
import scrollTo from "../helpers/ScrollTo";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

interface EventSubmitDataType {
    idLeads?: number,
    activityType?: string,
    activityAt?: string,
    activityDescription?: string,
    content?: string,
    activityStatus?: string,
}

interface EventProps {
    show: boolean;
    handleClose: (show: boolean) => void;
    id: number;
}

const ActivityListAddEvent: React.FC<EventProps> = ({ show, handleClose, id }) => {
    // const { currentUser } = useContext(AppContext);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedMethod, setSelectedMethod] = useState('visit');
    const [eventSubmitData, setEventSubmitData] = useState<EventSubmitDataType>({ activityType: 'visit', activityStatus: 'done' });
    const [location, setLocation] = useState<any>(null);

    const [leads, setLeads] = useState([]);
    const [lead, setLead] = useState({ label: 'Select Lead', value: '' });

    useEffect(() => {
        getLeads();
    }, [])

    const handleLeadChange = (lead: any) => {
        setLead(lead);
        setEventSubmitData({ ...eventSubmitData, idLeads: lead?.idLeads ?? null });

    }

    const getLeads = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'activities/leads',
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let leadObj = resp.data.map((item: any) => {
                    item.label = item.leadName;
                    item.value = item.idLeads;
                    return item
                })
                setLeads(leadObj);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        if (name === 'activityAt' || name === 'activityDescription' || name === 'content') {
            setEventSubmitData({ ...eventSubmitData, [name]: value.toString() });
        } else if (name === 'activityStatus') {
            setEventSubmitData({ ...eventSubmitData, [name]: value.toString() });
        }
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setIsLoading(true);
        setEventSubmitData({ ...eventSubmitData, activityType: selectedMethod });

        const requestOptions = postRequestOptions({ ...eventSubmitData, activityType: selectedMethod, location: location })
        try {
            let response = await fetch(
                `${process.env.REACT_APP_API_URL}lead/add-event`,
                requestOptions
            )
            let respData = await response.json()
            setIsLoading(false);
            if (respData.success === true) {
                window.scrollBy(0, 30);
                scrollTo(180, 20);
                handleClose(false);
            } else {
                const errorsMessage = validationErrorMsg(respData.errors).join('<br>')
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    html: errorsMessage,
                })
            }

        } catch (error) {
            setIsLoading(false);
        }

    }

    const onChangeLocation = (data: any) => {
        setLocation(JSON.stringify(data));
    }

    return (<Modal show={show} onHide={() => handleClose(false)} size="lg">
        <Modal.Header closeButton>
            <Modal.Title>Add Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>

            <Form.Group className="mb-3" controlId="lead">
                <Form.Label>Select Lead</Form.Label>
                <Select
                    value={lead}
                    onChange={handleLeadChange}
                    options={leads}
                    menuPosition="fixed"
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                />
            </Form.Group>
            <div className="row">
                {['visit', 'meeting', 'call', 'sms', 'email'].map((item, index) => {
                    return <div key={'at_' + index} className="col-sm-2">
                        <button onClick={() => { setSelectedMethod(item) }} className={"w-100 btn btn-outline btn-outline-dashed p-2 d-flex align-items-center justify-content-between mb-10 btn-active-light-" + (item === selectedMethod ? "primary" : "secondary")}>
                            <span className="d-block fw-semibold text-start text-dark fs-7">
                                {iconArray[item].text}
                            </span>
                            <KTSVG className="svg-icon-3" path={iconArray[item].icon} />
                        </button>
                    </div>
                })}
            </div>

            <Form onSubmit={handleSubmit}>
                <div className='fv-row mb-5 d-flex flex-start'>
                    <Form.Check // prettier-ignore
                        type={"radio"}
                        id={`default-checkbox-sc`}
                        label={`Scheduled`}
                        value={"scheduled"}
                        className='me-10 form-check-solid'
                        name="activityStatus"
                        checked={eventSubmitData.activityStatus === 'scheduled'}
                        onChange={handleChange}
                    />
                    <Form.Check // prettier-ignore
                        type={"radio"}
                        id={`default-checkbox-do`}
                        label={`Done`}
                        value={"done"}
                        className='me-10 form-check-solid'
                        name="activityStatus"
                        checked={eventSubmitData.activityStatus === 'done'}
                        onChange={handleChange}
                    />
                </div>
                <div className='fv-row mb-4'>
                    <label className='form-label required'>Purpose:</label>
                    <Form.Control placeholder="e.g. visit to factoy / call with manager" name='activityDescription' type="text" size={"sm"} className='form-control-solid' onChange={handleChange} />
                    <div className='text-danger mt-2'>
                        <Form.Control.Feedback />
                    </div>
                </div>

                <div className='fv-row mb-4'>
                    <label className='d-flex align-items-center form-label'>
                        <span style={{ textTransform: "capitalize" }} className='required'>{selectedMethod} date:</span>
                    </label>

                    <Form.Control
                        name='activityAt'
                        size={"sm"}
                        className='form-control-solid'
                        type="datetime-local"
                        onChange={handleChange}
                        max={eventSubmitData.activityStatus === 'done' ? new Date().toISOString().split('T')[0] + ' 23:59:59' : ''}
                        min={eventSubmitData.activityStatus !== 'done' ? new Date().toISOString().split('T')[0] + ' 00:00:00' : ''}
                    />
                    <div className='text-danger mt-2'>
                        <Form.Control.Feedback />
                    </div>
                </div>

                <div className='fv-row mb-4'>
                    <label className='form-label'>Outcome / Discussion:</label>

                    <Form.Control
                        as='textarea'
                        name='content'
                        className='form-control-solid'
                        rows={3}
                        size={"sm"}
                        placeholder='meeting minutes, discussion points, email body etc.'
                        onChange={handleChange}
                    ></Form.Control>
                </div>
                {(selectedMethod === 'visit' || selectedMethod === 'meeting') &&
                    <div className='fv-row mb-4'>
                        <label className='form-label'>Location:</label>
                        <div>
                            <GooglePlacesAutocomplete
                                apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
                                selectProps={{
                                    value: JSON.parse(location),
                                    onChange: (data) => { onChangeLocation(data) },
                                }}
                                // Use searchOptions to restrict results to Bangladesh
                                autocompletionRequest={{
                                    componentRestrictions: { country: ['bd'] }, // Restrict to Bangladesh
                                }}
                            />
                        </div>
                    </div>
                }
                <div className="d-flex justify-content-end px-3">
                    <Button disabled={isLoading} variant="success" size="sm" type="submit" className='me-2'>
                        {isLoading ? <i className='fa fa-spin fa-spinner'></i> : <><KTSVG className="svg-icon-3" path="/media/svg/icons/Communication/Send.svg" /> Submit</>}
                    </Button>
                    <Button variant='secondary' size="sm" onClick={() => handleClose(false)}>
                        Close
                    </Button>
                </div>
                {/* <pre>{JSON.stringify(eventSubmitData, null, 2)}</pre> */}
            </Form>
        </Modal.Body>
    </Modal>)
}

export default ActivityListAddEvent