/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { getRequestOptions } from '../helpers/Fetchwrapper';
import { KTSVG } from '../../_metronic/helpers';
import { Col, Row, Table } from 'react-bootstrap-v5';
import 'react-dropzone-uploader/dist/styles.css'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom';
import Dropzone from 'react-dropzone-uploader'
import Swal from 'sweetalert2'
import showLoader from '../helpers/ShowLoader';
import axios, { AxiosResponse, AxiosError } from 'axios'
import validationErrorMsg from '../helpers/ValidationErrorMsg'

interface PurchaseInfo {
    id_purchase: number;
    id_projects: number;
    id_vendors: number;
    purchaseType: string;
    purchaseDate: string;
    referenceNo: string;
    lcNo: string;
    chalanNo: string;
    chalanDate: string;
    remarks: string;
    purchaseAmount: number;
    created_by: number;
    createdAt: string;
    project: {
        id_projects: number;
        projectName: string;
    };
    vendor: { id_vendors: number, vendorName: string, phone: string, address: string };
    voucher: { id_acc_vouchers: number, voucherNo: string, voucherDate: string, voucherType: string };
    rows: { id_purchase: number, id_items: number, rate: number, quantity: number, item: any }[];
    files: { id_ref: number, refType: string, attachmentName: string, attachmentPath: string, fileSize: number, created_at: string, creator: any }[];
    creator: any;
    status: string;
}

interface RouteParams {
    idPurchase: string;
}

export function PurchaseDetails(props: any) {

    const [purchaseInfo, setPurchaseInfo] = useState<PurchaseInfo | null>(null);
    const { idPurchase }: RouteParams = useParams();

    useEffect(() => {
        if (idPurchase !== undefined) {
            getPurchaseInfo();
        }
    }, [idPurchase])

    const getPurchaseInfo = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'purchase/' + idPurchase,
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                const data: PurchaseInfo = resp.data;
                setPurchaseInfo(data);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }


    const numberFormat = (value: any) => {

        value = parseFloat(value)
        if (typeof (value) !== 'number') {
            return value;
        }
        return parseFloat(value.toString()).toLocaleString('en-US', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }


    const getReadableFileSizeString = (fileSizeInBytes: number) => {
        return ((fileSizeInBytes / (1024 * 1024)).toFixed(4) + " MB");
    }

    const jwt = () => {
        const myToken = localStorage.getItem('MyToken');
        return (myToken != null) ? JSON.parse(myToken) : null;
    }

    const handleFileSubmit = (files: any, allFiles: []) => {
        showLoader()

        const postFormData = new FormData();
        postFormData.append('idRef', idPurchase.toString());
        postFormData.append('refType', 'goods_receive');
        postFormData.append('folderPath', 'goods-receives');
        for (let i = 0; i < files.length; i++) {
            postFormData.append('files[]', files[i].file);
        }

        const url: string = `${process.env.REACT_APP_API_URL}file/upload`;

        axios({
            method: 'POST',
            url: url,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer ' + jwt(),
            },
            data: postFormData,
        })
            .then((response: AxiosResponse<any>) => {
                Swal.close();
                allFiles.forEach((f: any) => f.remove());
                getPurchaseInfo();
            })
            .catch((error: AxiosError<any>) => {
                Swal.close();
                // const errorMessage: string = error.response?.data?.errorMessage;
                const errorsMessage: string = validationErrorMsg(error?.response?.data?.errors).join('<br>');
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    html: errorsMessage,
                });
            });

    }

    return (<>
        <div className='card card-custom'>
            <div className='card-header card-header-stretch overflow-auto'>
                <div className='card-title'>
                    <h3 className='card-label'>Goods Received Details</h3>
                    <small className='text-muted'>| Information about the Goods Received</small>
                </div>
            </div>
            <div className="card-body">

            </div>
        </div>
        <Row>
            <Col md={4} className="pe-2">
                <div className="card card-flush overflow-hidden mt-3 ms-2 me-0">
                    <div className="card-header py-5">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bold text-dark">Summary</span>
                        </h3>
                    </div>
                    <div className="card-body pt-0">
                        <Table striped bordered hover size='sm'>
                            <tbody>
                                <tr>
                                    <td style={{ width: '50%' }}>ID</td>
                                    <td style={{ width: '50%' }}>{purchaseInfo?.id_purchase}</td>
                                </tr>
                                <tr>
                                    <td>Receive Type</td>
                                    <td>
                                        {
                                            purchaseInfo?.purchaseType === 'LC' ?
                                                <span className="badge badge-primary">LC</span>

                                                :
                                                <span className="badge badge-success">Local Purchase</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Project</td>
                                    <td>{purchaseInfo?.project?.projectName}</td>
                                </tr>
                                <tr>
                                    <td>Vendor</td>
                                    <td>{purchaseInfo?.vendor?.vendorName}</td>
                                </tr>
                                <tr>
                                    <td>Receive Date </td>
                                    <td>{purchaseInfo?.purchaseDate}</td>
                                </tr>
                                <tr>
                                    <td>Reference No </td>
                                    <td>{purchaseInfo?.referenceNo}</td>
                                </tr>
                                {
                                    purchaseInfo?.purchaseType === 'LC' && (
                                        <>
                                            <tr>
                                                <td>LC No</td>
                                                <td>{purchaseInfo?.lcNo}</td>
                                            </tr>
                                        </>
                                    )
                                }

                                <tr>
                                    <td>Chalan No</td>
                                    <td>{purchaseInfo?.chalanNo}</td>
                                </tr>
                                <tr>
                                    <td>Chalan Date</td>
                                    <td>{purchaseInfo?.chalanDate}</td>
                                </tr>
                                <tr>
                                    <td>Voucher No </td>
                                    <td>
                                        <span>{purchaseInfo?.voucher?.voucherNo} </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Status </td>
                                    <td>
                                        {purchaseInfo?.status === 'approved' && <span className="badge badge-success">Approved</span>}
                                        {purchaseInfo?.status === 'created' && <span className="badge badge-warning">Pending for Accountant Approval</span>}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Remarks</td>
                                    <td>{purchaseInfo?.remarks}</td>
                                </tr>
                                <tr>
                                    <td>Creator</td>
                                    <td>{purchaseInfo?.creator.firstName + ' ' + purchaseInfo?.creator.lastName + ' at ' + purchaseInfo?.createdAt}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
                <div className='card card-flush overflow-hidden mt-3 ms-2'>
                    <div className='card-header py-5'>
                        <h3 className="card-title">
                            <KTSVG className='svg-icon-3 me-2' path="/media/svg/icons/General/Clip.svg"></KTSVG>
                            Attach files
                        </h3>
                    </div>


                    <div className='card-body'>
                        <Table striped bordered hover size='sm'>
                            <thead>
                                <tr>
                                    <th style={{ width: '50%' }}>Attachment</th>
                                    <th style={{ width: '50%' }}>Uploaded By</th>
                                </tr>
                            </thead>
                            <tbody>
                                {purchaseInfo?.files?.length === 0 &&
                                    <tr>
                                        <td colSpan={2}>No File Attached</td>
                                    </tr>
                                }
                                {purchaseInfo?.files?.map((attachment, index) => (
                                    <tr>
                                        <td key={'attachment' + index}>
                                            <a rel='noreferrer' href={process.env.REACT_APP_AWS_URL + "goods-receives/" + attachment.attachmentPath}
                                                className="fs-6 text-hover-primary fw-bold" target="_blank">{attachment.attachmentName}</a>
                                            <div className="text-gray-400">{getReadableFileSizeString(attachment.fileSize)}</div>
                                        </td>
                                        <td>{attachment.creator.firstName + ' ' + attachment.creator.lastName + ' at ' + attachment.createdAt}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                        <Dropzone
                            styles={{ dropzone: { border: "none" } }}
                            accept='image/*,audio/*,application/pdf'
                            onSubmit={handleFileSubmit}
                        />
                    </div>
                </div>
            </Col>
            <Col md={8} className="pt-2">

                <div className="card card-flush overflow-hidden m-2 me-0">
                    <div className="card-header py-5">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bold text-dark">Items</span>
                        </h3>
                    </div>
                    <div className="card-body pt-0">
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th>SL No</th>
                                    <th>Item</th>
                                    <th>Item Code</th>
                                    <th>Item Category</th>
                                    <th className='text-center'>Received Quantity</th>
                                    <th className='text-center'>Rate</th>
                                    <th className='text-center'>Amount</th>
                                </tr>
                            </thead>
                            <tbody>

                                {purchaseInfo?.rows?.map((purchaseItem, index) => (
                                    <tr key={'item' + index}>
                                        <td>{index + 1}</td>
                                        <td>{purchaseItem?.item.itemName}</td>
                                        <td>{purchaseItem?.item.itemCode}</td>
                                        <td>{purchaseItem?.item.item_category.categoryName}</td>
                                        <td className='text-center'>
                                            <span className="badge badge-light-danger"> {Number(purchaseItem?.quantity) + ' ' + purchaseItem?.item.unit.unitName}</span>
                                        </td>
                                        <td className='text-end'>{Number(purchaseItem?.rate)}</td>
                                        <td className='text-end'>{numberFormat(purchaseItem?.quantity * purchaseItem?.rate)}</td>
                                    </tr>
                                ))}
                                <tr>
                                    <td colSpan={6} className='text-end'><b>Total Amount : </b></td>
                                    <td className='text-end'><b>{numberFormat(Number(purchaseInfo?.purchaseAmount))}</b></td>
                                </tr>
                            </tbody>

                        </Table>
                        <div className='row'>
                            <div className='col text-center'>

                                <Link className="btn btn-info btn-sm" to={`/purchase`}>
                                    <KTSVG
                                        path='/media/svg/icons/Media/Back.svg'
                                        className='svg-icon-5 svg-icon'
                                    />		Back to Goods Receive List
                                </Link>

                            </div>
                        </div>

                    </div>
                </div>
            </Col>
        </Row>
    </>
    )
}

export default PurchaseDetails
