/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, Fragment, useState, useContext } from 'react';
import BackendTable from 'react-backend-table';
import ExpenseCreate from '../components/ExpenseCreate';
import ExpensePayment from '../components/ExpensePayment';
import { ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2'
import { toast } from 'react-toastify';
import showLoader from "../helpers/ShowLoader"
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper';
import { AppContext } from '../context/AppContext';
import Select from 'react-select';

export function Expenses() {
    const {currentUser} = useContext(AppContext);
    const [createExpenseModalShow, setCreateExpenseModalShow] = useState(false);
    const [actionChannel, setActionChannel] = useState('');
    const [idAccExpenses, seIdAccExpenses] = useState('');

    const [selectProjectOptions, setSelectProjectOptions] = useState([])
    const [selectedProject, setSelectedProject] = useState<{ value: string; label: string; } | null>(null);

    const getProjectList = async () => {
        const url = process.env.REACT_APP_API_URL + 'projects';

        const response = await fetch(url, getRequestOptions());

        const data = await response.json();

        data.data.map((element: any) => {
            element.value = element.id_projects;
            element.label = element.project_name;
            return element;
        })

        setSelectProjectOptions(data.data);
    }

    useEffect(() => {
        getProjectList();
    }, [])


    const handleCreateExpenseModalShow = (actionChannel:string) => {
        setCreateExpenseModalShow(true);
        setActionChannel(actionChannel);
    }

    const handleCreateExpenseModalClose = () => {
        setCreateExpenseModalShow(false);
        options.reloadMyTable();
    }

    const [paymentExpenseModalShow, setPaymentExpenseModalShow] = useState(false);
    const handlePaymentExpenseModalShow = (data:any) => {
        setPaymentExpenseModalShow(true);
        seIdAccExpenses(data.idAccExpenses);
    }

    const handlePaymentExpenseModalClose = () => {
        setPaymentExpenseModalShow(false);
        options.reloadMyTable();
    }

    // Function to get the label based on status
    const getStatusLabel = (status:string) => {
        switch (status) {
            case 'approved':
                return <span className="badge badge-success label-inline">Approved</span>;
            case 'denied':
                return <span className="badge badge-danger label-inline">Denied</span>;
            case 'requested':
                return <span className="badge badge-warning label-inline">Requested</span>;
            case 'paid':
                return <span className="badge badge-info label-inline">Paid</span>;
            default:
                return null;
        }
    };

    const changeStatus = (rowValue: any) => {

        Swal.fire({
            title: 'Update Expense Status and Add Remarks',
            input: 'select',
            inputAttributes: {
                autocapitalize: 'off',
                placeholder: 'Select Status',
                defaultValue: rowValue.latestStatus,
            },
            inputOptions: {
                approved: 'Approved',
                denied: 'Denied',
            },
            inputValue: rowValue.latestStatus,
            html:
            '<textarea id="remarks" class="swal2-textarea" placeholder="Enter remarks"></textarea>',
            showCancelButton: true,
            confirmButtonText: 'Submit',
            showLoaderOnConfirm: true,
            preConfirm: (content: any) => {
                let status = Swal.getInput().value;
                let remarks = document.getElementById('remarks').value;
                let postData = { idAccExpenses: rowValue.idAccExpenses, status: status, remarks: remarks };
                
                // let postData = { idAccExpenses: rowValue.idAccExpenses, latestStatus: content };
                console.log(postData, status, remarks);
                updateStatus(postData);
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                console.log("result.value=", result.value);
            }
        })
    }

    const updateStatus = async (postData: any) => {
        showLoader();
        const requestOptions = postRequestOptions(postData);
        try {
            let response = await fetch(
                `${process.env.REACT_APP_API_URL}expense/update-status`,
                requestOptions
            )
            let respData = await response.json()
            Swal.close();

            if (respData.success === true) {
                toast(<strong>Expense status updated successfully</strong>, { type: 'success', position: 'bottom-right', autoClose: 3000 });
                options.reloadMyTable();
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    html: respData.errorMessage,
                })
            }

        } catch (error) {
            Swal.close();
        }
    }

    const numberFormat = (value: number) => {
        return parseFloat(value.toString()).toLocaleString('en-US', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }
    
    let columns = [
        { title: 'ID', field: 'idAccExpenses', sortable: true, searchable: true, thStyle: { textAlign: "center", width: "5%" }, tdStyle: { textAlign: "center" } },
        { title: 'Expense Date', field: 'expenseDate', sortable: true, searchable: true },
        { title: 'Expense Category', field: 'expenseCategory', sortable: true, searchable: true },
        {
            title: 'Requester',
            field: 'userName',
            sortable: true,
            searchable: true,
            hasComponent: true,
            componentValue: ((rowValue: any) => {
                return (
                    <>
                        {(rowValue.userName) ?? ''}
                    </>
                )
            })
        },
        {
            title: 'Approver',
            field: 'approverName',
            sortable: true,
            searchable: true,
            hasComponent: true,
            componentValue: ((rowValue: any) => {
                return (
                    <>
                        {(rowValue.approverName) ?? ''}
                    </>
                )
            })
        },
        {
            title: 'Project',
            field: 'projectName',
            sortable: true,
            searchable: true,
            hasComponent: true,
            componentValue: ((rowValue: any) => {
                return (
                    <>
                        {rowValue.projectName ?? ''}
                    </>
                )
            })
        },
        {
            title: 'Lead',
            field: 'leadName',
            sortable: true,
            searchable: true,
            hasComponent: true,
            componentValue: ((rowValue: any) => {
                return (
                    <>
                        {rowValue.idLeads ? <a href={"/#/lead-details?idLeads=" + rowValue.idLeads}>{rowValue.leadName}</a> : ''}
                    </>
                )
            })
        },
        { 
            title: 'Expense Amount', 
            field: 'expenseAmount', 
            sortable: true, 
            searchable: true, 
            tdStyle: { textAlign: "right" } ,
            hasComponent: true,
            componentValue: ((rowValue: any) => {
                return (
                    <>
                        {numberFormat(rowValue.expenseAmount)}
                    </>
                )
            })
        },
        {
            title: 'Remarks',
            field: 'remarks',
            sortable: true,
            searchable: true
        },
        {
            title: 'Status',
            field: 'latestStatus',
            sortable: true,
            searchable: true,
            hasComponent: true,
            componentValue: ((rowValue: any) => {
            
                return (
                    <>
                        {getStatusLabel(rowValue.latestStatus)}
                    </>
                );
            }),
        },
        {
            title: 'Action',
            field: 'idAccExpenses',
            sortable: false,
            searchable: false,
            hasComponent: true,
            componentValue: ((rowValue: any) => {
            
                return (
                    <>
                        {currentUser?.id_roles !== 1 && rowValue.latestStatus==='requested'  && (<button style={{ cursor: "pointer" }} className={'btn btn-info btn-sm'} title='Update Expense Status and Add Remarks' onClick={() => changeStatus(rowValue)}><i className='fa fa-check'></i> Approve</button>)}
                        {currentUser?.id_roles !== 1 && rowValue.latestStatus==='approved'  && (<button style={{ cursor: "pointer" }} className={'btn btn-info btn-sm'} title='Expense Payment' onClick={() => handlePaymentExpenseModalShow(rowValue)}><i className='fa fa-calculator'></i> Payment</button>)}
                    </>
                );
            }),
            thStyle: { textAlign: "center", width: "10%" }, tdStyle: { textAlign: "center" }
        },
    ]

    const jwt = () => {
        const myToken = localStorage.getItem('MyToken');
        return (myToken != null) ? JSON.parse(myToken) : null;
    }

    let options = {
        title: '',
        url: process.env.REACT_APP_API_URL + 'expenses',
        authorization: 'Bearer ' + jwt(),
        perPage: [10, 20, 50, 100, 500],
        orderBy: 'idAccExpenses',
        orderType: 'desc',
        columnSearch: true,
        reloadMyTable: () => { },
        extraData: { idProjects: selectedProject?.value }
    }

    useEffect(() => {
        options.reloadMyTable();
    }, [selectedProject])

    return (
        <>
            <div className='card card-custom'>
                <div className='card-header'>
                    <div className='card-title'>
                        <h3 className='card-label'>Expenses</h3>
                        <small className='text-muted'>| A List of All Expenses</small>
                    </div>
                    <div className="card-toolbar">
                        <div className='d-flex justify-content-end'>
                            <Select
                                defaultValue={selectProjectOptions}
                                onChange={setSelectedProject}
                                options={selectProjectOptions}
                                placeholder="Select project"
                            />
                             &nbsp;
                            {currentUser?.id_roles === 1 &&
                                <button type="button" className="btn btn-sm btn-primary me-2" onClick={() => handleCreateExpenseModalShow('requested')}><i className="fa fa-plus"></i>Make Request</button>
                            }
                            {currentUser?.id_roles !== 1 &&
                                <button type="button" className="btn btn-sm btn-primary" onClick={() => handleCreateExpenseModalShow('paid')}><i className="fa fa-plus"></i>Add Expense</button>
                            }
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="data-table col-md-12">
                            <BackendTable columns={columns} options={options} />
                        </div>
                    </div>
                </div>
            </div>

            
            <ExpenseCreate show={createExpenseModalShow} handleClose={handleCreateExpenseModalClose} id={Date.now()} actionChannel={actionChannel} />
            <ExpensePayment show={paymentExpenseModalShow} handleClose={handlePaymentExpenseModalClose} id={idAccExpenses}/>
            <ToastContainer />
        </>

    )
}

export default Expenses
