/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import { Modal, Button } from 'react-bootstrap'
import validationErrorMsg from '../helpers/ValidationErrorMsg'
import Spinner from 'react-bootstrap/Spinner'
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper'
import Select from 'react-select'

interface ProductCategoryCreateProps {
	show: boolean;
	handleClose: any;
	id: number;
}

interface InitialState {
	idProductCategories: number | null;
	idProjects: number | null;
	categoryName: string;
}

const ProductCategoryCreate: React.FC<ProductCategoryCreateProps> = ({ show, handleClose, id }) => {

	const [projects, setProjects] = useState([]);
	const [project, setProject] = useState({ label: 'Select Project', value: '' });

	const initialState: InitialState = {
		idProductCategories: null,
		idProjects: null,
		categoryName: '',
	};

	const getAllProjects = () => {
		fetch(
			process.env.REACT_APP_API_URL + 'projects',
			getRequestOptions()
		)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {

				// console.log(resp)
				let projects = resp.data.map((item: any) => {
					item.label = item.project_name;
					item.value = item.id_projects;
					return item
				})
				setProjects(projects);
			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})
	}

	const [productCategory, setProductCategory] = useState(initialState);


	useEffect(() => {
		getAllProjects()
	}, [])

	useEffect(() => {
		if (id === 0) {
			setProductCategory({ idProductCategories: null, categoryName: '', idProjects: null });
			setProject({ label: 'Select Project', value: '' })
		} else {
			fetch(
				process.env.REACT_APP_API_URL + 'productCategories/edit/' + id,
				getRequestOptions()
			)
				.then((resp) => {
					return resp.json()
				})
				.then((resp) => {
					setProductCategory({ idProductCategories: resp.data.id_product_categories, categoryName: resp.data.category_name, idProjects: resp.data.id_projects });
					setProject({ label: resp.data.project_name, value: resp.data.idProjects })
				})
				.catch((error) => {
					console.log(error, 'catch the hoop')
				})
		}
	}, [id]);


	const [isSubmitDisable, setSubmitDisable] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleChange = (e: any) => {
		setProductCategory({ ...productCategory, [e.target.name]: e.target.value });
	};

	const productCategorySave = async (event: any) => {
		event.preventDefault()
		setSubmitDisable(true);
		setLoading(true);
		const confirm = await Swal.fire({
			title: 'Are you sure?',
			text: "You want to " + (id === 0 ? "create" : "update") + " this Product Category?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#ec008c',
			cancelButtonText: 'Cancel',
			confirmButtonText: 'Yes, Submit it!',
		})
		if (confirm.value) {
			const requestOptions = postRequestOptions(productCategory)
			try {
				let url = (id === 0) ? `${process.env.REACT_APP_API_URL}productCategories/store` : `${process.env.REACT_APP_API_URL}productCategories/update`
				let response = await fetch(
					url,
					requestOptions
				)
				let respData = await response.json()
				if (respData.success === true) {
					const successMsg = respData.successMessage
					Swal.fire({
						icon: 'success',
						title: 'Success',
						text: successMsg,
					})
					handleClose(respData.success)
				} else {
					const errorsMessage = validationErrorMsg(respData.errors).join('<br>')
					Swal.fire({
						icon: 'error',
						title: 'Error',
						html: errorsMessage,
					})
				}
				setSubmitDisable(false);
				setLoading(false);
			} catch (error) {
				console.log(error, 'catch the hoop')
				setSubmitDisable(false);
				setLoading(false);
			}
		}
	}

	const handleProjectChange = (project: any) => {
		setProject(project);
		setProductCategory({ ...productCategory, idProjects: project.id_projects });
	}


	return (
		<Modal show={show} onHide={() => handleClose(false)} size="lg">
			<form onSubmit={productCategorySave}>
				<Modal.Header closeButton>
					<Modal.Title>Create Product Category</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="form-group row">
						<label htmlFor="project" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Project <span className="required text-danger"></span></label>
						<div className="col-lg-5">
							<Select
								value={project}
								onChange={handleProjectChange}
								options={projects}
								menuPosition="fixed"
							/>
						</div>
					</div>
					<div className="form-group row">
						<label htmlFor="categoryName" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Name <span className="required text-danger"></span></label>
						<div className="col-lg-5">
							<input type="text" name="categoryName" id="categoryName" value={productCategory.categoryName} onChange={handleChange} className="form-control form-control-sm" />
						</div>
					</div>
					{/* <pre>{JSON.stringify(company, null, 2)}</pre> */}
				</Modal.Body>
				<Modal.Footer>

					<button type="submit" disabled={isSubmitDisable || loading} className="btn btn-sm btn-primary me-2">
						{loading &&
							<Spinner
								as="span"
								animation="grow"
								size="sm"
								role="status"
								aria-hidden="true"
							/>
						}
						<span>  Submit</span>
					</button>
					<Button variant='secondary' size="sm" onClick={() => handleClose(false)}>
						Close
					</Button>
				</Modal.Footer>
			</form>
		</Modal>
	)
}

export default ProductCategoryCreate
