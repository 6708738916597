/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { getRequestOptions } from '../helpers/Fetchwrapper';
import { KTSVG } from '../../_metronic/helpers';
import { Col, Row, Table } from 'react-bootstrap-v5';
import 'react-dropzone-uploader/dist/styles.css'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom';
import Dropzone from 'react-dropzone-uploader'
import Swal from 'sweetalert2'
import showLoader from '../helpers/ShowLoader';
import axios, { AxiosResponse, AxiosError } from 'axios'
import validationErrorMsg from '../helpers/ValidationErrorMsg'


interface PurchaseInfo {
    id_po: number;
    id_projects: number;
    id_vendors: number;
    purchaseType: string;
    purpose: string;
    purchaseDate: string;
    referenceNo: string;
    representativeName: string;
    representativePhone: string;
    receivingAddress: string;
    creditDays: string;
    totalAmount: number;
    totalAmountAfterDiscount: number;
    discountAmount: number;
    carryingCost: number;
    vat: number;
    tax: number;
    vatAmount: number;
    taxAmount: number;
    purchaseAmount: number;
    created_by: number;
    createdAt: string;
    project: {
        id_projects: number;
        projectName: string;
    };
    vendor: { id_vendors: number, vendorName: string, phone: string, address: string };
    rows: {
        id_po: number,
        id_items: number,
        excludingRate: number,
        quantity: number,
        amount: number,
        discountAmount: number,
        carryingCost: number,
        totalAmount: number,
        rate: number,
        item: any
    }[];
    terms: { id_po: number, termsConditionDetails: number }[];
    creator: any;
    files: { id_ref: number, refType: string, attachmentName: string, attachmentPath: string, fileSize: number, created_at: string, creator: any }[];
}

interface RouteParams {
    idPo: string;
}

export function PurchaseOrderDetails(props: any) {

    const [purchaseInfo, setPurchaseInfo] = useState<PurchaseInfo | null>(null);
    const { idPo }: RouteParams = useParams();

    useEffect(() => {
        if (idPo !== undefined) {
            getPurchaseInfo();
        }
    }, [idPo])

    const getPurchaseInfo = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'po/' + idPo,
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                const data: PurchaseInfo = resp.data;
                data?.rows.map((row, index) => {
                    row.amount = Number(row.excludingRate) * Number(row.quantity);
                    row.discountAmount = Number(row.discountAmount);
                    row.carryingCost = Number(row.carryingCost);
                    row.totalAmount = (row.amount - Number(row.discountAmount) + Number(row.carryingCost));
                    return row;
                });
                setPurchaseInfo(data);

                calculateSummary();
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }


    const numberFormat = (value: any) => {

        value = parseFloat(value)
        if (typeof (value) !== 'number') {
            return value;
        }
        return parseFloat(value.toString()).toLocaleString('en-US', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }

    const calculateSummary = () => {
        console.log(purchaseInfo, 'purchaseInfo');
        let totalAmount = 0;
        let totalDiscountAmount = 0;
        let totalCarryingCost = 0;
        let totalAmountAfterDiscount = 0;
        if (purchaseInfo) {
            purchaseInfo?.rows.forEach((row, index) => {
                row.amount = Number(row.excludingRate) * Number(row.quantity);
                row.discountAmount = Number(row.discountAmount);
                row.carryingCost = Number(row.carryingCost);
                row.totalAmount = (row.amount - Number(row.discountAmount) + Number(row.carryingCost));
                totalAmount += row.amount;
                totalDiscountAmount += row.discountAmount;
                totalCarryingCost += row.carryingCost;
                totalAmountAfterDiscount += row.totalAmount;
            });

            let vatAmount = (totalAmountAfterDiscount * Number(purchaseInfo.vat)) / 100;
            let taxAmount = (totalAmountAfterDiscount * Number(purchaseInfo.tax)) / 100;
            let purchaseAmount = totalAmountAfterDiscount + vatAmount + taxAmount;

            let updateData = {
                ...purchaseInfo,
                totalAmount: totalAmount,
                totalDiscountAmount: totalDiscountAmount,
                totalCarryingCost: totalCarryingCost,
                totalAmountAfterDiscount: totalAmountAfterDiscount,
                vatAmount: vatAmount,
                taxAmount: taxAmount,
                purchaseAmount: purchaseAmount ?? 0
            }

            setPurchaseInfo(updateData);
        }


    };

    const totalAmountAfterDiscount = purchaseInfo?.rows?.reduce((total, row) => total + row.totalAmount, 0);

    const getReadableFileSizeString = (fileSizeInBytes: number) => {
        return ((fileSizeInBytes / (1024 * 1024)).toFixed(4) + " MB");
    }

    const jwt = () => {
        const myToken = localStorage.getItem('MyToken');
        return (myToken != null) ? JSON.parse(myToken) : null;
    }

    const handleFileSubmit = (files: any, allFiles: []) => {
        showLoader()

        const postFormData = new FormData();
        postFormData.append('idRef', idPo.toString());
        postFormData.append('refType', 'po');
        postFormData.append('folderPath', 'purchase-orders');
        for (let i = 0; i < files.length; i++) {
            postFormData.append('files[]', files[i].file);
        }

        const url: string = `${process.env.REACT_APP_API_URL}file/upload`;

        axios({
            method: 'POST',
            url: url,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer ' + jwt(),
            },
            data: postFormData,
        })
            .then((response: AxiosResponse<any>) => {
                Swal.close();
                allFiles.forEach((f: any) => f.remove());
                getPurchaseInfo();
            })
            .catch((error: AxiosError<any>) => {
                Swal.close();
                // const errorMessage: string = error.response?.data?.errorMessage;
                const errorsMessage: string = validationErrorMsg(error?.response?.data?.errors).join('<br>');
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    html: errorsMessage,
                });
            });

    }

    return (<>
        <div className='card card-custom'>
            <div className='card-header card-header-stretch overflow-auto'>
                <div className='card-title'>
                    <h3 className='card-label'>Purchase Order Details</h3>
                    <small className='text-muted'>| Information about the Purchase Order</small>
                </div>
            </div>
            <div className="card-body">

            </div>
        </div>

        <Row>
            <Col md={4} className="pe-2">
                <div className="card card-flush overflow-hidden mt-3 ms-2">
                    <div className="card-header py-5">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bold text-dark">Summary</span>
                        </h3>
                    </div>
                    <div className="card-body pt-0">
                        <Table striped bordered hover size='sm'>
                            <tbody>
                                <tr>
                                    <td style={{ width: '50%' }}>ID</td>
                                    <td style={{ width: '50%' }}>{purchaseInfo?.id_purchase}</td>
                                </tr>
                                <tr>
                                    <td>Purchase Type</td>
                                    <td><span className="badge badge-success">{purchaseInfo?.purchaseType}</span></td>
                                </tr>
                                {(purchaseInfo && purchaseInfo?.purchaseType === 'LC') &&
                                    <tr>
                                        <td>LC No</td>
                                        <td>{purchaseInfo?.lcNo}</td>
                                    </tr>
                                }
                                <tr>
                                    <td>Purpose</td>
                                    <td><span className="badge badge-info">{purchaseInfo?.purpose}</span></td>
                                </tr>
                                <tr>
                                    <td>Project</td>
                                    <td>{purchaseInfo?.project?.projectName}</td>
                                </tr>
                                <tr>
                                    <td>Vendor</td>
                                    <td>{purchaseInfo?.vendor?.vendorName}</td>
                                </tr>
                                <tr>
                                    <td>Purchase Date </td>
                                    <td>{purchaseInfo?.purchaseDate}</td>
                                </tr>
                                <tr>
                                    <td>Reference No </td>
                                    <td>{purchaseInfo?.referenceNo}</td>
                                </tr>

                                <tr>
                                    <td>Representative Name</td>
                                    <td>{purchaseInfo?.representativeName}</td>
                                </tr>
                                <tr>
                                    <td>Representative Number</td>
                                    <td>{purchaseInfo?.representativePhone}</td>
                                </tr>
                                <tr>
                                    <td>Receiving Address</td>
                                    <td>{purchaseInfo?.receivingAddress}</td>
                                </tr>
                                <tr>
                                    <td>Credit Days</td>
                                    <td>{purchaseInfo?.creditDays}</td>
                                </tr>
                                <tr>
                                    <td>Creator</td>
                                    <td>{purchaseInfo?.creator.firstName + ' ' + purchaseInfo?.creator.lastName + ' at ' + purchaseInfo?.createdAt}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
                <div className='card card-flush overflow-hidden mt-3 ms-2'>
                    <div className='card-header py-5'>
                        <h3 className="card-title">
                            <KTSVG className='svg-icon-3 me-2' path="/media/svg/icons/General/Clip.svg"></KTSVG>
                            Attach files
                        </h3>
                    </div>


                    <div className='card-body'>
                        <Table striped bordered hover size='sm'>
                            <thead>
                                <tr>
                                    <th style={{ width: '50%' }}>Attachment</th>
                                    <th style={{ width: '50%' }}>Uploaded By</th>
                                </tr>
                            </thead>
                            <tbody>
                                {purchaseInfo?.files?.length === 0 &&
                                    <tr>
                                        <td colSpan={2}>No File Attached</td>
                                    </tr>
                                }
                                {purchaseInfo?.files?.map((attachment, index) => (
                                    <tr>
                                        <td key={'attachment' + index}>
                                            <a rel='noreferrer' href={process.env.REACT_APP_AWS_URL + "purchase-orders/" + attachment.attachmentPath}
                                                className="fs-6 text-hover-primary fw-bold" target="_blank">{attachment.attachmentName}</a>
                                            <div className="text-gray-400">{getReadableFileSizeString(attachment.fileSize)}</div>
                                        </td>
                                        <td>{attachment.creator.firstName + ' ' + attachment.creator.lastName + ' at ' + attachment.createdAt}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                        <Dropzone
                            styles={{ dropzone: { border: "none" } }}
                            accept='image/*,audio/*,application/pdf'
                            onSubmit={handleFileSubmit}
                        />
                    </div>
                </div>

            </Col>
            <Col md={8} className="pt-2">
                <div className="row">
                    <div className="col">
                        <div className="card card-flush overflow-hidden m-2 me-0">
                            <div className="card-header py-5">
                                <h3 className="card-title align-items-start flex-column">
                                    <span className="card-label fw-bold text-dark">Items</span>
                                </h3>
                            </div>
                            <div className="card-body pt-0">
                                <Table striped bordered hover size="sm">
                                    <thead>
                                        <tr>
                                            <th>SL No</th>
                                            <th>Item</th>
                                            <th>Item Code</th>
                                            <th>Item Category</th>
                                            <th className='text-center'>Purchase Quantity</th>
                                            <th className='text-center'>Rate</th>
                                            <th className='text-center'>Amount</th>
                                            <th className='text-center'>Discount Amount</th>
                                            <th className='text-center'>Carrying Cost</th>
                                            <th className='text-center'>Total Amount</th>
                                            <th className='text-center'>Rate Including</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {purchaseInfo?.rows?.map((purchaseItem, index) => (
                                            <tr key={'item' + index}>
                                                <td>{index + 1}</td>
                                                <td>{purchaseItem?.item.itemName}</td>
                                                <td>{purchaseItem?.item.itemCode}</td>
                                                <td>{purchaseItem?.item.item_category.categoryName}</td>
                                                <td className='text-center'>
                                                    <span className="badge badge-light-danger"> {Number(purchaseItem?.quantity) + ' ' + purchaseItem?.item.unit.unitName}</span>
                                                </td>
                                                <td className='text-end'>{numberFormat(purchaseItem?.excludingRate)}</td>
                                                <td className='text-end'>{numberFormat(purchaseItem?.quantity * purchaseItem?.excludingRate)}</td>
                                                <td className='text-end'>{numberFormat(purchaseItem?.discountAmount)}</td>
                                                <td className='text-end'>{numberFormat(purchaseItem?.carryingCost)}</td>
                                                <td className='text-end'>{numberFormat(purchaseItem?.totalAmount)}</td>
                                                <td className='text-end'>{numberFormat(purchaseItem?.rate)}</td>
                                            </tr>
                                        ))}
                                        <tr>
                                            <td colSpan={6} className='text-end'><b>Total: </b></td>
                                            <td className='text-end'><b>{(numberFormat(purchaseInfo?.totalAmount))}</b></td>
                                            <td className='text-end'><b>{(numberFormat(purchaseInfo?.discountAmount))}</b></td>
                                            <td className='text-end'><b>{(numberFormat(purchaseInfo?.carryingCost))}</b></td>
                                            <td className='text-end'><b>{(numberFormat(totalAmountAfterDiscount))}</b></td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <div className="row">
                                    <div className="col-6"></div>
                                    <div className="col-6">
                                        <div className='notice bg-light-primary rounded border-primary border border-dashed h-lg-100 p-6'>
                                            <div className="row mt-2">
                                                <div className="col-md-8 text-end"><strong>Total Amount: </strong></div>
                                                <div className="col-md-4 text-end">
                                                    <strong>{numberFormat(purchaseInfo?.totalAmount)}</strong>
                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-md-8 text-end"><strong>Total Discount Amount: </strong></div>
                                                <div className="col-md-4 text-end">
                                                    <strong>{numberFormat(purchaseInfo?.discountAmount)}</strong>
                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-md-8 text-end"><strong>Total Carrying Cost: </strong></div>
                                                <div className="col-md-4 text-end">
                                                    <strong>{numberFormat(purchaseInfo?.carryingCost)}</strong>
                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-md-8 text-end"><strong>VAT Amount: </strong></div>
                                                <div className="col-md-4 text-end">
                                                    <strong>{numberFormat(purchaseInfo?.vatAmount)}</strong>
                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-md-8 text-end"><strong>TAX Amount: </strong></div>
                                                <div className="col-md-4 text-end">
                                                    <strong>{numberFormat(purchaseInfo?.taxAmount)}</strong>
                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-md-8 text-end"><strong>Net Amount: </strong></div>
                                                <div className="col-md-4 text-end">
                                                    <strong>{numberFormat(purchaseInfo?.purchaseAmount)}</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mt-5'>
                                    <div className='col text-center'>

                                        <Link className="btn btn-info btn-sm" to={`/po`}>
                                            <KTSVG
                                                path='/media/svg/icons/Media/Back.svg'
                                                className='svg-icon-5 svg-icon'
                                            />		Back to Purchase Order List
                                        </Link>

                                    </div>
                                </div>

                                {/* <pre> {JSON.stringify(purchaseInfo, null, 2)}</pre> */}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <div className="card card-flush overflow-hidden m-2 me-0">
                            <div className="card-header py-5">
                                <h3 className="card-title align-items-start flex-column">
                                    <span className="card-label fw-bold text-dark">Terms & Conditions</span>
                                </h3>
                            </div>
                            <div className="card-body pt-0">
                                <Table striped bordered hover size="sm">
                                    <tbody>

                                        {purchaseInfo?.terms?.length === 0 &&
                                            <tr>
                                                <td colSpan={2}>No terms/conditions added</td>
                                            </tr>
                                        }
                                        {purchaseInfo?.terms?.map((term, index) => (
                                            <tr key={'term-' + index}>
                                                <td className='text-center'>{index + 1}</td>
                                                <td key={index}>{term?.termsConditionDetails}</td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        </Row >

    </>
    )
}

export default PurchaseOrderDetails
