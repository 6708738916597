import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../_metronic/helpers'
import Select from 'react-select'
import {getRequestOptions, postRequestOptions} from '../helpers/Fetchwrapper'
import {Row, Col, Table, Tab, Tabs} from 'react-bootstrap'
import {GoogleMap, Marker, useJsApiLoader, Polygon} from '@react-google-maps/api'
import {toast, ToastContainer} from 'react-toastify'
import {Modal, Button} from 'react-bootstrap'

const containerStyle = {
  width: '100%',
  height: '800px',
}

const mapCenter = {
  lat: 23.8041,
  lng: 90.4152,
}

interface Coordinate {
  lat: number
  lng: number
}

const districtColor = '#FF0000'

const DistrictPolygon = ({coordinates, color}: {coordinates: Coordinate[]; color: string}) => {
  return (
    <Polygon
      path={coordinates}
      options={{
        fillColor: color,
        fillOpacity: 0.5,
        strokeColor: '#000000',
        strokeOpacity: 1,
        strokeWeight: 2,
      }}
    />
  )
}

const MapDashboard = () => {
  const [selectedProject, setSelectedProject] = useState<{value: string; label: string} | null>(
    null
  )
  const [selectProjectOptions, setSelectProjectOptions] = useState([])
  const [districts, setDistricts] = useState([])
  const [selectedDistrict, setSelectedDistrict] = useState<{value: string; label: string} | null>(
    null
  )
  const [selectedDistrictToAssignSr, setSelectedDistrictToAssignSr] = useState<{
    value: string
    label: string
  } | null>(null)
  const [coveredDistricts, setCoveredDistricts] = useState([])
  const [assignedSrList, setAssignedSrList] = useState([])
  const [assignSrModalShow, setAssignSrModalShow] = useState(false)
  const [sr, setSr] = useState({label: 'Select SR', value: ''})
  const [srs, setSrs] = useState([])
  const [srCount, setSrCount] = useState([])
  const [selectedTab, setSelectedTab] = useState('district-coverage')
  const [assignedSrSearchText, setAssignedSrSearchText] = useState('')

  const {isLoaded} = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY as string,
    libraries: ['places'],
  })

  const getProjectList = async () => {
    const url = process.env.REACT_APP_API_URL + 'projects'

    const response = await fetch(url, getRequestOptions())

    const data = await response.json()

    data.data.map((element: any) => {
      element.value = element.id_projects
      element.label = element.project_name
      return element
    })

    setSelectProjectOptions(data.data)
    setSelectedProject(data.data[0])
  }

  const getDistrictCoverage = async (idProject: string) => {
    const url = process.env.REACT_APP_API_URL + 'district-coverage/' + idProject

    const response = await fetch(url, getRequestOptions())
    const data = await response.json()

    setCoveredDistricts(data.data)
  }

  const getAllDistricts = async () => {
    const url = process.env.REACT_APP_API_URL + 'districts'
    const response = await fetch(url, getRequestOptions())
    const data = await response.json()

    setDistricts(data.data)
  }

  const coverDistrict = async (idDistrict: string) => {
    if (selectedProject) {
      // getDistrictCoverage(selectedProject.value)

      const postData = {
        idProject: selectedProject.value,
        idDistrict: idDistrict,
      }
      const requestOptions = postRequestOptions(postData)
      try {
        let url = process.env.REACT_APP_API_URL + 'district-coverage'
        let response = await fetch(url, requestOptions)
        let respData = await response.json()
        console.log(respData)
        if (respData.status === true) {

          toast.success('District covered successfully')

          setSelectedDistrict(null)
          getDistrictCoverage(selectedProject.value)
        } else {
          toast.error(respData.errorMessage)
        }
      } catch (error) {
        console.log(error, 'catch the hoop')
      }
    }
  }

  const getAssignedSrList = async (idProject: string, searchText: string = '') => {
      let url = process.env.REACT_APP_API_URL + 'assigned-sr-list/' + idProject

      if (searchText !== '') {
          url = url + '?searchText=' + searchText
      }

      const response = await fetch(url, getRequestOptions())
      const data = await response.json()
      setAssignedSrList(data.data)
  }

  const getSrCount = async (idProject: string) => {
    const url = process.env.REACT_APP_API_URL + 'sr-count/' + idProject
    const response = await fetch(url, getRequestOptions())
    const data = await response.json()
    setSrCount(data.data)
  }

  const handleAssignSrModalShow = () => {
    setAssignSrModalShow(true)
  }

  const handleCloseAssignSrModal = () => {
    setAssignSrModalShow(false)
  }

  const handleSrChange = (sr: any) => {
    setSr(sr)
  }

  const getProjectSrs = (idProjects: string) => {
    fetch(process.env.REACT_APP_API_URL + 'getAllSr?idProjects=' + idProjects, getRequestOptions())
      .then((resp) => {
        return resp.json()
      })
      .then((resp) => {
        let srsObj = resp.map((item: any) => {
          item.label = item.first_name + ' ' + item.last_name
          item.value = item.id
          return item
        })
        setSrs(srsObj)
      })
      .catch((error) => {
        console.log(error, 'catch the hoop')
      })
  }

  useEffect(() => {
    getProjectList()
    getAllDistricts()
  }, [])

  useEffect(() => {
    if (selectedProject) {
      getDistrictCoverage(selectedProject.value)
      getProjectSrs(selectedProject.value)
      getSrCount(selectedProject.value)
    }
  }, [selectedProject])

  useEffect(() => {
    if (selectedProject) {
      getAssignedSrList(selectedProject.value, assignedSrSearchText)
    }
  }, [assignedSrSearchText, selectedProject])

  const handleAssignSrSubmit = async () => {
    if (selectedProject) {
      const postData = {
        idProject: selectedProject.value,
        idDistrict: selectedDistrictToAssignSr?.value,
        idUsers: sr?.value,
      }
      const requestOptions = postRequestOptions(postData)
      try {
        let url = process.env.REACT_APP_API_URL + 'assign-sr-to-district'
        let response = await fetch(url, requestOptions)
        let respData = await response.json()
        console.log(respData)
        if (respData.status === true) {

          toast.success('SR assigned successfully')

          setSelectedDistrictToAssignSr({value: '0', label: 'Select District'})
          setSr({value: '0', label: 'Select SR'})
          getAssignedSrList(selectedProject.value)
          getSrCount(selectedProject.value)
          handleCloseAssignSrModal()
        } else {
          toast.error(respData.errorMessage)
        }
      } catch (error) {
        console.log(error, 'catch the hoop')
      }
    }
  }


  return (
    <div className='card card-custom'>
      <div className='card-header'>
        <div className='card-title'>
          <KTSVG
            path='/media/svg/icons/Text/Bullet-list.svg'
            className='svg-icon-1 svg-icon-primary'
          />
          <span className='text fw-bolder' style={{marginLeft: '10px'}}>
            Map Dashboard
          </span>
        </div>
        <div className='card-toolbar'>
          <div className='d-flex justify-content-end align-items-center'>
            <Select
              // defaultValue={selectProjectOptions}
              onChange={setSelectedProject}
              options={selectProjectOptions}
              placeholder={selectedProject?.label}
            />
          </div>
        </div>
      </div>
      <div className='card-body bg-secondary' style={{minHeight: '80vh'}}>
        <Row style={{display: 'flex', flexWrap: 'nowrap', marginLeft: '0', marginRight: '0'}}>
          <Col xs={12} md={8}>
            {isLoaded && (
              <GoogleMap mapContainerStyle={containerStyle} center={mapCenter} zoom={7}>
                {/* <Marker position={mapCenter} label={'3'} /> */}
                {selectedTab === 'assign-sr' && srCount.map((element: any) => {
                  return (
                    <Marker position={{lat: Number(element.lat), lng: Number(element.lon)}} label={element.srCount.toString()} />
                  )
                })}
                {selectedProject &&
                  coveredDistricts.map((element: any) => {
                    return (
                      <DistrictPolygon
                        coordinates={JSON.parse(element.boundary_coordinates).map(
                          ([lng, lat]: [number, number]) => ({lat, lng})
                        )}
                        color={districtColor}
                      />
                    )
                  })}
              </GoogleMap>
            )}
          </Col>
          <Col xs={6} md={4}>
            <Tabs defaultActiveKey='district-coverage' id='map-dashboard-tabs' className='mb-3' onSelect={e => setSelectedTab(e?e:'district-coverage')}>
              <Tab eventKey='district-coverage' title='District Coverage'>
                {selectedProject && (
                  <Row>
                    <Col xs={12} md={12}>
                      <h3>District Coverage</h3>
                    </Col>
                    <Col xs={12} md={12}>
                      <Select
                        // defaultValue={selectDistrictOptions}
                        onChange={setSelectedDistrict}
                        options={districts}
                        placeholder={selectedDistrict?.label}
                      />
                    </Col>
                    <Col xs={12} md={12} style={{marginTop: '5px', height: '50px'}}>
                      {selectedDistrict && (
                        <button
                          className='btn btn-primary w-100'
                          onClick={() => coverDistrict(selectedDistrict.value)}
                        >
                          Cover District
                        </button>
                      )}
                    </Col>
                    <Col xs={12} md={12} style={{marginTop: '10px'}}>
                      <h4>Covered Districts</h4>
                      <div style={{height: '550px', overflow: 'auto'}}>
                        <Table striped bordered hover size='sm'>
                          <tbody>
                            {coveredDistricts.map((element: any) => (
                              <tr>
                                <td>{element.name}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>
                )}
              </Tab>
              <Tab eventKey='assign-sr' title='Assign SR to District'>
                <div className='card card-custom'>
                  <div className='card-header'>
                    <div className='card-title'>
                      <KTSVG
                        path='/media/svg/icons/Text/Bullet-list.svg'
                        className='svg-icon-1 svg-icon-primary'
                      />
                      <span className='text fw-bolder' style={{marginLeft: '10px'}}>
                        Assign SR to District
                      </span>
                    </div>
                    <div className='card-toolbar'>
                      <div className='d-flex justify-content-end'>
                        <button
                          type='button'
                          className='btn btn-sm btn-primary'
                          onClick={() => handleAssignSrModalShow()}
                        >
                          <i className='fa fa-plus'></i> Assign SR
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className='card-body'>
                    <div style={{height: '670px', overflow: 'auto'}}>
                      <Table striped bordered hover size='sm'>
                        <thead>
                          <tr>
                            <th colSpan={2}>
                              <input type='text' className='form-control form-control-sm' placeholder='Search' onChange={(e) => setAssignedSrSearchText(e.target.value)} />
                            </th>
                          </tr>
                          <tr>
                            <th>District</th>
                            <th>SR</th>
                          </tr>
                        </thead>
                        <tbody>
                          {assignedSrList.map((element: any) => (
                            <tr>
                              <td>{element.districtName}</td>
                              <td>{element.assignedSrName}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey='assign-wh' title='Assign WH to District'>
                <h3>Assign Warehouse to District</h3>
              </Tab>
            </Tabs>

            {/* <pre>{JSON.stringify(selectedDistrict, null, 2)}</pre> */}
            <ToastContainer theme='colored' />

            <Modal show={assignSrModalShow} onHide={handleCloseAssignSrModal}>
              <Modal.Header closeButton>
                <Modal.Title>Assign SR to District</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className='form-group row'>
                  <label className='col-xl-3 col-lg-3 col-form-label'>Select District</label>
                  <Select
                    options={districts}
                    placeholder='Select District'
                    onChange={setSelectedDistrictToAssignSr}
                  />
                </div>
                <div className='form-group row'>
                  <label className='col-xl-3 col-lg-3 col-form-label'>Select SR</label>
                  <Select
                    value={sr}
                    onChange={handleSrChange}
                    options={srs}
                    placeholder='Select SR'
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant='secondary' onClick={handleCloseAssignSrModal}>
                  Close
                </Button>
                <Button variant='primary' onClick={handleAssignSrSubmit}>
                  Save
                </Button>
              </Modal.Footer>
            </Modal>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default MapDashboard
