/* eslint-disable react-hooks/exhaustive-deps */
import { KTSVG } from '../../_metronic/helpers'
import React, { useState, useEffect } from 'react'
import { Table } from 'react-bootstrap-v5';
import Swal from 'sweetalert2'
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper'
import Select from 'react-select'
import { Link } from 'react-router-dom';
import validationErrorMsg from '../helpers/ValidationErrorMsg'
import { useHistory } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';

interface PurchaseRows {
    idItems: number;
    quantity: number;
    excludingRate: number;
    amount: number;
    discountAmount: number;
    carryingCost: number;
    totalAmount: number;
    rate: number;
    [key: string]: any; // Index signature
}

interface Item {
    idItems: number;
    itemName: string;
    unitName: string;
}

interface PurchaseData {
    purchaseType: string;
    purpose: string;
    idProjects: number;
    idVendors: number;
    purchaseDate: string;
    lcNo: string;
    representativeName: string;
    representativePhone: string;
    receivingAddress: string;
    totalAmount: number;
    totalDiscountAmount: number;
    totalCarryingCost: number;
    totalAmountAfterDiscount: number;
    vatPercentage: number;
    vatAmount: number;
    taxPercentage: number;
    taxAmount: number;
    purchaseAmount: number;
    creditDays: number;
}

interface TermsAndCondition {
    termsConditionDetails: string;
    [key: string]: any; // Index signature
}


const PurchaseOrderCreate = () => {

    let history = useHistory();

    const purchaseData: PurchaseData = {
        purchaseType: "PO",
        purpose: "Raw Materials",
        idProjects: 0,
        idVendors: 0,
        purchaseDate: '',
        lcNo: '',
        representativeName: '',
        representativePhone: '',
        receivingAddress: '',
        creditDays: 0,
        totalAmount: 0,
        totalDiscountAmount: 0,
        totalCarryingCost: 0,
        totalAmountAfterDiscount: 0,
        vatPercentage: 0,
        vatAmount: 0,
        taxPercentage: 0,
        taxAmount: 0,
        purchaseAmount: 0
    };

    const [projects, setProjects] = useState([]);
    const [project, setProject] = useState({ label: 'Select Project', value: '' });
    const [vendors, setVendors] = useState([]);
    const [vendor, setVendor] = useState({ label: 'Select Vendor', value: '' });
    let [purchase, setPurchase] = useState(purchaseData);
    const [purchaseRows, setPurchaseRows] = useState<PurchaseRows[]>([{
        idItems: 0,
        quantity: 0,
        excludingRate: 0,
        amount: 0,
        discountAmount: 0,
        carryingCost: 0,
        totalAmount: 0,
        rate: 0,
    }]);

    const [termsAndConditions, setTermsAndConditions] = useState<TermsAndCondition[]>([]);

    const [items, setItems] = useState<Item[]>([]);

    useEffect(() => {
        getProjects();
        getVendors();
        getItems();
    }, [])


    const getProjects = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'lead/projects',
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let projectObj = resp.data.map((item: any) => {
                    item.label = item.name;
                    item.value = item.id;
                    return item
                })
                setProjects(projectObj);
                if (projectObj.length > 0) {
                    setProject(projectObj[0]);
                    setPurchase({ ...purchase, idProjects: projectObj[0].value });
                }
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const getVendors = () => {

        fetch(
            process.env.REACT_APP_API_URL + 'vendors',
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let leadObj = resp.data.map((item: any) => {
                    item.label = item.vendorName;
                    item.value = item.idVendors;
                    return item
                })
                setVendors(leadObj);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const handleProjectChange = (project: any) => {
        setProject(project);
        setPurchase({ ...purchase, idProjects: project.value });
    }

    const handleVendorChange = (vendor: any) => {
        setVendor(vendor);
        setPurchase({ ...purchase, idVendors: vendor.value });
    }

    const handleItemChange = (data: any, index: number) => {
        const updatedPurchaseRows = [...purchaseRows];
        updatedPurchaseRows[index].idItems = data.value;
        updatedPurchaseRows[index].excludingRate = 0;
        setPurchaseRows(updatedPurchaseRows);
    }

    const getItems = () => {

        fetch(
            process.env.REACT_APP_API_URL + 'production-items',
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {

                let items = resp.data.map((item: any) => {
                    item.label = item.itemName;
                    item.value = item.idItems;
                    return item
                })
                setItems(items);

            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const updatedPurchaseRows = [...purchaseRows];
        updatedPurchaseRows[index][e.target.name] = e.target.value;

        setPurchaseRows(updatedPurchaseRows);
        calculateSummary();
    }


    const deleteItem = (index: number) => {
        const updatedPurchaseRows = [...purchaseRows];
        if (updatedPurchaseRows && updatedPurchaseRows[index]) {
            updatedPurchaseRows.splice(index, 1);
            setPurchaseRows(updatedPurchaseRows);
        }
    }

    const addItem = (index: number) => {
        const newRow: PurchaseRows = {
            idItems: 0,
            quantity: 0,
            excludingRate: 0,
            amount: 0,
            discountAmount: 0,
            carryingCost: 0,
            totalAmount: 0,
            rate: 0,
        };

        const updatedRows = [...purchaseRows];
        updatedRows.push(newRow);

        setPurchaseRows(updatedRows);
    }

    const handleInputOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPurchase({ ...purchase, [event.target.name]: event.target.value });
    }

    const handleVatOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        purchase = { ...purchase, vatPercentage: event.target.value };

        calculateSummary();
    }

    const handleTaxOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        purchase = { ...purchase, taxPercentage: event.target.value };
        calculateSummary();
    }



    const handleSelectOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setPurchase({ ...purchase, [event.target.id]: event.target.value });
    };

    const calculateSummary = async () => {
        if (!purchaseRows.length) return; // Early return for empty array
        let totalAmount = 0;
        let totalDiscountAmount = 0;
        let totalCarryingCost = 0;
        purchaseRows.forEach((row, index) => {
            row.amount = Number(row.excludingRate) * Number(row.quantity);
            row.discountAmount = Number(row.discountAmount);
            row.carryingCost = Number(row.carryingCost);
            row.totalAmount = row.amount - row.discountAmount + row.carryingCost;
            row.rate = row.quantity > 0 ? (row.amount - row.discountAmount + row.carryingCost) / row.quantity : row.excludingRate;
            totalAmount += row.amount;
            totalDiscountAmount += row.discountAmount;
            totalCarryingCost += row.carryingCost;
        });

        // let totalAmount = purchaseRows.reduce((acc, row) => acc + row.amount, 0);
        // let totalDiscountAmount = purchaseRows.reduce((acc, row) => acc + row.discountAmount, 0);
        // let totalCarryingCost = purchaseRows.reduce((acc, row) => acc + row.carryingCost, 0);
        let totalAmountAfterDiscount = totalAmount - totalDiscountAmount + totalCarryingCost;
        let vatAmount = (totalAmountAfterDiscount * Number(purchase.vatPercentage)) / 100;
        let taxAmount = (totalAmountAfterDiscount * Number(purchase.taxPercentage)) / 100;
        let purchaseAmount = totalAmountAfterDiscount + vatAmount + taxAmount;

        await setPurchase({
            ...purchase,
            totalAmount: totalAmount,
            totalDiscountAmount: totalDiscountAmount,
            totalCarryingCost: totalCarryingCost,
            totalAmountAfterDiscount: totalAmountAfterDiscount,
            vatPercentage: purchase.vatPercentage,
            taxPercentage: purchase.taxPercentage,
            vatAmount: vatAmount,
            taxAmount: taxAmount,
            purchaseAmount: purchaseAmount
        });

    };

    const numberFormat = (value: number) => {
        return parseFloat(value.toString()).toLocaleString('en-US', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }


    const deleteTermItem = (index: number) => {
        const updatedTermRows = [...termsAndConditions];
        if (updatedTermRows && updatedTermRows[index]) {
            updatedTermRows.splice(index, 1);
            setTermsAndConditions(updatedTermRows);
        }
    }

    const addTermItem = () => {
        const newTermRow: TermsAndCondition = {
            termsConditionDetails: '',
        };

        const updatedTermRows = [...termsAndConditions];
        updatedTermRows.push(newTermRow);

        setTermsAndConditions(updatedTermRows);
    }

    const handleTermsChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const updatedTermRows = [...termsAndConditions];
        updatedTermRows[index][e.target.name] = e.target.value;

        setTermsAndConditions(updatedTermRows);
    }

    const purchaseSave = async (event: any) => {
        event.preventDefault()

        setPurchase({ ...purchase });

        const confirm = await Swal.fire({
            title: 'Are you sure?',
            text: "You want to create this Purchase Order?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#ec008c',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Yes, Create it!',
        })
        if (confirm.value) {
            const postData = {
                purchase: purchase,
                purchaseRows: purchaseRows,
                customTermsConditions: termsAndConditions
            }
            const requestOptions = postRequestOptions(postData)
            try {
                let response = await fetch(
                    `${process.env.REACT_APP_API_URL}po`,
                    requestOptions
                )
                let respData = await response.json()
                if (respData.success === true) {
                    const successMsg = respData.successMessage
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: successMsg,
                    })

                    history.push("/po");
                } else {
                    const errorsMessage = validationErrorMsg(respData.errors).join('<br>')
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        html: errorsMessage,
                    })
                }

            } catch (error) {
                console.log(error, 'catch the hoop')
            }
        }
    }

    return (

        <div className="card card-custom">
            <div className="card-header">
                <div className="card-title">
                    <KTSVG
                        path='/media/svg/icons/Home/Book-open.svg'
                        className='svg-icon-1 svg-icon-primary'
                    />
                    <span className="text fw-bolder" style={{ marginLeft: "10px" }}>
                        Create Purchase Order
                    </span>
                </div>
            </div>

            <div className="ml-12">
                <div className="card-body">

                    <form onSubmit={purchaseSave}>

                        <div className="form-group row">
                            <label htmlFor="purchaseType" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Purchase Type <span className="required text-danger"></span></label>
                            <div className="col-lg-5">
                                <select name="purchaseType" id="purchaseType" onChange={handleSelectOnChange} className="form-control form-control-sm">
                                    <option value={'PO'}>PO</option>
                                    <option value={'LC'}>LC</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="purpose" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Purpose <span className="required text-danger"></span></label>
                            <div className="col-lg-5">
                                <select name="purpose" id="purpose" onChange={handleSelectOnChange} className="form-control form-control-sm">
                                    <option value={'Raw Materials'}>Raw Materials</option>
                                    <option value={'Consumable'}>Consumable</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="idProjects" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Select Project <span className="required text-danger"></span></label>
                            <div className="col-lg-5">
                                <Select
                                    value={project}
                                    onChange={handleProjectChange}
                                    options={projects}
                                    menuPosition="fixed"
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="idVendors" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Select Vendor <span className="required text-danger"></span></label>
                            <div className="col-lg-5">
                                <Select
                                    value={vendor}
                                    onChange={handleVendorChange}
                                    options={vendors}
                                    menuPosition="fixed"
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="purchaseDate" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Purchase Date <span className="required text-danger"></span></label>
                            <div className="col-lg-5">
                                <input type='date' name="purchaseDate" id="purchaseDate" onChange={handleInputOnChange} className="form-control form-control-sm" />
                            </div>
                        </div>

                        {
                            purchase.purchaseType === 'LC' && (
                                <>
                                    <div className="form-group row">
                                        <label htmlFor="lcNo" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>LC No <span className="required text-danger"></span></label>
                                        <div className="col-lg-5">
                                            <input name="lcNo" id="lcNo" onChange={handleInputOnChange} className="form-control form-control-sm" />
                                            <span className="form-text text-muted">Hint: LC # 0000-00-00-0000</span>
                                        </div>
                                    </div>
                                    {/* <div className="form-group row">
                                        <label htmlFor="issueDate" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Date Of Issues <span className="required text-danger"></span></label>
                                        <div className="col-lg-5">
                                            <input type='date' name="issueDate" id="issueDate" onChange={handleInputOnChange} className="form-control form-control-sm" />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="dateOfExpiry" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Date Of Expiry <span className="required text-danger"></span></label>
                                        <div className="col-lg-5">
                                            <input type='date' name="dateOfExpiry" id="dateOfExpiry" onChange={handleInputOnChange} className="form-control form-control-sm" />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="lcNo" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Transport Mode <span className="required text-danger"></span></label>
                                        <div className="col-lg-5">
                                            <select name="transportMode" id="transportMode" onChange={handleSelectOnChange} className="form-control form-control-sm">
                                                <option value={''}>Select Transport mode</option>
                                                <option value={'Road'}>By Road</option>
                                                <option value={'Air'}>By Air</option>
                                                <option value={'Sea'}>By Sea</option>
                                                <option value={'River'}>By River</option>
                                                <option value={'Train'}>By Train</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="lcNo" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>LC Payment Mode <span className="required text-danger"></span></label>
                                        <div className="col-lg-5">
                                            <select name="transportMode" id="transportMode" onChange={handleSelectOnChange} className="form-control form-control-sm">
                                                <option value={''}>Select Payment Mode</option>
                                                <option value={'At Sight'}>At Sight</option>
                                                <option value={'UPass'}>UPass</option>
                                                <option value={'Deferred'}>Deferred</option>
                                                <option value={'TT'}>TT</option>
                                            </select>
                                        </div>
                                    </div> */}
                                </>
                            )
                        }

                        <div className="form-group row mb-2">
                            <label htmlFor="representativeName" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Representative Name</label>
                            <div className="col-lg-5">
                                <input name="representativeName" id="representativeName" onChange={handleInputOnChange} className="form-control form-control-sm" />
                            </div>
                        </div>
                        <div className="form-group row mb-2">
                            <label htmlFor="representativePhone" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Representative Number</label>
                            <div className="col-lg-5">
                                <input name="representativePhone" id="representativePhone" onChange={handleInputOnChange} className="form-control form-control-sm" />
                            </div>
                        </div>
                        <div className="form-group row mb-2">
                            <label htmlFor="receivingAddress" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Receiving Address</label>
                            <div className="col-lg-5">
                                <input name="receivingAddress" id="receivingAddress" onChange={handleInputOnChange} className="form-control form-control-sm" />
                            </div>
                        </div>

                        <br></br>

                        <Accordion defaultActiveKey={['0', '1']} alwaysOpen>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Purchase Items</Accordion.Header>
                                <Accordion.Body>
                                    <div className="row">
                                        <div className="col">
                                            <Table size='sm' bordered hover>
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: '5%' }} className='text-center'>SL</th>
                                                        <th style={{ width: '10%' }}>Item Name <span className='text-danger'>*</span> </th>
                                                        <th style={{ width: '5%' }}>Unit <span className='text-danger'>*</span> </th>
                                                        <th style={{ width: '10%' }}>Rate</th>
                                                        <th style={{ width: '10%' }}>Quantity</th>
                                                        <th style={{ width: '10%' }}>Amount</th>
                                                        <th style={{ width: '10%' }}>Discount Amount</th>
                                                        <th style={{ width: '10%' }}>Carrying Cost</th>
                                                        <th style={{ width: '10%' }}>Total Amount</th>
                                                        <th style={{ width: '10%' }}>Rate Including </th>
                                                        <th style={{ width: '5%' }}></th>
                                                        <th style={{ width: '5%' }}></th>
                                                    </tr>
                                                </thead>

                                                <tbody>

                                                    {purchaseRows.map((row, index) => (
                                                        <tr key={index}>
                                                            <td className='text-center'>{index + 1}</td>
                                                            <td>
                                                                <Select
                                                                    placeholder={row.itemName}
                                                                    onChange={(selectedOption) => handleItemChange(selectedOption, index)}
                                                                    options={items}
                                                                    className='sm'
                                                                    menuPosition="fixed"
                                                                    styles={{
                                                                        control: (provided, state) => ({
                                                                            ...provided,
                                                                            height: '32px',
                                                                            minHeight: '32px',
                                                                        }),
                                                                        container: (provided, state) => ({
                                                                            ...provided,
                                                                            height: '32px',
                                                                        }),
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input type="text" name="unitName" value={items.find(item => item.idItems === row.idItems)?.unitName} readOnly className="form-control form-control-sm" />
                                                            </td>
                                                            <td>
                                                                <input type="number" name="excludingRate" value={row.excludingRate} onChange={(event) => handleChange(event, index)} className="form-control form-control-sm" />
                                                            </td>
                                                            <td>
                                                                <input type="number" name="quantity" value={row.quantity} onChange={(event) => handleChange(event, index)} className="form-control form-control-sm" />
                                                            </td>
                                                            <td>
                                                                <input type="number" name="amount" readOnly value={row.amount} className="form-control form-control-sm" />
                                                            </td>
                                                            <td>
                                                                <input type="number" name="discountAmount" value={row.discountAmount} onChange={(event) => handleChange(event, index)} className="form-control form-control-sm" />
                                                            </td>
                                                            <td>
                                                                <input type="number" name="carryingCost" value={row.carryingCost} onChange={(event) => handleChange(event, index)} className="form-control form-control-sm" />
                                                            </td>
                                                            <td>
                                                                <input type="number" name="totalAmount" readOnly value={row.totalAmount} className="form-control form-control-sm" />
                                                            </td>
                                                            <td>
                                                                <input type="number" name="rate" readOnly value={row.rate} className="form-control form-control-sm" />
                                                            </td>
                                                            <td>
                                                                {
                                                                    purchaseRows.length > 1 &&
                                                                    <button type='button' className="btn btn-light-primary btn-sm px-2 py-1" onClick={() => deleteItem(index)}>
                                                                        <KTSVG path='/media/svg/icons/General/Trash.svg' className='svg-icon-5 svg-icon-danger me-0' />
                                                                    </button>
                                                                }

                                                                {index === purchaseRows.length - 1 && (
                                                                    <button type='button' className="btn btn-success btn-sm px-2 py-1" onClick={() => addItem(index)}>
                                                                        <KTSVG path='/media/svg/icons/Navigation/Plus.svg' className='svg-icon-5 svg-icon-danger me-0' /> Add
                                                                    </button>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    ))}

                                                    <tr>
                                                        <td colSpan={5} className='text-end'><b>Total:</b></td>
                                                        <td><b>{numberFormat(purchase.totalAmount)}</b></td>
                                                        <td><b>{numberFormat(purchase.totalDiscountAmount)}</b></td>
                                                        <td><b>{numberFormat(purchase.totalCarryingCost)}</b></td>
                                                        <td><b>{numberFormat(purchase.totalAmountAfterDiscount)}</b></td>
                                                    </tr>
                                                </tbody>

                                            </Table>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6"></div>
                                        <div className="col-6">
                                            <div className='notice bg-light-primary rounded border-primary border border-dashed h-lg-100 p-6'>
                                                <div className="row mt-2">
                                                    <div className="col-md-5"><strong>Total Amount: </strong></div>
                                                    <div className="col-md-4">
                                                        {numberFormat(purchase.totalAmount)}
                                                    </div>
                                                </div>
                                                <div className="row mt-2">
                                                    <div className="col-md-5"><strong>Total Discount Amount: </strong></div>
                                                    <div className="col-md-4">
                                                        {numberFormat(purchase.totalDiscountAmount)}
                                                    </div>
                                                </div>
                                                <div className="row mt-2">
                                                    <div className="col-md-5"><strong>Total Carrying Cost: </strong></div>
                                                    <div className="col-md-4">
                                                        {numberFormat(purchase.totalCarryingCost)}
                                                    </div>
                                                </div>
                                                <div className="row mt-2">
                                                    <div className="col-md-5"><strong>VAT: </strong></div>
                                                    <div className="col-md-4">
                                                        <div className="input-group input-group-sm mb-3">
                                                            <input type='number' name="vatPercentage" onChange={handleVatOnChange} className="form-control form-control-sm" min={0} step={'any'} />
                                                            <span className="input-group-text">%</span>
                                                            <input type='number' name="vatAmount" id="vatAmount" value={purchase.vatAmount} className="form-control form-control-sm" readOnly />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-2">
                                                    <div className="col-md-5"><strong>TAX: </strong></div>
                                                    <div className="col-md-4">
                                                        <div className="input-group input-group-sm mb-3">
                                                            <input type='number' name="taxPercentage" onChange={(event) => handleTaxOnChange(event)} className="form-control form-control-sm" min={0} step={'any'} />
                                                            <span className="input-group-text">%</span>
                                                            <input type='number' name="taxAmount" id="taxAmount" value={purchase.taxAmount} className="form-control form-control-sm" readOnly />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-2">
                                                    <div className="col-md-5"><strong>Net Amount: </strong></div>
                                                    <div className="col-md-4">
                                                        {numberFormat(purchase.purchaseAmount)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Terms and Conditions and Additional Print Information</Accordion.Header>
                                <Accordion.Body>
                                    <div className="form-group row mb-2">
                                        <label htmlFor="creditDays" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Credit Days</label>
                                        <div className="col-lg-5">
                                            <input type='number' min={0} step={'any'} name="creditDays" id="creditDays" onChange={handleInputOnChange} className="form-control form-control-sm" />
                                        </div>
                                    </div>
                                    <Table size='sm' bordered hover>
                                        <thead>
                                            <tr>
                                                <th style={{ width: '10%' }} className='text-center'>SL</th>
                                                <th style={{ width: '80%' }}>Terms & Condition <span className='text-danger'>*</span> </th>
                                                <th style={{ width: '10%' }}></th>
                                            </tr>
                                        </thead>

                                        <tbody>

                                            {termsAndConditions.map((row, index) => (
                                                <tr key={'terms-' + index}>
                                                    <td className='text-center'>{index + 1}</td>
                                                    <td>
                                                        <input type="text" name="termsConditionDetails" value={row.termsConditionDetails} className="form-control form-control-sm" onChange={(event) => handleTermsChange(event, index)} />
                                                    </td>
                                                    <td>
                                                        <button type='button' className="btn btn-light-primary btn-sm px-2 py-1" onClick={() => deleteTermItem(index)}>
                                                            <KTSVG path='/media/svg/icons/General/Trash.svg' className='svg-icon-5 svg-icon-danger me-0' />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                            {termsAndConditions.length === 0 && (
                                                <tr>
                                                    <td colSpan={2} className='text-center'>No terms and conditions added</td>
                                                </tr>
                                            )}
                                            <tr>
                                                <td colSpan={2} className='text-end'>
                                                    <button type='button' className="btn btn-success btn-sm px-2 py-1" onClick={() => addTermItem()}>
                                                        <KTSVG path='/media/svg/icons/Navigation/Plus.svg' className='svg-icon-5 svg-icon-danger me-0' /> Add
                                                    </button>
                                                </td>
                                            </tr>

                                        </tbody>

                                    </Table>
                                    {/* <pre>{JSON.stringify(purchase, null, 2)}</pre>
                                    <pre>{JSON.stringify(purchaseRows, null, 2)}</pre> */}
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        {/* <pre>{JSON.stringify(purchase, null, 2)}</pre> */}

                        <div className="row mt-4">
                            <div className="col-12 text-center">
                                <button type="submit" className="btn btn-sm btn-primary me-2">
                                    <span>  Create PO</span>
                                </button>
                                <Link to='/po' type="reset" className="btn btn-sm btn-secondary">Cancel</Link>
                            </div>
                        </div>

                    </form>

                </div>
            </div>
        </div>

    )
}

export default PurchaseOrderCreate