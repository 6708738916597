import DataTable from '../components/DataTable';
import { KTSVG } from '../../_metronic/helpers'
import 'react-loading-skeleton/dist/skeleton.css';
import { Link } from 'react-router-dom';

function Purchase() {

    const hasPermission = 1; //parseInt(GetPagePermission("/setup-company-list"));
    const numberFormat = (value: number) => {
        return parseFloat(value.toString()).toLocaleString('en-US', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }

    const columns = [
        { value: 'id', title: 'ID', style: { textAlign: 'center' } },
        { value: 'projectName', title: 'Project', style: { textAlign: 'left' } },
        { value: 'purchaseType', title: 'Type', style: { textAlign: 'left' } },
        { value: 'purpose', title: 'Purpose', style: { textAlign: 'left' } },
        { value: 'referenceNo', title: 'PO No', style: { textAlign: 'left' } },
        { value: 'lcNo', title: 'LC No', style: { textAlign: 'left' } },
        { value: 'vendorName', title: 'Vendor', style: { textAlign: 'left' } },
        { value: 'purchaseDate', title: 'Purchase Date', style: { textAlign: 'left' } },
        {
            value: 'purchaseAmount', title: 'Amount', style: { textAlign: 'center' },
            hasHtmlComponent: true,
            htmlComponent: (row: any) => {
                return (
                    <>
                        {numberFormat(row.purchaseAmount)}
                    </>
                )
            },
        },
        { value: 'createdBy', title: 'Created By', style: { textAlign: 'left' } },
        {
            value: 'action', title: 'Action', style: { textAlign: 'left', whiteSpace: 'nowrap' }, hasActionsComponent: true, actionsComponent: ((row: any) => {
                return (
                    <>
                        <a className="btn btn-light-primary btn-sm" target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_BACKEND}printPo/${row.id}`}>
                            <KTSVG
                                path='/media/svg/icons/Devices/Printer.svg'
                                className='svg-icon-5 svg-icon'
                            />		Print
                        </a>
                        <Link className="btn btn-light-primary btn-sm px-2 py-1" to={`/po-details/${row.id}`}>
                            <KTSVG
                                path='/media/svg/icons/Layout/Layout-grid.svg'
                                className='svg-icon-5 svg-icon'
                            />		Details
                        </Link>
                        {/* <Link className="btn btn-light-primary btn-sm px-2 py-1" to={`/po-edit/${row.id}`}>
                            <KTSVG
                                path='/media/svg/icons/Design/Edit.svg'
                                className='svg-icon-5 svg-icon'
                            />		Edit
                        </Link> */}

                    </>
                )
            })
        }
    ]

    const filters = {
        id: {
            filterType: 'like',
            filterValue: '',
        },
        projectName: {
            filterType: 'like',
            filterValue: '',
        },
        purchaseType: {
            filterType: 'like',
            filterValue: '',
        },
        purpose: {
            filterType: 'like',
            filterValue: '',
        },
        referenceNo: {
            filterType: 'like',
            filterValue: '',
        },
        lcNo: {
            filterType: 'like',
            filterValue: '',
        },
        vendorName: {
            filterType: 'like',
            filterValue: '',
        },
        purchaseDate: {
            filterType: 'like',
            filterValue: '',
        },
        purchaseAmount: {
            filterType: 'like',
            filterValue: '',
        },
        createdBy: {
            filterType: 'like',
            filterValue: '',
        },
    }

    const url = process.env.REACT_APP_API_URL + 'po/dt'

    return (
        <>
            {hasPermission === 1 &&
                <>
                    <div className='card card-custom'>
                        <div className='card-header'>
                            <div className="card-title">
                                <KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-1 svg-icon-primary' />
                                <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
                                    Purchase Order List
                                </span>
                            </div>
                            <div className="card-toolbar">
                                <div className='d-flex justify-content-end'>
                                    <Link to={`/po-create`} className="btn btn-sm btn-primary"><i className="fa fa-plus"></i>Create New Purchase</Link>
                                </div>
                            </div>
                        </div>

                        <div className='card-body'>
                            <DataTable url={url} columns={columns} filters={filters} />
                        </div>
                    </div >
                </>
            }

        </>
    )
}

export default Purchase
