/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import { Modal, Button } from 'react-bootstrap'
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper'
import validationErrorMsg from '../helpers/ValidationErrorMsg'
import Spinner from 'react-bootstrap/Spinner'
import { Table } from 'react-bootstrap-v5'

interface ItemCreateProps {
	show: boolean;
	handleClose: any;
	salesOrderInfo: any;
	id: number;
}

interface OrderData {
	idSalesOrders: number;
	idProjects: number;
	projectName: string;
	shippingAddress: string;
	returnDate: string;
	chalanNo: string;
	vehicleNo: string;
	driverName: string;
	driverPhoneNo: string;
	remarks: string;
}

interface OrderRows {
	idProducts: number;
	quantity: number;
	rate: number;
	discount: number;
	originalAmount: number;
	amount: number;
	originalRate: number;
	[key: string]: any; // Index signature
}

// interface ProjectAmount {
// 	invoice: number;
// 	payment: number;
// 	shipment: number;
// }

const initialOrderData: OrderData = {
	idSalesOrders: 0,
	idProjects: 0,
	projectName: '',
	shippingAddress: '',
	returnDate: '',
	chalanNo: '',
	vehicleNo: '',
	driverName: '',
	driverPhoneNo: '',
	remarks: '',
}

const ProductReturn: React.FC<ItemCreateProps> = ({ show, handleClose, salesOrderInfo, id }) => {

	// const [projects, setProjects] = useState([]);
	// const [project, setProject] = useState({ label: 'Select Project', value: '' });
	// const [salesOrders, setSalesOrders] = useState([]);
	// const [salesOrder, setSalesOrder] = useState({ label: 'Select Sales Order', value: '' });
	const [orderData, setOrderData] = useState<OrderData>(initialOrderData);
	const [orderRows, setOrderRows] = useState<OrderRows[]>([]);

	useEffect(() => {
		// getProjects()
		setOrderData(initialOrderData)
		setOrderRows([])
		// setSalesOrder({ label: 'Select Sales Order', value: '' })
		if (salesOrderInfo && salesOrderInfo.idSalesOrders !== undefined) {
			getSalesOrderRows(salesOrderInfo.idSalesOrders);
		}

	}, [salesOrderInfo, id]);

	const getSalesOrderRows = (idSalesOrders: number) => {
		fetch(
			process.env.REACT_APP_API_URL + 'sales-order-rows/' + idSalesOrders,
			getRequestOptions()
		)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				let orderRows = resp.data.map((item: any) => {
					return item
				})
				setOrderRows(orderRows);
				setOrderData(resp.salesorder);
			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})
	};

	const [isSubmitDisable, setSubmitDisable] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleChange = (e: any) => {
		setOrderData({ ...orderData, [e.target.name]: e.target.value });
	};

	const handleOrderDataChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
		const updatedOrderRows = [...orderRows];

		let maxReturnQuantity = updatedOrderRows[index].already_delivered - updatedOrderRows[index].already_returned

		console.log(maxReturnQuantity)

		if (maxReturnQuantity < parseFloat(e.target.value)) {
			Swal.fire({
				icon: 'error',
				title: 'Warning',
				text: 'Quantity exceeds',
			})
			updatedOrderRows[index][e.target.name] = 0;
			setOrderRows(updatedOrderRows);
			e.target.value = '';
			return true
		}
		updatedOrderRows[index][e.target.name] = e.target.value;
		setOrderRows(updatedOrderRows);
	}

	// const handleProjectChange = (project: any) => {
	// 	setProject(project);
	// 	getOrdersByProjects(project.value);
	// 	setOrderData({ ...orderData, idProjects: project.id });
	// }

	// const getOrdersByProjects = (idProjects: number) => {

	// 	fetch(
	// 		process.env.REACT_APP_API_URL + 'shipment-sales-order-by-project/' + idProjects,
	// 		getRequestOptions()
	// 	)
	// 		.then((resp) => {
	// 			return resp.json()
	// 		})
	// 		.then((resp) => {
	// 			let orderObj = resp.data.map((item: any) => {
	// 				item.label = 'Order # ' + item.id + ', Date: ' + item.sales_order_date + ', Client: ' + item.company_name + ', Amount: ' + item.order_amount;
	// 				item.value = item.id;
	// 				return item
	// 			})
	// 			setSalesOrders(orderObj);
	// 		})
	// 		.catch((error) => {
	// 			console.log(error, 'catch the hoop')
	// 		})
	// }

	// const getProjects = () => {
	// 	fetch(
	// 		process.env.REACT_APP_API_URL + 'lead/projects',
	// 		getRequestOptions()
	// 	)
	// 		.then((resp) => {
	// 			return resp.json()
	// 		})
	// 		.then((resp) => {
	// 			let projects = resp.data.map((item: any) => {
	// 				item.label = item.name;
	// 				item.value = item.id;
	// 				return item
	// 			})
	// 			setProjects(projects);
	// 		})
	// 		.catch((error) => {
	// 			console.log(error, 'catch the hoop')
	// 		})
	// }

	const returnSave = async (event: any) => {
		setLoading(true);
		event.preventDefault()
		setSubmitDisable(true);
		const confirm = await Swal.fire({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#ec008c',
			cancelButtonText: 'Cancel',
			confirmButtonText: 'Yes, Return it!',
		})
		if (confirm.value) {

			const postData = {
				return: orderData,
				returnRows: orderRows
			}
			const requestOptions = postRequestOptions(postData)
			try {
				let response = await fetch(
					`${process.env.REACT_APP_API_URL}sales-order-return`,
					requestOptions
				)
				let respData = await response.json()
				if (respData.success === true) {
					const successMsg = respData.successMessage
					Swal.fire({
						icon: 'success',
						title: 'Success',
						text: successMsg,
					})
					handleClose(respData.success)
					// history.push('/shipments')
				} else {
					const errorsMessage = validationErrorMsg(respData.errors).join('<br>')
					Swal.fire({
						icon: 'error',
						title: 'Error',
						html: errorsMessage,
					})
				}
				setSubmitDisable(false);
				setLoading(false);


			} catch (error) {
				setLoading(false);
				console.log(error, 'catch the hoop')
				setSubmitDisable(false);
			}
		}
	}

	// const handleSalesOrderChange = (salesOrder: any) => {

	// 	setSalesOrder(salesOrder);
	// 	setOrderData({ ...orderData, idSalesOrders: salesOrder.value });
	// 	getSalesOrderRows(salesOrder.value);

	// }


	return (
		<Modal show={show} onHide={() => handleClose(false)} size="xl">
			<form onSubmit={returnSave}>
				<Modal.Header closeButton>
					{orderData.idSalesOrders !== 0 &&
						<Modal.Title>Product Return (Order No # {orderData.idSalesOrders})</Modal.Title>
					}
					{orderData.idSalesOrders === 0 &&
						<Modal.Title>Create New Shipment</Modal.Title>
					}
				</Modal.Header>
				<Modal.Body>
					{/* {JSON.stringify(orderData, null, 2)} */}
					{orderData.idProjects !== null &&
						<div className="form-group row">
							<label htmlFor="companyName" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Project <span className="required text-danger"></span></label>
							<div className="col-lg-5">


								{/* {salesOrderInfo === null &&
									<Select
										value={project}
										onChange={handleProjectChange}
										options={projects}
										menuPosition="fixed"
									/>
								} */}
								{salesOrderInfo !== null &&
									<div>{orderData.projectName}</div>
								}
							</div>
						</div>
					}
					{/* {orderData.idProjects !== 0 && orderData.idProjects !== null &&
						<div className="form-group row">
							<label htmlFor="companyName" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}></label>
							<div className="col-lg-5">Total Shipment Amount : {projectAmount.shipment}; Total Invoice Amount : {projectAmount.invoice}; Total Payment Made: {projectAmount.payment}</div>
						</div>
					} */}
					<div className="form-group row">
						<label htmlFor="idCompanyProjects" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Sales Order <span className="required text-danger"></span></label>
						<div className="col-lg-5">
							{/* {salesOrderInfo === null &&
								<Select
									value={salesOrder}
									onChange={handleSalesOrderChange}
									options={salesOrders}
									menuPosition="fixed"
								/>
							} */}
							{salesOrderInfo !== null &&
								<div>Order # {orderData.idSalesOrders}</div>
							}
						</div>
					</div>
					<div className="form-group row">
						<label htmlFor="returnDate" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Return Date <span className="required text-danger"></span></label>
						<div className="col-lg-5">
							<input type="date" name="returnDate" id="returnDate" value={orderData.returnDate} onChange={handleChange} className="form-control form-control-sm" />
						</div>
					</div>

					<div className="form-group row">
						<label htmlFor="remarks" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Remarks <span className="required text-danger"></span></label>
						<div className="col-lg-5">
							<textarea name="remarks" id="remarks" rows={5} value={orderData.remarks} onChange={handleChange} className="form-control form-control-sm"></textarea>
						</div>
					</div>

					<br></br>
					<Table size='sm' bordered hover>
						<thead>
							<tr>
								<th style={{ width: '5%' }} className='text-center'>SL</th>
								<th style={{ width: '25%' }}>Product Name</th>
								<th style={{ width: '10%' }}>Packing</th>
								<th style={{ width: '10%' }}>Rate</th>
								<th style={{ width: '15%' }}>Order Quantity</th>
								<th style={{ width: '15%' }}>Delivered Quantity</th>
								<th style={{ width: '10%' }}>Previous Returned</th>
								<th style={{ width: '15%' }}>Return Quantity</th>
							</tr>
						</thead>


						<tbody>

							{orderRows.map((row, index) => (
								<tr key={index}>
									<td className='text-center'>{index + 1}</td>
									<td>{row.product_name}</td>
									<td>{row.packing}</td>
									<td>{row.unit_rate}</td>
									<td>{row.quantity}</td>
									<td>{row.already_delivered}</td>
									<td>{row.already_returned}</td>
									<td>
										<input disabled={row.already_delivered === row.already_returned} type="number" name="return_quantity" onChange={(event) => handleOrderDataChange(event, index)} className="form-control form-control-sm" />
									</td>
								</tr>
							))}



						</tbody>



					</Table>
					{/* <pre>{JSON.stringify(orderRows, null, 2)}</pre> */}
				</Modal.Body>
				<Modal.Footer>

					<button type="submit" disabled={isSubmitDisable || loading} className="btn btn-sm btn-primary me-2">
						{loading &&
							<Spinner
								as="span"
								animation="grow"
								size="sm"
								role="status"
								aria-hidden="true"
							/>
						}
						<span>  Submit</span>
					</button>
					<Button variant='secondary' size="sm" onClick={() => handleClose(false)}>
						Close
					</Button>
				</Modal.Footer>
			</form>
		</Modal>
	)
}

export default ProductReturn
