import { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap-v5";
import Select from 'react-select'
import { ToastContainer } from 'react-toastify';
import ExpenseReport from '../components/ExpenseReport';
import SalespersonWiseSalesReport from '../components/SalespersonWiseSalesReport';
import ProductWiseSalesReport from '../components/ProductWiseSalesReport';
import SalesReport from '../components/SalesReport';
import DueReport from '../components/DueReport';
import SalespersonActivitesReport from '../components/SalespersonActivitesReport';
import DeliveryReport from '../components/DeliveryReport';
import OrderSheetReport from '../components/OrderSheetReport';
import LedgerStatementReport from '../components/LedgerStatementReport';
import CompanyWiseSalesReport from '../components/CompanyWiseSalesReport';
import CollectionReport from '../components/CollectionReport';
import InvoiceHistoryReport from '../components/InvoiceHistoryReport';
import CustomerEdgingReport from '../components/CustomerEdgingReport';

import GetPagePermission from "../components/GetPagePermission";
import { useLocation } from "react-router-dom";

export default function Reports() {

    const hasExpensePermission = GetPagePermission("/expense-report");
    const hasSalesPermission = GetPagePermission("/industrial-sales-report");
    const hasDeliveryPermission = GetPagePermission("/delivery-report");
    const hasLedgerStatementPermission = GetPagePermission("/ledger-statement");
    const hasOrderSheetPermission = GetPagePermission("/order-sheet-report");
    const hasDueReportPermission = GetPagePermission("/due-report");
    const hasCompanyWiseSalesReportPermission = GetPagePermission("/company-wise-sales-report");
    const hasCollectionReportPermission = GetPagePermission("/collection-report");
    const hasSalespersonActivitesReportPermission = GetPagePermission("/salesperson-activites-report");
    const hasInvoiceHistoryReportPermission = GetPagePermission("/invoice-history-report");
    const hasSalespersonWiseSalesPermission = GetPagePermission("/salesperson-wise-sales");
    const hasProductWiseSalesPermission = GetPagePermission("/product-wise-sales");
    const hasCustomerEdgingReportPermission = GetPagePermission("/customer-edging-report");

    const location = useLocation();
    const query = new URLSearchParams(location.search);

    const [currentReport, setCurrentReport] = useState({ label: 'Select a Report', value: '' });
    const [availableReports, setAvailableReports] = useState([{ label: 'Select a Report', value: '' }]);

    useEffect(() => {
        // Reset updatedReports to contain the default value at the beginning
        const updatedReports = [{ label: 'Select a Report', value: '' }];
        if (hasExpensePermission) {
            updatedReports.push({ label: 'Expense >> Expense Report', value: 'generalExpenseReport' });
        }
        if (hasSalesPermission) {
            updatedReports.push({ label: 'Sales >> Industrial Sales Report', value: 'generalSalesReport' });
        }
        if (hasDeliveryPermission) {
            updatedReports.push({ label: 'Delivery >> Delivery Report', value: 'generalDeliveryReport' });
        }
        if (hasLedgerStatementPermission) {
            updatedReports.push({ label: 'Accounting >> Ledger Statement', value: 'ledgerStatement' });
        }
        if (hasOrderSheetPermission) {
            updatedReports.push({ label: 'Sales >> Order Sheet Report', value: 'orderSheetReport' });
        }
        if (hasDueReportPermission) {
            updatedReports.push({ label: 'Sales >> Due Report', value: 'dueReport' });
        }
        if (hasCompanyWiseSalesReportPermission) {
            updatedReports.push({ label: 'Sales >> Company Wise Sales Report', value: 'companyWiseSalesReport' });
        }

        if (hasDueReportPermission) {
            updatedReports.push({ label: 'Sales >> Collection Report', value: 'collectionReport' });
        }

        if (hasSalespersonActivitesReportPermission) {
            updatedReports.push({ label: 'Sales >> Salesperson Activites Report', value: 'salespersonActivitesReport' });
        }

        if (hasInvoiceHistoryReportPermission) {
            updatedReports.push({ label: 'Sales >> Invoice History Report', value: 'invoiceHistoryReport' });
        }

        if (hasSalespersonWiseSalesPermission) {
            updatedReports.push({ label: 'Sales >> Salesperson Wise Product Report', value: 'salespersonWiseSales' });
        }
        if (hasProductWiseSalesPermission) {
            updatedReports.push({ label: 'Sales >> Product Wise Sales Report', value: 'productWiseSales' });
        }

        if (hasCustomerEdgingReportPermission) {
            updatedReports.push({ label: 'Sales >> Client Edging Report', value: 'customerEdgingReport' });
        }

        updatedReports.sort((a, b) => a.label.localeCompare(b.label)); // Sort the array by label

        setAvailableReports(updatedReports); // Update the state with the new array
        // eslint-disable-next-line
    }, [hasExpensePermission, hasSalesPermission, hasDeliveryPermission, hasLedgerStatementPermission, hasOrderSheetPermission, hasDueReportPermission, hasCompanyWiseSalesReportPermission, hasSalespersonActivitesReportPermission]);

    useEffect(() => {
        if (query.get('type') === 'companyWiseSalesReport') {
            setCurrentReport({ label: 'Sales >> Company Wise Sales Report', value: 'companyWiseSalesReport' })
        }
        if (query.get('type') === 'dueReport') {
            setCurrentReport({ label: 'Sales >> Due Report', value: 'dueReport' })
        }

        if (query.get('type') === 'orderSheetReport') {
            setCurrentReport({ label: 'Sales >> Order Sheet Report', value: 'orderSheetReport' })
        }

        if (query.get('type') === 'ledgerStatement') {
            setCurrentReport({ label: 'Accounting >> Ledger Statement', value: 'ledgerStatement' })
        }
        // eslint-disable-next-line
    }, [query.get('type')])


    return (
        <>
            <div className='card card-custom'>
                <div className='card-header'>
                    <div className="card-title">
                        <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
                            Report Viewer
                        </span>
                    </div>
                </div>
                <div className='card-body pt-0'>
                    <Row>
                        <Col xs={8} md={10} lg={10}>
                            <Select
                                className="form-select-sm"
                                value={currentReport}
                                onChange={(e: any) => setCurrentReport(e)}
                                options={availableReports}
                                menuPosition="fixed"
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            />
                        </Col>
                        <Col xs={4} md={2} lg={2} className={"form-control-sm ps-0"}>
                            <button type="button" className="btn btn-primary btn-sm w-100 h-100">Generate</button>
                        </Col>
                    </Row>
                    <div className="mt-5">
                        {currentReport.value === 'generalExpenseReport' && hasExpensePermission && <ExpenseReport />}
                        {currentReport.value === 'salespersonWiseSales' && hasSalespersonWiseSalesPermission && <SalespersonWiseSalesReport />}
                        {currentReport.value === 'productWiseSales' && hasProductWiseSalesPermission && <ProductWiseSalesReport />}
                        {currentReport.value === 'generalSalesReport' && hasSalesPermission && <SalesReport />}
                        {currentReport.value === 'generalDeliveryReport' && hasDeliveryPermission && <DeliveryReport />}
                        {currentReport.value === 'ledgerStatement' && hasLedgerStatementPermission && <LedgerStatementReport />}
                        {currentReport.value === 'orderSheetReport' && hasOrderSheetPermission && <OrderSheetReport />}
                        {currentReport.value === 'dueReport' && hasDueReportPermission && <DueReport />}
                        {currentReport.value === 'companyWiseSalesReport' && hasCompanyWiseSalesReportPermission && <CompanyWiseSalesReport />}
                        {currentReport.value === 'collectionReport' && hasCollectionReportPermission && <CollectionReport />}
                        {currentReport.value === 'salespersonActivitesReport' && hasSalespersonActivitesReportPermission && <SalespersonActivitesReport />}
                        {currentReport.value === 'invoiceHistoryReport' && hasInvoiceHistoryReportPermission && <InvoiceHistoryReport />}
                        {currentReport.value === 'customerEdgingReport' && hasCustomerEdgingReportPermission && <CustomerEdgingReport />}
                        <ToastContainer />
                    </div>
                </div>
            </div >
        </>
    )
}
