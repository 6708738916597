import { useState } from 'react'
import DataTable from '../components/DataTable';
import { KTSVG } from '../../_metronic/helpers'
import Swal from 'sweetalert2'
import CompanyCategoryCreate from '../components/CompanyCategoryCreate';
import 'react-loading-skeleton/dist/skeleton.css';

function CompanyCategoryList() {

    const hasPermission = 1; //parseInt(GetPagePermission("/setup-company-list"));
    const [newData, setNewData] = useState({});
    const [id, setId] = useState(0);

    const [createCompanyCategoryModalShow, setCreateCompanyCategoryModalShow] = useState(false);

    const handleCreateCompanyCategoryModalShow = () => {
        setId(0);
        setCreateCompanyCategoryModalShow(true);
    }

    const handleCreateCompanyCategoryModalClose = (isReload: boolean) => {
        setCreateCompanyCategoryModalShow(false);
        if (isReload) {
            setNewData({ id: Date.now() });
        }
    }

    const deleteCompanyCategory = (id: number): void => {
        if (window.confirm("Are you sure you want to delete this Client Category?")) {
            const myToken: string | null = localStorage.getItem('MyToken');
            let jwt: string | null = (myToken != null) ? JSON.parse(myToken) : null;
            fetch(`${process.env.REACT_APP_API_URL}company-category/delete/${id}`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwt}` }
            })
                .then((resp: Response) => {
                    return resp.json();
                })
                .then((resp: any) => {
                    if (resp.success) {
                        const successMsg: string = resp.successMessage;
                        Swal.fire({
                            icon: 'success',
                            title: 'Deleted!',
                            text: successMsg,
                        });
                        setNewData({ id: Date.now() });
                    }
                })
                .catch((error: any) => {
                    console.log(error, 'catch the hoop');
                });
        }
    };

    const editCompanyCategory = (id: number): void => {
        setId(id);
        setCreateCompanyCategoryModalShow(true);
    };

    const columns = [
        { value: 'id', title: 'Id', style: { textAlign: 'center' } },
        { value: 'name', title: 'Name', style: { textAlign: 'left' } },
        { value: 'remarks', title: 'Remarks', style: { textAlign: 'left' } },
        {
            value: 'action', title: 'Action', style: { textAlign: 'left', whiteSpace: 'nowrap' }, hasActionsComponent: true, actionsComponent: ((row: any) => {
                return (
                    <>

                        <button className="btn btn-light-primary btn-sm" onClick={() => editCompanyCategory(row.id)}>
                            <KTSVG
                                path='/media/svg/icons/Communication/Write.svg'
                                className='svg-icon-5 svg-icon'
                            />		Edit
                        </button>

                        <button className="btn btn-light-danger btn-sm p-2" onClick={() => deleteCompanyCategory(row.id)}>
                            <KTSVG
                                path='/media/svg/icons/General/Trash.svg'
                                className='svg-icon-5 svg-icon'
                            />		Delete
                        </button>
                    </>
                )
            })
        }
    ]

    const filters = {
        id: {
            filterType: 'like',
            filterValue: '',
        },
        name: {
            filterType: 'like',
            filterValue: '',
        },
        remarks: {
            filterType: 'remarks',
            filterValue: '',
        },
    }

    const url = process.env.REACT_APP_API_URL + 'company-category/dt'

    return (
        <>
            {hasPermission === 1 &&
                <div className='card card-custom'>
                    <div className='card-header'>
                        <div className="card-title">
                            <KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-1 svg-icon-primary' />
                            <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
                                Client Category List
                            </span>
                        </div>
                        <div className="card-toolbar">
                            <div className='d-flex justify-content-end'>
                                <button type="button" className="btn btn-sm btn-primary" onClick={() => handleCreateCompanyCategoryModalShow()}><i className="fa fa-plus"></i>Add Client Category</button>
                            </div>
                        </div>
                    </div>

                    <div className='card-body'>
                        <DataTable url={url} columns={columns} filters={filters} newData={newData} />
                    </div>
                </div>
            }

            <CompanyCategoryCreate show={createCompanyCategoryModalShow} handleClose={handleCreateCompanyCategoryModalClose} id={id} />

            {/* {hasPermission === 0 &&
                <Error />
            }
            {hasPermission === 0 &&
                <div className='card card-custom' style={{ padding: "15px" }} >
                    <SkeletonTheme>
                        <p>
                            <Skeleton
                                containerClassName="avatar-skeleton"
                                count={40} ></Skeleton>
                        </p>
                    </SkeletonTheme>
                </div>
            } */}

        </>
    )
}

export default CompanyCategoryList
