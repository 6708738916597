/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from "react";
import { KTSVG } from '../../_metronic/helpers';
import { Editor } from '@tinymce/tinymce-react';
import Swal from 'sweetalert2';
import { getRequestOptions, postRequestOptions } from "../helpers/Fetchwrapper";
import validationErrorMsg from "../helpers/ValidationErrorMsg";
import Error from '../components/Error500'
import GetPagePermission from "../components/GetPagePermission";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';


function EmailTemplate() {

    const hasPermission = GetPagePermission("/setup-email-template");
    const [emailSubject, setEmailSubject] = useState('');
    const [emailType, setEmailType] = useState('');

    const editorRef = useRef();

    useEffect(() => {
        const getEmailTemplate = () => {
            fetch(
                process.env.REACT_APP_API_URL + 'get_email_template/' + emailType,
                getRequestOptions()
            )
                .then((resp) => {
                    return resp.json()
                })
                .then((resp) => {
                    if (resp.data !== null) {
                        console.log(resp.data, 'resp.data')
                        editorRef.current.setContent(resp.data.emailBody);
                        setEmailSubject(resp.data.subject);
                    }
                    else {
                        editorRef.current.setContent('');
                        setEmailSubject('');
                    }
                })
                .catch((error) => {
                    console.log(error, 'catch the hoop')
                })
        }
        if (emailType !== '') {
            getEmailTemplate();
        }
    }, [emailType])

    const handleEmailSubjectChange = (e) => {
        setEmailSubject(e.target.value);
    }

    const handleEmailTypeChange = (e) => {
        setEmailType(e.target.value);
        editorRef.current.setContent('');
    }

    const handleSubmit = (evt) => {
        evt.preventDefault()

        Swal.fire({
            title: 'Are you sure?',
            text: 'You wont to create this email template',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            confirmButtonColor: '#ec008c'
        }).then((result) => {
            if (result.value) {
                const newFormData = {
                    emailSubject: emailSubject,
                    emailType: emailType,
                    emailBody: editorRef.current.getContent()
                }

                const requestOptions = postRequestOptions(newFormData);

                const apiEnd = 'email_template_store';

                fetch(process.env.REACT_APP_API_URL + apiEnd, requestOptions)
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        // console.log(resp)

                        if (resp.success === true) {
                            const successMsg = resp.successMessage;

                            Swal.fire({
                                icon: 'success',
                                title: 'Success',
                                text: successMsg,
                            })
                            setEmailSubject('');
                            setEmailType('');
                            editorRef.current.setContent('');

                        } else {

                            const errorsMessage = validationErrorMsg(resp.errors).join("<br>");
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                html: errorsMessage,
                            })
                        }
                    })
                    .catch((error) => {
                        console.log(error, 'catch the hoop')
                    })
            }
        })
    }

    return (
        <>
            {hasPermission === 1 &&
                <div className='card card-custom'>
                    <div className='card-header'>
                        <div className='card-title'>
                            <KTSVG
                                path='/media/icons/duotune/general/gen005.svg'
                                className='svg-icon-1 svg-icon-primary'
                            />
                            <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
                                Email Template
                            </span>
                        </div>
                    </div>
                    <div className='card-body'>
                        <div className="ml-12">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="form-group row">
                                        <label htmlFor="emailType" style={{ "textAlign": "right" }} className="col-lg-3 col-form-label"> Email Type<span className="required text-danger"></span></label>
                                        <div className="col-lg-6">
                                            <select className="form-select form-select-sm" id="emailType" name="emailType" onChange={handleEmailTypeChange} value={emailType}>
                                                <option value="">Select Email Type</option>
                                                <option value="requisition_approve">Requisition Approve</option>
                                                <option value="requisition_receive">Requisition Receive</option>
                                                <option value="requisition_task_complete">Requisition Task Complete</option>
                                                <option value="send_back_for_review">Task Send Back for Review</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="emailSubject" style={{ "textAlign": "right" }} className="col-lg-3 col-form-label"> Email Subject<span className="required text-danger"></span></label>
                                        <div className="col-lg-6">
                                            <input type="text" name="emailSubject" id="emailSubject" value={emailSubject} onChange={handleEmailSubjectChange} className="form-control form-control-sm mt-1" />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label htmlFor="emailBody" style={{ "textAlign": "right" }} className="col-lg-3 col-form-label"> Email Body<span className="required text-danger"></span></label>
                                        <div className="col-lg-6">
                                            <Editor
                                                apiKey="27k7mo6dhwbg8ogpsyq0gfjtfd4d5682zmurtqp44ean979x"
                                                onInit={(evt, editor) => editorRef.current = editor}
                                                init={{
                                                    height: 500,
                                                    plugins: [
                                                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                                        'insertdatetime', 'media', 'table', 'help', 'wordcount'
                                                    ],
                                                    toolbar: 'undo redo | blocks | ' +
                                                        'bold italic backcolor | alignleft aligncenter ' +
                                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                                        'removeformat | help',
                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'

                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-5"></div>
                                    <div className='col-6'>
                                        <button className="btn btn-sm btn-primary me-2">
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            }
            {hasPermission === 0 &&
                <Error />
            }
            {hasPermission === '' &&
                <div className='card card-custom' style={{ padding: "15px" }} >
                    <SkeletonTheme>
                        <p>
                            <Skeleton
                                containerClassName="avatar-skeleton"
                                count={40} ></Skeleton>
                        </p>
                    </SkeletonTheme>
                </div>
            }
        </>
    )
}

export default EmailTemplate;