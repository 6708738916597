import { useState, useEffect } from "react";
import { Button, Modal, Form, Table, Row, Col } from "react-bootstrap-v5";
import { KTSVG } from '../../_metronic/helpers';
import { getRequestOptions, postRequestOptions, deleteRequestOptions } from '../helpers/Fetchwrapper';
import validationErrorMsg from '../helpers/ValidationErrorMsg'
import Swal from 'sweetalert2'
import Select from 'react-select'

interface FormDataType {
    idLeads?: number,
    idCompetitors?: any,
    idProducts?: any,
    idUnits?: any,
    unitName?: any,
    quantity?: any,
    rate?: any,
    amount?: any,
}

interface ProductUseProps {
    idLeads: number,
    getLeadInfo: any,
    leadProducts: []
}

interface ProductUseType {
    id: string;
    name: string;
    label: string;
    value: string;
}

interface CompetitorType {
    label: string;
    value: string;
}


export default function CurrentProductUse({ idLeads, getLeadInfo, leadProducts }: ProductUseProps) {

    const initialState: FormDataType = {
        idLeads: idLeads,
        idCompetitors: '',
        idProducts: '',
        idUnits: '',
        unitName: '',
        quantity: '',
        rate: '',
        amount: ''
    };

    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState<FormDataType>(initialState);

    const [products, setProducts] = useState<ProductUseType[]>([]);
    const [selectedProduct, setSelectedProduct] = useState<any>({ label: 'Select Product', value: '' });
    const [searchQuery, setSearchQuery] = useState<string>('');
    console.log(searchQuery);

    const [competitors, setCompetitors] = useState<CompetitorType[]>([]);
    const [selectedCompetitor, setSelectedCompetitor] = useState<any>({ label: 'Select', value: '' });

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        getAllProducts('');
        getCompetitors();
    }, []);

    // const loadOptions1 = async (searchTerm: string): Promise<ProductUseType[]> => {
    //     const response = await fetch(`${process.env.REACT_APP_API_URL}/lead/products?q=${searchTerm}`);
    //     const data = await response.json();
    //     return data?.data ?? [];
    // };

    const getAllProducts = (searchTerm: string) => {
        fetch(`${process.env.REACT_APP_API_URL}lead/products?q=${searchTerm}`, getRequestOptions())
            .then((resp) => resp.json())
            .then((resp) => {
                let product: ProductUseType[] = resp.data.map((item: ProductUseType) => {
                    item.label = item.name;
                    item.value = item.id;
                    return item;
                });
                setProducts(product);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop');
            });
    };

    const getCompetitors = () => {
        fetch(`${process.env.REACT_APP_API_URL}lead/competitors`, getRequestOptions())
            .then((resp) => resp.json())
            .then((resp) => {
                let competitor: CompetitorType[] = resp.data.map((item: any) => {
                    item.label = item.name;
                    item.value = item.id;
                    return item;
                });
                setCompetitors(competitor);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop');
            });
    };

    const handleCompetitorChange = (competitor: any) => {
        setSelectedCompetitor(competitor);
        setFormData({ ...formData, idCompetitors: competitor?.value ?? null });
    };

    const handleProductChange = (product: any) => {
        setSelectedProduct(product);
        setFormData({ ...formData, idProducts: product?.value ?? null, idUnits: product?.idUnits ?? null, unitName: product?.unitName ?? null });
    };

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value.toString() });
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setIsLoading(true);
        // return false;
        setFormData({ ...formData, idLeads: idLeads });

        const requestOptions = postRequestOptions(formData)
        try {
            let response = await fetch(
                `${process.env.REACT_APP_API_URL}lead/store-product`,
                requestOptions
            )
            let respData = await response.json()
            setIsLoading(false);
            if (respData.success === true) {
                setFormData(initialState);
                getLeadInfo();
            } else {
                const errorsMessage = validationErrorMsg(respData.errors).join('<br>')
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    html: errorsMessage,
                })
            }

        } catch (error) {
            setIsLoading(false);
        }

    }

    const handleDelete = (id: number) => {

        console.log(id);
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to delete this product!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            confirmButtonColor: '#ec008c'

        }).then((result) => {
            if (result.value) {
                fetch(process.env.REACT_APP_API_URL + 'lead/delete-product/' + id, deleteRequestOptions())
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        if (resp.success) {
                            getLeadInfo();
                        }
                    })
                    .catch((error) => {
                        console.log(error, 'catch the hoop')
                    })
            }
        })

    }


    return (<>

        <div className="card-header py-5">
            <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-dark">Current Product Use(Yearly)</span>
            </h3>
        </div>
        <div className="card-body pt-0">
            <Table striped bordered hover size="sm">
                <tbody>
                    <tr>
                        <td>Competitor</td>
                        <td>Product</td>
                        <td>Qty</td>
                        <td>Rate</td>
                        <td width="10%">Amt</td>
                        <td>Action</td>
                    </tr>
                    {leadProducts.map((item: any, index) => {
                        return <tr key={'product_' + index}>
                            <td>{item.competitor?.competitorName}</td>
                            <td>{item.product?.productName}</td>
                            <td>{Number(item.quantity)} {item.unit?.unitName}</td>
                            <td>{Number(item.rate)}</td>
                            <td>{Number(item.amount)}</td>
                            <td className="text-center">
                                <Button variant="danger" size="sm" onClick={() => handleDelete(item.idLeadProducts)}>
                                    <KTSVG className="m-0" path="/media/svg/icons/General/Trash.svg" />
                                </Button>
                            </td>
                        </tr>
                    })}
                </tbody>
            </Table>
        </div>
        <div className="d-flex justify-content-end px-3 pb-2">
            <Button onClick={handleShow} variant="success" size="sm">
                <KTSVG className="svg-icon-3" path="/media/svg/icons/Code/Plus.svg" /> Add Product
            </Button>
        </div>

        <Modal show={show} onHide={handleClose}>
            <Form onSubmit={handleSubmit}>
                <Modal.Header className="py-3" closeButton>
                    <Modal.Title>Add New Product to Lead</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="inputPassword5">Select Competitor<span className="required text-danger"></span></Form.Label>
                        <Select
                            isClearable={true}
                            isSearchable={true}
                            value={selectedCompetitor}
                            onChange={handleCompetitorChange}
                            options={competitors}
                            menuPosition="fixed"
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="inputPassword5">Select Product</Form.Label>
                        <Select
                            isClearable={true}
                            isSearchable={true}
                            value={selectedProduct}
                            onChange={handleProductChange}
                            onInputChange={setSearchQuery}
                            // loadOptions={getAllProducts}
                            options={products}
                        />
                    </Form.Group>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="quantity">Quantity</Form.Label>
                                <Form.Control
                                    type="number"
                                    step={'any'}
                                    id="quantity"
                                    name="quantity"
                                    size="sm"
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="unit">Unit</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="unit"
                                    size="sm"
                                    value={formData.unitName ?? ''}
                                    readOnly
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="rate">Rate</Form.Label>
                        <Form.Control
                            type="number"
                            min={0}
                            step={'any'}
                            id="rate"
                            name="rate"
                            size="sm"
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="amount">Amount</Form.Label>
                        <Form.Control
                            type="number"
                            min={0}
                            step={'any'}
                            id="amount"
                            name="amount"
                            size="sm"
                            onChange={handleChange}
                        />
                    </Form.Group>
                    {/* <pre>{JSON.stringify(formData, null, 2)}</pre> */}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size="sm" onClick={handleClose}>
                        Close
                    </Button>

                    <Button disabled={isLoading} variant="success" size="sm" type="submit" onClick={handleClose}>
                        {isLoading ? <i className='fa fa-spin fa-spinner'></i> : <><KTSVG className="svg-icon-3" path="/media/svg/icons/General/Save.svg" /> Save</>}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    </>)
}