/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import { Modal, Button } from 'react-bootstrap'
import { postRequestOptions } from '../helpers/Fetchwrapper'
import validationErrorMsg from '../helpers/ValidationErrorMsg'
import Spinner from 'react-bootstrap/Spinner'

interface CompanyCategoryCreateProps {
	show: boolean;
	handleClose: any;
	id: number;
}

interface InitialState {
	name: string;
	remarks: string;
}


const CompanyCategoryCreate: React.FC<CompanyCategoryCreateProps> = ({ show, handleClose, id }) => {

	const initialState: InitialState = {
		name: '',
		remarks: '',
	};

	const [companyCategory, setCompanyCategory] = useState(initialState);

	useEffect(() => {
		console.log('not here', id)
	}, [])

	useEffect(() => {
		console.log('here', id)
		if (id !== 0) {
			const myToken: string | null = localStorage.getItem('MyToken');
			let jwt: string | null = (myToken != null) ? JSON.parse(myToken) : null;
			fetch(`${process.env.REACT_APP_API_URL}company-category/details/${id}`, {
				headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwt}` }
			})
				.then((resp: Response) => {
					return resp.json();
				})
				.then((resp: any) => {
					setCompanyCategory(resp);
				})
				.catch((error: any) => {
					console.log(error, 'catch the hoop');
				});
		} else {
			setCompanyCategory(initialState)
		}
	}, [id]);


	const [isSubmitDisable, setSubmitDisable] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleChange = (e: any) => {
		setCompanyCategory({ ...companyCategory, [e.target.name]: e.target.value });
	};

	const companyCategorySave = async (event: any) => {
		event.preventDefault()
		setSubmitDisable(true);
		setLoading(true);
		const confirm = await Swal.fire({
			title: 'Are you sure?',
			text: "You want to create this Client Category?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#ec008c',
			cancelButtonText: 'Cancel',
			confirmButtonText: 'Yes, Submit it!',
		})
		if (confirm.value) {
			const requestOptions = postRequestOptions(companyCategory)
			try {
				let url = '';
				if (id === 0) {
					url = `${process.env.REACT_APP_API_URL}company-category/create`
				} else {
					url = `${process.env.REACT_APP_API_URL}company-category/update`
				}

				let response = await fetch(
					url,
					requestOptions
				)

				let respData = await response.json()
				if (respData.success === true) {
					const successMsg = respData.successMessage
					Swal.fire({
						icon: 'success',
						title: 'Success',
						text: successMsg,
					})
					handleClose(respData.success)
				} else {
					const errorsMessage = validationErrorMsg(respData.errors).join('<br>')
					Swal.fire({
						icon: 'error',
						title: 'Error',
						html: errorsMessage,
					})
				}
				setSubmitDisable(false);
				setLoading(false);
			} catch (error) {
				setLoading(false);
				console.log(error, 'catch the hoop')
				setSubmitDisable(false);
			}
		}
	}


	return (
		<Modal show={show} onHide={() => handleClose(false)} size="lg">
			<form onSubmit={companyCategorySave}>
				<Modal.Header closeButton>
					<Modal.Title>Create Client Category</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{/* {JSON.stringify(companyCategory)} */}
					<div className="form-group row">
						<label htmlFor="name" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Name <span className="required text-danger"></span></label>
						<div className="col-lg-7">
							<input type="text" name="name" id="name" value={companyCategory.name} onChange={handleChange} className="form-control form-control-sm" />
						</div>
					</div>
					<div className="form-group row">
						<label htmlFor="remarks" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Remarks </label>
						<div className="col-lg-7">
							<textarea name="remarks" id="remarks" value={companyCategory.remarks} onChange={handleChange} className="form-control form-control-sm" rows={5}></textarea>
						</div>
					</div>
					{/* <pre>{JSON.stringify(company, null, 2)}</pre> */}
				</Modal.Body>
				<Modal.Footer>

					<button type="submit" disabled={isSubmitDisable || loading} className="btn btn-sm btn-primary me-2">
						{loading &&
							<Spinner
								as="span"
								animation="grow"
								size="sm"
								role="status"
								aria-hidden="true"
							/>
						}
						<span>  Submit</span>
					</button>
					<Button variant='secondary' size="sm" onClick={() => handleClose(false)}>
						Close
					</Button>
				</Modal.Footer>
			</form>
		</Modal>
	)
}

export default CompanyCategoryCreate
