import { useState, useEffect } from 'react'
import DataTable from '../components/DataTable';
import { KTSVG } from '../../_metronic/helpers'
import 'react-loading-skeleton/dist/skeleton.css';
import SalesPaymentCreate from '../components/SalesPaymentCreate';
import SalesPaymentEdit from '../components/SalesPaymentEdit';
import { getRequestOptions } from '../helpers/Fetchwrapper';
import Select from 'react-select';
import GetPagePermission from "../components/GetPagePermission";
import { deleteRequestOptions } from '../helpers/Fetchwrapper';
import Swal from 'sweetalert2';

function SalesPayment() {
    const paymentEditPermission = GetPagePermission("/sales-payment-edit");
    const hasPermission = 1; //parseInt(GetPagePermission("/setup-company-list"));
    const paymentDeleteHasPermission = GetPagePermission("/sales-payment-delete");

    const [selectProjectOptions, setSelectProjectOptions] = useState([])
    const [project, setProject] = useState({ label: 'Select Project', value: '' });
    const [idSalesPayments, setIdSalesPayments] = useState(0);

    const handleProjectChange = (project: any) => {
        setProject(project);
    }

    useEffect(() => {
        getProjectList();
    }, [])

    const getProjectList = async () => {
        const url = process.env.REACT_APP_API_URL + 'projects';
        const response = await fetch(url, getRequestOptions());
        const data = await response.json();
        data.data.map((element: any) => {
            element.value = element.id_projects;
            element.label = element.project_name;
            return element;
        })
        if (data.data.length === 1) {
            setSelectProjectOptions(data.data)
        } else {
            var allProjectsOpt = [{ id: '', name: '', label: 'All Project', value: '' }]
            let allProjects = data.data.concat(allProjectsOpt);
            setSelectProjectOptions(allProjects);
        }
    }

    useEffect(() => {
        if (selectProjectOptions.length === 1) {
            setProject({ label: selectProjectOptions[0]['label'], value: selectProjectOptions[0]['value'] })
        }
    }, [selectProjectOptions])

    useEffect(() => {
        setAdditionalPostData({ idProjects: project.value })
    }, [project])

    const [additionalPostData, setAdditionalPostData] = useState({ idProjects: '' });

    const [createCompanyModalShow, setPaymentModalShow] = useState(false);
    const [salesOrderInfo, setSalesOrderInfo] = useState({});
    const [newData, setNewData] = useState({});

    //Edit Sales Payment
    const [editModalShow, setEditPaymentModalShow] = useState(false);

    const handlePaymentModalClose = (isReload: boolean) => {
        setPaymentModalShow(false);
        if (isReload) {
            setNewData({ id: Date.now() });
        }
    }

    const handleSalesPaymentModalShow = (salesOrderInfo: any) => {
        setSalesOrderInfo(salesOrderInfo);
        setPaymentModalShow(true);
        setIdSalesPayments(0);
    }

    const handleSalesPaymentEditModalShow = (idSalesPayments: any) => {
        setIdSalesPayments(idSalesPayments);
        setEditPaymentModalShow(true);
    }

    const handleEditPaymentModalClose = (isReload: boolean) => {
        Swal.close();
        setEditPaymentModalShow(false);
        if (isReload) {
            setNewData({ id: Date.now() });
        }
    }

    const columns = [
        { value: 'id', title: 'ID', style: { textAlign: 'center' } },
        { value: 'paymentType', title: 'Payment Type', style: { textAlign: 'center' } },
        { value: 'projectName', title: 'Project', style: { textAlign: 'left' } },
        { value: 'companyName', title: 'Client', style: { textAlign: 'left' } },
        { value: 'srName', title: 'SR', style: { textAlign: 'left' } },
        { value: 'salesType', title: 'Sales Type', style: { textAlign: 'left' } },
        { value: 'idSalesOrders', title: 'Sales Order', style: { textAlign: 'center' } },
        { value: 'idSalesInvoice', title: 'Sales Invoice', style: { textAlign: 'center' } },
        { value: 'paymentMethod', title: 'Payment Method', style: { textAlign: 'center' } },
        { value: 'paymentDate', title: 'Payment Date', style: { textAlign: 'left' } },
        {
            value: 'paymentAmount', title: 'Payment Amount', style: { textAlign: 'center' }, hasActionsComponent: true, actionsComponent: ((row: any) => {
                return (
                    <>{numberFormat(row.paymentAmount)}</>
                )
            })
        },
        { value: 'remarks', title: 'Remarks', style: { textAlign: 'left' } },
        { value: 'transactionId', title: 'Transaction ID', style: { textAlign: 'center' } },
        { value: 'paymentInfo', title: 'Payment Info', style: { textAlign: 'left' } },
        { value: 'createdBy', title: 'Created By', style: { textAlign: 'left' } },
        {
            value: 'action', title: 'Action', style: { textAlign: 'left', whiteSpace: 'nowrap' }, hasActionsComponent: true, actionsComponent: ((row: any) => {
                return (
                    <>
                        {paymentEditPermission === 1 && (
                            <button className="btn btn-light-primary btn-sm" onClick={() => handleSalesPaymentEditModalShow(row.id)}>
                                <KTSVG
                                    path='/media/svg/icons/Shopping/Cart3.svg'
                                    className='svg-icon-5 svg-icon'
                                />		Edit
                            </button>
                        )}

                        {paymentDeleteHasPermission === 1 && (
                            <button className="btn btn-light-danger btn-sm px-2 py-1" onClick={() => deletePayment(row.id)}>
                                <KTSVG
                                    path='/media/svg/icons/General/Trash.svg'
                                    className='svg-icon-5 svg-icon'
                                />		Delete
                            </button>
                        )}

                    </>
                )
            })
        }
    ]

    const filters = {
        id: {
            filterType: 'like',
            filterValue: '',
        },

        paymentType: {
            filterType: 'like',
            filterValue: '',
        },
        projectName: {
            filterType: 'like',
            filterValue: '',
        },
        companyName: {
            filterType: 'like',
            filterValue: '',
        },
        srName: {
            filterType: 'like',
            filterValue: '',
        },
        salesType: {
            filterType: 'like',
            filterValue: '',
        },
        idSalesOrders: {
            filterType: 'like',
            filterValue: '',
        },
        idSalesInvoice: {
            filterType: 'like',
            filterValue: '',
        },
        paymentDate: {
            filterType: 'like',
            filterValue: '',
        },
        paymentAmount: {
            filterType: 'like',
            filterValue: '',
        },
        paymentMethod: {
            filterType: 'like',
            filterValue: '',
        },
        remarks: {
            filterType: 'like',
            filterValue: '',
        },
        transactionId: {
            filterType: 'like',
            filterValue: '',
        },
        createdBy: {
            filterType: 'like',
            filterValue: '',
        },
        paymentInfo: {
            filterType: 'like',
            filterValue: '',
        },
    }

    const url = process.env.REACT_APP_API_URL + 'sales-payment/dt'

    const deletePayment = (id: number) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You will not be able to recover this payment!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it',
            showLoaderOnConfirm: true,
            customClass: {
                confirmButton: 'btn btn-danger me-2',
                cancelButton: 'btn btn-secondary'
            },
            preConfirm: () => {
                return fetch(process.env.REACT_APP_API_URL + 'sales-payment/' + id, deleteRequestOptions())
                    .then(response => {
                        if (!response.ok) {
                            throw new Error(response.statusText)
                        }
                        return response.json()
                    })
                    .catch(error => {
                        Swal.showValidationMessage(
                            `Request failed: ${error}`
                        )
                    })
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.value.success) {
                    Swal.fire(
                        'Deleted!',
                        'Payment has been deleted.',
                        'success'
                    )
                    window.location.reload();

                } else {
                    Swal.fire(
                        'Error!',
                        result.value.errors,
                        'error'
                    )
                }
            }
        })
    }

    const numberFormat = (value: number) => {
        return parseFloat(value.toString()).toLocaleString('en-US', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }

    return (
        <>
            {hasPermission === 1 &&
                <>
                    <div className='card card-custom'>
                        <div className='card-header'>
                            <div className="card-title">
                                <KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-1 svg-icon-primary' />
                                <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
                                    Sales Payments
                                </span>
                            </div>
                            <div className="card-toolbar">
                                <div className='d-flex justify-content-end'>
                                    <Select
                                        // defaultValue={selectProjectOptions}
                                        onChange={handleProjectChange}
                                        options={selectProjectOptions}
                                        placeholder={project.label}
                                    />
                                    &nbsp;
                                    <button onClick={() => handleSalesPaymentModalShow(null)} className="btn btn-sm btn-primary"><i className="fa fa-plus"></i>Create New Sales Payment</button>
                                </div>
                            </div>
                        </div>

                        <div className='card-body'>
                            <DataTable url={url} columns={columns} filters={filters} newData={newData} additionalPostData={additionalPostData} />
                        </div>
                    </div >
                    {idSalesPayments === 0 && <SalesPaymentCreate show={createCompanyModalShow} handleClose={handlePaymentModalClose} salesOrderInfo={salesOrderInfo} id={Date.now()} />}
                    {idSalesPayments !== 0 && <SalesPaymentEdit show={editModalShow} handleClose={handleEditPaymentModalClose} id={Date.now()} idSalesPayments={idSalesPayments} />}
                </>
            }

        </>
    )
}

export default SalesPayment
