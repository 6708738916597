import React, { useEffect, useState, useContext } from 'react'
import { KTSVG } from '../../_metronic/helpers'
import { AppContext } from '../context/AppContext';
import { Row, Col, Table } from 'react-bootstrap'
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper'
import Select from 'react-select';
import Swal from 'sweetalert2'
import AddEvent from '../components/VisitPlanAddEvent';
import LeadCreate from '../components/LeadCreate';

interface VisitPlanData {
    visits: [];
    leads: [];
}

const VisitPlan: React.FC = () => {

    const { currentUser } = useContext(AppContext);
    const [selectProjectOptions, setSelectProjectOptions] = useState([])
    const [selectedProject, setSelectedProject] = useState<{ value: string; label: string; } | null>(null);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [idLeadsForVisit, setIdLeadsForVisit] = useState(0);

    const [weeks, setWeeks] = useState([]);
    const [week, setWeek] = useState({ label: 'Select Week', value: '' });
    const [srs, setSrs] = useState([]);
    const [sr, setSr] = useState({ label: 'Select SR', value: '' });
    const [visitPlan, setVisitPlan] = useState<VisitPlanData>({
        visits: [],
        leads: []
    });

    const [showLeadModal, setShowLeadModal] = useState(false);
    const handleClose = (isShow: any) => {
        setShowLeadModal(false);
        getVisitPlan(selectedProject?.value, fromDate, toDate, sr?.value)
    }

    const getProjectList = async () => {
        const url = process.env.REACT_APP_API_URL + 'projects';

        const response = await fetch(url, getRequestOptions());

        const data = await response.json();

        data.data.map((element: any) => {
            element.value = element.id_projects;
            element.label = element.project_name;
            return element;
        })

        setSelectProjectOptions(data.data);
        setSelectedProject(data.data[0])
    }

    const getProjectSrs = (idProjects: string) => {
        fetch(
            process.env.REACT_APP_API_URL + 'getAllSr?idProjects=' + idProjects,
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {

                let srsObj = resp.map((item: any) => {
                    item.label = item.first_name + ' ' + item.last_name;
                    item.value = item.id;
                    return item
                })

                var dd = [{ id: '', first_name: '', last_name: '', label: 'All SR', value: '' }]
                let allUsers = srsObj.concat(dd);

                setSrs(allUsers);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const getWeekList = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'getVisitPlanWeekList',
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {

                let weeks = resp.data.map((item: any) => {
                    item.label = 'Week - ' + item.week_no + ' - ' + item.week_start_label + ' to ' + item.week_end_label + '';
                    item.value = item.week_start + '-' + item.week_end;
                    return item
                })
                setWeeks(weeks);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const getVisitPlan = async (idProject: string, fromDate: string, toDate: string, idSr: string = '') => {

        if (fromDate === '' || toDate === '') {
            return;
        }
        const url = process.env.REACT_APP_API_URL + 'visit-plan?idProjects=' + idProject + '&fromDate=' + fromDate + '&toDate=' + toDate + '&idSr=' + idSr;
        const response = await fetch(url, getRequestOptions());
        const data = await response.json();

        setVisitPlan(data);
    }

    const handleSrChange = (sr: any) => {
        setSr(sr);
        getVisitPlan(selectedProject?.value, fromDate, toDate, sr?.value)
    }

    const handleProjectChange = (project: any) => {
        setSelectedProject(project)
        getVisitPlan(project?.value, fromDate, toDate)
    }

    useEffect(() => {
        getProjectList();
        getWeekList();
    }, [])

    useEffect(() => {
        if (selectedProject) {
            // getProjectStages(selectedProject.value);
            getProjectSrs(selectedProject.value);
            setSr({ label: 'Select SR', value: '' });

        }

    }, [selectedProject])

    useEffect(() => {
        if (selectProjectOptions.length === 1) {
            setSelectedProject({ label: selectProjectOptions[0]['label'], value: selectProjectOptions[0]['value'] })
        }
    }, [selectProjectOptions])

    // Function to handle week change
    const handleWeekChange = (week: any) => {
        setWeek(week)
        setFromDate(week.week_start)
        setToDate(week.week_end)
        getVisitPlan(selectedProject?.value, week.week_start, week.week_end, sr?.value)
    };

    const handleActivityStatus = async (idLeadActivities: number, status: string) => {

        if (status === 'done') {
            Swal.fire({
                title: 'Outcome / Discussion',
                input: 'textarea',
                inputAttributes: {
                    autocapitalize: 'off',
                    placeholder: 'Meeting minutes, discussion points, email body etc.'
                },
                showCancelButton: true,
                confirmButtonText: 'Submit',
                showLoaderOnConfirm: true,
                preConfirm: (content) => {
                    let postData = { idLeadActivities: idLeadActivities, status: status, content: content };
                    updateActivityStatus(postData);
                },
                allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
                if (result.isConfirmed) {
                    console.log("result.value = ", result.value);
                }
            })
        } else {
            const confirm = await Swal.fire({
                title: 'Are you sure?',
                text: "You want to cancel this visit?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#ec008c',
                cancelButtonText: 'No',
                confirmButtonText: 'Yes, Cancel it!',
            })
            if (confirm.value) {
                updateActivityStatus({ idLeadActivities: idLeadActivities, status: status });
            }
        }

    }

    const updateActivityStatus = async (postData: any) => {
        const requestOptions = postRequestOptions(postData);
        try {
            let response = await fetch(
                `${process.env.REACT_APP_API_URL}sr/dashboard/update-activity-status`,
                requestOptions
            )
            let respData = await response.json()
            Swal.close();

            if (respData.success === true) {
                getVisitPlan(selectedProject?.value, fromDate, toDate)
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    html: respData.errorMessage,
                })
            }

        } catch (error) {
            Swal.close();
        }
    }

    const [createEventModalShow, setCreateEventModalShow] = useState(false);
    const handleCreateEventModalShow = (idLeads: number) => {
        setIdLeadsForVisit(idLeads)
        setCreateEventModalShow(true);
    }

    const handleCreateEventModalClose = () => {
        setCreateEventModalShow(false);
        getVisitPlan(selectedProject?.value, fromDate, toDate, sr?.value)
    }

    const [searchTerm, setSearchTerm] = useState<string>("");

    const filterRows = (rows: any[]) => {
        return rows.filter(row =>
            // console.log(row)
            row.lead_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            row.company.company_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            row.assigned_to.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            row.assigned_to.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            row.stage_status?.project_stage.stage_name.toLowerCase().includes(searchTerm.toLowerCase())
        );
    };

    const [expandedSections, setExpandedSections] = useState<{ [key: string]: boolean }>({
        high: true,
        medium: true,
        low: true,
    });

    // Toggle function to minimize or expand sections
    const toggleSection = (category: string) => {
        setExpandedSections(prevState => ({
            ...prevState,
            [category]: !prevState[category]
        }));
    };

    return (
        <div className='card card-custom'>
            <div className='card-header'>
                <div className='card-title'>
                    <KTSVG
                        path='/media/svg/icons/Text/Bullet-list.svg'
                        className='svg-icon-1 svg-icon-primary'
                    />
                    <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
                        Weekly Visit Plan
                    </span>
                </div>
                <div className='card-toolbar'>
                    <div className='d-flex justify-content-end align-items-center'>
                        <Select
                            // defaultValue={selectProjectOptions}
                            onChange={handleProjectChange}
                            options={selectProjectOptions}
                            placeholder={selectedProject?.label}
                        />
                    </div>
                </div>
            </div>

            <div className='card-body' style={{ minHeight: "80vh" }}>

                {/* {JSON.stringify(week, null, 2)} */}
                <Row>
                    <Col className='col-4'>
                        {/* <input type='week' name='week' className='form form-control' onChange={handleWeekChange}></input> */}
                        <Select
                            value={week}
                            onChange={handleWeekChange}
                            options={weeks}
                            menuPosition="fixed"
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        />
                    </Col>
                    {(currentUser && currentUser.id_roles !== 1) &&
                        <Col className='col-2'>

                            <Select
                                value={sr}
                                onChange={handleSrChange}
                                options={srs}
                                menuPosition="fixed"
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            />
                        </Col>
                    }
                </Row>

                {week.value !== '' &&
                    <>
                        <Row className='mt-5'>
                            <Col>Total Visit: {visitPlan.visits.length}</Col>
                        </Row>
                        <Row className='mt-5'>
                            <Col className='col-6'>
                                <Table size='sm' bordered hover>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '25%' }} className='text-center'>SR</th>
                                            <th style={{ width: '25%' }}>Lead</th>
                                            {/* <th style={{ width: '15%' }}>Client</th> */}
                                            <th style={{ width: '25%' }}>Visit Time</th>
                                            {/* <th style={{ width: '15%' }}>Location</th> */}
                                            <th style={{ width: '25%' }} className='text-center'>Action</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {visitPlan.visits.length > 0 ? (
                                            visitPlan.visits.map((val: any, key: number) => (
                                                <>
                                                    <tr key={val.id_leads}>
                                                        <td>{val.srName}</td>
                                                        <td>{val.leadName}</td>
                                                        {/* <td>{val.companyName}</td> */}
                                                        <td>{val.activityAt}</td>
                                                        {/* <td>{val.location}</td> */}
                                                        <td rowSpan={2} className='text-center'>
                                                            {val.activityStatus === 'scheduled' &&

                                                                <>
                                                                    <button type="button" className='btn btn-success  btn-sm fw-800 me-2' onClick={() => handleActivityStatus(val.id_lead_activities, 'done')}>
                                                                        Done
                                                                        <KTSVG path='/media/svg/icons/Navigation/Double-check.svg' className='svg-icon-3 ms-2 me-0' />
                                                                    </button>
                                                                    <button type="button" className='btn btn-danger btn-sm fw-800' onClick={() => handleActivityStatus(val.id_lead_activities, 'cancelled')}>
                                                                        Cancel
                                                                        <KTSVG path='/media/svg/icons/Code/Error-circle.svg' className='svg-icon-3 ms-2 me-0' />
                                                                    </button>
                                                                </>
                                                            }
                                                            {val.activityStatus === 'cancelled' &&
                                                                <><span style={{ color: 'red' }}>Cancelled</span></>
                                                            }
                                                            {val.activityStatus === 'done' &&
                                                                <><span style={{ color: 'green' }}>Done - {val.content}</span></>
                                                            }
                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td colSpan={3} className='text-start'>{val.activityDescription}</td>
                                                    </tr>
                                                </>

                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={6} className='text-center'>No Vist Found</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>

                            </Col>
                            <Col className='col-6'>

                                <Row>
                                    <Col>
                                    </Col>
                                    <Col>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {/* <button type="button" onClick={() => { setShowLeadModal(true) }} className="btn btn-success btn-sm float-start">
                                            <KTSVG
                                                path='/media/svg/icons/Code/Plus.svg'
                                                className='svg-icon-sm svg-icon-danger'
                                            />
                                            Add Lead
                                        </button> */}
                                    </Col>
                                    <Col>
                                        <input
                                            type="text"
                                            placeholder="Search Leads"
                                            value={searchTerm}
                                            className='form-control input-sm'
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                            style={{ marginBottom: '20px', padding: '10px', width: '100%' }}
                                        />
                                    </Col>
                                </Row>

                                <Table size='sm' bordered hover>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '25%' }} className='text-center'>Lead</th>
                                            <th style={{ width: '25%' }}>Client</th>
                                            <th style={{ width: '25%' }}>SR</th>
                                            <th style={{ width: '25%' }}>Status</th>
                                        </tr>
                                    </thead>

                                    <tbody>

                                        {visitPlan.leads.map((section: any) => (
                                            <>
                                                <React.Fragment key={section.category}>
                                                    <tr key={section.category}>
                                                        <th colSpan={4} className="ps-3 text-center capitalize" style={{ backgroundColor: section.category === "high" ? "lightpink" : section.category === "medium" ? "darkseagreen" : "bisque", textTransform: 'capitalize' }}>
                                                            <b>{section.category} ({section.data.length})</b>

                                                            <i
                                                                onClick={() => toggleSection(section.category)}
                                                                style={{ float: "right", margin: '4px', color: '#000' }}
                                                                className={`fa fa-${expandedSections[section.category] ? "minus" : "plus"}-square`}
                                                                title={`Click To ${expandedSections[section.category] ? "Minimize" : "Expand"}`}
                                                            >
                                                            </i>

                                                        </th>
                                                    </tr>
                                                    {expandedSections[section.category] &&
                                                        filterRows(section.data).map((row, index) => (
                                                            <tr key={index} title="Click To Add Event" style={{ cursor: "pointer" }} onClick={() => handleCreateEventModalShow(row.id_leads)}>
                                                                <td>{row.lead_name}</td>
                                                                <td>{row.company.company_name}</td>
                                                                <td>{row.assigned_to.first_name + ' ' + row.assigned_to.last_name}</td>
                                                                <td>

                                                                    {row.stage_status?.project_stage.stage_name === 'Won' &&
                                                                        <button className="btn btn-sm btn-success font-weight-bold"><i className='fa fa-star'></i> Won </button>
                                                                    }
                                                                    {row.stage_status?.project_stage.stage_name === 'Lost' &&
                                                                        <button className="btn btn-sm btn-danger font-weight-bold"><i className='fa fa-thumbs-down'></i> Lost </button>
                                                                    }
                                                                    {(row.stage_status?.project_stage.stage_name !== 'Won' && row.stage_status?.project_stage.stage_name !== 'Lost') &&
                                                                        <span>{row.stage_status?.project_stage.stage_name}</span>
                                                                    }

                                                                </td>
                                                            </tr>
                                                        ))}
                                                </React.Fragment>
                                            </>
                                        ))}
                                    </tbody>
                                </Table>

                            </Col>
                        </Row>
                    </>
                }


                <AddEvent show={createEventModalShow} handleClose={handleCreateEventModalClose} id={Date.now()} idLeadsForVisit={idLeadsForVisit} fromDate={fromDate} toDate={toDate} />
                <LeadCreate show={showLeadModal} handleClose={handleClose} id={Date.now()}></LeadCreate>


            </div>
        </div>
    )
}

export default VisitPlan
