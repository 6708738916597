/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState } from 'react';
import BackendTable from 'react-backend-table';
import JournalModal from '../components/JournalModal';
import VoucherEdit from '../components/VoucherEdit';
import { ToastContainer } from 'react-toastify';
import { KTSVG } from '../../_metronic/helpers'
import { Link } from 'react-router-dom';
import GetPagePermission from "../components/GetPagePermission";

export function Transactions() {

    const hasPermissionVoucherCreate = GetPagePermission("/voucher-create");
    const hasPermissionVoucherEdit = GetPagePermission("/voucher-edit");

    let columns = [
        { title: 'ID', field: 'idAccVouchers', sortable: true, searchable: true, thStyle: { textAlign: "center" }, tdStyle: { textAlign: "center" } },
        {
            title: 'Project',
            field: 'projectName',
            sortable: true,
            searchable: true
        },
        {
            title: 'Voucher No',
            field: 'voucherNo',
            sortable: true,
            searchable: true
        },
        {
            title: 'Voucher Type',
            field: 'voucherType',
            sortable: true,
            searchable: true
        },
        {
            title: 'Ref Type',
            field: 'refType',
            sortable: true,
            searchable: true
        },
        { title: 'Voucher Date', field: 'voucherDate', sortable: true, searchable: true },
        {
            title: 'Debit Ledger',
            field: 'drLedger',
            sortable: true,
            searchable: true,
            hasComponent: true,
            componentValue: ((rowValue: any) => {
                return (
                    <>
                        {rowValue.drLedger && rowValue.drLedger.split('\n').map((line: any, index: any) => (
                            <Fragment key={index}>
                                {line}
                                <br />
                            </Fragment>
                        ))}
                    </>
                )
            })
        },

        {
            title: 'Credit Ledger',
            field: 'crLedger',
            tdClass: 'text-right',
            sortable: true,
            searchable: true,
            hasComponent: true,
            componentValue: ((rowValue: any) => {
                return (
                    <>
                        {rowValue.crLedger && rowValue.crLedger.split('\n').map((line: any, index: any) => (
                            <Fragment key={index}>
                                {line}
                                <br />
                            </Fragment>
                        ))}
                    </>
                )
            })
        },
        {
            title: 'Narration',
            field: 'narration',
            sortable: true,
            searchable: true,
        },
        {
            title: 'Action',
            field: 'idAccVouchers',
            sortable: false,
            searchable: false,
            hasComponent: true,
            componentValue: ((rowValue: any) => {

                return (
                    <>
                        <Link className="btn btn-light-primary btn-sm" to={`transaction-details/${rowValue.idAccVouchers}`}>
                            <KTSVG
                                path='/media/svg/icons/Layout/Layout-grid.svg'
                                className='svg-icon-5 svg-icon'
                            />		Details
                        </Link>

                        <a
                            className="btn btn-sm btn-light-primary ms-2"
                            href={`${process.env.REACT_APP_BACKEND}voucher_print?id=${rowValue?.idAccVouchers}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <i className='fas fa-print'></i>Print
                        </a>
                        {hasPermissionVoucherEdit === 1 && (
                            <button
                                className="btn btn-sm btn-light-primary ms-2"
                                onClick={() => handleEditVoucherModalShow(rowValue.idAccVouchers)}
                            >
                                <i className='fas fa-edit'></i>Edit
                            </button>
                        )}
                    </>
                );
            }),
            thStyle: { textAlign: "center", width: "10%" }, tdStyle: { textAlign: "center" }
        },
    ]

    const jwt = () => {
        const myToken = localStorage.getItem('MyToken');
        return (myToken != null) ? JSON.parse(myToken) : null;
    }

    let options = {
        title: '',
        url: process.env.REACT_APP_API_URL + 'transactions',
        authorization: 'Bearer ' + jwt(),
        perPage: [10, 20, 50, 100, 500],
        orderBy: 'idAccVouchers',
        orderType: 'desc',
        columnSearch: true,
        reloadMyTable: () => { },
    }

    const [createJournalModalShow, setCreateJournalModalShow] = useState(false);
    const handleCreateJournalModalShow = () => {
        setCreateJournalModalShow(true);
    }

    const handleCreateJournalModalClose = () => {
        setCreateJournalModalShow(false);
        options.reloadMyTable();
    }


    const [editVoucherModalShow, setEditVoucherModalShow] = useState(false);
    const [voucherId, setVoucherId] = useState(0);
    const handleEditVoucherModalShow = (id: number) => {
        setVoucherId(id);
        setEditVoucherModalShow(true);
    }

    const handleEditVoucherModalClose = () => {
        setEditVoucherModalShow(false);
        options.reloadMyTable();
    }

    return (
        <>
            <div className='card card-custom'>
                <div className='card-header'>
                    <div className='card-title'>
                        <h3 className='card-label'>Vouchers</h3>
                        <small className='text-muted'>| A List of All Vouchers</small>
                    </div>
                    <div className="card-toolbar">
                        <div className='d-flex justify-content-end'>
                            {hasPermissionVoucherCreate === 1 && (
                                <button type="button" className="btn btn-sm btn-primary" onClick={() => handleCreateJournalModalShow()}><i className="fa fa-plus"></i>Add Voucher</button>
                            )}
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <div className="alert alert-warning" role="alert">To see the automatic voucher setup <a href="https://docs.google.com/spreadsheets/d/19fuQ3l1e1SOT20b9-HiYEYn8z1JXuEm5jagQJguYSUw/edit?pli=1#gid=0" className="alert-link text-primary" target='blank'> click here </a>.</div>
                        </div>
                        <div className="data-table col-md-12">
                            <BackendTable columns={columns} options={options} />
                        </div>
                    </div>
                </div>
            </div>

            <JournalModal show={createJournalModalShow} handleClose={handleCreateJournalModalClose} id={Date.now()} />
            <VoucherEdit show={editVoucherModalShow} handleClose={handleEditVoucherModalClose} id={voucherId} />
            <ToastContainer />
        </>

    )
}

export default Transactions
