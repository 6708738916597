/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, ChangeEvent } from 'react'
import { KTSVG } from '../../_metronic/helpers';
import { Row, Col } from 'react-bootstrap-v5';
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper';
import BackendTable from 'react-backend-table';
import Select from 'react-select';
import DataTable from './DataTable'
import { Link } from 'react-router-dom';
import { numberFormat } from '../helpers/GlobalHelper';
import Swal from 'sweetalert2';
import showLoader from "../helpers/ShowLoader"
import IssueEdit from './IssueEdit';

const DashboardAccountant = () => {

	const [dateRange, setDateRange] = useState('year');
	const [customDate, setCustomDate] = useState({ customFromDate: '', customToDate: '' });
	const [fromDate, setFromDate] = useState('');
	const [toDate, setToDate] = useState('');
	const [newData, setNewData] = useState({});

	useEffect(() => {

		if (dateRange === 'custom') {
			setFromDate(customDate.customFromDate);
			setToDate(customDate.customToDate);
		} else if (dateRange === 'year') {
			let date = new Date();
			let year = date.getFullYear();
			setFromDate(year + '-01-01');
			setToDate(year + '-12-31');

		} else if (dateRange === 'month') {
			let date = new Date();
			let year = date.getFullYear();
			let month = date.getMonth() + 1;
			let lastDayOfMonth = new Date(year, month, 0).getDate();
			let monthStr = month < 10 ? '0' + month : month;

			setFromDate(year + '-' + monthStr + '-01');
			setToDate(year + '-' + monthStr + '-' + lastDayOfMonth);
		}

	}, [dateRange, customDate.customFromDate, customDate.customToDate])

	const [dashboardData, setDashboardData] = useState({
		overdueAmount: 0,
		totalInvoice: 0,
		totalShipment: 0,
		totalSales: 0,
		totalPayment: 0,
		totalDue: 0,
	});

	useEffect(() => {
		getProjectList()
	}, [])

	const [selectProjectOptions, setSelectProjectOptions] = useState([])
	const [project, setProject] = useState({ label: 'Select Project', value: '' });

	const handleProjectChange = (project: any) => {
		setProject(project);
	}

	useEffect(() => {
		if (dateRange !== '') {
			options.reloadMyTable();
			getUserDashboard()
		}
	}, [project, dateRange])

	const getProjectList = async () => {
		const url = process.env.REACT_APP_API_URL + 'projects';

		const response = await fetch(url, getRequestOptions());

		const data = await response.json();

		data.data.map((element: any) => {
			element.value = element.id_projects;
			element.label = element.project_name;
			return element;
		})

		if (data.data.length === 1) {
			setSelectProjectOptions(data.data)

		} else {
			var allProjectsOpt = [{ id: '', name: '', label: 'All Project', value: '' }]
			let allProjects = data.data.concat(allProjectsOpt);
			setSelectProjectOptions(allProjects);
		}

		// var allProjectsOpt = [{ id: '', name: '', label: 'All Project', value: '' }]
		// let allProjects = data.data.concat(allProjectsOpt);
		// setSelectProjectOptions(allProjects);
	}

	useEffect(() => {
		if (selectProjectOptions.length === 1) {
			setProject({ label: selectProjectOptions[0]['label'], value: selectProjectOptions[0]['value'] })
		}
	}, [selectProjectOptions])


	const getUserDashboard = () => {
		fetch(
			// process.env.REACT_APP_API_URL + 'user/dashboard?idProjects=' + project.value,
			process.env.REACT_APP_API_URL + 'app/dashboard?idProjects=' + project.value + '&type=' + dateRange + '&customFromDate=' + customDate.customFromDate + '&customToDate=' + customDate.customToDate,
			getRequestOptions()
		)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				// console.log(resp, 'resp.data')
				setDashboardData({
					...dashboardData,
					overdueAmount: resp.overdueAmount,
					totalInvoice: resp.invoice,
					totalShipment: resp.shipment,
					totalSales: resp.sales.amount,
					totalPayment: resp.collection,
					totalDue: resp.due,
				});
			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})
	}

	//Pending Good Received Approval 
	const goodsReceivedColumns = [
		{ value: 'id', title: 'ID', style: { textAlign: 'center' } },
        { value: 'projectName', title: 'Project', style: { textAlign: 'left' } },
        { value: 'purchaseType', title: 'Receive Type', style: { textAlign: 'left' } },
        { value: 'referenceNo', title: 'Reference No', style: { textAlign: 'left' } },
        { value: 'vendorName', title: 'Vendor', style: { textAlign: 'left' } },
        { value: 'purchaseDate', title: 'Receive Date', style: { textAlign: 'left' } },
        { 
            value: 'purchaseAmount', title: 'Amount', style: { textAlign: 'right' },
            hasHtmlComponent: true,
            htmlComponent: (row: any) => {
                return (
                    <>
                        {numberFormat(row.purchaseAmount)}
                    </>
                )
             },
        },
		{
            value: 'action', title: 'Action', style: { textAlign: 'center', whiteSpace: 'nowrap' }, hasActionsComponent: true, actionsComponent: ((row: any) => {
                return (
                    <>
                        <button className="btn btn-light-success btn-sm px-2 py-1" onClick={() => goodsReceivedStatusChange(row, 'approved')}>
							<KTSVG
								path='/media/svg/icons/Navigation/Double-check.svg'
								className='svg-icon-5 svg-icon'
							/>		Approve
						</button>
                        
                    </>
                )
            })
        }
	]

	const goodsReceivedFilters = {
		id: {
            filterType: 'like',
            filterValue: '',
        },
        projectName: {
            filterType: 'like',
            filterValue: '',
        },
        purchaseType: {
            filterType: 'like',
            filterValue: '',
        },
        referenceNo: {
            filterType: 'like',
            filterValue: '',
        },
        vendorName: {
            filterType: 'like',
            filterValue: '',
        },
        purchaseDate: {
            filterType: 'like',
            filterValue: '',
        },
        purchaseAmount: {
            filterType: 'like',
            filterValue: '',
        }
	}

	const goodsReceivedAdditionalPostData = {
		idProjects: project.value,
		dateRange: dateRange,
		customFromDate: customDate.customFromDate,
		customToDate: customDate.customToDate,
	};

	const goodsReceivedUrl = process.env.REACT_APP_API_URL + 'dashboard/goods-received/dt'

	const goodsReceivedStatusChange = (rowValue: any, status: any) => {

        Swal.fire({
            title: 'Are you sure you want to approve this goods received? ',
            text: "This action cannot be undone!",
			howCancelButton: true,
			confirmButtonText: 'Yes, approve it!',
            cancelButtonText: 'No, keep it',
            showLoaderOnConfirm: true,
			preConfirm: () => {
                let postData = { idPurchase: rowValue.idPurchase, status: status };
                console.log(postData);
                goodsReceivedStatusUpdate(postData);

            }
        }).then((result) => {
            if (result.isConfirmed) {
                // Swal.fire(
				// 	'Approved!',
				// 	'Goods received has been approved.',
				// 	'success'
				// )
				// setNewData({ id: Date.now() });
            }
        })
    }

    const goodsReceivedStatusUpdate = async (postData: any) => {
        showLoader();
        const requestOptions = postRequestOptions(postData);
        try {
            let response = await fetch(
                `${process.env.REACT_APP_API_URL}purchase/status-change`,
                requestOptions
            )
            let respData = await response.json()
            Swal.close();

            if (respData.success === true) {
                Swal.fire(
					'Approved!',
					'Goods received has been approved.',
					'success'
				)
				setNewData({ id: Date.now() });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    html: respData.errorMessage,
                })
            }

        } catch (error) {
            Swal.close();
        }
    }


	//Pending Stock Issue Approval 
	const stockIssueColumns = [
		{ value: 'id', title: 'ID', style: { textAlign: 'center' } },
        { value: 'projectName', title: 'Project', style: { textAlign: 'left' } },
        { value: 'referenceNo', title: 'Issue No', style: { textAlign: 'left' } },
        { value: 'issueDate', title: 'Issue Date', style: { textAlign: 'left' } },
        { 
            value: 'totalAmount', title: 'Amount', style: { textAlign: 'center' },
            hasHtmlComponent: true,
            htmlComponent: (row: any) => {
                return (
                    <>
                        {numberFormat(row.totalAmount)}
                    </>
                )
             },
        },
		{
            value: 'action', title: 'Action', style: { textAlign: 'center', whiteSpace: 'nowrap' }, hasActionsComponent: true, actionsComponent: ((row: any) => {
                return (
                    <>
                        <button className="btn btn-light-success btn-sm px-2 py-1" onClick={() => handleIssueDetailsModalShow(row)}>
							<KTSVG
								path='/media/svg/icons/Text/Align-justify.svg'
								className='svg-icon-5 svg-icon'
							/>		Details
						</button>
                        
                    </>
                )
            })
        }
	]

	const stockIssueFilters = {
		id: {
            filterType: 'like',
            filterValue: '',
        },
        projectName: {
            filterType: 'like',
            filterValue: '',
        },
        referenceNo: {
            filterType: 'like',
            filterValue: '',
        },
        issueDate: {
            filterType: 'like',
            filterValue: '',
        },
        totalAmount: {
            filterType: 'like',
            filterValue: '',
        }
	}

	const stockIssueAdditionalPostData = {
		idProjects: project.value,
		dateRange: dateRange,
		customFromDate: customDate.customFromDate,
		customToDate: customDate.customToDate,
	};

	const stockIssueUrl = process.env.REACT_APP_API_URL + 'dashboard/inventory-stock-issue/dt'

	const [issueDetailsModalShow, setIssueDetailsModalShow] = useState(false);
	const [idInvIssues, setIdInvIssues] = useState('');

	const handleIssueDetailsModalShow = (issue: any) => {
        setIdInvIssues(issue.id);
        setIssueDetailsModalShow(true);
    }

	const handleIssueModalClose = (isReload: boolean) => {
        setIssueDetailsModalShow(false);
    }


	const paymentColumns = [
		{ title: 'ID', field: 'id', sortable: true, searchable: true, thStyle: { textAlign: "center", width: "5%" }, tdStyle: { textAlign: "center" } },
		{ title: 'Payment Type', field: 'paymentType', sortable: true, searchable: true },
		{
			title: 'Project',
			field: 'projectName',
			sortable: true,
			searchable: true,
			hasComponent: true,
			componentValue: ((rowValue: any) => {
				return (
					<>
						{rowValue.projectName ?? ''}
					</>
				)
			})
		},
		{
			title: 'Lead',
			field: 'leadName',
			sortable: true,
			searchable: true,
			hasComponent: true,
			componentValue: ((rowValue: any) => {
				return (
					<>
						{rowValue.idLeads ? <a href={"/#/lead-details?idLeads=" + rowValue.idLeads}>{rowValue.leadName}</a> : ''}
					</>
				)
			})
		},
		{ title: 'Sales Order', field: 'idSalesOrders', sortable: true, searchable: true },
		{ title: 'Sales Invoice', field: 'idSalesInvoice', sortable: true, searchable: true },
		{ title: 'Payment Method', field: 'paymentMethod', sortable: true, searchable: true },
		{ title: 'Payment Date', field: 'paymentDate', sortable: true, searchable: true },
		{ title: 'Payment Amount', field: 'paymentAmount', sortable: true, searchable: true },
		{ title: 'Remarks', field: 'remarks', sortable: true, searchable: true },
		{ title: 'Transaction ID', field: 'transactionId', sortable: true, searchable: true },
		{ title: 'Payment Info', field: 'paymentInfo', sortable: true, searchable: true },
	]

	const jwt = () => {
		const myToken = localStorage.getItem('MyToken');
		return (myToken != null) ? JSON.parse(myToken) : null;
	}

	let options = {
		title: '',
		url: process.env.REACT_APP_API_URL + 'dashboard/sales-payment/dt',
		authorization: 'Bearer ' + jwt(),
		perPage: [10, 20, 50, 100, 500],
		orderBy: 'id',
		orderType: 'desc',
		columnSearch: true,
		reloadMyTable: () => { },
		extraData: {
			"idProjects": project.value,
			"dateRange": dateRange,
			"customFromDate": customDate.customFromDate,
			"customToDate": customDate.customToDate,
		}
	}

	const invoiceColumns = [
		{ value: 'id', title: 'ID', style: { textAlign: 'left' } },
		{ value: 'invoiceId', title: 'Invoice ID', style: { textAlign: 'left' } },
		{ value: 'idSalesOrders', title: 'Sales Order ID', style: { textAlign: 'left' } },
		{ value: 'projectName', title: 'Project', style: { textAlign: 'left' } },
		{ value: 'invoiceDate', title: 'Invoice Date', style: { textAlign: 'left' } },
		{ value: 'invoiceAmount', title: 'Invoice Amount', style: { textAlign: 'left' } },
		{ value: 'shipments', title: 'Shipments', style: { textAlign: 'left' } },
	]

	const invoiceFilters = {
		id: {
			filterType: 'like',
			filterValue: '',
		},
		invoiceId: {
			filterType: 'like',
			filterValue: '',
		},
		idSalesOrders: {
			filterType: 'like',
			filterValue: '',
		},
		projectName: {
			filterType: 'like',
			filterValue: '',
		},
		invoiceDate: {
			filterType: 'like',
			filterValue: '',
		},
		invoiceAmount: {
			filterType: 'like',
			filterValue: '',
		},
		shipments: {
			filterType: 'like',
			filterValue: '',
		},
	}

	const invoiceAdditionalPostData = {
		idProjects: project.value,
		dateRange: dateRange,
		customFromDate: customDate.customFromDate,
		customToDate: customDate.customToDate,
	};

	const invoiceUrl = process.env.REACT_APP_API_URL + 'dashboard/sales-overdue-invoice/dt'

	
	const handleDateRangeChange = (event: any) => {
		if (event === 'custom') {
			setDateRange('custom');
		} else {
			setDateRange(event.target.value);
		}
	}

	const handleCustomDateChange = (e: ChangeEvent<HTMLInputElement>) => {
		setCustomDate({ ...customDate, [e.target.name]: e.target.value });
	}

	const showCustomDateData = () => {
		setDateRange('custom')
		options.reloadMyTable();
		getUserDashboard()
	}

	const convertToBDAmount = (label: any) => {
		let labelVal = Number(label);

		if (label < 0) {
			labelVal = label * -1;
		}

		if (labelVal > 10000000) {
			labelVal = (labelVal / 10000000).toFixed(1) + ' Cr';
		}
		if (labelVal > 100000) {
			labelVal = (labelVal / 100000).toFixed(1) + ' Lac';
		}
		if (labelVal > 1000) {
			labelVal = (labelVal / 1000).toFixed(1) + ' K';
		}
		if (label < 0) {
			return '-' + labelVal;
		}
		return labelVal;
	}

	return (
		<>
			<div style={{ background: "none" }} className='card card-custom'>
				<div className='card-header card-header-stretch overflow-auto'>
					<div className='card-title'>
						<h3 className='card-label'>Accountant Dashboard</h3>
					</div>
					<div className="card-toolbar">
						<div className='d-flex justify-content-end'>
							<div className='mt-5 mb-2'>
								<select className='form-control' onChange={(event) => handleDateRangeChange(event)}>
									<option value={'year'}>This Year</option>
									<option value={'month'}>This Month</option>
									<option value={''}>Custom</option>
								</select>
							</div>
							{(dateRange === '' || dateRange === 'custom') &&
								<>
									&nbsp;&nbsp;&nbsp;
									<div className='mt-5 mb-2'>
										<input type='date' className='form-control  form-control-sm' name='customFromDate' onChange={handleCustomDateChange}></input>
									</div>
									&nbsp;&nbsp;&nbsp;
									<div className='mt-5 mb-2'>
										<input type='date' className='form-control  form-control-sm' name='customToDate' onChange={handleCustomDateChange}></input>
									</div>
									&nbsp;&nbsp;&nbsp;
									<div className='mt-5 mb-2'>
										<button className='btn-primary form-control  form-control-sm' name='showCustomData' onClick={showCustomDateData}>Show</button>
									</div>
								</>
							}
							&nbsp;&nbsp;&nbsp;

							<div className='mt-5 mb-2'>
								<Select
									defaultValue={selectProjectOptions}
									onChange={handleProjectChange}
									options={selectProjectOptions}
									placeholder={project.label}
									menuPosition="fixed"
									styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
								/>
							</div>
						</div>
					</div>
				</div>
				{/* {JSON.stringify(activityType)} */}
				<div className="card-body">
					<div className="row gy-5 g-xl-2 mt-4">
						<div className="col-sm-6 col-xl-2 mb-xl-10">
							<div className="card h-lg-100 bg-light-warning">
								<div className="card-body card-lead d-flex justify-content-between align-items-start flex-column">
									<Link to={`reports?type=companyWiseSalesReport&idProjects=${project.value}&fromDate=${fromDate}&toDate=${toDate}`}>
										<div className="d-flex flex-column my-4">
											<span className="fw-semibold fs-2x text-gray-800 lh-1 ls-n2">{convertToBDAmount(dashboardData.totalSales)}</span>
											<div className="my-1">
												<span className="fw-semibold fs-4 text-gray-400"> Total Sales </span>
											</div>
										</div>
									</Link>
								</div>
							</div>
						</div>
						<div className="col-sm-6 col-xl-2 mb-xl-10">
							<div style={{ background: "#f3d6ef82" }} className="card h-lg-100 bg-light-info">
								<div className="card-body card-activity d-flex justify-content-between align-items-start flex-column">
									<div className="d-flex flex-column my-4">
										<span className="fw-semibold fs-2x text-gray-800 lh-1 ls-n2">{convertToBDAmount(dashboardData.totalShipment)}</span>
										<div className="my-1">
											<span className="fw-semibold fs-4 text-gray-400"> Total Shipment </span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-sm-6 col-xl-2 mb-xl-10">
							<div style={{ background: "#f3d6ef82" }} className="card h-lg-100">
								<div className="card-body card-activity d-flex justify-content-between align-items-start flex-column">
									<div className="d-flex flex-column my-4">
										<span className="fw-semibold fs-2x text-gray-800 lh-1 ls-n2">{convertToBDAmount(dashboardData.totalInvoice)}</span>
										<div className="my-1">
											<span className="fw-semibold fs-4 text-gray-400"> Total Invoice </span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-sm-6 col-xl-2 mb-xl-10">
							<div className="card h-lg-100 bg-light-success">
								<div className="card-body card-activity d-flex justify-content-between align-items-start flex-column">
									<div className="d-flex flex-column my-4">
										<span className="fw-semibold fs-2x text-gray-800 lh-1 ls-n2">{convertToBDAmount(dashboardData.totalPayment)}</span>
										<div className="my-1">
											<span className="fw-semibold fs-4 text-gray-400"> Total Payment </span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-sm-6 col-xl-2 mb-xl-10">
							<div style={{ background: "#bfdde380" }} className="card h-lg-100">
								<div className="card-body card-activity d-flex justify-content-between align-items-start flex-column">
									<Link to={`reports?type=dueReport&idProjects=${project.value}&fromDate=${fromDate}&toDate=${toDate}`}>
										<div className="d-flex flex-column my-4">
											<span className="fw-semibold fs-2x text-gray-800 lh-1 ls-n2">{convertToBDAmount(dashboardData.totalDue)}</span>
											<div className="my-1">
												<span className="fw-semibold fs-4 text-gray-400"> Total Due </span>
											</div>
										</div>
									</Link>
								</div>
							</div>
						</div>
						<div className="col-sm-6 col-xl-2 mb-xl-10">
							<div style={{ background: "#bfdde380" }} className="card h-lg-100">
								<div className="card-body card-activity d-flex justify-content-between align-items-start flex-column">
									<div className="d-flex flex-column my-4">
										<span className="fw-semibold fs-2x text-gray-800 lh-1 ls-n2">{convertToBDAmount(dashboardData.overdueAmount)}</span>
										<div className="my-1">
											<span className="fw-semibold fs-4 text-gray-400"> Overdue Amount</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<Row className="gy-5 g-xl-2">
						<Col md={12}>
							<div className='card card-custom'>
								<div className='card-header'>
									<div className="card-title">
										<KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-1 svg-icon-primary' />
										<span className='text fw-bolder' style={{ marginLeft: '10px' }}>
											Pending Goods Received Approval										</span>
									</div>
									<div className="card-toolbar">
									</div>
								</div>

								<div className='card-body'>
									<DataTable url={goodsReceivedUrl} columns={goodsReceivedColumns} filters={goodsReceivedFilters} additionalPostData={goodsReceivedAdditionalPostData} newData={newData} />
								</div>
							</div>
						</Col>
						<Col md={12}>
							<div className='card card-custom'>
								<div className='card-header'>
									<div className="card-title">
										<KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-1 svg-icon-primary' />
										<span className='text fw-bolder' style={{ marginLeft: '10px' }}>
											Pending Stock Issue Approval										</span>
									</div>
									<div className="card-toolbar">
									</div>
								</div>

								<div className='card-body'>
									<DataTable url={stockIssueUrl} columns={stockIssueColumns} filters={stockIssueFilters} additionalPostData={stockIssueAdditionalPostData} newData={newData} />
									{issueDetailsModalShow && idInvIssues !=='' && <IssueEdit show={issueDetailsModalShow} handleClose={handleIssueModalClose} idInvIssues={idInvIssues} />}
								</div>
							</div>
						</Col>
						<Col md={12}>
							<div className='card card-custom'>
								<div className='card-header'>
									<div className="card-title">
										<KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-1 svg-icon-primary' />
										<span className='text fw-bolder' style={{ marginLeft: '10px' }}>
											List of Pending Payments
										</span>
									</div>
									<div className="card-toolbar">
									</div>
								</div>

								<div className='card-body'>
									<BackendTable columns={paymentColumns} options={options} />
								</div>
							</div>
						</Col>
						<Col md={12}>
							<div className='card card-custom'>
								<div className='card-header'>
									<div className="card-title">
										<KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-1 svg-icon-primary' />
										<span className='text fw-bolder' style={{ marginLeft: '10px' }}>
											List of Overdue Invoices
										</span>
									</div>
									<div className="card-toolbar">
									</div>
								</div>

								<div className='card-body'>
									<DataTable url={invoiceUrl} columns={invoiceColumns} filters={invoiceFilters} additionalPostData={invoiceAdditionalPostData} />
								</div>
							</div>
						</Col>
					</Row>

				</div>
			</div>

		</>

	)
}

export default DashboardAccountant
