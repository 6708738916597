/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react'
import { AppContext } from '../context/AppContext'

const Sales = () => {
	const { currentUser } = useContext(AppContext);
	
	useEffect(() => {
		
	}, [currentUser])

	
	return (
		<>
			<div className='card card-custom'>
				<div className='card-header card-header-stretch overflow-auto'>
					<div className='card-title'>
						<h3 className='card-label'>Sales</h3>
					</div>
				</div>
				<div className="card-body">
					<h5 className="card-title text-center mt-10 mb-10">Manage your sales</h5>
				</div>
			</div>
		</>

	)
}

export default Sales
