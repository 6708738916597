/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button, Form } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper';
import { AppContext } from '../context/AppContext';
import Swal from 'sweetalert2'
import validationErrorMsg from '../helpers/ValidationErrorMsg'

interface FormData {
    expenseAmount: string;
    expenseDate: string;
    expenseCategory: string;
    idUsers: string;
    idApprover: string;
    idProjects: string;
    idLeads: string;
    transactionType: string;
    idBankCash: string;
    idAccLedgers: string;
    remarks: string;
    status?: string;
}

interface ExpenseCreateProps {
    show: boolean;
    handleClose: (show: boolean) => void;
    id: number;
    actionChannel: string;
}

// approved
// requested
const ExpenseCreate: React.FC<ExpenseCreateProps> = ({ show, handleClose, id, actionChannel }) => {
    const { currentUser } = useContext(AppContext);
    // console.log(currentUser, "currentUser")
    const [isSubmitDisable] = useState(false);
    const [loading, setLoading] = useState(false);

    const [expenseCategories, setExpenseCategories] = useState([]);
    const [expenseCategory, setExpenseCategory] = useState(null);

    const [users, setUsers] = useState([]);
    const [user, setUser] = useState({ label: 'Select Sales Representative', value: '' });

    const [approvers, setApprovers] = useState([]);
    const [approver, setApprover] = useState({ label: 'Select Approver', value: '' });

    const [projects, setProjects] = useState([]);
    const [project, setProject] = useState({ label: 'Select Project', value: '' });

    const [leads, setLeads] = useState([]);
    const [lead, setLead] = useState({ label: 'Select Lead', value: '' });

    const [bankCashes, setBankCashes] = useState([]);
    const [bankCash, setBankCash] = useState({ label: 'Select Bank Cash', value: '' });

    const [ledgers, setLedgers] = useState([]);
    const [ledger, setLedger] = useState({ label: 'Select Expense Ledger', value: '' });

    const [formData, setFormData] = useState<FormData>({
        expenseAmount: '',
        expenseDate: '',
        expenseCategory: '',
        idUsers: currentUser?.id_roles === 1 ? currentUser?.id : '',
        idApprover: '',
        idProjects: '',
        idLeads: '',
        transactionType: 'bank_account',
        idBankCash: '',
        idAccLedgers: '',
        remarks: '',
        status: actionChannel,
    });

    useEffect(() => {

        getExpenseCategory();

        setFormData({
            expenseAmount: '',
            expenseDate: '',
            expenseCategory: '',
            idUsers: currentUser?.id_roles === 1 ? currentUser?.id : '',
            idApprover: '',
            idProjects: '',
            idLeads: '',
            transactionType: 'bank_account',
            idBankCash: '',
            idAccLedgers: '',
            remarks: '',
            status: actionChannel,
        })

    }, [id]);

    useEffect(() => {
        getUsers();
        getApprovers();
        getProjects();
        getBankCash('bank_account');
        getLedgers();
    }, [])

    const getExpenseCategory = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'expense/expense-category',
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let expenseCategoryObj = resp.data.map((item: any) => {
                    item.label = item.expenseCategory;
                    item.value = item.expenseCategory;
                    return item
                })
                setExpenseCategories(expenseCategoryObj);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const getUsers = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'expense/users',
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let userObj = resp.data.map((item: any) => {
                    item.label = item.name;
                    item.value = item.id;
                    return item
                })
                setUsers(userObj);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const getApprovers = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'expense/approvers',
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let approverObj = resp.data.map((item: any) => {
                    item.label = item.name;
                    item.value = item.id;
                    return item
                })
                setApprovers(approverObj);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const getProjects = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'lead/projects',
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let projectObj = resp.data.map((item: any) => {
                    item.label = item.name;
                    item.value = item.id;
                    return item
                })
                setProjects(projectObj);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const getLeads = (idProjects: number) => {
        fetch(
            process.env.REACT_APP_API_URL + 'expense/leads/' + idProjects,
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let leadObj = resp.data.map((item: any) => {
                    item.label = item.leadName;
                    item.value = item.idLeads;
                    return item
                })
                setLeads(leadObj);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const getBankCash = (transactionType: string) => {
        fetch(
            process.env.REACT_APP_API_URL + 'expense/bank-cash/' + transactionType,
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let bankCashObj = resp.data.map((item: any) => {
                    item.label = item.name;
                    item.value = item.idBankCash;
                    return item
                })
                setBankCashes(bankCashObj);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }


    const getLedgers = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'expense/ledgers',
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let ledgerObj = resp.data.map((item: any) => {
                    item.label = item.ledgerName;
                    item.value = item.idAccLedgers;
                    return item
                })
                setLedgers(ledgerObj);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const handleExpenseCategoryChange = (expenseCategory: any) => {
        // console.log(expenseCategory, "expenseCategory");
        setExpenseCategory(expenseCategory);
        if (expenseCategory?.label) {
            setFormData({ ...formData, expenseCategory: expenseCategory.label });
        } else {
            setFormData({ ...formData, expenseCategory: '' });
        }

    };

    const handleCreateOption = (inputValue: any) => {

        const newOption = { label: inputValue, value: inputValue.toLowerCase() };
        setExpenseCategories(expenseCategories => [...expenseCategories, newOption]);
        setExpenseCategory(newOption);
        setFormData({ ...formData, expenseCategory: inputValue });
    };

    const handleUserChange = (user: any) => {
        setUser(user);
        setFormData({ ...formData, idUsers: user.value });
    }


    const handleApproverChange = (approver: any) => {
        setApprover(approver);
        setFormData({ ...formData, idApprover: approver.value });
    }

    const handleProjectChange = (project: any) => {
        setProject(project);
        setLead({ label: 'Select Lead', value: '' });
        //Get Leads by project
        getLeads(project.value);
        setFormData({ ...formData, idProjects: project.value });
    }

    const handleLeadChange = (lead: any) => {
        setLead(lead);
        setFormData({ ...formData, idLeads: lead.value });
    }

    const transactionTypeChange = (event: any) => {
        const { name, value } = event.target;
        setBankCash({ label: 'Select Bank Cash', value: '' });
        getBankCash(value);
        setFormData({ ...formData, [name]: value });
    };

    const handleBankCashChange = (bankCash: any) => {
        setBankCash(bankCash);
        setFormData({ ...formData, idBankCash: bankCash.value });
    }

    const handleLedgerChange = (ledger: any) => {
        setLedger(ledger);
        setFormData({ ...formData, idAccLedgers: ledger.value });
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true);

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + 'expense/store', postRequestOptions(formData));

            const data = await response.json();

            if (data.success) {
                toast(<strong>Expense created successfully</strong>, { type: 'success', position: 'bottom-right', autoClose: 3000 });
                setFormData({
                    expenseAmount: '',
                    expenseDate: '',
                    expenseCategory: '',
                    idUsers: currentUser?.id_roles === 1 ? currentUser?.id : '',
                    idApprover: '',
                    idProjects: '',
                    idLeads: '',
                    transactionType: 'bank_account',
                    idBankCash: '',
                    idAccLedgers: '',
                    remarks: '',
                });
                setExpenseCategory(null);
                setUser({ label: 'Select Sales Representative', value: '' });
                setApprover({ label: 'Select Approver', value: '' });
                setProject({ label: 'Select Project', value: '' });
                setLead({ label: 'Select Lead', value: '' });
                setBankCash({ label: 'Select Bank Cash', value: '' });
                setLedger({ label: 'Select Expense Ledger', value: '' });

                handleClose(false);
            } else {

                Swal.close();
                if (data?.errors) {
                    const errorsMessage = validationErrorMsg(data.errors).join('<br>')
                    Swal.fire({
                        icon: 'error',
                        title: 'Invalid Data',
                        html: errorsMessage,
                    })
                }

                if (data?.errorMessage) {
                    toast(data?.errorMessage, { type: 'error', position: 'bottom-right', autoClose: 4000 });
                }


            }
        } catch (error) {
            console.error('Error submitting data:', error);
        } finally {
            setLoading(false);
        }
    };

    return (<Modal show={show} onHide={() => handleClose(false)} size="lg">
        <Modal.Header closeButton>
            <Modal.Title>Create Expense</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
            <Modal.Body>
                <Form.Group controlId="expenseDate">
                    <Form.Label>Expense Date <span className="required text-danger"></span></Form.Label>
                    <Form.Control
                        type="date"
                        name="expenseDate"
                        value={formData.expenseDate}
                        onChange={handleChange}
                        className='form-control-sm'
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="expenseAmount">
                    <Form.Label>Expense Amount <span className="required text-danger"></span></Form.Label>
                    <Form.Control
                        type="number"
                        name="expenseAmount"
                        value={formData.expenseAmount}
                        onChange={handleChange}
                        className='form-control-sm'
                        min={0}
                        step={'any'}
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="expenseCategory">
                    <Form.Label>Expense Category <span className="required text-danger"></span></Form.Label>
                    <CreatableSelect
                        isClearable
                        isSearchable
                        name="expenseCategory"
                        value={expenseCategory}
                        onCreateOption={handleCreateOption}
                        onChange={handleExpenseCategoryChange}
                        options={expenseCategories}
                        menuPosition="fixed"
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}

                    />
                </Form.Group>
                {currentUser?.id_roles === 1 ? (
                    <Form.Group className="mb-3" controlId="user">
                        <Form.Label>Sales Representative</Form.Label>
                        <Form.Control
                            type="text"
                            value={currentUser.fullName}
                            className='form-control-sm'
                            disabled={true}
                        />
                    </Form.Group>
                ) : (
                    <Form.Group className="mb-3" controlId="user">
                        <Form.Label>Select Sales Representative <span className="required text-danger"></span></Form.Label>
                        <Select
                            value={user}
                            onChange={handleUserChange}
                            options={users}
                            menuPosition="fixed"
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        />
                    </Form.Group>
                )}


                {currentUser?.id_roles === 1 && (
                    <Form.Group className="mb-3" controlId="idApprover">
                        <Form.Label>Select Approver<span className="required text-danger"></span></Form.Label>
                        <Select
                            value={approver}
                            onChange={handleApproverChange}
                            options={approvers}
                            menuPosition="fixed"
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        />
                    </Form.Group>
                )}

                <Form.Group className="mb-3" controlId="project">
                    <Form.Label>Project</Form.Label>
                    <Select
                        value={project}
                        onChange={handleProjectChange}
                        options={projects}
                        menuPosition="fixed"
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="lead">
                    <Form.Label>Select Lead</Form.Label>
                    <Select
                        value={lead}
                        onChange={handleLeadChange}
                        options={leads}
                        menuPosition="fixed"
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    />
                </Form.Group>
                {actionChannel === 'paid' && (
                    <>
                        <Form.Group className="mb-3" controlId="transactionType">
                            <Form.Label>Mode of Transaction</Form.Label>
                            <select className="form-select form-select-sm"
                                name='transactionType'
                                value={formData.transactionType}
                                onChange={transactionTypeChange}
                            >
                                <option value="bank_account">Bank</option>
                                <option value="cash_box">Cash</option>
                                <option value="mobile_wallet">Mobile Wallet</option>
                            </select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="lead">
                            <Form.Label>Select Bank/Cash <span className="required text-danger"></span></Form.Label>
                            <Select
                                value={bankCash}
                                onChange={handleBankCashChange}
                                options={bankCashes}
                                menuPosition="fixed"
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="lead">
                            <Form.Label>Select Expense Ledger<span className="required text-danger"></span></Form.Label>
                            <Select
                                value={ledger}
                                onChange={handleLedgerChange}
                                options={ledgers}
                                menuPosition="fixed"
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            />
                        </Form.Group>
                    </>
                )}

                <Form.Group className="mb-3" controlId="lead">
                    <Form.Label>Remarks</Form.Label>
                    <Form.Control as="textarea" rows={3} name="remarks" value={formData.remarks} onChange={handleChange} />
                </Form.Group>

            </Modal.Body>
            <Modal.Footer>

                <button type="submit" disabled={isSubmitDisable || loading} className="btn btn-sm btn-primary me-2">
                    {loading &&
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    }
                    <span>  Submit</span>
                </button>
                <Button variant='secondary' size="sm" onClick={() => handleClose(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Form>
    </Modal>)
}

export default ExpenseCreate