function postRequestOptions(body) {
	//var jwt = { token: getCookie('MyToken') };
	let jwt = JSON.parse(localStorage.getItem('MyToken'));
	let requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + jwt },
		body: JSON.stringify(body)
	};
	return requestOptions;
}

function getRequestOptions() {
	let jwt = JSON.parse(localStorage.getItem('MyToken'));

	let requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + jwt }
	};
	return requestOptions;
}

function deleteRequestOptions() {
	let jwt = JSON.parse(localStorage.getItem('MyToken'));

	let requestOptions = {
		method: 'DELETE',
		headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + jwt }
	};
	return requestOptions;
}

export { postRequestOptions, getRequestOptions, deleteRequestOptions };

