import React, { useState, useEffect } from 'react'
import DataTable from '../components/DataTable'
import { KTSVG } from '../../_metronic/helpers'
import { Link } from "react-router-dom"
import UserCreate from '../components/UserCreate'
import 'react-loading-skeleton/dist/skeleton.css';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Select from 'react-select'
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper'
import { Table } from 'react-bootstrap-v5';
import Swal from 'sweetalert2';


function Users() {

	// const hasPermission = 1;
	const [newData, setNewData] = useState('');
	const [id, setId] = useState('');
	const [idUsers, setIdUsers] = useState('');
	const [salesRepresentitives, setSalesRepresentatives] = useState([]);
	const [projects, setProjects] = useState([]);

	useEffect(() => {
		getAllSalesRepresentative();
	}, [])

	const getAllSalesRepresentative = () => {

		fetch(
			process.env.REACT_APP_API_URL + 'get_all_sales_representative',
			getRequestOptions()
		)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {

				let users = resp.map((item) => {
					item.label = item.firstName + ' ' + item.lastName;
					item.value = item.id;
					return item
				})
				setSalesRepresentatives(users);

			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})


	}

	const handleItemChange = (data) => {

		setIdUsers(data.id)

		fetch(
			process.env.REACT_APP_API_URL + 'user_projects?id_users=' + data.id,
			getRequestOptions()
		)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				setProjects(resp);
			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})


	}

	const [createItemModalShow, setCreateItemModalShow] = useState(false);
	const handleCreateItemModalShow = () => {
		setCreateItemModalShow(true);
	}

	const handleCreateItemModalClose = (isReload) => {
		setCreateItemModalShow(false);
		if (isReload) {
			setNewData({ id: Date.now() });
		}
	}

	const handleProjectAccessChange = (e, k) => {


		const postData = {
			value: e.target.checked,
			idProjects: e.target.value,
			idUsers: idUsers,
		};

		const requestOptions = postRequestOptions(postData);

		fetch(
			process.env.REACT_APP_API_URL + 'set_user_projects', requestOptions
		)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				const test = [...projects];
				test[k].id_project_users = resp.idProjectUsers;
				setProjects(test)

				Swal.fire({
					icon: 'success',
					title: "Done",
					text: resp.message,
				})
			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})

	}

	const columns = [
		{ value: 'id', title: 'Id', style: { textAlign: 'center' } },
		{
			value: 'roleName', title: 'Role', style: {}, hasHtmlComponent: true, htmlComponent: ((row) => {
				return (
					<>
						<Link to={`/sr-details/${row.id}`}>{row.roleName}</Link>
					</>
				)
			})
		},
		{
			value: 'firstName', title: 'Fist Name', style: {}, hasHtmlComponent: true, htmlComponent: ((row) => {
				return (
					<>
						<Link to={`/sr-details/${row.id}`}>{row.firstName}</Link>
					</>
				)
			})
		},
		{
			value: 'lastName', title: 'Last Name', style: {}, hasHtmlComponent: true, htmlComponent: ((row) => {
				return (
					<>
						<Link to={`/sr-details/${row.id}`}>{row.lastName}</Link>
					</>
				)
			})
		},
		{
			value: 'email', title: 'Email', style: {}, hasHtmlComponent: true, htmlComponent: ((row) => {
				return (
					<>
						<Link to={`/sr-details/${row.id}`}>{row.email}</Link>
					</>
				)
			})
		},
		{ value: 'phoneNumber', title: 'Phone Number', style: { textAlign: 'left' } },
		{
			value: 'status', title: 'Status', style: { textAlign: 'center' }, hasHtmlComponent: true, htmlComponent: ((row) => {
				return (
					<>
						{row.status === 'active' && <span className="badge badge-light-primary">Active</span>}
						{row.status === 'inactive' && <span className="badge badge-light-danger">Inactive</span>}
					</>
				)
			})
		},
		{
			value: 'profilePicture', title: 'Image', style: { textAlign: 'center' }, hasHtmlComponent: true, htmlComponent: ((row) => {
				return (
					<>
						{row.profilePicture !== null &&
							<img src={`${process.env.REACT_APP_AWS_URL}next-crm/profile_picture/${row.profilePicture}`} alt='user' style={{ width: '80px', height: '80px' }} />
						}
					</>
				)
			})
		},
		{
			value: 'action', title: 'Action', style: { textAlign: 'center' }, hasActionsComponent: true, actionsComponent: ((row) => {
				return (
					<>
						<button className="btn btn-light-primary btn-sm" onClick={() => onAction(row)}>
							<KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-1 svg-icon-3' />
							Edit
						</button>
					</>)

			})
		}
	]

	const filters = {
		id: {
			filterType: 'like',
			filterValue: '',
		},
		roleName: {
			filterType: 'like',
			filterValue: '',
		},
		firstName: {
			filterType: 'like',
			filterValue: '',
		},
		lastName: {
			filterType: 'like',
			filterValue: '',
		},
		email: {
			filterType: 'like',
			filterValue: '',
		},
		phoneNumber: {
			filterType: 'like',
			filterValue: '',
		},
		profilePicture: {
			filterType: 'like',
			filterValue: '',
		},
		status: {
			filterType: 'like',
			filterValue: '',
		}

	}

	const url = process.env.REACT_APP_API_URL + 'user_dt'

	const onAction = (data) => {
		setId(data.id);
		setCreateItemModalShow(true);
		// setEmail(data.firstName + ' ' + data.lastName);
	}

	return (
		<>


			<div className='card card-custom'>
				<div className="card-header">
					<div className="card-title">
						<KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-1 svg-icon-primary' />
						<span className='text fw-bolder' style={{ marginLeft: '10px' }}>
							User Management
						</span>
					</div>
					<div className="card-toolbar">

					</div>
				</div>
				<div className='card-body'>
					<Tabs
						defaultActiveKey="user-list"
						id="uncontrolled-tab-example"
						className="mb-3"
					>
						<Tab eventKey="user-list" title="User List">
							<div className='d-flex justify-content-end'>
								<button type="button" className="btn btn-sm btn-primary" onClick={() => handleCreateItemModalShow()}><i className="fa fa-plus"></i>Add User</button>
							</div>
							<DataTable url={url} columns={columns} filters={filters} newData={newData} />
						</Tab>
						<Tab eventKey="assign-project" title="Assign to Project">

							<div className='form-group row'>
								<label htmlFor='role' className='col-lg-3 col-form-label' style={{ textAlign: 'right' }}>
									Select User
								</label>
								<div className='col-lg-5'>
									<Select
										onChange={(selectedOption) => handleItemChange(selectedOption)}
										options={salesRepresentitives}
										className='sm'
										menuPosition="fixed"
										styles={{
											control: (provided, state) => ({
												...provided,
												height: '32px',
												minHeight: '32px',
											}),
											container: (provided, state) => ({
												...provided,
												height: '32px',
											}),
										}}
									/>
								</div>
							</div>

							{projects.length > 0 &&
								<div className='form-group row'>
									<div className='col-3'></div>
									<div className='col-5'>
										{/* <pre>{JSON.stringify(projects, null, 2)}</pre> */}
										<Table striped bordered hover size='sm'>
											<thead>
												<tr>
													<th className='text-start'>Project</th>
													<th className='text-center'>Access</th>
												</tr>
											</thead>
											<tbody>
												{projects.map((v, k) => (
													<tr key={k}>
														<td>{v.project_name}</td>
														<td className='text-center'>
															<input type="checkbox" value={v.id_projects} onChange={(e) => handleProjectAccessChange(e, k)} checked={v.id_project_users === null ? false : true} />
														</td>
													</tr>
												))}
												{projects.length === 0 &&
													<tr>
														<td colSpan={2}>No Project Found</td>
													</tr>
												}
											</tbody>
										</Table>
									</div>
								</div>
							}
						</Tab>
					</Tabs>

				</div>
			</div>


			<UserCreate show={createItemModalShow} handleClose={handleCreateItemModalClose} id={id} />

		</>
	)
}

export default Users
