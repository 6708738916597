import { useState, useEffect } from 'react'
import DataTable from '../components/DataTable';
import { KTSVG } from '../../_metronic/helpers'
import 'react-loading-skeleton/dist/skeleton.css';
import ShipmentCreate from '../components/ShipmentCreate';
import ShipmentDetails from '../components/ShipmentDetails';
import { getRequestOptions } from '../helpers/Fetchwrapper';
import Select from 'react-select';


function Shipment() {

    const [selectProjectOptions, setSelectProjectOptions] = useState([])
    const [project, setProject] = useState({ label: 'Select Project', value: '' });

    const handleProjectChange = (project: any) => {
        setProject(project);
    }

    useEffect(() => {
        getProjectList();
    }, [])

    const getProjectList = async () => {
        const url = process.env.REACT_APP_API_URL + 'projects';

        const response = await fetch(url, getRequestOptions());

        const data = await response.json();

        data.data.map((element: any) => {
            element.value = element.id_projects;
            element.label = element.project_name;
            return element;
        })

        if (data.data.length === 1) {
            setSelectProjectOptions(data.data)
        } else {
            var allProjectsOpt = [{ id: '', name: '', label: 'All Project', value: '' }]
            let allProjects = data.data.concat(allProjectsOpt);
            setSelectProjectOptions(allProjects);
        }
    }

    useEffect(() => {
        if (selectProjectOptions.length === 1) {
            setProject({ label: selectProjectOptions[0]['label'], value: selectProjectOptions[0]['value'] })
        }
    }, [selectProjectOptions])

    useEffect(() => {
        setAdditionalPostData({ idProjects: project.value })
    }, [project])

    const [additionalPostData, setAdditionalPostData] = useState({ idProjects: '' });
    const [createShipmentModalShow, setCreateShipmentModalShow] = useState(false);
    const [detailsShipmentModalShow, setDetailsShipmentModalShow] = useState(false);
    const [salesOrderInfo, setSalesOrderInfo] = useState({});
    const [shipmentId, setShipmentId] = useState('');
    const [newData, setNewData] = useState({});

    const handleCreateShipmentModalClose = (isReload: boolean) => {
        setCreateShipmentModalShow(false);
        if (isReload) {
            setNewData({ id: Date.now() });
        }
    }

    const handleDetailsShipmentModalClose = (isReload: boolean) => {
        setDetailsShipmentModalShow(false);
    }

    const handleShipmentModalShow = (salesOrderInfo: any) => {
        setSalesOrderInfo(salesOrderInfo);
        setCreateShipmentModalShow(true);
    }

    const handleShipmentDetailsModalShow = (shipment: any) => {
        setShipmentId(shipment.id);
        setDetailsShipmentModalShow(true);
    }

    const hasPermission = 1; //parseInt(GetPagePermission("/setup-company-list"));

    const columns = [
        { value: 'id', title: 'ID', style: { textAlign: 'center' } },
        { value: 'idSalesOrders', title: 'Sales Order', style: { textAlign: 'center' } },
        { value: 'projectName', title: 'Project', style: { textAlign: 'center' } },
        { value: 'companyName', title: 'Client', style: { textAlign: 'left' } },
        { value: 'leadName', title: 'Lead', style: { textAlign: 'left' } },
        { value: 'shipmentDate', title: 'Shipment Date', style: { textAlign: 'left' } },
        { value: 'chalanNo', title: 'Chalan No', style: { textAlign: 'left' } },
        { value: 'shippingAddress', title: 'Shipping Address', style: { textAlign: 'left' } },
        // { value: 'shipmentAmount', title: 'Shipment Amount', style: { textAlign: 'center' } },
        {
            value: 'shipmentAmount', title: 'Shipment Amount', style: { textAlign: 'center' }, hasActionsComponent: true, actionsComponent: ((row: any) => {
                return (
                    <>{numberFormat(row.shipmentAmount)}</>
                )
            })
        },
        { value: 'remarks', title: 'Remarks', style: { textAlign: 'left' } },
        { value: 'createdBy', title: 'Created By', style: { textAlign: 'left' } },
        {
            value: 'action', title: 'Action', style: { textAlign: 'left', whiteSpace: 'nowrap' }, hasActionsComponent: true, actionsComponent: ((row: any) => {
                return (
                    <>
                        {/* <Link className="btn btn-light-primary btn-sm" to={`shipment/${row.idShipments}`}>
                            <KTSVG
                                path='/media/svg/icons/Layout/Layout-grid.svg'
                                className='svg-icon-5 svg-icon'
                            />		Details
                        </Link> */}
                        <button className="btn btn-light-primary btn-sm" onClick={() => handleShipmentDetailsModalShow(row)}>
                            <KTSVG
                                path='/media/svg/icons/Layout/Layout-grid.svg'
                                className='svg-icon-5 svg-icon'
                            />		Details
                        </button>

                    </>
                )
            })
        }
    ]

    const filters = {
        id: {
            filterType: 'like',
            filterValue: '',
        },
        idSalesOrders: {
            filterType: 'like',
            filterValue: '',
        },
        projectName: {
            filterType: 'like',
            filterValue: '',
        },
        companyName: {
            filterType: 'like',
            filterValue: '',
        },
        leadName: {
            filterType: 'like',
            filterValue: '',
        },
        shipmentDate: {
            filterType: 'like',
            filterValue: '',
        },
        chalanNo: {
            filterType: 'like',
            filterValue: '',
        },
        shippingAddress: {
            filterType: 'like',
            filterValue: '',
        },
        shipmentAmount: {
            filterType: 'like',
            filterValue: '',
        },
        remarks: {
            filterType: 'like',
            filterValue: '',
        },
        createdBy: {
            filterType: 'like',
            filterValue: '',
        },
    }

    const url = process.env.REACT_APP_API_URL + 'shipment/dt'

    const numberFormat = (value: number) => {
        return parseFloat(value.toString()).toLocaleString('en-US', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }

    return (
        <>
            {hasPermission === 1 &&
                <>
                    <div className='card card-custom'>
                        <div className='card-header'>
                            <div className="card-title">
                                <KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-1 svg-icon-primary' />
                                <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
                                    Shipments
                                </span>
                            </div>
                            <div className="card-toolbar">
                                <div className='d-flex justify-content-end'>
                                    <Select
                                        // defaultValue={selectProjectOptions}
                                        onChange={handleProjectChange}
                                        options={selectProjectOptions}
                                        placeholder={project.label}
                                    />
                                    &nbsp;
                                    <button onClick={() => handleShipmentModalShow(null)} className="btn btn-sm btn-primary"><i className="fa fa-plus"></i>Create New Shipment</button>
                                </div>
                            </div>
                        </div>

                        <div className='card-body'>
                            <DataTable url={url} columns={columns} filters={filters} newData={newData} additionalPostData={additionalPostData} />
                        </div>
                    </div >

                    <ShipmentCreate show={createShipmentModalShow} handleClose={handleCreateShipmentModalClose} salesOrderInfo={salesOrderInfo} id={Date.now()} />
                    {shipmentId !== '' &&
                        <ShipmentDetails show={detailsShipmentModalShow} handleClose={handleDetailsShipmentModalClose} shipmentId={shipmentId} />
                    }

                </>
            }


            {/* {hasPermission === 0 &&
                <Error />
            }
            {hasPermission === 0 &&
                <div className='card card-custom' style={{ padding: "15px" }} >
                    <SkeletonTheme>
                        <p>
                            <Skeleton
                                containerClassName="avatar-skeleton"
                                count={40} ></Skeleton>
                        </p>
                    </SkeletonTheme>
                </div>
            } */}

        </>
    )
}

export default Shipment
