import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { LayoutSplashScreen } from '../../_metronic/layout/core'
import { getRequestOptions } from '../helpers/Fetchwrapper';
const jwt = () => { return JSON.parse(localStorage.getItem('MyToken')); }


export default function Logout() {
	useEffect(() => {
		logout();
	}, [])

	const logout = () => {

		const removeJwt = () => {
			localStorage.removeItem('MyToken');
		}

		fetch(process.env.REACT_APP_API_URL + "auth/logout", getRequestOptions())
			.then((resp) => resp.json())
			.then((resp) => {
				if (resp.success === true) {
					removeJwt();
					window.location.href = process.env.REACT_APP_URL;
					//window.location.reload();
				}
			})

	}

	return (
		<>
			{jwt ? <LayoutSplashScreen /> : <Redirect to={{ pathname: '/login' }} />}
		</>
	)
}



