/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import { Modal, Button } from 'react-bootstrap'
import { postRequestOptions, getRequestOptions } from '../helpers/Fetchwrapper'
import validationErrorMsg from '../helpers/ValidationErrorMsg'
import Spinner from 'react-bootstrap/Spinner'

interface UnitCreateProps {
	show: boolean;
	handleClose: any;
	id: number;
}

interface InitialState {
	idSalesInvoice: number;
	invoiceId: string;
	adjustmentAmount: number;
	invoiceAmount: number;
	maxAdjustableAmount: number;
}


const SalesInvoiceAdjustment: React.FC<UnitCreateProps> = ({ show, handleClose, id }) => {

	const initialState: InitialState = {
		idSalesInvoice: 0,
		invoiceId: '',
		adjustmentAmount: 0,
		invoiceAmount: 0,
		maxAdjustableAmount: 0,
	};

	const [adjustmentData, setAdjustmentData] = useState(initialState);


	useEffect(() => {

	}, [])

	useEffect(() => {

		fetch(
			process.env.REACT_APP_API_URL + 'sales-invoice/adjustment/' + id,
			getRequestOptions()
		)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				setAdjustmentData({ idSalesInvoice: id, invoiceId: resp.data.invoice_id, adjustmentAmount: 0, invoiceAmount: resp.data.invoice_amount, maxAdjustableAmount: resp.maxAdjustableAmount });
			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})

	}, [id]);


	const [isSubmitDisable, setSubmitDisable] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleChange = (e: any) => {
		if (e.target.value > adjustmentData.maxAdjustableAmount) {
			e.target.value = 0;
			Swal.fire({
				icon: 'error',
				title: 'Error',
				html: 'Maximum Adjustable Amount Exceeds!',
			})
		}
		setAdjustmentData({ ...adjustmentData, [e.target.name]: e.target.value });
	};

	const saveAdjustmentData = async (event: any) => {
		event.preventDefault()
		setSubmitDisable(true);
		setLoading(true);
		const confirm = await Swal.fire({
			title: 'Are you sure?',
			text: "You want to make this adjustment?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#ec008c',
			cancelButtonText: 'Cancel',
			confirmButtonText: 'Yes, Submit it!',
		})
		if (confirm.value) {
			const requestOptions = postRequestOptions(adjustmentData)
			try {

				let url = `${process.env.REACT_APP_API_URL}sales-invoice/make-adjustment`

				let response = await fetch(
					url,
					requestOptions
				)
				let respData = await response.json()
				if (respData.success === true) {
					const successMsg = respData.successMessage
					Swal.fire({
						icon: 'success',
						title: 'Success',
						text: successMsg,
					})
					handleClose(respData.success)
				} else {
					const errorsMessage = validationErrorMsg(respData.errors).join('<br>')
					Swal.fire({
						icon: 'error',
						title: 'Error',
						html: errorsMessage,
					})
				}
				setSubmitDisable(false);
				setLoading(false);
			} catch (error) {
				setLoading(false);
				console.log(error, 'catch the hoop')
				setSubmitDisable(false);
			}
		}
	}


	return (
		<Modal show={show} onHide={() => handleClose(false)} size="lg">
			<form onSubmit={saveAdjustmentData}>
				<Modal.Header closeButton>
					<Modal.Title>Sales Invoice Adjustment</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="form-group row">
						<label htmlFor="invoiceId" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Invoice ID <span className="required text-danger"></span></label>
						<div className="col-lg-5">
							<input type="text" readOnly name="invoiceId" id="invoiceId" value={adjustmentData.invoiceId} className="form-control form-control-sm" />
						</div>
					</div>
					<div className="form-group row">
						<label htmlFor="invoiceAmount" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Invoice Amount <span className="required text-danger"></span></label>
						<div className="col-lg-5">
							<input type="text" readOnly name="invoiceAmount" id="invoiceAmount" value={adjustmentData.invoiceAmount} className="form-control form-control-sm" />
						</div>
					</div>
					<div className="form-group row">
						<label htmlFor="maxAdjustableAmount" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Maximum Adjustbale Amount <span className="required text-danger"></span></label>
						<div className="col-lg-5">
							<input type="text" readOnly name="maxAdjustableAmount" id="maxAdjustableAmount" value={adjustmentData.maxAdjustableAmount} className="form-control form-control-sm" />
						</div>
					</div>
					<div className="form-group row">
						<label htmlFor="adjustmentAmount" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Adjustment Amount <span className="required text-danger"></span></label>
						<div className="col-lg-5">
							<input type="text" name="adjustmentAmount" id="adjustmentAmount" value={adjustmentData.adjustmentAmount} onChange={handleChange} className="form-control form-control-sm" />
						</div>
					</div>
					{/* <pre>{JSON.stringify(adjustmentData, null, 2)}</pre> */}
				</Modal.Body>
				<Modal.Footer>

					<button type="submit" disabled={isSubmitDisable || loading} className="btn btn-sm btn-primary me-2">
						{loading &&
							<Spinner
								as="span"
								animation="grow"
								size="sm"
								role="status"
								aria-hidden="true"
							/>
						}
						<span>  Submit</span>
					</button>
					<Button variant='secondary' size="sm" onClick={() => handleClose(false)}>
						Close
					</Button>
				</Modal.Footer>
			</form>
		</Modal>
	)
}

export default SalesInvoiceAdjustment
