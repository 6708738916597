
import { AppContext } from '../context/AppContext';
import { useContext } from 'react'
import DashboardSr from '../components/DashboardSr';
import DashboardUser from '../components/DashboardUser';
import DashboardAccountant from '../components/DashboardAccountant';



const Dashboard = () => {

	const { currentUser } = useContext(AppContext);
	// console.log(currentUser)

	return (
		<>
			{currentUser !== undefined ? ( // Check if currentUser exists
				currentUser.id_roles === 1 ? ( // Check for Senior Rep role
					<DashboardSr />
				) : currentUser.id_roles === 6 ? ( // Check for Accountant role
					<DashboardAccountant />
				) : (
					<DashboardUser /> // Default for other roles
				)
			) : (
				<h1 className='pt-10 text-center'>Loading...</h1>
			)}
		</>
	)
}

export default Dashboard
