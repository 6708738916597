function UserLogInfo(props) {

	return (
		<table className='table table-bordered table-hover'>
			<tbody>
				<tr>
					<th>IP</th>
					<td>{props.ip}</td>
				</tr>
				<tr>
					<th>User Agent</th>
					<td>{props.userAgent}</td>
				</tr>
			</tbody>
		</table>
	)
}

export default UserLogInfo