// import { useState } from 'react'
import DataTable from '../components/DataTable';
import { KTSVG } from '../../_metronic/helpers'
import 'react-loading-skeleton/dist/skeleton.css';
import { Link } from 'react-router-dom';

function ManufacturingList() {

    const hasPermission = 1; //parseInt(GetPagePermission("/setup-company-list"));
    // const [newData, setNewData] = useState({});

    const columns = [
        { value: 'id', title: 'Id', style: { textAlign: 'center' } },
        { value: 'projectName', title: 'Project', style: { textAlign: 'left' } },
        { value: 'productionDate', title: 'Production Date', style: { textAlign: 'left' } },
        { value: 'referenceNo', title: 'Reference No', style: { textAlign: 'left' } },
        { value: 'productionBy', title: 'Manufactured By', style: { textAlign: 'left' } },
        { value: 'productionAt', title: 'Manufactured At', style: { textAlign: 'left' } },
        {
            value: 'action', title: 'Action', style: { textAlign: 'left', whiteSpace: 'nowrap' }, hasActionsComponent: true, actionsComponent: ((row: any) => {
                return (
                    <>
                        <Link className="btn btn-light-primary btn-sm" to={`manufacturing-edit/${row.id}`}>
                            <KTSVG
                                path='/media/svg/icons/Communication/Write.svg'
                                className='svg-icon-5 svg-icon'
                            />		Edit
                        </Link>
                        <Link className="btn btn-light-primary btn-sm" to={`manufacturing-details/${row.id}`}>
                            <KTSVG
                                path='/media/svg/icons/Layout/Layout-grid.svg'
                                className='svg-icon-5 svg-icon'
                            />		Details
                        </Link>
                    </>
                )
            })
        }
    ]

    const filters = {
        id: {
            filterType: 'like',
            filterValue: '',
        },
        projectName: {
            filterType: 'like',
            filterValue: '',
        },
        productionDate: {
            filterType: 'like',
            filterValue: '',
        },
        referenceNo: {
            filterType: 'like',
            filterValue: '',
        },
        productionBy: {
            filterType: 'like',
            filterValue: '',
        },
        productionAt: {
            filterType: 'like',
            filterValue: '',
        },
    }

    const url = process.env.REACT_APP_API_URL + 'manufacturing/dt'

    return (
        <>
            {hasPermission === 1 &&
                <div className='card card-custom'>
                    <div className='card-header'>
                        <div className="card-title">
                            <KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-1 svg-icon-primary' />
                            <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
                                Manufacturing List
                            </span>
                        </div>
                        <div className="card-toolbar">
                            <div className='d-flex justify-content-end'>
                                <Link to="/manufacturing-create" className="btn btn-sm btn-primary"><i className="fa fa-plus"></i>Start New Manufacture</Link>
                            </div>
                        </div>
                    </div>

                    <div className='card-body'>
                        <DataTable url={url} columns={columns} filters={filters} />
                    </div>
                </div>
            }


            {/* {hasPermission === 0 &&
                <Error />
            }
            {hasPermission === 0 &&
                <div className='card card-custom' style={{ padding: "15px" }} >
                    <SkeletonTheme>
                        <p>
                            <Skeleton
                                containerClassName="avatar-skeleton"
                                count={40} ></Skeleton>
                        </p>
                    </SkeletonTheme>
                </div>
            } */}

        </>
    )
}

export default ManufacturingList
